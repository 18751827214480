import qs from 'query-string';
import React from 'react';
import {withRouter} from "react-router-dom";
import {Checkbox, Popup, Table} from 'semantic-ui-react';
import {
    WaybillStatusAnomalyOptions,
    WaybillStatusAvizationOptions,
    WaybillStatusOptions,
    WaybillTypeOptions
} from '../../../api/model/Waybill';
import O from '../../../components/Option';
import WbGridFilterList from './wbGridFilters/WbGridFilterList';
import WbGridFilterString from './wbGridFilters/WbGridFilterString';
import WbGridFilterStringContains from './wbGridFilters/WbGridFilterStringContains';
import iconClearFilters from './WbGridHeadersClearFiltersIcon.png';
import WbGridFilterDatePeriod from "./wbGridFilters/WbGridFilterDatePeriod";
import WbGridFilterListMulti from "./wbGridFilters/WbGridFilterListMulti";
import { useTranslation } from 'react-i18next';

function WbGridHeaders({
    headers,
    selectedIds,
    selectIds,
    getRowsIds,
    location,
    history
}) {

    const firstLoad = false;

    let query = qs.parse(location.search);

    function updateQuery(q) {
        query = q;
        history.push('?' + qs.stringify(query));
    }

    const handleSort = clickedColumn => () => {
        let { sortBy, sortDir } = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = 'ascending';
        } else {
            sortDir = sortDir === 'ascending' ? 'descending' : 'ascending';
        }

        updateQuery({ ...query, sortBy, sortDir });
    };

    function applyFilter(filter) {
        updateQuery({ ...query, ...filter });
        selectionExist && selectIds([]);

    }

    function removeFilter(key) {
        updateQuery({ ...query, [key]: undefined });
        selectionExist && selectIds([]);
    }

    function clearFilters() {
        updateQuery({});
        selectionExist && selectIds([]);
    }

    function getFilter(key) {

        if (firstLoad)
            return null;

        switch (key) {
            case 'fmid':
            case 'clientId':
            case 'clientName':
			case 'shipper':
            case 'refs':
            case 'torg12No':
            case 'consignee':
            case 'shipto':
            case 'slotId':
			case 'reservationId':
            case 'providerName':
            case 'reservationDriver':
                return <WbGridFilterStringContains
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
				/>;
			case 'dateCreated':
            case 'loadingDate':
            case 'deliveryDate':
            case 'unloadingDateDeparture':
                return <WbGridFilterDatePeriod
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter} />;
            case 'cost':
            case 'totalWeight':
            case 'totalUnits':
                return <WbGridFilterString
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter} />;
            case 'type':
                return <WbGridFilterList
                    field="type"
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillTypeOptions} />;
            case 'status':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillStatusOptions} />;
            case 'statusAnomaly':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillStatusAnomalyOptions} />;

           case 'avisationStatus':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillStatusAvizationOptions} />;
            case 'document':
                return <WbGridFilterList
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={[
                        new O(false, t('base_enum_no')),
                        new O(true, t('base_enum_there_is'))
                    ]} />;
            default:
                return <div
                    className="filter-flex-container"></div>;
        }
    }

    function deselect() {
        if (selectionExist) {
            selectIds([]);
        }
        else {
            selectIds(getRowsIds());
        }
    }

    const { sortBy, sortDir } = query;

    const selectionExist = selectedIds.length > 0;

    const clearFilterButton = (
        <span
            className="table-clear-filters"
            onClick={clearFilters}
            style={{ paddingTop: '5px', display: 'inline-block', width: '17px' }}>
            <img
                src={iconClearFilters}
                alt="icf"
                style={{ height: '13px' }} />
        </span>
    );

    const styleDisabledHeader = {
        background: '#f9fafb',
        cursor: 'default'
    };
    const { t } = useTranslation();

    const selectorDisabled = selectionExist
        ? false
        : !Object.keys(query).length;
    return (
        <Table celled fixed singleLine sortable className="table-header-table">
            <Table.Header>
                <Table.Row key="headers" style={{ fontSize: 'smaller' }}>
                    <Table.HeaderCell className="table-first-col table-select-all-cell table-item-sticky">
                        <Checkbox
                            checked={false}
                            //disabled={!selectionExist}
                            disabled={selectorDisabled}
                            indeterminate={selectionExist}
                            onChange={(e, data) => deselect()} />
                    </Table.HeaderCell>
                    {headers.map(({ text, key, sortable }) => (
                        <Table.HeaderCell
                            key={key}
                            sorted={sortable && sortBy === key ? sortDir : null}
                            className="table-header-cell"
                            style={sortable ? null : styleDisabledHeader}
                            onClick={sortable ? handleSort(key) : null}
                        >
                            <span>{t(text)}</span>
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
                <Table.Row key="filters" style={{height: "37px"}}>
                    <Table.Cell
                        style={{ backgroundColor: '#f9fafb', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                        <Popup content={t('base_btn_clear-all-filters')} trigger={clearFilterButton} />
                    </Table.Cell>
                    {headers.map(({ key, filter }) => (
                        <Table.Cell
                            key={key}
                            sorted={sortBy === key ? sortDir : null}
                            className="table-filter-cell"
                        >
                            {getFilter(key)}
                        </Table.Cell>
                    ))}
                </Table.Row>
            </Table.Header>
        </Table>
    );

}

export default withRouter(WbGridHeaders);
