import React from 'react';
import SelectCheckboxMulti from "../../../../components/inputs/SelectCheckboxMulti";
import {withRouter} from "react-router-dom";
import UrlFilter from "./models/UrlFilter";
import FilterInputClearButton from "./FilterInputClearButton";


class WbGridFilterListMulti extends React.Component {

    constructor(props){
        super(props);

        const urlFilter = new UrlFilter(this.props.location.search, this.props.field);

        this.state = {
            value: urlFilter !== '' ? urlFilter.value : ''
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.location.search !== this.props.location.search) {
            const urlFilter = new UrlFilter(this.props.location.search, this.props.field);

            this.setState({
                value: urlFilter !== '' ? urlFilter.value : ''
            })
        }
    }

    handleOptionClick(value) {
        this.setState({value})
    };

    handleClearClick() {
        this.props.removeFilter(this.props.field);
    };

    onCloseSelect() {
        const {value} = this.state;
        this.props.applyFilter({
            [this.props.field]: value
        });
    }
    render() {
        const {options, field} = this.props;
        const {value, isClose} = this.state;

        return(

            <div
                className="filter-flex-container"
                style={{backgroundColor: value ? '#ffface' : null}}>
                <div className="ui input filter-flex-center">
                    <SelectCheckboxMulti
                        value={value}
                        onChange={this.handleOptionClick.bind(this)}
                        options={options}
                        name={field}
                        onClose={this.onCloseSelect.bind(this)}
                    />
                </div>
                <div className={value ? "filter-flex-right filter-flex-right-active" : "filter-flex-right"}>
                    {value && <FilterInputClearButton
                        handleInputClearClick={this.handleClearClick.bind(this)}/>}
                </div>
            </div>
        )
    }
}

export default withRouter(WbGridFilterListMulti)
