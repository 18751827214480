import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

export default function NavbarPath(props) {
    
    const root = props.items[0];
    const rest = props.items.slice(1);
    const restLength = rest.length;

    const linkStyle = restLength === 0
        ? { color: 'black', pointerEvents: 'none' }
        : { color: '#4183c4' };

    const {t} = useTranslation();

    return (
        <Breadcrumb style={{ fontSize: '16px' }}>

            <Breadcrumb.Section
                as={Link}
                to={root.url || ''}
                style={linkStyle}
            >{t(root.name)}</Breadcrumb.Section>
            {rest.map((i, idx) => (
                <React.Fragment key={idx}>
                    <Breadcrumb.Divider icon='right chevron' />
                    <Breadcrumb.Section active={restLength === idx}>
                        {typeof i.name === 'object' ? t(i.name.key, {index: t(i.name.index)}) : t(i.name)}
                    </Breadcrumb.Section>
                </React.Fragment>
            ))}
        </Breadcrumb>
    );
} 