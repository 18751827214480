import React, {useContext, useEffect, useRef, useState} from 'react';
import {LinkInfo} from '../../layout/navbar/Navbar';
import {ContextNavi} from '../../services/context';
import {useTranslation} from 'react-i18next';
import {getEnums, getList} from '../../api/translate';
import {Dropdown, Form, Grid, Table} from 'semantic-ui-react';
import TranslateCard from "./translateCard";
import ScrollPreloader from "../../components/miscs/ScrollPreloader";
import qs from "query-string";
import {withRouter} from "react-router";
import WbGridFilterStringContains from "../waybills/wbGrid/wbGridFilters/WbGridFilterStringContains";
import Shield from "../../layout/page/shield/Shield";

const TranslateList = ({location, history}) => {
    let [headers, setHeaders] = useState([]);
    let [rows, setRows] = useState([]);
    let [sections, setSections] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    const page = useRef(0);
    const scrollContainer = useRef(null);

    const pageSize = 50;

    const [scrollLoading, setScrollLoading] = useState(false);

    const contextNavi = useContext(ContextNavi);

    const needScroll = rows.length >= pageSize && rows.length % pageSize === 0;

    const {t} = useTranslation();

    let query = qs.parse(location.search);
    const {sortBy, sortDir} = query;

    useEffect(() => {
        fetchData();
    }, [location.search]);

    useEffect(() => {
        getFiltersEnums();

        contextNavi.setItems([new LinkInfo('translate_label_link', '/translate')]);
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await getList(location.search);

            setRows(result.translations);

            setHeaders(result.languageKeys);
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    };

    const getFiltersEnums = async () => {
        const result = await getEnums();

        setSections(result);
    };

    const onBottomVisible = async () => {
        if (scrollLoading) {
            return;
        }
        const nextPage = page.current + 1;

        const filter = qs.parse(location.search);

        filter.page = nextPage;

        const filterString = qs.stringify(filter);

        setScrollLoading(true);
        const {translations} = await getList("?" + filterString);

        if (translations && translations.length) {

            const allRows = rows.concat(translations);

            setRows(allRows);

            page.current = nextPage;
        }
        setScrollLoading(false);
    };

    const scrollTop = () => {
        page.current = 0;
        scrollContainer.current.scrollTop = 0;
    };


    function updateQuery(q) {
        query = q;
        history.push('?' + qs.stringify(query));
    }

    function applyFilter(filter) {
        scrollTop();
        updateQuery({...query, ...filter});

    }

    function removeFilter(key) {
        scrollTop();
        updateQuery({...query, [key]: undefined});
    }

    const handleSort = clickedColumn => () => {
        let {sortBy, sortDir} = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = 'ascending';
        } else {
            sortDir = sortDir === 'ascending' ? 'descending' : 'ascending';
        }

        updateQuery({...query, sortBy, sortDir});
    };


    return (
        <Shield loading={loading} loadingOver={loaded}>
            <Form>
                <Grid style={{padding: "12px"}}>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Form.Field>
                                <label>{t('translate_enum_sections')}</label>
                                <Dropdown
                                    selection
                                    fluid
                                    options={sections.map(i => ({
                                        key: i.key,
                                        value: i.key,
                                        text: i.name,
                                    }))}
                                    value={query.parentSection}
                                    clearable
                                    onChange={(e, {value}) => applyFilter({
                                        parentSection: value,
                                        childSection: undefined
                                    })}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Field>
                                <label>{t('translate_enum_text-type')}</label>
                                <Dropdown
                                    selection
                                    fluid
                                    options={((sections.find(i => i.key === query.parentSection) || {}).sections || []).map(i => ({
                                        key: i.key,
                                        value: i.key,
                                        text: i.name,
                                    }))}
                                    value={query.childSection}
                                    clearable
                                    onChange={(e, {value}) => applyFilter({childSection: value})}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
            <div className="table-wrapper-grid">
                <div className="table-scroll-grid" ref={scrollContainer}>
                    <Table celled sortable>
                        <Table.Header className="table-header-fixed">
                            <Table.Row style={{fontSize: 'smaller', height: '46px'}}>
                                <Table.HeaderCell
                                    className="table-select-cell table-header-fixed__title"
                                    sorted={sortBy === 'key' ? sortDir : null}
                                    onClick={handleSort('key')}
                                >
                                    {t('translate_label_key')}
                                </Table.HeaderCell>
                                {
                                    headers.map(column => (
                                        <Table.HeaderCell
                                            className="table-select-cell table-header-fixed__title"
                                            key={column.value}
                                            sorted={sortBy === column.value ? sortDir : null}
                                            onClick={handleSort(column.value)}
                                        >
                                            {column.name}
                                        </Table.HeaderCell>
                                    ))
                                }
                            </Table.Row>
                            <Table.Row key="filters" style={{height: "37px"}}>
                                <Table.Cell
                                    className="table-filter-cell table-header-fixed__filter"
                                >
                                    <WbGridFilterStringContains
                                        field="key"
                                        applyFilter={applyFilter}
                                        removeFilter={removeFilter}
                                    />
                                </Table.Cell>
                                {
                                    headers.map(column => (
                                        <Table.Cell
                                            key={`filters_${column.value}`}
                                            className="table-filter-cell table-header-fixed__filter"
                                        >
                                            <WbGridFilterStringContains
                                                field={`filters.${column.value}`}
                                                applyFilter={applyFilter}
                                                removeFilter={removeFilter}
                                            />
                                        </Table.Cell>
                                    ))
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                rows.map(row => (
                                    <TranslateCard key={row.key} translateKey={row.key} columns={headers}
                                                   loadList={fetchData}>
                                        <Table.Row style={{ cursor: 'pointer' }}>
                                            <Table.Cell>{row.key}</Table.Cell>
                                            {
                                                headers.map(column => (
                                                    <Table.Cell>{(row.values.find(i => i.languageKey === column.value) || {}).value}</Table.Cell>
                                                ))
                                            }
                                        </Table.Row>
                                    </TranslateCard>
                                ))
                            }
                        </Table.Body>
                    </Table>
                    {needScroll && <ScrollPreloader onVisible={onBottomVisible} continuous={true}/>}
                </div>
            </div>
        </Shield>
    );
};

export default withRouter(TranslateList);
