import React, {useState, useEffect} from "react";
import {Button, Divider, Form, Header, Input, Message, Modal, TextArea} from "semantic-ui-react";
import InputDate from "../../components/inputs/InputDate";
import {createBanner, editBanner, getBanner} from "../../api/banners";
import {useTranslation} from 'react-i18next';

const CreateBannerModal = ({open, onClose, id, fetchData}) => {
    let [params, setParams] = useState({});
    let [loader, setLoader] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        id && handleGetBanner();
    }, [open]);

    const handleGetBanner = async () => {
        const params = await getBanner(id);
        setParams(params);
    };

    const handleClose = () => {
        setParams({});
        onClose();
    };

    const handleChange = (e, {name, value}) => {
        setParams(prevParams => ({
            ...prevParams,
            [name]: value
        }));
    };

    const handleCreate = async () => {
        setLoader(true);
        try {
            if (!id) {
                await createBanner(params);
            } else {
                await editBanner(params);
            }
            handleClose();
            fetchData();
        } finally {
            setLoader(false);
        }

    };

    return (
        <Modal open={open} closeOnDimmerClick={false} onClose={handleClose}>
            <Modal.Header>{t(id ? 'banners_label_edit' : 'banners_label_create')}</Modal.Header>
            <Modal.Content>
                <Message
                    icon="info"
                    info
                    content={
                        <div>
                            {t('banners_text_message-top')}
                            <br/>
                            <br/>
                            {t('banners_text_message-bottom')}
                        </div>
                    }
                />
                <Form className="banner-form">
                    <Form.Field>
                        <div className="banner-form_counter">
                            <label>{t('banners_input_topic')}</label>
                            <Input
                                value={params.title}
                                name="title"
                                placeholder={t('banners_placeholder_topic')}
                                maxLength={100}
                                onChange={handleChange}
                            />
                            <span>{`${params.title ? params.title.length : 0}/100`}</span>
                        </div>
                    </Form.Field>
                    <Form.Field>
                        <div className="banner-form_counter">
                            <label>{t('banners_input_text')}</label>
                            <TextArea
                                value={params.text}
                                name="text"
                                placeholder={t('banners_placeholder_text')}
                                maxLength={200}
                                onChange={handleChange}
                            />
                            <span>{`${params.text ? params.text.length : 0}/200`}</span>
                        </div>
                    </Form.Field>
                    <Form.Field>
                        <div className="banner-form_counter">
                            <label>{t('banners_input_text-btn')}</label>
                            <Input
                                value={params.buttonText}
                                name="buttonText"
                                maxLength={80}
                                placeholder={t('banners_placeholder_text-btn')}
                                onChange={handleChange}
                            />
                            <span>{`${
                                params.buttonText ? params.buttonText.length : 0
                            }/80`}</span>
                        </div>
                    </Form.Field>
                    <Form.Field>
                        <div className="banner-form_url">
                            <label>{t('banners_input_url')}</label>
                            <Input
                                fluid
                                value={params.url}
                                placeholder="http://example.com"
                                name="url"
                                onChange={handleChange}/>
                        </div>
                    </Form.Field>
                    <Header className="m-b-10">{t('banners_label_show-period')}</Header>
                    <Divider className="m-t-0"/>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>{t('banners_input_begin')}</label>
                            <InputDate
                                icon="calendar outline"
                                position="bottom center"
                                onChange={value =>
                                    handleChange(null, {name: "periodFrom", value})
                                }
                                value={params.periodFrom}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>{t('banners_input_inclusive-up-to')}</label>
                            <InputDate
                                icon="calendar outline"
                                position="bottom center"
                                onChange={value =>
                                    handleChange(null, {name: "periodTo", value})
                                }
                                value={params.periodTo}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t('base_btn_undo')}</Button>
                <Button primary loading={loader} disabled={loader}
                        onClick={handleCreate}>{t(id ? 'base_btn_save' : 'base_btn_create')}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateBannerModal;
