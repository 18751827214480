import {debounce} from 'awesome-debounce-promise';
import React from 'react';
import CompanyForm from '../CompanyForm';
import {companyUpdate} from '../../../../api/companies';
import {Grid, GridColumn} from "semantic-ui-react";

const saveData = debounce((fmid, co) => {
    return companyUpdate(fmid, co)
}, 1200);

export default function CompanyTabProps({data, setData}) {

    function updateCompany(companyChanged) {
        setData(companyChanged);
        saveData(companyChanged.fmid, companyChanged);
    }

    return (
        <Grid>
            <Grid.Row>
                <GridColumn width={11}>
                    <CompanyForm data={data} setData={updateCompany}/>
                </GridColumn>
            </Grid.Row>
        </Grid>
    );
}
