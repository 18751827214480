import React, { useEffect, useState } from 'react';
import {downloadFilePod} from "../../api/waybillDocs";
import downloadFile from "../../services/downloadFile";
import Shield from "../../layout/page/shield/Shield";

const Downloads = ({history, location}) => {
    let [loading, setLoading] = useState(false);

    const handleDownload = async(id) => {
        try {
            setLoading(true);

            const result = await downloadFilePod(id);


            if (result) {
                downloadFile(result);
            }
        } finally {
            setLoading(false);
            history.push('/waybills');
        }
    }


    useEffect(() => {
        const { search } = location;
        const [params, id] = search.split('=');

        handleDownload(id);
    }, []);

    return (
        <Shield loading={loading}>
            {''}
        </Shield>
    );
};

export default Downloads;
