import debounce from 'awesome-debounce-promise';
import moment from 'moment';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Divider, Dropdown, Grid, GridColumn, Icon, Segment} from 'semantic-ui-react';
import {getCompanyById, getCompanyCargoRestrictions, getCompanyFieldConfigs,} from '../../../api/companies';
import {PickupTypeEnum, WaybillFieldEnum} from '../../../api/model/Enums';
import UserPermissions from '../../../api/model/UserPermissions';
import Waybill, {WaybillStatusAvizationEnum, WaybillStatusEnum, WaybillTypeEnum,} from '../../../api/model/Waybill';
import {getMinCapacity} from '../../../api/tonnage';
import {getUserCompanyOptions} from '../../../api/users';
import {
    createWaybill,
    getAwisationIds,
    getHasToMirror,
    getWaybillById,
    getWaybillsInVisit,
    updateWaybill,
    updateWaybillClient,
    waybillResend,
    waybillResendTorg12,
    waybillValidationStatusUpdate
} from '../../../api/waybills';
import {getWaybillStatusTexts} from '../../../api/waybillsUtils';
import O from '../../../components/Option';
import {LinkInfo} from '../../../layout/navbar/Navbar';
import Spinner from '../../../layout/page/Spinner';
import {getToken} from '../../../services/auth';
import {ContextFieldConfigs, ContextFooter, ContextNavi, ContextUser, ContextWb,} from '../../../services/context';
import {toast} from '../../../services/toast';
import {showField} from '../../admin/companies/companyTabs/companyTabFieldConfig/companyTabUtils';
import WaybillStatusOrder from '../common/WaybillStatusOrder';
import WaybillStatusShipping from '../common/WaybillStatusShipping';
import WbProcessing from '../common/WbProcessing';
import './WbEdit.css';
import WbEditMain from './WbEditMain';
import WbEditPoints from './WbEditPoints';
import {PointServiceEnum, PointServiceOptions, PointServiceValueTypeEnum} from "../../../api/model/PointService";
import getIndexWbPoints from "../../../services/getIndexWbPoints";
import WbAvizModal from '../wbGrid/WbAvizModal';
import {LoadCapacityOptions} from '../../../api/model/Point';
import { useTranslation } from 'react-i18next';

const updateDataWithTimeout = debounce((dtoUpdated, fetchData) => updateWaybill(dtoUpdated, fetchData), 1000);

const MAX_WEIGHT = 22000;

export default function WbEditor({match, history, location, isClient}) {
    const { t } = useTranslation();

    const contextNavi = useContext(ContextNavi);
    const contextUser = useContext(ContextUser);
    const contextFooter = useContext(ContextFooter);
    const canEdit = contextUser.current.permissions.includes(UserPermissions.WAYBILL_EDIT);
    const avisationPermitted = !contextUser.current.permissions.includes(UserPermissions.DISALLOW_AVISATION);

    const [loading, setLoading] = useState(true);
    const [dto, setDto] = useState({});
    const [dtoError, setDtoError] = useState(null);
    const [cargoRestrictions, setCargoRestrictions] = useState([]);
    const [hasUnsaved, setHasUnsaved] = useState(false);
    const [fieldConfigs, setFieldConfigs] = useState([]);
    const [isOpenAvizModal, setIsOpenAvizModal] = useState(false);
    const [waybillsWithVisit, setWaybillsWithVisit] = useState([]);
    const lastSavePromise = useRef(null);
    let wbId = match.params.id;
    const isDraft = dto && dto.status === WaybillStatusEnum.DRAFT;
    const isWbHubMoscow =
        dto.pointsLoading && dto.pointsLoading.some(pl => pl.crossDockWithActivity || pl.avisationByEmail);
    const canChangePickupType = canEdit && !isDraft && isWbHubMoscow && !dto.pickupType;

    function removeIds(dto) {
        return {
            ...dto,
            pointsLoading: dto.pointsLoading.map(point => {
                return {
                    ...point,
                    loadUnitSets: point.loadUnitSets.map(set => {
                        const copy = { ...set };
                        delete copy.id;
                        return copy;
                    }),
                };
            }),
            pointsUnloading: dto.pointsUnloading.map(point => {
                return {
                    ...point,
                    loadUnitsToOrderMappings: point.loadUnitsToOrderMappings.map(set => {
                        const copy = { ...set };
                        delete copy.id;
                        return copy;
                    }),
                };
            }),
        };
    }

    async function save(pickupTypeInner) {
        setHasUnsaved(false);
        setLoading(true);
        try {
            if (isClient) {
                await updateWaybillClient(pickupTypeInner ? {
                    ...dto,
                    pickupType: pickupTypeInner,
                } : dto).catch(err => {
                    fetchData();
                    throw err;
                });
            } else {
                await updateWaybill(removeIds(dto)).catch(err => {
                    fetchData();
                    throw err;
                });
            }
        } finally {
            setLoading(false);
        }
    }

    async function fetchData() {
        const currentUserToken = getToken();

        let companyOptions = [];

        currentUserToken && (companyOptions = await getUserCompanyOptions(currentUserToken));

        let wb;
        try {
            wb = await getWaybillById(wbId, true);

            wb = getIndexWbPoints(wb);

        } catch (e) {
            toast.error(t('waybills_label_loading-error'));
            return;
        }
        if (!wb.clientId) {
            wb.clientId =
                (companyOptions && companyOptions.length) === 1 ? companyOptions[0].key : null;
        } else {
            if (!companyOptions || !companyOptions.find(co => co.key === wb.clientId)) {
                const cmp = await getCompanyById(wb.clientId);
                companyOptions = [new O(cmp.fmid, cmp.name)].concat(companyOptions).sort((a, b) => {
                    if (a.key === wb.clientId) {
                        return -1;
                    }
                    return 1;
                });
            }
        }

        wb.__optionsCompany = companyOptions;
        wb.__canEdit = wb.status !== WaybillStatusEnum.DRAFT && canEdit;

        const companyId = wb && wb.clientId;
        const configs = await getCompanyFieldConfigs(companyId);
        // wb can be invalid by some error - need to reinvalidate
        let error = getValidationError(wb, configs);
        if (error) {
            setDtoError(error);
            wb.isValid = false;
        } else {
            if (!wb.isValid) {
                wb.isValid = true;
                waybillValidationStatusUpdate(wb.id, true);
            }
        }
        delete wb['providerName'];
        setDto(wb);

        setLoading(false);

        if (contextNavi.setItems) {
            const wbLinkInfo = wb.__canEdit
                ? new LinkInfo({key: "waybills_label_application", index: wb.fmid || wb.id}, '/')
                : new LinkInfo('waybills_label_draft', '/');

            contextNavi.setItems([new LinkInfo('waybills_label_my-applications', '/waybills'), wbLinkInfo]);
        }
    }

    async function goToNew() {

        let companyOptions = await getUserCompanyOptions();
        await updateWaybill({ ...dto });

        const wb = new Waybill();

        wb.clientId = companyOptions && companyOptions.length === 1 ? companyOptions[0].key : null;

        wb.__optionsCompany = companyOptions;

        const newDto = await createWaybill(wb);

        history.push(`/waybills/${newDto.id}/edit`);

        match.params.id = newDto.id;

        wbId = newDto.id;

        await fetchData();
    }

    function positionsCountMatch(wb) {
        let usedIndexes = [];

        const loadingSetsAll = (wb.pointsLoading || []).reduce(
            (res, pl) => res.concat(pl.loadUnitSets || []),
            [],
        );

        const totalUnitCount = loadingSetsAll.reduce((res, lus) => res + lus.loadUnitsCount, 0);

        const positions = (wb.pointsUnloading || []).reduce(
            (arr, pu) => arr.concat(pu.loadUnitsToOrderMappings || []),
            [],
        );

        positions.forEach(p => {
            let posStart = p.loadUnitPositionStart || 0;
            let posEnd = p.loadUnitPositionEnd || posStart;

            if (posStart) {
                for (let index = posStart; index <= posEnd; index++) {
                    if (!usedIndexes.includes(index)) {
                        usedIndexes.push(index);
                    }
                }
            }
        });

        return usedIndexes.length === totalUnitCount;
    }

    function checkTime(str) {
        if (!str) return true;
        const result = new moment(str, 'HH:mm', true).isValid();
        return result;
    }

    function getValidationError(dto, configs) {
        const empty = field => `${t('waybills_label_field-not-filled-error')} "${field}"`;

        if (!dto.clientId) return empty(t('waybills_label_client'));

        if (!dto.cargoType && showField(WaybillFieldEnum.CargoType, dto, configs))
            return empty(t('waybills_label_cargo-type'));

        if (
            (dto.shippingType !== WaybillTypeEnum.Rail &&  dto.shippingType !== WaybillTypeEnum.UrbanDelivery) &&
            !dto.shippingTemperatureCondition &&
            showField(WaybillFieldEnum.ShippingTempCondition, dto, configs)
        )
            return empty(t('waybills_label_temp-condition'));

        if (dto.shippingType === WaybillTypeEnum.FTL && dto.shippingTemperatureCondition === 60 && (!dto.temperatureCondition || !dto.temperatureCondition.from || ! dto.temperatureCondition.to)) {
            return empty(t('waybills_label_temp-condition-client'));
        }

        if ((dto.shippingType === WaybillTypeEnum.Rail || dto.shippingType === WaybillTypeEnum.UrbanDelivery) && !dto.carType && showField(WaybillFieldEnum.CarType, dto, configs))
            return empty(t('waybills_label_type-ts'));

        if (!dto.cargoCost)
            return empty(t('waybills_label_sum'));

        if (
            !(
                dto.pointsLoading &&
                dto.pointsLoading.length &&
                (dto.pointsUnloading && dto.pointsUnloading.length)
            )
        )
            return t('waybills_label_no-points');

        if (
            !dto.pointsLoading.every(
                pl => pl.arrivalDatePlan && pl.arrivalTimeslotPlan && pl.arrivalTimeslotPlan.from,
            )
        )
            return t('waybills_label_no-date-info');

        if (
            !dto.pointsLoading.every(
                pl =>
                    pl.arrivalTimeslotPlan &&
                    checkTime(pl.arrivalTimeslotPlan.from) &&
                    checkTime(pl.arrivalTimeslotPlan.to),
            )
        )
            return t('waybills_label_incorrect-time-format-loading');

        if (!dto.pointsLoading.every(pl => pl.arrivalTimeslotPlan && pl.arrivalTimeslotPlan.from !== '00:00' && pl.arrivalTimeslotPlan.to !== '00:00'))
            return t('waybills_label_incorrect-time-0-00-loading');

        if (!dto.pointsLoading.every(pl => !dto.pointsUnloading.every(pu => pl.arrivalTimeslotPlan && pu.arrivalTimeslotPlan && pl.arrivalDatePlan === pu.arrivalDatePlan && pl.arrivalTimeslotPlan.from === pu.arrivalTimeslotPlan.from)))
            return t('waybills_label_incorrect-time-coincidence');
        if (
            !dto.pointsLoading.filter(i => i.pointType === 2).length &&
            !dto.pointsLoading.every(
                pl =>
                    pl.arrivalTimeslotPlan &&
                    pl.arrivalTimeslotPlan.from &&
                    pl.arrivalTimeslotPlan.to >=
                        pl.arrivalTimeslotPlan.from /*|| !pl.arrivalTimeslotPlan.to*/,
            )
        )
            return t('waybills_label_incorrect-time-loading');

        if (
            !dto.pointsUnloading.filter(i => i.pointType === 2).length &&
            !dto.pointsUnloading.every(
                pl =>
                    pl.arrivalTimeslotPlan &&
                    pl.arrivalTimeslotPlan.from &&
                    pl.arrivalTimeslotPlan.to >=
                        pl.arrivalTimeslotPlan.from /*|| !pl.arrivalTimeslotPlan.to*/,
            )
        )
            return t('waybills_label_incorrect-time-unloading');

        if (!dto.pointsLoading.every(pl => pl.loadUnitSets && pl.loadUnitSets.length))
            return t('waybills_label_no-info-points');

        if (
            dto.pointsLoading.filter(
                pl =>
                    pl.services.length > 0 &&
                    pl.services.some(i => {
                        const service = PointServiceOptions.find(s => s.key === i.key) || {};

                        return service.valueType !== PointServiceValueTypeEnum.NONE && !i.value;
                    })
            ).length
        )
            return t('waybills_label_no-value-add-services');

        if (
            !dto.pointsUnloading.every(
                pl => pl.arrivalDatePlan && pl.arrivalTimeslotPlan && pl.arrivalTimeslotPlan.from,
            )
        )
            return t('waybills_label_no-info-dates');

        if (
            !dto.pointsUnloading.every(
                pl =>
                    pl.arrivalTimeslotPlan &&
                    checkTime(pl.arrivalTimeslotPlan.from) &&
                    checkTime(pl.arrivalTimeslotPlan.to),
            )
        )
            return t('waybills_label_incorrect-time-format-unloading');

        if (!dto.pointsUnloading.every(pl => pl.arrivalTimeslotPlan && pl.arrivalTimeslotPlan.from !== '00:00' && pl.arrivalTimeslotPlan.to !== '00:00'))
            return t('waybills_label_incorrect-time-0-00-unloading');

        if (!dto.pointsUnloading.every(pu => pu.loadUnitsToOrderMappings && pu.loadUnitsToOrderMappings.length)) {
            return t('waybills_label_no-info-order-num-unloading');
        }
        if (
            !dto.pointsUnloading.every(
                pu => pu.loadUnitsToOrderMappings && pu.loadUnitsToOrderMappings.length,
            )
        ) {
            return t('waybills_label_no-info-order-num-unloading');
        }


        if (!positionsCountMatch(dto))
            return t('waybills_label_positions-not-match');

        if (!isValidDataAndTime(dto))
            return t('waybills_label_incorrect-date-time');

        if (!isValidWeight(dto))
            return t('waybills_label_max-weight-exceeded');

        return null;
    }

    function update(prop) {
        let dtoUpdated = { ...dto, ...prop };
        delete dtoUpdated['providerName'];
        setDto(prevState => {
            return dtoUpdated;
        });
    }

    async function sendToFm(pickupTypeInner) {
        try {
            if (hasUnsaved) {
                await save(pickupTypeInner);
                setHasUnsaved(false);
            }

            setLoading(true);
            try {
                if (location && location.state && location.state.isEditTorg12) {
                    await waybillResendTorg12(dto.id);
                } else {
                    await waybillResend(dto.id);
                    if (dto.reservationId && dto.avisationStatus !== WaybillStatusAvizationEnum.REQUIRES && avisationPermitted) {
                        const param = await getAwisationIds(dto.reservationId);
                        if (param && param.length > 0) {
                            const res = await getWaybillsInVisit(param);
                            setWaybillsWithVisit(res);
                            setIsOpenAvizModal(true);
                        }
                    } else {
                        history.push(`/waybills`);
                    }
                }
            } finally {
                setLoading(false);
            }
        } catch (e) {
            return;
        }

    }

    function setLoadPonitMax(wb, value) {
        const point = wb.pointsLoading[0];
        const set = point.loadUnitSets[0];

        return {
            ...wb,
            pointsLoading: [
                {
                    ...point,
                    loadUnitSets: [
                        {
                            ...set,
                            loadUnitsCount: value,
                        },
                    ],
                },
            ],
        };
    }

    function getLoadPointMax(wb) {
        const point = wb.pointsLoading[0];
        const set = point.loadUnitSets[0];
        return set.loadUnitsCount;
    }

    function setUnloadPonitMax(wb, value) {
        const point = wb.pointsUnloading[0];
        const set = point.loadUnitsToOrderMappings[0];

        return {
            ...wb,
            pointsUnloading: [
                {
                    ...point,
                    loadUnitsToOrderMappings: [
                        {
                            ...set,
                            loadUnitPositionEnd: value,
                        },
                    ],
                },
            ],
        };
    }

    function getUnloadPonitMax(wb) {
        const point = wb.pointsUnloading[0];
        const set = point.loadUnitsToOrderMappings[0];
        return set.loadUnitPositionEnd;
    }

    function hasNewPositions(dto1, dto2) {
        if (
            dto1.pointsLoading.length !== dto2.pointsLoading.length ||
            dto1.pointsUnloading.length !== dto2.pointsUnloading.length
        ) {
            return false;
        }
        const dto1Load = dto1.pointsLoading[0];
        const dto2Load = dto2.pointsLoading[0];
        const dto1Unload = dto1.pointsUnloading[0];
        const dto2Unload = dto2.pointsUnloading[0];
        if (!dto1Load || !dto2Load || !dto1Unload || !dto2Unload) {
            return false;
        }

        return (
            dto1Load.loadUnitSets.length !== dto2Load.loadUnitSets.length ||
            dto1Unload.loadUnitsToOrderMappings.length !==
                dto2Unload.loadUnitsToOrderMappings.length
        );
    }

    function getIsWaybillDraftAndAvizationRequires() {
        return (
            dto &&
            dto.status === WaybillStatusEnum.DRAFT &&
            dto.avisationStatus === WaybillStatusAvizationEnum.REQUIRES
        );
    }

    function indicator() {
        if (!dto) return null;

        const iconName = dto.isValid ? 'checkmark' : 'exclamation triangle';
        const iconColor = dto.isValid ? 'green' : 'orange';
        let text = dto.isValid ? t('waybills_label_application-ready') : '';
        /*if (getIsWaybillDraftAndAvizationRequires() && dto.isValid) {
            text = 'Можно изменить способ доставки и отправить'.t;
        }*/
        const error = getValidationError(dto, fieldConfigs);
        const errors = dto.isValid ? '' : error;
        const style = { fontSize: '13px' };

        return (
            <div>
                <Icon name={iconName} color={iconColor} style={{ marginBottom: '1px' }} />
                <span style={style}>
                    {text} {errors}
                </span>
            </div>
        );
    }

    function findIndexPointAfterAndBeforeTimeSlot(data, dto) {
        const pointsAll = dto.pointsLoading.concat(dto.pointsUnloading);

        const findPointsAfter = pointsAll.filter(i => data.arrivalDatePlan !== null && i.arrivalDatePlan === data.arrivalDatePlan && i.positionNumber > data.positionNumber);

        const findPointsBefore = pointsAll.filter(i => data.arrivalDatePlan !== null && i.arrivalDatePlan === data.arrivalDatePlan && i.positionNumber < data.positionNumber);

        const findIndexPointAfter = findPointsAfter.find(
            i =>
                (data.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan.from < data.arrivalTimeslotPlan.from) ||
                (data.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan.from === data.arrivalTimeslotPlan.from),
        );
        const findIndexPointBefore = findPointsBefore.find(
            i =>
                (data.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan.from > data.arrivalTimeslotPlan.from) ||
                (data.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan.from === data.arrivalTimeslotPlan.from),
        );
        return !!findIndexPointAfter || !!findIndexPointBefore;
    }

    function isValidDataAndTime(data) {
        const pointsAll = data ? data.pointsLoading.map(item => ({...item, isLoading: true})).concat(data.pointsUnloading) : [];

        if (data.shippingType === WaybillTypeEnum.FTL || data.shippingType === WaybillTypeEnum.PlanReturn) {
            const findIndexPointBefore = pointsAll.find(i => i.positionNumber === 1 && !i.isLoading && i.arrivalTimeslotPlan);

            return !(findIndexPointBefore && pointsAll.find(i => i.isLoading && i.arrivalTimeslotPlan))
        }

        const findIndexList = pointsAll.map(i => findIndexPointAfterAndBeforeTimeSlot(i, data));
        return !findIndexList.includes(true);
    }

    function isValidWeight(data) {
        const pointsAll = data ? data.pointsLoading : [];
        let weight = 0;
        pointsAll.map(point => {
            point.loadUnitSets.map(unit => weight+=unit.loadTotalWeigthBruttoKg)
        });
        return weight <= MAX_WEIGHT;
    }

    function isServices() {
        const pointsAll = dto ? dto.pointsLoading.concat(dto.pointsUnloading) : [];
        const findIndexList = pointsAll.map(i => findIndexPointAfterAndBeforeTimeSlot(i));
        return !findIndexList.includes(true);
    }

    function updateData(prop, options = {}) {
        const {loadingsChanged, skipUpdate, isUpdatePickupType} = options;
        let dtoUpdated = getIndexWbPoints({...dto, ...prop});

        if (isUpdatePickupType) {
            dtoUpdated = {
                ...dtoUpdated,
                pickupType: PickupTypeEnum.NOT_SPECIFIED,
            }
        }

        const hasToMirror = getHasToMirror(dtoUpdated) && !hasNewPositions(dto, dtoUpdated);

        if (hasToMirror) {
            if (options.unloadingsChanged) {
                const newMax = getUnloadPonitMax(dtoUpdated);
                const prevMax = getLoadPointMax(dtoUpdated);
                if (newMax > prevMax) {
                    dtoUpdated = setLoadPonitMax(dtoUpdated, newMax);
                }
            } else {
                const newMax = getLoadPointMax(dtoUpdated);
                const prevMax = getUnloadPonitMax(dtoUpdated);
                if (newMax > prevMax) {
                    dtoUpdated = setUnloadPonitMax(dtoUpdated, newMax);
                }
            }
        }

        if (loadingsChanged) {
            adjustLoadCapacity(dtoUpdated);
        }
        delete dtoUpdated['providerName'];
        if (skipUpdate) {
            setDto(dtoUpdated);
        } else {
            const verror = getValidationError(dtoUpdated, fieldConfigs);

            dtoUpdated.isValid = !verror;
            setDto(dtoUpdated);

            setDtoError(verror);

            if (isDraft) {
                if (isValidDataAndTime(dtoUpdated)) {
                    lastSavePromise.current = updateDataWithTimeout(removeIds(dtoUpdated), fetchData);
                }
            }
        }
        const errorFormWaybill = getValidationError(dtoUpdated, fieldConfigs);
        const hasUnSave = errorFormWaybill === null ? true : false;
        setHasUnsaved(hasUnSave);
    }

    function adjustLoadCapacity(wb, restrictions = cargoRestrictions) {
        if (wb.shippingType === WaybillTypeEnum.FTL || wb.shippingType === WaybillTypeEnum.PlanReturn) {
            const currentCapacity = wb.vehicleCapacity || 0;

            const minRequiredCapacity = getMinCapacity(wb, restrictions);

            wb.vehicleCapacity = minRequiredCapacity;
        }
    }

    function onCloseAvizModal() {
        setIsOpenAvizModal(false);
    }

    async function loadClientRestrictions() {
        const companyId = dto && dto.clientId;
        if (!companyId) {
            return;
        }
        const configs = await getCompanyFieldConfigs(companyId);
        const restrictions = await getCompanyCargoRestrictions(companyId);
        /*if (cargoRestrictions.length) {
            adjustLoadCapacity(dto, restrictions);

            const error = getValidationError(dto, configs);
            dto.isValid = !error;
            setDtoError(error);
            setDto(dto);
            lastSavePromise.current = updateDataWithTimeout(removeIds(dto));
        }*/
        setFieldConfigs(configs);
        setCargoRestrictions(restrictions);
    }

    useEffect(() => {
        loadClientRestrictions();
    }, [dto && dto.clientId]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        contextFooter.setIndicator(indicator);
        return () => {
            contextFooter.setIndicator(null);
        };
    }, [dto]);
console.log()

    if (loading && (!dto || !dto.id)) {
        return <Spinner show />;
    } else {
        const sendable = [
            WaybillStatusEnum.SUBMITTED,
            WaybillStatusEnum.ON_APPROVAL,
            WaybillStatusEnum.CARGO_DELIVERED,
            WaybillStatusEnum.EXECUTING,
            WaybillStatusEnum.CARGO_DELIVERED
        ].includes(dto.status);

        const actionMenu = (
            <Dropdown
                icon="caret down"
                floating
                button
                className="icon button-complete-wiz"
                disabled={dto.__canEdit}
            >
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => goToNew()}>
                        <Icon name="redo" />
                        {t('waybills_btn_new')}
                    </Dropdown.Item>
                    {/* <Dropdown.Item><Icon name="trash" />Удалить черновик</Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>
        );

        const saveMenu = () => {
            let menu = [];

            if (
                dto.avisationStatus !== WaybillStatusAvizationEnum.CONFIRMED &&
                dto.avisationStatus !== WaybillStatusAvizationEnum.REQUIRES &&
                !(
                    dto.avisationStatus === WaybillStatusAvizationEnum.FILL_DRIVER &&
                    dto.status > WaybillStatusEnum.DRAFT
                )
            ) {
                menu.push(
                    <Dropdown.Item disabled={!hasUnsaved} onClick={save}>
                        <Icon name="save" />
                        {t('base_btn_save')}
                    </Dropdown.Item>,
                );
            }
            return menu;
        };

        /*const saveMenu = (
            <Dropdown
                icon="caret down"
                floating
                button
                className="icon button-complete-wiz"
                disabled={!sendable}
            >
                <Dropdown.Menu>
                    {/!*  <Dropdown.Item onClick={sendToFm} disabled={!dto.isValid || !sendable}>
                        <Icon name="upload"/>
                        <T>Отправить в FM</T>
                    </Dropdown.Item> *!/}
                    {dto.avisationStatus !== WaybillStatusAvizationEnum.CONFIRMED &&
                    dto.avisationStatus !== WaybillStatusAvizationEnum.REQUIRES &&
                    !(
                        dto.avisationStatus === WaybillStatusAvizationEnum.FILL_DRIVER &&
                        dto.status > WaybillStatusEnum.DRAFT
                    ) ? (
                        <Dropdown.Item disabled={!hasUnsaved} onClick={save}>
                            <Icon name="save" />
                            <T>Сохранить</T>
                        </Dropdown.Item>
                    ) : null}
                </Dropdown.Menu>
            </Dropdown>
        );
*/
        let buttonSend;

        const buttonChangeShippingMethod = (
            <Button.Group color="green">
                <WbProcessing dto={dto} lastSavePromise={lastSavePromise.current}>
                    <Button primary disabled={!dto.isValid}>
                        {t('waybills_btn_change-delivery-method')}
                    </Button>
                </WbProcessing>
                {actionMenu}
            </Button.Group>
        );
        if (dto.__canEdit || isClient) {
            buttonSend = (
                isDraft ?

                    <Button color='blue' disabled={!dto.isValid || !sendable} onClick={sendToFm}>
                        <Icon name="upload"/>
                        {t('waybills_btn_save-send-to-fm')}
                    </Button> :

                    <Button.Group color="green">
                        {
                            canChangePickupType ?
                                <WbProcessing dto={dto} sendToFm={sendToFm}>
                                    <Button> {t('waybills_btn_save-send-to-fm')} </Button>
                                </WbProcessing>
                                : <Button primary onClick={sendToFm} disabled={!dto.isValid || !sendable}>
                                    {t('waybills_btn_save-send-to-fm')}
                                </Button>
                        }
                        {dto.avisationStatus !== WaybillStatusAvizationEnum.CONFIRMED &&
                        dto.avisationStatus !== WaybillStatusAvizationEnum.REQUIRES &&
                        saveMenu().length ? (
                            <Dropdown
                                icon="caret down"
                                floating
                                button
                                className="icon button-complete-wiz"
                                disabled={!sendable}
                            >
                                <Dropdown.Menu>{saveMenu().map(item => item)}</Dropdown.Menu>
                            </Dropdown>
                        ) : null}
                    </Button.Group>
            );
        } else {
            buttonSend = (
                <Button.Group color="green">
                    <WbProcessing dto={dto} lastSavePromise={lastSavePromise.current}>
                        <Button primary disabled={!dto.isValid}>
                            {t('waybills_btn_for-execution')}
                        </Button>
                    </WbProcessing>
                    {actionMenu}
                </Button.Group>
            );
        }

        const wbStatus = getWaybillStatusTexts(dto);

        const headerGrid = (
            <Grid verticalAlign="middle" columns={3} style={{ minHeight: '87px' }}>
                <GridColumn>
                    <div className="display-flex">
                        <WaybillStatusOrder statusOrder={wbStatus.statusOrder} />
                        <WaybillStatusShipping
                            statusShipping={wbStatus.statusShipping}
                            className="m-l-10"
                        />
                    </div>
                </GridColumn>
                <GridColumn textAlign="center">
                    <h3 className="m-0 p-0">
                        {t('waybills_grid_addresses-and-cargo')}
                    </h3>
                </GridColumn>
                <GridColumn textAlign="right">
                    {buttonSend}
                </GridColumn>
            </Grid>
        );
        return (
            <ContextWb.Provider
                value={{
                    wb: dto,
                    update: update,
                    save: updateData,
                }}
            >
                <ContextFieldConfigs.Provider value={fieldConfigs}>
                    <Grid className="p-t-0 m-b-0" style={{ marginTop: '-1px' }}>
                        <Grid.Row stretched className="p-t-0 p-b-0">
                            <Grid.Column className="p-t-0" style={{width: "18%"}}>
                                <Segment className="step-wrapper" style={{
                                    paddingTop: '34px',
                                    paddingRight: '20px',
                                    paddingLeft: '20px',
                                    paddingBottom: '20px',
                                    zIndex: "1",
                                    height: "calc(100vh - 87px)",
                                    overflowX: "auto"
                                }}>
                                    <WbEditMain
                                        dto={dto}
                                        isClient={isClient}
                                        updateData={updateData}
                                        cargoRestrictions={cargoRestrictions}
                                        fieldConfigs={fieldConfigs}
                                    />
                                </Segment>
                            </Grid.Column>
                            <Grid.Column
                                className="editor-rightcol"
                                style={{ marginLeft: '-15px', width: '82%' }}
                            >
                                <Segment style={{ paddingTop: '13px', paddingLeft: '18px' }}>
                                    {headerGrid}
                                    <Divider className="m-t-10" />
                                    <div
                                        style={{
                                            paddingTop: '5px',
                                            overflowY: 'hidden',
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        <WbEditPoints
                                            isClient={isClient}
                                            dto={dto}
                                            updateData={updateData}
                                        />
                                    </div>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {loading && dto && dto.id && <Spinner show />}
                    <WbAvizModal
                        open={isOpenAvizModal}
                        onClose={onCloseAvizModal}
                        selectedRows={waybillsWithVisit}
                        optionsTypeTransport={LoadCapacityOptions}
                        onAvisation={() => {
                            onCloseAvizModal();
                            setWaybillsWithVisit([]);
                            history.push(`/waybills/`);
                        }}
                    />
                </ContextFieldConfigs.Provider>
            </ContextWb.Provider>
        );
    }
}
