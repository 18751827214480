import React from 'react';
import {Button, Message, Modal} from "semantic-ui-react";
import { useTranslation } from 'react-i18next';

const RgSendModal = ({open, onClose, onSend}) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
        >
            <Modal.Header>{t('registers_label_sending-to-fm')}</Modal.Header>
            <Modal.Content>
                <Message
                    icon="info"
                    info
                    content={
                        <>
                            <div>{t('registers_text_sending-to-fm-message-top')}</div>
                            <div>{t('registers_text_sending-to-fm-message-center')}</div>
                            <div>{t('registers_text_sending-to-fm-message-bottom')}</div>
                        </>
                    }
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>
                    {t('base_btn_cancel')}
                </Button>
                <Button
                    color="green"
                    onClick={onSend}
                >
                    {t('registers_btn_send-to-fm')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
};

export default RgSendModal;
