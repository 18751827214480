import React, {useState, useMemo} from 'react';
import {Button, Divider, Form, Modal} from 'semantic-ui-react';
import {RequestTypeOptions} from '../../../api/model/Enums';
import {getHasToMirror, getMaxIndex} from '../../../api/waybills';
import InputDropdown from '../../../components/inputs/InputDropdown';
import {toast} from '../../../services/toast';
import {formatNumber, getFloatOrString, getValuePassZero, restrictionFloat} from "../../../services/utils";
import { useTranslation } from 'react-i18next';

function getPrevMaxPosition(points) {
    let prevMaxPosition = 0;

    points.forEach(p => {
        p.loadUnitsToOrderMappings.forEach(pm => {
            if (pm.loadUnitPositionEnd > prevMaxPosition) {
                prevMaxPosition = pm.loadUnitPositionEnd;
            }
        });
    });

    return prevMaxPosition;
};

export default function WbPointCardTabsPositionsUnloadForm({
                                                               header, submit, value = {}, unitsLoadedTotal, wb, points, pointId, isAdding, children, isClient, disabled
                                                           }) {
    const { t } = useTranslation();

    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [canSave, setCanSave] = useState(true);

    const loadUnitSets = useMemo(() => wb.pointsLoading || []).reduce((val, p) => val.concat(p.loadUnitSets), [], [wb]);

    const totalWeightLoadUnit = useMemo(() => {
        return loadUnitSets && loadUnitSets.length ? loadUnitSets.reduce((sum = 0, item) => sum + parseFloat(item.loadTotalWeigthBruttoKg), 0) : 0;
    }, [loadUnitSets]);

    const totalVolumeM3LoadUnit = useMemo(() => {
        return loadUnitSets && loadUnitSets.length ? loadUnitSets.reduce((sum = 0, item) => sum + parseFloat(item.loadTotalVolumeM3), 0) : 0;
    }, [loadUnitSets]);

    const loadUnitsToOrderMappings = useMemo(() => {
        return points ? points.reduce((val, p) => val.concat(p.loadUnitsToOrderMappings), []) : [];
    }, [points]);


    function validate(position) {

        const {loadUnitPositionStart, loadUnitPositionEnd} = position;

        if (!(loadUnitPositionStart > 0)) {
            setErrors(['loadUnitPositionStart']);
            //toast.warning('Поле Начальный индекс не заполнено'.t);
            return false;
        }
        if (!(loadUnitPositionEnd > 0)) {
            setErrors(['loadUnitPositionEnd']);
            //toast.warning('Поле Конечный индекс не заполнено'.t);
            return false;
        }
        if (loadUnitPositionStart > loadUnitPositionEnd) {
            setErrors(['loadUnitPositionStart']);
            toast.warning(t('waybills_label_start-index-greater'));
            return false;
        }

        /*if (!position.recipientOrderNo) {
            setErrors(['recipientOrderNo']);
            //toast.warning('Поле номер грузополузателя не заполнено'.t);
            return false;
        }*/

        if (position.recipientOrderNo && position.recipientOrderNo.length > 200) {
            setErrors(['recipientOrderNo']);
            toast.warning(t('waybills_label_num-more-200'));
            return false;
        }

        if (position.recipientOrderNo && position.recipientOrderNo.split(';').some(i => i.length > 70)) {
            setErrors(['recipientOrderNo']);
            toast.warning(t('waybills_label_num-invalid-format'));
            return false;
        }

        if (position.shipperOrderNo && position.shipperOrderNo.length > 200) {
            setErrors(['shipperOrderNo']);
            toast.warning(t('waybills_label_num-consignee-more-200'));
            return false;
        }

        if (position.torg12No && position.torg12No.length > 500) {
            setErrors(['torg12No']);
            toast.warning(t('waybills_label_ttn-more-500'));
            return false;
        }

       if (parseFloat((loadUnitsToOrderMappings.filter(item => item.id !== position.id)
           .reduce((sum, item) => sum + parseFloat(item.weightKgBrutto || 0), 0) + parseFloat(position.weightKgBrutto || 0)).toFixed(3)) > parseFloat((totalWeightLoadUnit || 0).toFixed(3))) {
            setErrors(['weightKgBrutto']);
            toast.warning(t('waybills_label_not-correspond-total-weight'));
            return false;
        }

        if (parseFloat((loadUnitsToOrderMappings.filter(item => item.id !== position.id)
            .reduce((sum, item) => sum + parseFloat(item.volumeM3 || 0), 0) + parseFloat(position.volumeM3 || 0)).toFixed(3)) > parseFloat((totalVolumeM3LoadUnit || 0).toFixed(3))) {
            setErrors(['volumeM3']);
            toast.warning(t('waybills_label_not-correspond-total-volume'));
            return false;
        }

        if (parseFloat(parseFloat(loadUnitsToOrderMappings.filter(item => item.id !== position.id)
            .reduce((sum, item) => sum + parseFloat(item.cost || 0), 0)) + parseFloat(position.cost || 0)).toFixed(2) > parseFloat(wb.cargoCost || 0)) {
            setErrors(['cost']);
            toast.warning(t('waybills_label_not-correspond-total-price'));
            return false;
        }

        return true;
    };

    function onChange(key, val) {
        let dataCloned = {...data};
        dataCloned[key] = val;

        const {loadUnitPositionEnd, loadUnitPositionStart} = dataCloned;

        if (!getHasToMirror(wb) || isAdding) {
            const maxIndex = getMaxIndex(wb);
            if (loadUnitPositionEnd > maxIndex) {
                dataCloned = {
                    ...dataCloned,
                    loadUnitPositionEnd: maxIndex
                };
            }
        }

        if (loadUnitPositionStart < 1) {
            dataCloned = {
                ...dataCloned,
                loadUnitPositionStart: 1
            };
        }

        setErrors([]);
        setData(dataCloned);
        setCanSave(validate(dataCloned));
    };

    function onOpen() {
        if (disabled) {
            return
        }
        const prevMaxPosition = getPrevMaxPosition(points);

        if (isAdding) {
            let loadUnitPositionStart = prevMaxPosition + 1;
            if (loadUnitPositionStart > unitsLoadedTotal) {
                loadUnitPositionStart = unitsLoadedTotal;
            }
            const presetFormData = unitsLoadedTotal
                ? {
                    pointToId: pointId,
                    loadUnitPositionStart: prevMaxPosition + 1,
                    loadUnitPositionEnd: unitsLoadedTotal
                }
                : {pointToId: pointId};

            setData(presetFormData);
        } else {
            setData(value);
        }
        setOpen(true);
    }

    function onClose() {
        setOpen(false);
        setData({});
        setErrors([]);
    };

    async function onSave() {
        try {
            setLoading(true);
            await submit(data);
        } finally {
            setLoading(false);
            onClose();
        }
    }

    function getDeleteExtraChart(str) {
        let strNew = '';
        for (let char of str) {
            if (/[\s+*"№%:?*<>.,|\\()_!'@#%$^&=\/`]/i.test(char)) {
                strNew = `${strNew};`;
            } else strNew = `${strNew}${char}`;
        }
        return strNew.replace(/\;{2,}/i, ';');
    }

    return (
        <Modal
            trigger={children}
            centered={true}
            dimmer="inverted"
            size="small"
            closeIcon
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input
                            required
                            type="number"
                            min="1"
                            error={errors.includes('loadUnitPositionStart')}
                            label={t('waybills_input_num-cargo-units-from')}
                            placeholder={t('waybills_input_num-cargo-units-from')}
                            value={data.loadUnitPositionStart || ''}
                            onChange={e => onChange('loadUnitPositionStart', Number.parseInt(e.target.value))}
                            disabled={isClient}
                        />
                        <Form.Input
                            required
                            type="number"
                            min="1"
                            max={unitsLoadedTotal}
                            error={errors.includes('loadUnitPositionEnd')}
                            label={t('waybills_input_num-cargo-units-to')}
                            placeholder={t('waybills_input_num-cargo-units-to')}
                            value={data.loadUnitPositionEnd || ''}
                            onChange={e => onChange('loadUnitPositionEnd', Number.parseInt(e.target.value))}
                            disabled={isClient}
                        />
                    </Form.Group>
                    <Divider hidden/>
                    <Form.Group widths='equal'>
                        <Form.Input
                            error={errors.includes('recipientOrderNo')}
                            label={t('waybills_input_num-order-consignee')}
                            placeholder={t('waybills_placeholder_num-order-consignee')}
                            value={data.recipientOrderNo || ''}
                            onChange={e => onChange('recipientOrderNo', getDeleteExtraChart(e.target.value))}
                            disabled={isClient}
                        />
                        <Form.Input
                            error={errors.includes('shipperOrderNo')}
                            label={t('waybills_input_num-order-shipper')}
                            placeholder={t('waybills_input_num-order-shipper')}
                            value={data.shipperOrderNo || ''}
                            onChange={e => onChange('shipperOrderNo', e.target.value)}
                            disabled={isClient}
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input
                            error={errors.includes('torg12No')}
                            label={t('waybills_input_num-ttn')}
                            placeholder={t('waybills_input_num-ttn')}
                            value={data.torg12No || ''}
                            onChange={e => onChange('torg12No', e.target.value)}
                        />
                        {/*<Form.Field control={InputDropdown}
                                    label={'Тип заказа'.t}
                                    options={RequestTypeOptions}
                                    placeholder={'Тип заказа'.t}
                                    value={data.orderType}
                                    onChange={val => onChange('orderType', val)}
                                    disabled={isClient}
                        />*/}
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input
                            error={errors.includes('weightKgBrutto')}
                            type="number"
                            min="0"
                            step="0.1"
                            label={t('waybills_input_weight-kg')}
                            placeholder={t('waybills_input_weight-kg')}
                            value={getValuePassZero(data.weightKgBrutto)}
                            onChange={e => onChange('weightKgBrutto', restrictionFloat(e.target.value, 3))}
                            onBlur={(e) =>  onChange('weightKgBrutto', formatNumber(Number.parseFloat(e.target.value)))}
                            disabled={isClient}
                        />
                        <Form.Input
                            error={errors.includes('volumeM3')}
                            type="number"
                            min="0"
                            step="0.1"
                            label={t('waybills_input_volume-m3')}
                            placeholder={t('waybills_input_volume-m3')}
                            value={getValuePassZero(data.volumeM3)}
                            onChange={e => onChange('volumeM3', restrictionFloat(e.target.value, 3))}
                            onBlur={(e) =>  onChange('volumeM3', formatNumber(Number.parseFloat(e.target.value)))}
                            disabled={isClient}
                        />
                    </Form.Group>
                    <Form.Group widths={2}>
                        <Form.Input
                            error={errors.includes('cost')}
                            label={t('waybills_input_cost')}
                            placeholder={t('waybills_input_cost')}
                            value={data.cost || ''}
                            onChange={e => onChange('cost', restrictionFloat(e.target.value.replace(/[^\d,.]/g, '').replace(',', '.'), 2))}
                            disabled={isClient}
                        />
                    </Form.Group>
                </Form>


            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={onClose}
                    disabled={loading}>{t('base_btn_cancel')}</Button>
                <Button
                    primary
                    disabled={!canSave}
                    loading={loading}
                    onClick={onSave}>{t('base_btn_save')}</Button>
            </Modal.Actions>
        </Modal>
    );
}
