import React, {useEffect, useState} from 'react';
import qs from "query-string";
import useReactRouter from "use-react-router";
import FilterInputClearButton from "./FilterInputClearButton";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import FilterInputDateTime from "./FilterInputDateTime";
import { useTranslation } from 'react-i18next';

const handleValueChangeDebounced = AwesomeDebouncePromise(handleFilterApply, 1500);

function handleFilterApply(applyFilter, field, val) {
    applyFilter({
        [field]: val
    });
}

const WbGridFilterDateTime = ({ fieldDate, fieldTimeFrom, fieldTimeTo, applyFilter, removeFilter}) => {
    const { t } = useTranslation();

    const {location} = useReactRouter();
    const q = qs.parse(location.search);

    const [date, setDate] = useState(q[fieldDate] || '');
    const [timeFrom, setTimeFrom] = useState(q[fieldTimeFrom] || '');
    const [timeTo, setTimeTo] = useState(q[fieldTimeTo] || '');

    useEffect(() => {
        const q = qs.parse(location.search);
        setDate(q[fieldDate] || '');
        setTimeFrom(q[fieldTimeFrom] || '');
        setTimeTo(q[fieldTimeTo] || '');
    }, [location.search]);

    async function handleValueChange(val) {
        setDate(val);
        await handleValueChangeDebounced(applyFilter, fieldDate, val);
        if (!val) {
            setDate("");
            setTimeFrom("");
            setTimeTo("");
            removeFilter(fieldDate);
            removeFilter(fieldTimeFrom);
            removeFilter(fieldTimeTo);
        }
    }

    function handleClearFilterClick() {
        if (date) {
            setDate("");
            setTimeFrom("");
            setTimeTo("");
            removeFilter(fieldDate);
            removeFilter(fieldTimeFrom);
            removeFilter(fieldTimeTo);
        }
    };

    const handleChangeTime = async (val, key) => {
        if (key === 'from') {
            setTimeFrom(val);
            await handleValueChangeDebounced(applyFilter, fieldTimeFrom, val);
            if (!val) {
                setTimeFrom("");
                setTimeTo("");
                removeFilter(fieldTimeFrom);
                removeFilter(fieldTimeTo);
            }
        } else if (key === 'to') {
            setTimeTo(val);
            await handleValueChangeDebounced(applyFilter, fieldTimeTo, val);
        }
    };

    const bgnClass = date
        ? "filter-flex-right filter-flex-right-active"
        : "filter-flex-right";

    return (
        <div>
            <div
                className="filter-flex-container"
                style={{ backgroundColor: date ?  '#ffface' : null }}>
                <div className="filter-flex-center">
                    <FilterInputDateTime
                        onChange={handleValueChange}
                        onChangeTime={handleChangeTime}
                        date={date}
                        time={{
                            from: timeFrom,
                            to: timeTo,
                        }}
                        name={t('waybills_input_select-date')}/>
                </div>
                {
                    date
                        ? (
                            <div className={bgnClass}>
                                <FilterInputClearButton
                                    handleInputClearClick={handleClearFilterClick} />
                            </div>
                        )
                        : null
                }
            </div>
        </div>
    );
};

export default WbGridFilterDateTime;
