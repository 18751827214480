import React, { useEffect, useState, useContext } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { companyPointAdd } from '../../../api/companies';
import { hasPointForExportClient, pointCreate } from '../../../api/points';
import { getUserCompanyOptions } from '../../../api/users';
import { useTranslation } from "react-i18next";
import PointsFormExportExcel from '../../_shared/pointsFormExportExcel/PointsFormExportExcel';
import ActionPointRemove from './ActionPointRemove';
import ActionPointSearch from './ActionPointSearch';
import {ContextUser} from "../../../services/context";
import UserPermissions from "../../../api/model/UserPermissions";

export default function PointsToolbar({
    query,
    rows,
    updateRows,
    selectedPoints
}) {

    const { t } = useTranslation();

    const [companyOptions, setCompanyOptions] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [fmidAddress, setFmidAddress] = useState(null);
    const [selectedCompanyNew, setSelectedCompanyNew] = useState(null);
    const [canExport, setCanExport] = useState(true);
    const contextUser = useContext(ContextUser);

    const selectedRows = rows.filter(r => {
        return selectedPoints.includes(r.id);
    });

    const isCreatingMyPoints = contextUser.current.permissions.includes(UserPermissions.CREATING_MY_POINTS);

    const showRemoveFromBookmarks = !!selectedRows.length && isCreatingMyPoints;

    async function handleAddToList(point, company, searchname) {
        await companyPointAdd(point, company, searchname);
        updateRows();
    };

    async function createItem(item, options = {}) {

        //onPointSearchClose(); // setIsPointSearchOpen(false);

        item.createdByClient = selectedCompanyNew;

        let { companyName } = item;

        if (companyName && companyName.length) {
            companyName = companyName.trim();
            if (!'"“”\'«'.includes(companyName[0])) {
                companyName = '"' + companyName;
            }
            if (!'"“”\'»'.includes(companyName[companyName.length - 1])) {
                companyName = companyName + '"';
            }
        }

        item.companyName = companyName;

        const point = await pointCreate(item, options.exportOnSave);

        if (point) {
            await companyPointAdd(selectedCompanyNew, point.id, item.pointName);
            updateRows();
        }
    };

    useEffect(() => {
        (async () => {
            const options = await getUserCompanyOptions();
            setCompanyOptions(options);
            if (options.length === 1) {
                setSelectedCompany(options[0].value);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const hasExportPoints = await hasPointForExportClient(query);
            setCanExport(hasExportPoints);
        })();
    }, [query]);

    return (
        <Menu className="waybills-toolbar shd-inset" style={{ marginBottom: '0' }} size='small' borderless>
            <Menu.Item>
                <Icon name="info" />
                <em>{t("points_text_find_and_create_addresses")}</em>
            </Menu.Item>
            <Menu.Menu position='right'>
                {showRemoveFromBookmarks &&
                    <ActionPointRemove {...{selectedRows, updateRows}}>
                        <Menu.Item>
                            <Icon name="minus" />
                            {t("points_text_remove_address")}
                        </Menu.Item>
                    </ActionPointRemove>
                }
                {
                    isCreatingMyPoints &&
                        <ActionPointSearch
                            companyOptions={companyOptions}
                            selectedCompany={selectedCompany}
                            setSelectedCompany={setSelectedCompany}
                            setFmidAddress={setFmidAddress}
                            fmidAddress={fmidAddress}
                            selectedCompanyNew={selectedCompanyNew}
                            setSelectedCompanyNew={setSelectedCompanyNew}
                            handleAddToList={handleAddToList}
                            createItem={createItem}
                        >
                            <Menu.Item>
                                <Icon name="plus" />
                                {t("points_text_find_create_my_address")}
                            </Menu.Item>
                        </ActionPointSearch>
                }
                {/* <ActionImport
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                >
                    <Menu.Item>
                        <Icon name="arrow down" />
                        <T>Импорт адресов</T>
                    </Menu.Item>
                </ActionImport> */}

                {canExport &&
                    <PointsFormExportExcel isClient selectedPoints={selectedPoints} selectedExport={rows.filter(r => {
                        return selectedPoints.includes(r.id)})}>
                        <Menu.Item>
                            <Icon name="arrow up" />
                            {t("points_label_export_addresses_into_excel")}
                        </Menu.Item>
                    </PointsFormExportExcel>
                }
            </Menu.Menu>
        </Menu>
    );
}
