import O from "../../components/Option";
// eslint-disable-next-line no-unused-vars
import PointAddress from "./PointAddress";
// eslint-disable-next-line no-unused-vars
import PointContact from "./PointContact";
// eslint-disable-next-line no-unused-vars
import Persistable from "./Persistable";
import {PointDays} from "./PointDays"; // eslint-disable-line

export const LoadCapacityOptions = [
    new O(2000, "points_enum_2_t"),
    new O(5000, "points_enum_5_t"),
    new O(10000, "points_enum_10_t"),
    new O(20000, "points_enum_20_t"),
];

export const PointTypeEnum = {
    RECEPIENT: 0,
    CLIENT_WAREHOUSE: 1,
    FM: 2,
    SHOP: 3,
    DISTRIBUTION_CENTER: 4,
};

export const PointStatusEnum = {
    СREATED: 0,
    ON_APPROVAL: 1,
    CANCELLED: 2,
    NEEDS_CORRECTION: 9,
    REJECTED: 10,
    ACCEPTED: 11,
};

export const PointExportStatusEnum = {
    CREATED: 0,
    EXPORTED: 1,
    EXPORT_ERROR: 2
};

// вариант с получением через заявки
// получить все заявки со статусом подтв адреса
// получить все pointsIds

export const PointStatusOptions = [
    new O(PointStatusEnum.ON_APPROVAL, "points_enum_on_approval"),
    new O(PointStatusEnum.NEEDS_CORRECTION, "points_enum_needs_correction"),
    new O(PointStatusEnum.REJECTED, "points_enum_rejected"),
    new O(PointStatusEnum.ACCEPTED, "points_enum_accepted"),
];

export const PointExportStatusOptions = [
    new O(PointExportStatusEnum.CREATED, "points_enum_not_exported"),
    new O(PointExportStatusEnum.EXPORTED, "points_enum_exported"),
    new O(PointExportStatusEnum.EXPORT_ERROR, "points_enum_export_error")
];

export const PointRegistrationStatusOptions = [
    new O(PointExportStatusEnum.CREATED, "points_enum_not_registered"),
    new O(PointExportStatusEnum.EXPORTED, "points_enum_registered"),
    new O(PointExportStatusEnum.EXPORT_ERROR, "points_enum_registration_error")
];

export const PointLoadingPositionEnum = {
    BACK: 0,
    SIDE: 1,
    TOP: 2
};

export const LoadPositionOptions = [
    new O(PointLoadingPositionEnum.BACK, "points_enum_back"),
    new O(PointLoadingPositionEnum.SIDE, "points_enum_side"),
    new O(PointLoadingPositionEnum.TOP, "points_enum_top"),
];

export const CompanyTypeOptions = [
    new O(PointTypeEnum.SHOP, "points_enum_shop"),
    new O(PointTypeEnum.DISTRIBUTION_CENTER, "points_enum_distribution_center"),
    new O(PointTypeEnum.RECEPIENT, "points_enum_recepient"),
    new O(PointTypeEnum.CLIENT_WAREHOUSE, "points_enum_client_warehouse"),
    new O(PointTypeEnum.FM, "points_enum_cross_dock"),
];

export function getPointStatusColor(statusId) {
    switch (statusId) {
        case PointStatusEnum.ON_APPROVAL: return "orange";
        case PointStatusEnum.NEEDS_CORRECTION: return "orange";
        case PointStatusEnum.APPROVAL_ERROR: return "red";
        case PointStatusEnum.REJECTED: return "grey";
        case PointStatusEnum.ACCEPTED: return "teal";
        default: return null;
    }
}

export const PointBookmarkTypeEnum = {
    NONE: 0,
    LOADING: 1,
    UNLOADING: 2,
    SHARE: 3
};

export const PointBookmarkTypeName = {
    [PointBookmarkTypeEnum.NONE]: 'points_enum_empty',
    [PointBookmarkTypeEnum.LOADING]: 'points_enum_on_loading',
    [PointBookmarkTypeEnum.UNLOADING]: 'points_enum_on_unloading',
    [PointBookmarkTypeEnum.SHARE]: 'points_enum_share'
};

export const PointBookmarkTypeColor = {
    [PointBookmarkTypeEnum.NONE]: 'grey',
    [PointBookmarkTypeEnum.LOADING]: 'orange',
    [PointBookmarkTypeEnum.UNLOADING]: 'green',
    [PointBookmarkTypeEnum.SHARE]: 'blue'
};

export const PointBookmarkTypeOptions = Object.keys(PointBookmarkTypeName).map((key) => {
    return new O(Number(key), PointBookmarkTypeName[key]);
});


export function getPointStatusName(statusId) {

    const option = PointStatusOptions.find(o => o.key === statusId);

    return option ? option.text : statusId;
}

export function getCompanyTypeName(typeId) {
    const option = CompanyTypeOptions.find(o => o.key === typeId);

    return option ? option.text : typeId;
}

export default class Point extends Persistable {

    /** @param {Point} dto */
    constructor(dto = {}) {
        super(dto);

        /** @type {string} */
        this.id = dto.id;

        /** @type {string} */
        this.fmid = dto.fmid;   // searchName

        /** @enum {PointStatusEnum} */
        this.statusId = dto.statusId || PointStatusEnum.СREATED;

        /** @enum {PointTypeEnum} */
        this.pointType = dto.pointType;

        /** @type {string} */   // clientSearchName
        this.pointName = dto.pointName;

        /** @type {string} */
        this.companyName = dto.companyName;

        /** @type {string} */
        this.companyLegalForm = dto.companyLegalForm;

        /** @type {string} */
        this.companyLegalName = dto.companyLegalName;

        /** @type {PointAddress} */
        this.address = dto.address instanceof PointAddress ? dto.address : new PointAddress(dto.address);

        /** @type {PointDays} */
        this.days = dto.days;

        /** @type {PointContact[]} */
        this.contacts = dto.contacts;


        /** @enum {number} */
        this.dockCapacity = dto.dockCapacity;

        /** @enum {PointLoadingPositionEnum} */
        this.dockPosition = dto.dockPosition;

        /** @type {string} */
        this.comment = dto.comment;

        /** @type {boolean} */
        this.isHub = dto.isHub;

        /** @type {PointDays[] | undefined}  */
        this.comments = dto.comments;

        this.exportStatus = dto.exportStatus;

        /** @type {string | undefined} */
        this.zone = dto.zone;

		this.createdByClient = dto.createdByClient;

		this.useCount = dto.useCount;

		this.activityId = dto.activityId;

		this.platformId = dto.platformId;
    }
}
