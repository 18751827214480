import React from 'react';
import { Icon } from 'semantic-ui-react';
import T from '../Translate';
import './AppButtonLink.css';

export default function AppButtonLink({disabled = false, floated, style, icon, onClick, children}) {

    const className = disabled ? "app-button-link-disabled" : "app-button-link";

    return (
        <span
            className={className}
            style={style}
            floated={floated}
            onClick={() => disabled ? null : onClick && onClick()}>
            {icon ? <Icon name={icon} /> : null}{children || 'Button'}
        </span>
    );

}
