import React, { useContext } from 'react';
import { Header, Label, Segment, SegmentGroup } from 'semantic-ui-react';
import { ContextWb } from '../../../services/context';
import './WbPointCardShell.css';
import WbPointCardShellMenu from './WbPointCardShellMenu';
import WbPointCardTabs from './WbPointCardTabs';
import { useTranslation } from 'react-i18next';

export default function PointCardShell({
    positions, wbPoint, profile, updateWb, remove, pointAdd, update, pointReplace,
    activeTab, unitsLoadedTotal, handleTabChange,
    wbType, wbId, oppositeDate, needCalc, pointsAll, isClient, wbClientId
}) {
    const { t } = useTranslation();

    const contextWb = useContext(ContextWb);

    const wb = contextWb.wb;

    const addr0 = (
        <span
            className="ellipsis"
            title={wbPoint.address || t('waybills_label_address')}
            style={{
                color: '#737373',
            }}
        >
            {wbPoint.address || t('waybills_label_address')}
        </span>
    );

    return (
        <SegmentGroup className="m-b-15 m-t-0">
            <Segment clearing={true} style={{ paddingTop: '7px', paddingBottom: '7px', minHeight: '54px' }}>
                <div className="wbPointCardShellHeader-container">
                    <div className="wbPointCardShellHeader-item-left">
                        <Label size="big" circular>{wbPoint.positionNumber}</Label>
                    </div>
                    <div className="wbPointCardShellHeader-item-center">
                        <Header className="m-0">
                            {addr0}
                            <Header.Subheader className="m-t-3">{wbPoint.companyName}</Header.Subheader>
                        </Header>
                    </div>
                    <div className="wbPointCardShellHeader-item-right">
                        <div className="t-r" style={{marginTop: '9px'}}>
                            <WbPointCardShellMenu
                                wb={wb}
                                isClient={isClient}
                                wbPoint={wbPoint}
                                profile={profile}
                                pointRemove={remove}
                                pointReplace={pointReplace}
                            />
                        </div>
                    </div>
                </div>
            </Segment>
            <Segment placeholder size="small" className="point-card-form">
                <WbPointCardTabs
                    isClient={isClient}
                    wbClientId={wbClientId}
                    profile={profile}
                    pointsAll={pointsAll}
                    value={wbPoint}
                    oppositeDate={oppositeDate}
                    wbId={wbId}
                    wbType={wbType}
                    positions={positions}
                    updatePoint={update}
                    updateWb={updateWb}
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    unitsLoadedTotal={unitsLoadedTotal}
                    needCalc={needCalc}
                />
            </Segment>
        </SegmentGroup>
    );
}
