import React from 'react';
import { Container, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function Error(props) {
    const { t } = useTranslation();

    const msg = t(props.error) || t('base_label_unknown-error');
    const header = t(props.header) || t('base_label_unknown-error-contact-admin');

    return (
        <Container className="page-offset">
            <Message
                negative
                icon='bug'
                header={header}
                content={msg}
            />
        </Container>
    );
}