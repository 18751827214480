import React, {useState, useContext, useEffect} from 'react';
import { Button, Icon, Container } from 'semantic-ui-react';
import {getRole, getUserByIdWithOptions, getUserCurrent, postUser} from "../../../api/users";
import { LinkInfo } from '../../../layout/navbar/Navbar';
import { ContextUser, ContextNavi } from '../../../services/context';
import { toast } from '../../../services/toast';
import ResetPassword from '../../access/resetPassword/ResetPassword';
import UserForm from './UserForm';
import {useTranslation} from 'react-i18next';

export default function UserFormEdit({match}) {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [user, setUser] = useState({});
    const [errors, setErrors] = useState([]);

    const contextUser = useContext(ContextUser);
    const contextNavi = useContext(ContextNavi);

    function setPageTitle(user) {

        const items = [
            new LinkInfo("users_label_users", "/admin/users")
        ];

        if (user) {
            let userName;
            if (user.firstName || user.lastName) {
                userName = `${user.firstName || ''} ${user.lastName || ''}`.trim();
            } else {
                userName = user.email;
            }

            items.push(new LinkInfo(userName, "/"));
        }

        contextNavi.setItems(items);
    }

    function update(prop) {
        const userUpdated = { ...user, ...prop };

        setErrors([]);
        setUser(userUpdated);
    }


    function setHeaderCompanies(companies) {

        if (user.email === contextUser.current.email) {
            contextUser.setCompanies(companies);
        }
    }

    async function getContextUserCurrent() {
        const user = await getUserCurrent();
        const role = await getRole();

        return {...user, role}
    }

    async function sumbit() {
		const { email } = user;
        if (!email) {
            setErrors(['email']);
            toast.warning(t("users_text_required-fields"));
            return;
        }

        setLoading(true);

        try {

            await postUser(user);


            const current = await getContextUserCurrent();

            contextUser.setIsCompaniesAll(current.isAllCompanies);

            setHeaderCompanies(current.companies);
            setLoading(false);
            setPageTitle(user);

        } catch (error) {

            setLoading(false);
        }
    }

    useEffect(() => {
        const userId = match.params.id;

        getUserByIdWithOptions(userId, true).then(userInitial => {
            setLoading(false);
            setHasLoaded(true);
            setUser(userInitial);
            setPageTitle(userInitial);
        });
    }, [match.params.id]);

    return (
        <div className="page-height bgn-grey" style={{overflowY:'auto'}}>
            <Container>
                <div className="page-offset">
                    <UserForm
                        data={user}
                        companyOptions={user.companyOptions}
                        profileOptions={user.roleOptions}
                        update={update}
                        isEdit={true}
                        errors={errors}
                    />
                    <div style={{ marginTop: '30px' }}>
						<Button loading={hasLoaded && loading} positive floated="right" className="m-l-15"
							disabled={!(user.firstName && user.email && user.roleId)}
							style={{ minWidth: '113px' }}
                            onClick={sumbit}>{t("base_btn_save")}</Button>
                        <ResetPassword email={user.email}>
                            <Button negative floated="right">
                                <Icon name="exclamation triangle" />{t("users_label_reset-password")}</Button>
                        </ResetPassword>
                    </div>
                </div>
                {/* <Spinner show={this.state.loading} /> */}
            </Container>
        </div>

    );
}
