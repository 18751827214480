import { LoadCapacityEnum } from "./model/Waybill";
import { PackTypeEnum } from "./model/Enums";

export function getWeightCapacityFromPalletes(palletsCount, restrictions) {
    for (let i = 0; i < restrictions.length; i++) {
        if (restrictions[i].maxPallets >= palletsCount) {
            return restrictions[i].maxCapacity;
        }
    }
    return LoadCapacityEnum.C20000;
}

export function getMinCapacity(wb, restrictions) {

    const loadUnitSetsAll = wb.pointsLoading.reduce((res, i) => res.concat(i.loadUnitSets || []), []);

    const currentWeightTotal = loadUnitSetsAll.reduce((res, i) => res + (i.loadTotalWeigthBruttoKg || 0), 0);

    let minRequiredCapacityWeight = LoadCapacityEnum.C20000;

    if (currentWeightTotal <= 10000) {
        minRequiredCapacityWeight = LoadCapacityEnum.C10000;

        if (currentWeightTotal <= 5000) {
            minRequiredCapacityWeight = LoadCapacityEnum.C5000;

            if (currentWeightTotal <= 2000) {
                minRequiredCapacityWeight = LoadCapacityEnum.C2000;
            }
        }

    }


    let minRequiredCapacityPallets = LoadCapacityEnum.C2000;

    if (restrictions && restrictions.length) {

        const loadUnitSetsWithPallets = loadUnitSetsAll.filter(lus => lus.loadUnitType === PackTypeEnum.EP);

        if (loadUnitSetsWithPallets.length) {

            const currentPalletesCount = loadUnitSetsWithPallets.reduce((res, i) => res + (i.loadUnitsCount || 0), 0);

            minRequiredCapacityPallets = getWeightCapacityFromPalletes(currentPalletesCount, restrictions);
        }
    }

    let minRequiredCapacity = Math.max(minRequiredCapacityPallets, minRequiredCapacityWeight);

    return minRequiredCapacity;
}
