import React from 'react';
import {Message, Icon, Header} from 'semantic-ui-react';
import './EntryBrowserFailed.css';
import {useTranslation} from 'react-i18next';

export default function EntryBrowserFailed() {
    const {t} = useTranslation();
    return (
        <div className="login-bgn">
            <div className="entry-outdated-message-container">
                <Message
                    className="entry-outdated-message"
                    floating
                    // negative
                    color="black"
                    icon={<Icon name="ban" color="red"/>}
                    header={<Header as="h3" style={{
                        color: 'white',
                        marginBottom: '7px'
                    }}>{t('accesses_text_message-browsers')}</Header>}
                    content={
                        <span>{t('accesses_text_message-browsers-top')}<br/> {t('accesses_text_message-browsers-bottom')}<a
                            href="https://www.google.ru/intl/ru/chrome/" target="_blank" rel="noopener noreferrer">Google Chrome</a></span>}
                />
            </div>
        </div>
    );
}