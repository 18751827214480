import React, { useContext } from 'react';
import { ContextFooter } from '../../services/context';
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t } = useTranslation();

    const indicator = useContext(ContextFooter).indicator;

    const style = {
        boxShadow: '0px -1px 2px rgba(34,36,38,0.10)',
        zIndex: '99',
        height: '37px',
    };

    const indicatorStyle = {
        paddingLeft: '17px'
    };

    const linkStyle = {
        fontSize: '13px',
        fontWeight: 'normal',
        lineHeight: '21px',
        paddingRight: '10px',
    };

    return (
        <div className="flex-row flex-row--halign-justified flex-row-valign-center" style={style}>
            <div style={indicatorStyle} className="flex-row flex-row--valign-center flex-grow">
                {indicator}
            </div>
            <div className="flex-col flex-col--valign-center">
                <span style={linkStyle}>
                    <a className="a-black a-underline" href="http://www.fmlogistic.ru/footer/Politika-konfidencial-nosti">{t('base_label_privacy-policy')}</a>
                    <span> | © </span>
                    <a className="a-black" href="http://www.fmlogistic.ru" target="_blank" rel="noreferrer noopener">{t('base_label_fm-logistic')}</a>
                </span>
            </div>
        </div>
    );
}