 import React from 'react';
import { List } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';

export class RecordData {
    constructor(name, value, isRequired = true) {
        this.key = name;
        this.name = name;
        this.value = value;
        this.isRequired = isRequired;
    }
}

export function Record({ data }) {
    const { t } = useTranslation();

    const emptyMsg = t('waybills_label_no-data');
    const errorLabel = <span style={{ color: '#cccccc' }}>{emptyMsg}</span>;


    return (
        <List.Item>
            <List.Header>{t(data.name)}</List.Header>
            <List.Description>{data.value || (data.isRequired ? errorLabel : emptyMsg)}</List.Description>
        </List.Item>
    );
}

export function TableRecord({ data }) {
    const { t } = useTranslation();

    const emptyMsg = t('waybills_label_no-data');
    const isEmpty = !!!data.value;


    return (
        <div>
            <div style={{fontWeight: 'bold'}}>{t(data.name)}</div>
            <div style={{color: isEmpty ? "#cccccc" : "inherit"}} className="ellipsis-base" title={data.value}>{data.value || emptyMsg}</div>
        </div>
    );
}

export function TableRecordElement({ label, children}) {
    const { t } = useTranslation();

    return (
        <div>
            <div style={{ fontWeight: 'bold' }}>{t(label)}</div>
            <div>{children}</div>
        </div>
    );
}



