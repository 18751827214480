import React, {useEffect, useMemo, useRef, useState} from 'react';
import Shield from "../../layout/page/shield/Shield";
import {Dropdown, Form, Grid, Icon, Menu, Table} from "semantic-ui-react";
import ScheduleShema from "./scheduleShema";
import declOfNum from "../../services/declOfNum";
import {exportViews, getFilters, getSchedulesView} from "../../api/schedules";
import O from '../../components/Option';
import {useTranslation} from "react-i18next";

import AwesomeDebouncePromise from "awesome-debounce-promise";
import {CargoTypeOptions, WeekDayOptions} from "../../api/model/Enums";


const searchDebounced = AwesomeDebouncePromise((search) => search(), 1000);

const SchedulesList = () => {

    let [loading, setLoading] = useState(false);
    let [filters, setFilters] = useState({});
    let [rows, setRows] = useState([]);
    let [filterOptions, setFilterOptions] = useState({});

    const { t } = useTranslation();

    const mounted = useRef();

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            getSchelules();
            getFiltersOptions();
        }
    }, []);

    useEffect(() => {
        changeFilters()
    }, [filters]);

    useEffect(() => {
        getFiltersOptions();
    }, [filters.destinationRegions]);

    const changeFilters = async () => {
        await searchDebounced(getSchelules)
    };

    const getSchelules = async () => {
        const result = await getSchedulesView(filters);
        setRows(result);
    };

    const getFiltersOptions = async () => {
        const result = await getFilters(filters.destinationRegions);

        setFilterOptions({
            chains: filterOptions.chains ? filterOptions.chains : result.chains.map(item => ({
                key: item.name,
                value: {
                    pointType: item.pointType,
                    companyName: item.companyName,
                },
                text: item.name
            })),
            sendingRegions: result.sendingRegions.map(item => new O(item.value, item.value, item.address)),
            destinationCities: result.destinationCities.map(item => new O(item, item)),
            destinationRegions: result.destinationRegions.map(item => new O(item, item)),
            cargoTypes: result.cargoTypes.map(item => new O(item, CargoTypeOptions.getText(item)))
        })
    };

    const handleFiltersChange = (e, {name, value}) => {
        if (name === 'destinationRegions') {
            setFilters(filters => ({
                ...filters,
                [name]: value,
                destinationCities: null,
            }))
        } else {
            setFilters(filters => ({
                ...filters,
                [name]: value,
            }))
        }
    };

    const handleExportClick = async () => {
        const fileInfo = await exportViews({
            filter: filters
        });


        if (!fileInfo.error) {
            window.open(`/api/file/${fileInfo.id}`, "_blank");
        }
    };

    const optionsTranslate = useMemo(() => {
        return (filterOptions.cargoTypes || []).map(bto => ({
            ...bto,
            text: t(bto.text)
        }))
    }, [filterOptions.cargoTypes, t]);

    return (
        <Shield loading={loading}>
            <Menu className="waybills-toolbar shd-inset" style={{marginBottom: '0'}} size='small' borderless>
                <Menu.Menu position='right'>
                    <Menu.Item onClick={() => handleExportClick()}>
                        <Icon name="arrow up"/>{t("schedules_label_export-schedules-export")}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <div>
                <Form>
                    <Grid className="schedule_filters">
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Form.Field>
                                    <label>{t("schedules_label_network")}</label>
                                    <Dropdown
                                        options={filterOptions.chains}
                                        placeholder={t("schedules_label_all-sm")}
                                        value={filters.chains}
                                        name="chains"
                                        fluid
                                        multiple
                                        search
                                        selection
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>{t("schedules_label_region-dispatch")}</label>
                                    <Dropdown
                                        options={filterOptions.sendingRegions}
                                        placeholder={t("schedules_label_all-sm")}
                                        value={filters.sendingRegions}
                                        name="sendingRegions"
                                        className="filter_description"
                                        fluid
                                        multiple
                                        search
                                        selection
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>{t("schedules_grid_destination-city")}</label>
                                    <Dropdown
                                        options={filterOptions.destinationRegions}
                                        placeholder={t("schedules_label_all-sm")}
                                        name="destinationRegions"
                                        value={filters.destinationRegions}
                                        fluid
                                        multiple
                                        search
                                        selection
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>{t("schedules_label_delivery-city")}</label>
                                    <Dropdown
                                        options={filterOptions.destinationCities}
                                        placeholder={t("schedules_label_all-sm")}
                                        name="destinationCities"
                                        fluid
                                        multiple
                                        search
                                        selection
                                        value={filters.destinationCities}
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>{t("users_grid_cargo-type")}</label>
                                    <Dropdown
                                        options={optionsTranslate}
                                        placeholder={t("schedules_label_all-sm")}
                                        name="cargoTypes"
                                        fluid
                                        multiple
                                        search
                                        selection
                                        value={filters.cargoTypes}
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            {/*<Grid.Column width={5}>
                            </Grid.Column>*/}
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
            <div className="table-wrapper-grid table-wrapper-grid-290">
                <Table celled structured>
                    <Table.Header className="table-header-fixed">
                        <Table.Row>
                            <Table.HeaderCell className="table-header-fixed__title">
                                {t("schedules_label_distribution-center")}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' className="table-header-fixed__title">
                                {t("schedules_label_delivery-schedule")}
                            </Table.HeaderCell>
                            <Table.HeaderCell className="table-header-fixed__title">
                                {t("schedules_label_recommended-time-window")}
                            </Table.HeaderCell>
                            <Table.HeaderCell className="table-header-fixed__title">
                                {t("schedules_label_transit")}
                            </Table.HeaderCell>
                            <Table.HeaderCell className="table-header-fixed__title">
                                {t("schedules_label_additional-info")}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            rows && rows.map(row => (
                                <React.Fragment key={row.id}>
                                    <Table.Row>
                                        <Table.Cell colSpan="5">
                                            <div><b>{t("schedules_label_consolidation-address")}</b></div>
                                            <div>
                                                <i>{row.consolidationWarehouse && row.consolidationWarehouse.address}</i>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    {
                                        row.consolidationWarehouse && row.consolidationWarehouse.schedules.map(schedule => (
                                            <Table.Row>
                                                <Table.Cell textAlign='center'>
                                                    <>
                                                        <div className="p-b-10 f-s-14">
                                                            <u><b>{schedule.distributionCenter && schedule.distributionCenter.chain}</b></u>
                                                        </div>
                                                        <div>{schedule.distributionCenter && schedule.distributionCenter.address}</div>
                                                    </>
                                                </Table.Cell>
                                                <Table.Cell textAlign='center'>
                                                    <ScheduleShema points={schedule.points}/>
                                                </Table.Cell>
                                                <Table.Cell textAlign='center'>
                                                    {`${schedule.recommendedTime && schedule.recommendedTime.from ? schedule.recommendedTime.from.slice(0, 5) : ''} - ${row.recommendedTime && row.recommendedTime.to ? row.recommendedTime.to.slice(0, 5) : ''}`}
                                                </Table.Cell>
                                                <Table.Cell textAlign='center'>
                                                    {declOfNum(schedule.transit && schedule.transit.daysRoad, [t("schedules_text_day1"), t("schedules_text_day2"), t("schedules_text_days")])}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <div style={{
                                                        maxWidth: '200px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}><span
                                                        title={schedule.additionalInformationForClient}>{schedule.additionalInformationForClient}</span>
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </React.Fragment>
                            ))
                        }
                    </Table.Body>
                </Table>
            </div>
        </Shield>
    );
};

export default SchedulesList;
