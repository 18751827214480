import React, {useContext, useState} from 'react';
import { Button, Dropdown, Form, Header, Icon, List, Modal, Popup } from 'semantic-ui-react';
import {getPointOptionsFmidBySearch, getPointsBySearch, Od} from '../../../api/points';
import AppButtonLink from '../../../components/buttons/AppButtonLink';
import InputDropdown from '../../../components/inputs/InputDropdown';
import { useTranslation } from "react-i18next";
import { toast } from '../../../services/toast';
import ActionPointForm from './ActionPointForm';
import InputDropdownSearchMultiple from "../../../components/inputs/InputDropdownSearchMultiple";
import InputDropdownSearch from "../../../components/inputs/InputDropdownSearch";
import {getCompanyOptionsBySearch} from "../../../api/companies";
import {getCompanyOptionsBySearchUser} from "../../../api/users";
import {ContextUser} from "../../../services/context";
import UserPermissions from "../../../api/model/UserPermissions";
import Input from "semantic-ui-react/dist/commonjs/elements/Input/Input";

export default function ActionPointSearch({
                                              onClose,
                                              selectedCompany,
                                              setSelectedCompany,
                                              fmidAddress,
                                              setFmidAddress,
                                              createItem,
                                              setSelectedCompanyNew,
                                              selectedCompanyNew,
                                              companyOptions,
                                              children,
                                              handleAddToList,
                                          }) {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const [value, setValue] = useState({});
    const [addressSearchIsFetching, setAddressSearchIsFetching] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [searchname, setSearchname] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const contextUser = useContext(ContextUser);
    const hasPermissionFmidAddress = contextUser.current.permissions.includes(UserPermissions.FMID_ADDRESS);
    function onSelect(point, company, searchname) {
        handleAddToList(point, company, searchname);
    };

    function onOpen() {
        if (!companyOptions.length) {
            toast.warning(t("points_text_for_creation_of_application "));
            return;
        }
        setOpen(true);
    };

    function handleClose() {
        setValue({});
        setFmidAddress(null);
        setSearchname(null);
        setSelectedCompany(null);
        onClose && onClose();
        setIsSubmitting(false);
        setOpen(false);
    };

    function searchTextAndDescription(options, query) {
        const q = query.toLowerCase();
        const qterms = q.split(' ');
        const q0 = qterms[0];
        return options.length ? options.filter(opt =>
            opt.text && opt.text.toLowerCase().includes(q0) ||
            opt.description && opt.description.toLowerCase().includes(q0)
        ) : [];
    };

    async function handlePointSearchChange(e, { searchQuery }) {
        searchQuery = searchQuery && searchQuery.trim();
        const hasSearch = searchQuery && searchQuery.length > 2;
        setAddressSearchIsFetching(hasSearch);

        if (hasSearch) {
            const newAddresses = await getPointsBySearch(searchQuery, selectedCompany);
            if (newAddresses.length) {
                const addresses = newAddresses;
                setAddressSearchIsFetching(false);
                setAddresses(addresses);
            } else {
                setAddressSearchIsFetching(false);
            }
        }
    };

    async function handlePointChange(e, dd) {
        const address = addresses.find(adr => adr.id === dd.value);
        if (address === undefined) {
            return setValue({});
        }
        setValue(address);
    };

    async function submit() {
        onSelect(selectedCompany, value.id, searchname);
        setValue({});
        onClose && onClose();
        setOpen(false);
    };

    async function createItemAndClose() {
        await createItem(...arguments);
        setSelectedCompanyNew(null);
        setOpen(false);
    }

    const valueIsOk = !!(value.id && selectedCompany);

    const addressOptions = (addresses || [])
        .map(p => new Od(p.id, p.address.value, (p.pointName || p.companyName || '').trunc(50)));
    const searchTipsText = (
        <List>
            <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle' />{t("points_text_enter_address_by_parts_for_example")} <em>{t("points_text_address_example")}</em></span></List.Item>
            <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle' />{t("points_text_company_name_for_example")}<em>{t("points_text_company_name_example")}</em></span></List.Item>
            <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle' />{t("points_text_address_name_for_fast_search")}</span></List.Item>
            {false && hasPermissionFmidAddress &&  <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle' />{t("points_text_address_name_in_fm_system")}</span></List.Item>}
        </List>
    );

    const searchTips = (
        <Popup
            flowing
            trigger={<Icon name='info circle' color="blue" style={{ fontSize: "1.1em" }} />}
            content={
                <>
                    <Header>{t("points_label_prompt_for_search")}</Header>
                    <div>{t("points_text_for_search")}<em>{t("points_text_addresses_fm")}</em>{t("points_text_use_one_of_criterries")}</div>
                    {searchTipsText}
                    <div>{t("points_text_may_use_part_of_name")}<em>{t("points_text_part_of_name_example")}</em></div>
                </>
            }
        />
    );

    const newPointLink = (
        <ActionPointForm
            companyOptions={companyOptions}
            selectedCompany={selectedCompanyNew}
            setSelectedCompany={setSelectedCompanyNew}
            handleAddToList={handleAddToList}
            createItem={createItemAndClose}
        >
            <AppButtonLink
                style={{ textDecoration: 'underline', paddingTop: '1px' }}
                floated="right"
            >
                {t("points_btn_create_address_fm")}
            </AppButtonLink>
        </ActionPointForm>

    );

    async function searchAddressOptions() {
        if (fmidAddress) {
            const res = await getPointOptionsFmidBySearch(fmidAddress, selectedCompany);
            setAddresses(res);
            if (res && res[0] && res[0].id) {
                setValue(res[0]);
            } else setValue({});
        }
    }

    function onKeyPressFmid (e) {
        if (e.key === "Enter") {
            searchAddressOptions();
        }
    }


    return (
        <Modal
            trigger={children}
            closeOnDimmerClick={false}
            size="small"
            dimmer="inverted"
            closeIcon
            open={open}
            onOpen={onOpen}
            onClose={handleClose}
        >
            <Modal.Header>{t("points_label_add_my_address")}</Modal.Header>
            <Modal.Content>
                <div className="m-b-10">
                    <Form
                        disabled={isSubmitting}
                    >
                        <Form.Field>
                            <label>{t("points_label_choose_company")}</label>
                            {companyOptions.length > 1 ? (<InputDropdownSearchMultiple
                                    getOptionsBySearch={getCompanyOptionsBySearchUser}
                                    placeholder={t("points_label_choose_company")}
                                    options={companyOptions}
                                    value={selectedCompany}
                                    onChange={(values, options) => {
                                        setSelectedCompany(values);
                                        setValue({});
                                        setAddresses([]);
                                    }}
                                />) :
                                (<InputDropdownSearch
                                    getOptionsBySearch={getCompanyOptionsBySearchUser}
                                    placeholder={t("points_label_choose_company")}
                                    options={companyOptions}
                                    value={selectedCompany}
                                    onChange={(values, options) => setSelectedCompany(values)}
                                />)}
                        </Form.Field>
                        <Form.Field>
                            <label>{t("points_placeholder_name_for_fast_search")}</label>
                            { <Input
                                placeholder={t("points_placeholder_name_for_fast_search")}
                                value={searchname}
                                onChange={(e) => setSearchname(e.target.value)}
                            />}
                        </Form.Field>
                        {hasPermissionFmidAddress && (<Form.Field>
                            <label>{t("points_text_address_name_in_fm_system")}</label>
                            { <Input
                            placeholder={t("points_text_address_name_in_fm_system")}
                            value={fmidAddress}
                            onChange={(e) => setFmidAddress(e.target.value)}
                            onKeyPress={onKeyPressFmid}
                            onBlur={searchAddressOptions}
                        />}
                        </Form.Field>)}
                        <Form.Field>
                            <label>{t("points_input_search_in_fm_addresses")} {searchTips} {newPointLink}</label>
                            <Dropdown
                                clearable

                                selection
                                search={searchTextAndDescription}
                                value={value.id || ''}
                                noResultsMessage={t("base_label_no_results")}
                                onSearchChange={handlePointSearchChange}
                                onChange={handlePointChange}

                                className="InputDropdown input-pointSearch"
                                placeholder={t("points_placeholder_enter_by_parts_company_name")}
                                fluid

                                closeOnChange

                                options={addressOptions || []}
                                loading={addressSearchIsFetching === true}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>{t("points_label_company")}</label>
                            <span>{value.companyName || <span className="tc-grey">{t("points_text_no_data")}</span>}</span>
                        </Form.Field>
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t("base_btn_cancel")}</Button>
                <Button
                    loading={isSubmitting}
                    positive
                    onClick={submit}
                    disabled={!valueIsOk}>{t("base_btn_add")}</Button>
            </Modal.Actions>
        </Modal>
    );
};

