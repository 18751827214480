import React from 'react';
import {Route, Switch} from "react-router";
import ErrorNotFound from "../ErrorNotFound";
import BannersList from "./list";
import './style.css';

const Banners = () => {
    return (
        <Switch>
            <Route exact path="/banners" component={BannersList} />
            <Route component={ErrorNotFound} />
        </Switch>
    )
};

export  default Banners;
