import React from 'react';
import { Label, Icon } from 'semantic-ui-react';
import { getCompanyTypeName, getPointStatusColor, getPointStatusName,
    PointBookmarkTypeColor, PointBookmarkTypeName,
    PointBookmarkTypeOptions } from '../../../../api/model/Point';
import {getProviderStatusName, getProviderStatusColor} from '../../../../api/model/Provider';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import T from '../../../../components/Translate';
import { useTranslation } from "react-i18next";
import PointViewFooterExportStatusLabel from '../pointView/PointViewFooterExportStatusLabel';

const PointGridCell = ({ item, keyName, onChange = () => {} }) => {
    const val = item[keyName];

    const { t } = useTranslation();

    switch (keyName) {
        case 'companyName':
            return val;
        case 'status':
            return <Label
                key={keyName}
                color={getProviderStatusColor(val)}>
                {t(getProviderStatusName(val))}
            </Label>;
        case 'companySearchName':
        case 'fmid':
        case 'address':
            return val || <span style={{color: "#cccccc"}}>{t("points_text_not_set")}</span>;
        case 'exportStatus':
            return <PointViewFooterExportStatusLabel value={val}/>;
        case 'companyType':
        case 'pointType':
            return t(getCompanyTypeName(val));
        case 'pointStatus':
        case 'statusId':
            return <Label
                        key={keyName}
                        color={getPointStatusColor(val)}>
                        {t(getPointStatusName(val))}
                    </Label>;
        case 'bookmarkType':
            if (item.pointStatus < 11) {
                return null;
            }
            return <>
                <Icon
                    name="bookmark outline"
                    color={PointBookmarkTypeColor[val]}
                    title={PointBookmarkTypeName[val]}
                    style={{
                        position: 'absolute',
                        marginTop: 5
                    }}
                />

                <InputDropdown
                    placeholder={t("points_placeholder_in_favourites")}
                    options={PointBookmarkTypeOptions}
                    value={val}
                    onChange={val => onChange('bookmarkType', val)}
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        display: 'block',
                        marginLeft: -8,
                        marginRight: -8,
                        marginTop: -5,
                        marginBottom: -5,
                        boxShadow: 'none',
                        paddingLeft: 30
                    }}
                />
            </>;
        default:
            return keyName.includes('.')
                ? keyName.split('.').reduce((o, i) => o[i], item)
                : val || <span style={{color: "#cccccc"}}>{t("points_text_not_set")}</span>;
    }
};

export default PointGridCell;
