import React from 'react';
import { Label } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
 
export default function PointViewFooterExportStatusLabel({value}) {

    const { t } = useTranslation();

    let exportStatusColor, exportStatusName;

    switch (value) {
        case 1:
            exportStatusColor = "teal";
            exportStatusName = t("points_text_signed_up");
            break;
        case 2:
            exportStatusColor = "red";
            exportStatusName = t("points_text_sign_up_error");
            break;
        default:
            exportStatusColor = null;
            exportStatusName = t("points_text_not_signed_up");
            break;
    }

    return (
        <Label
            key={value}
            color={exportStatusColor}>
            {t(exportStatusName)}
        </Label>
    );
}