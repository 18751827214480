import moment from 'moment';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { PointTimeslot } from '../../../api/model/PointTimeslot';
import { RecordData, TableRecord, TableRecordElement } from './record/Record';
import { useTranslation } from 'react-i18next';


const styleNoBorderYellow = {
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    borderTop: '2px solid #fbbd08'
};

const dateFormat = "DD.MM.YYYY";

/**  */
export default function CardLoading({ point, getServiceName, submitPoint }) {
    const { t } = useTranslation();

    const noData = `[${t('waybills_label_no-data')}]`;
    const getValue = val => val ? ": " + val : "";

    const shipper = new RecordData(t('waybills_label_shipper'), point.companyName || noData);

    const adr = <span>{point.address || noData}</span>;

    const formatNumber = (number) => {
        if (number % 1 !== 0) {
            return number.toFixed(3).replace(/0+$/, '');
        }
        return number;
    };

    const buildContact = (contact) => {
        if (!contact) return null;
        const { name, phone } = contact;
        if (name && phone) return `${name}, ${phone}`;
        if (!name && !phone) return null;
        return `${(name || '')}${phone || ''}`;
    };

    const loadDate = new RecordData(t('waybills_label_date-arrival-address'), moment(point.arrivalDatePlan).format(dateFormat) || noData);    // "Дата привоза на FM"
    const loadTime = new RecordData(t('waybills_label_time-arrival-address'), (point.arrivalTimeslotPlan && new PointTimeslot(point.arrivalTimeslotPlan).toString()) || noData);        // "Время привоза на FM"
    const services = new RecordData(t('waybills_label_options'), point.services && point.services.length && point.services.map(s => `${t(getServiceName(s.key))}${getValue(s.value)}`).join('; '));
    const contact = new RecordData(t('waybills_label_contact'), buildContact(point.contact));

    const loadUnitsCount = new RecordData(t('waybills_label_load-units-count'), point.loadUnitSets.reduce((result, us) => result + Number.parseInt(us.loadUnitsCount || 0), 0));
    const loadWeight = new RecordData(t('waybills_label_weight-kg-gross'), formatNumber(point.loadUnitSets.reduce((result, us) => result + Number.parseFloat(us.loadTotalWeigthBruttoKg || 0), 0)));
    const loadVolume = new RecordData(t('waybills_grid_volume-m3'), formatNumber(point.loadUnitSets.reduce((result, us) => result + Number.parseFloat(us.loadTotalVolumeM3 || 0), 0)));
	// const sscc = point.loadUnitSets.map(lus => lus.loadUnitsSSCCNos).join(',');
	// const loadSSCC = new RecordData(t('waybills_label_sscc'), sscc);


    const tableLayout = (
        <Table basic fixed style={styleNoBorderYellow}>
            <Table.Body>
                <Table.Row style={{backgroundColor: '#eeeeee'}}>
                    <Table.Cell><TableRecord data={shipper} /></Table.Cell>
                    {point.comment
                        ? <>
                            <Table.Cell colSpan="2"><TableRecordElement label={t('waybills_grid_load-address')}>{adr}</TableRecordElement> </Table.Cell>
                            <Table.Cell><TableRecord data={new RecordData(t('waybills_grid_comment'), point.comment)} /></Table.Cell>
                        </>
                        : <Table.Cell colSpan="3"><TableRecordElement label={t('waybills_grid_load-address')}>{adr}</TableRecordElement></Table.Cell>
                    }
                </Table.Row>
                <Table.Row>
                    <Table.Cell><TableRecord data={loadDate} /></Table.Cell>
                    <Table.Cell><TableRecord data={loadTime} /></Table.Cell>
                    <Table.Cell><TableRecord data={services} /></Table.Cell>
                    <Table.Cell><TableRecord data={contact} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell><TableRecord data={loadUnitsCount} /></Table.Cell>
                    <Table.Cell><TableRecord data={loadWeight} /></Table.Cell>
                    <Table.Cell><TableRecord data={loadVolume} /></Table.Cell>
                    {/*<Table.Cell><TableRecord data={loadSSCC}/></Table.Cell>*/}
                </Table.Row>
            </Table.Body>
        </Table>
    );

    return tableLayout;
}
