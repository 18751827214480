import {debounce} from 'awesome-debounce-promise';
import React, {useState} from 'react';
import {Button, Divider, Form, Grid, GridColumn, Header} from 'semantic-ui-react';
import {companyUpdateOApi} from '../../../../api/companies';
import {getSubscriptionDocsTest, getSubscriptionStatusTest} from '../../../../api/waybillDocs';
import {useTranslation} from 'react-i18next';

const saveData = debounce((fmid, co) => companyUpdateOApi(fmid, co), 1200);

const validateData = data => {
    return !!(data.authLogin && data.authPassword && (data.urlStatus || data.urlDocs));
};

export default function CompanyTabOApi({data = {}, setData}) {

    const {t} = useTranslation();
    const oapi = data.companyOApi || {};
    const [dataIsValid, setDataIsValid] = useState(validateData(oapi));
    const hasAuth = oapi.authLogin && oapi.authPassword;
    const testDocsDisabled = !(hasAuth && isUrlValid(oapi.urlDocs));
    const testStatusDisabled = !(hasAuth && isUrlValid(oapi.urlStatus));

    function update(oapiChanged) {
        const dataChanged = {...data};
        dataChanged.companyOApi = {...dataChanged.companyOApi, ...oapiChanged};

        setData(dataChanged);
        setDataIsValid(validateData(dataChanged.companyOApi));
        saveData(dataChanged.fmid, dataChanged.companyOApi);
    }

    function isUrlValid(url) {
        return /^(http|https):\/\/[^ "]+$/.test(url);
    }

    return (
        <Grid>
            <Grid.Row>
                <GridColumn width={10}>
                    <Form className="p-l-5">
                        <Header>{t("users_label_api-accepting")}</Header>
                        <Form.Group widths="equal">
                            <Form.Checkbox
                                toggle
                                label={t("users_grid_api-tms")}
                                checked={oapi.apiWaybillsActive}
                                onChange={(e, item) => update({apiWaybillsActive: item.checked})}
                            />
                            <Form.Checkbox
                                toggle
                                label={t("users_grid_api-wms")}
                                checked={oapi.apiWMSWaybillsActive}
                                onChange={(e, item) => update({apiWMSWaybillsActive: item.checked})}
                            />
                            <Form.Checkbox
                                toggle
                                label={t("users_grid_api-pooling")}
                                checked={oapi.apiPoolingWaybillsActive}
                                onChange={(e, item) => update({apiPoolingWaybillsActive: item.checked})}
                            />
                        </Form.Group>
                        <Divider/>
                        <Header>{t("users_label_api-sending")}</Header>
                        <Form.Group widths="equal">
                            <Form.Input
                                disabled={oapi.isActive}
                                required
                                label={t("users_input_login-api")}
                                placeholder={t("users_placeholder_login-api")}
                                value={oapi.authLogin}
                                onChange={e => update({authLogin: e.target.value})}
                            />
                            <Form.Input
                                disabled={oapi.isActive}
                                required
                                label={t("users_input_password-api")}
                                placeholder={t("users_placeholder_password-api")}
                                value={oapi.authPassword}
                                onChange={e => update({authPassword: e.target.value})}
                            />
                        </Form.Group>
                        {/* <Form.Field disabled={oapi.isActive}>
				<label>URL приема событий изменения статуса заявки</label>
				<Input
					placeholder='https://status_url'
					value={oapi.urlStatus}
					onChange={e => update({ urlStatus: e.target.value })}
				>
					<input />
					<Button
						primary
						content={'Тест'}
						style={{color: 'pink !important'}}
						disabled={testStatusDisabled}
						onClick={ () => getSubscriptionStatusTest(oapi.companyFMID) }
					/>
				</Input>
			</Form.Field> */}
                        <Form.Input
                            action={<Button
                                primary
                                content={t("users_input_test")}
                                disabled={testStatusDisabled}
                                onClick={() => getSubscriptionStatusTest(oapi.companyFMID)}/>}
                            disabled={oapi.isActive}
                            label={t("users_input_url")}
                            placeholder={'https://status_url'}
                            value={oapi.urlStatus}
                            onChange={e => update({urlStatus: e.target.value})}
                        />
                        <Form.Input
                            action={<Button primary
                                            content={t("users_input_test")}
                                            disabled={testDocsDisabled}
                                            onClick={() => getSubscriptionDocsTest(oapi.companyFMID)}/>}
                            disabled={oapi.isActive}
                            label={t("users_input_url-pod")}
                            placeholder={'https://docs url'}
                            value={oapi.urlDocs}
                            onChange={e => update({urlDocs: e.target.value})}
                        />
                        <Divider hidden/>
                        <Form.Checkbox
                            toggle
                            disabled={!dataIsValid}
                            label={t('users_input_sending-events-include')}
                            checked={oapi.apiEventsActive}
                            onChange={(e, item) => update({apiEventsActive: item.checked})}
                        />
                    </Form>
                </GridColumn></Grid.Row>
        </Grid>
    );

}
