import React from 'react';
import './ProgressVisual.css';
import {useTranslation} from 'react-i18next';
import {TransTypeEnum} from "../../../../api/model/Waybill";
import {copyTextFromEvent} from "../../../../services/utils";
import {toast} from "../../../../services/toast";


export default function ProgressVisual({data = [], pointsLoading = [], pointsUnloading = []}) {
    const {t} = useTranslation();

    const copyData = (e) => {
        if (window.getSelection().toString().length) return null;
        copyTextFromEvent(e);
        toast.success(t('base_label_copied'));
    };

    const dataTransTypePickUp = data && data.find(d => d.transType === TransTypeEnum.PICK_UP);
    const dataTransTypeDelivery = data && data.find(d => d.transType === TransTypeEnum.DELIVERY);
    const dataTransTypePickUpDelivery = data && data.find(d => d.transType === TransTypeEnum.PICK_UP_AND_DELIVERY);

    const classTransType = (type) => {
        switch (type) {
            case TransTypeEnum.PICK_UP:
                return 'pick-up';
            case TransTypeEnum.DELIVERY:
                return 'delivery';
            case TransTypeEnum.PICK_UP_AND_DELIVERY:
                return 'pick-up-delivery';
        }
    }

    const loadingAddress = (pointsLoading && pointsLoading.length) ? pointsLoading.sort((a, b) => a.positionNumber - b.positionNumber)[0].address : '';
    const unloadingAddress = (pointsUnloading && pointsUnloading.length) ? pointsUnloading.sort((a, b) => b.positionNumber - a.positionNumber)[0].address : '';

    const dataVehicleAndDriver = (data) => {
        const {driver, vehicle} = data;

        return <div className={`vehicle-driver-data vehicle-driver-data_${classTransType(data.transType)}`}>
            {
                driver && <>
                    <div onClick={copyData}>
                        {`${driver.lastName || ''} ${driver.firstName || ''} ${driver.patronymicName || ''} ${driver.licenseNumber || ''}`}
                    </div>
                    <div onClick={copyData}>
                        {`${driver.phoneNumber || ''}`}
                    </div>
                </>
            }
            {
                vehicle && <>
                    <div onClick={copyData} style={{textTransform: 'uppercase'}}>
                        {`${vehicle.truckModel || ''} ${vehicle.truckMake || ''} ${vehicle.truckType || ''} ${vehicle.truckLicensePlate || ''}`}
                    </div>
                    <div onClick={copyData}>
                        {`${vehicle.truckWidth ? `${vehicle.truckWidth}${t('waybills_label_meters')}${(vehicle.truckLength || vehicle.truckHeight)? ' x ' : ''}` : ''}${vehicle.truckLength ? `${vehicle.truckLength}${t('waybills_label_meters')}${vehicle.truckHeight? ' x ' : ''}` : ''}${vehicle.truckHeight ? `${vehicle.truckHeight}${t('waybills_label_meters')}`: ''}${((vehicle.truckWidth || vehicle.truckLength || vehicle.truckHeight) && vehicle.truckWeight) ? '; ' : ''}${vehicle.truckWeight ? `${vehicle.truckWeight}${t('waybills_label_tons')}` : ''}`}
                    </div>
                </>
            }
            {
                vehicle && <>
                    <div onClick={copyData} style={{textTransform: 'uppercase'}}>
                        {`${vehicle.trailerModel || ''} ${vehicle.trailerMake || ''} ${vehicle.trailerType || ''} ${vehicle.trailerLicensePlate || ''}`}
                    </div>
                    <div onClick={copyData}>
                        {`${vehicle.trailerWidth ? `${vehicle.trailerWidth}${t('waybills_label_meters')}${(vehicle.trailerLength || vehicle.trailerHeight)? ' x ' : ''}` : ''}${vehicle.trailerLength ? `${vehicle.trailerLength}${t('waybills_label_meters')}${vehicle.trailerHeight? ' x ' : ''}` : ''}${vehicle.trailerHeight ? `${vehicle.trailerHeight}${t('waybills_label_meters')}` : ''}${((vehicle.trailerWidth || vehicle.trailerLength || vehicle.trailerHeight) && vehicle.trailerWeight) ? '; ' : ''}${vehicle.trailerWeight ? `${vehicle.trailerWeight}${t('waybills_label_tons')}` : ''}`}
                    </div>
                </>
            }
        </div>;
    };

    return (
        <div className='progress-visual'>
            <div className='progress-visual__block' style={{justifyContent: !dataTransTypePickUp ? 'end' : 'start'}}>
                {dataTransTypePickUp && dataVehicleAndDriver(dataTransTypePickUp)}
                {dataTransTypeDelivery && dataVehicleAndDriver(dataTransTypeDelivery)}
                {dataTransTypePickUpDelivery && dataVehicleAndDriver(dataTransTypePickUpDelivery)}
            </div>

            <div className='progress-visual__block'>
                <svg width="400" height="20" style={{margin: '5px'}}>
                    <circle r="8" cx="10" cy="10"
                            fill="#d4d4d5"
                    />
                    <line
                        x1="18" y1="10" x2="382" y2="10"
                        stroke="black"
                    />
                    <circle r="8" cx="390" cy="10"
                            fill="#d4d4d5"
                    />
                </svg>
            </div>

            <div className='progress-visual__block'>
                <div onClick={copyData} className='progress-visual__address progress-visual__address_loading'>
                    {loadingAddress}
                </div>
                <div onClick={copyData} className='progress-visual__address progress-visual__address_unloading'>
                    {unloadingAddress}
                </div>
            </div>
        </div>
    );
}
