import {WaybillTypeEnum} from "../api/model/Waybill";

const newIndexes = (pointsLoading, pointsUnloading) => {
    let points = [...pointsLoading, ...pointsUnloading].sort((a, b) =>
        a.index - b.index);
    for (let i=0; i<points.length; i++){
        if (points[i].index !== i+1) points[i].index = i+1;
    }
    return points;
};

export default (wb) => {
    let newPointsLoading = [];
    let newPointsUnloading = [];
    let indexGlobal = 0;

    if (wb.shippingType === WaybillTypeEnum.FTL) {
        let allPoints = wb.pointsLoading.map(i => ({
            ...i,
            date: i.arrivalTimeslotPlan && i.arrivalTimeslotPlan.from && i.arrivalDatePlan
                ? new Date(`${i.arrivalDatePlan.split('T')[0]}T${i.arrivalTimeslotPlan.from}`)
                : i.arrivalDatePlan ? new Date(i.arrivalDatePlan) : null,
        }))
            .concat(wb.pointsUnloading.map(i => ({
                ...i,
                date: i.arrivalTimeslotPlan && i.arrivalTimeslotPlan.from && i.arrivalDatePlan
                    ? new Date(`${i.arrivalDatePlan.split('T')[0]}T${i.arrivalTimeslotPlan.from}`)
                    : i.arrivalDatePlan ? new Date(i.arrivalDatePlan) : null,
            })));

        let sortPointFromDateTime = allPoints
            .filter(item => item.date)
            .sort((a, b) =>
                a.date.getTime() - b.date.getTime()) || [];

        let pointsNotDateTime = allPoints.filter(item => !item.date).sort((a, b) =>
            a.index - b.index);

        wb.pointsLoading.sort((a, b) =>
            a.index - b.index).forEach((item, index) => {
            const findIndexPointsLoading = sortPointFromDateTime.findIndex(i => i.id === item.id);
            if (findIndexPointsLoading >= 0) {
                let positionNumber = findIndexPointsLoading + 1;
                newPointsLoading.push({
                    ...item,
                    positionNumber
                })
            } else {
                let positionNumber = sortPointFromDateTime.length + pointsNotDateTime.findIndex(i => i.id === item.id) + 1;
                newPointsLoading.push({
                    ...item,
                    positionNumber
                })
            }
        });

        wb.pointsUnloading.sort((a, b) =>
            a.index - b.index).forEach(item => {
            const findIndexPointsUnloading = sortPointFromDateTime.findIndex(i => i.id === item.id);
            if (findIndexPointsUnloading >= 0) {
                let positionNumber = findIndexPointsUnloading + 1;
                newPointsUnloading.push({
                    ...item,
                    positionNumber
                })
            } else {
                let positionNumber = sortPointFromDateTime.length + pointsNotDateTime.findIndex(i => i.id === item.id) + 1;
                newPointsUnloading.push({
                    ...item,
                    positionNumber
                })
            }
        });
    } else {
        wb.pointsLoading.forEach(item => {
            let positionNumber = ++indexGlobal;
            newPointsLoading.push({
                ...item,
                positionNumber
            })
        });
        wb.pointsUnloading.forEach(item => {
            let positionNumber = ++indexGlobal;
            newPointsUnloading.push({
                ...item,
                positionNumber
            })
        });
    }

    let pointsNewIndexes = newIndexes(newPointsLoading, newPointsUnloading);

    return {
        ...wb,
        pointsLoading: [...pointsNewIndexes.filter(p=>p.type===0)],
        pointsUnloading: [...pointsNewIndexes.filter(p=>p.type===1)],
    };

}
