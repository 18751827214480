import React from 'react';
import {Button, Dimmer, Form, Grid, GridColumn, Loader, Message, Modal} from "semantic-ui-react";
import {PackTypeEnum, TypeAssemblyOptions} from "../../../api/model/Enums";
import InputDropdown from "../../../components/inputs/InputDropdown";
import WbAvizAvailableTimeslot from "./WbAvizAvailableTimeslot";
import {waybillAvisationRegistration, waybillAvisationSet} from "../../../api/waybills";
import moment from "moment";
import { withTranslation } from 'react-i18next';

class WbAvizModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.setInitial(props);
    }


    setInitial = (props) => {
        let countPallet = 0;
        let countBox = 0;
        let textCountPallet = '';

        props.selectedRows.forEach(value => {
            const {totalUnits} = value;
            for (let variable in totalUnits) {
                if (variable === PackTypeEnum.BX || variable === PackTypeEnum.LB) {
                    countBox = countBox + totalUnits[variable]
                } else if (variable !== PackTypeEnum.PP) {
                    countPallet = countPallet + totalUnits[variable]
                }
            }

        });
        if (countBox > 0) {
            textCountPallet = this.getLabelCountPallet(countBox);
        }
        return {
            dto: {
                typeAssembly: 1,
                countPallet: null,
                typeTransport: null,
                driver: null,
                transportNumber: null,
                dateTimeAvisation: {date: this.props.selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0]}
            },
            step: 0,
            errors: [],
            countPallet,
            countBox,
            textCountPallet,
            loading: false
        }
    };


    componentDidMount() {
        this.setState({
            loadingDateMin: this.props.selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0],
            deliveryDateMin: this.props.selectedRows.length > 1 ? this.props.selectedRows.map(i => new Date(i.deliveryDate)).sort((a, b) => a - b)[0] : this.props.selectedRows.map(i => new Date(i.deliveryDate))[0]
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const {dto: dtoPrevState} = prevState;
        const {selectedRows} = this.props;
        const {selectedRows: selectedRowsPrevProps} = prevProps;
        if (!dtoPrevState.typeTransport && this.state.dto.typeTransport ||
            !dtoPrevState.dateTimeAvisation.timeslot && this.state.dto.dateTimeAvisation.timeslot ||
            dtoPrevState.dateTimeAvisation.date && dtoPrevState.dateTimeAvisation.date !== this.state.dto.dateTimeAvisation.date && moment(this.state.dto.dateTimeAvisation.date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
            this.setState({
                errors: []
            })
        }
        if (selectedRowsPrevProps !==  this.props.selectedRows) {
            let countPallet = 0;
            let countBox = 0;
            let textCountPallet = '';
            selectedRows.forEach(value => {
                const {totalUnits} = value;
                for (let variable in totalUnits) {
                    if (variable === PackTypeEnum.BX || variable === PackTypeEnum.LB) {
                        countBox = countBox + totalUnits[variable]
                    } else if (variable !== PackTypeEnum.PP) {
                        countPallet = countPallet + totalUnits[variable]
                    }
                }

            });

            if (countBox > 0) {
                textCountPallet = this.getLabelCountPallet(countBox);
            }

            let newDto =  {...this.state.dto};
            newDto.dateTimeAvisation = {date: selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0]}
            this.setState({
                deliveryDateMin: this.props.selectedRows.length > 1 ? this.props.selectedRows.map(i => new Date(i.deliveryDate)).sort((a, b) => a - b)[0] : this.props.selectedRows.map(i => new Date(i.deliveryDate))[0],
                countPallet,
                countBox,
                textCountPallet,
                dto:newDto,
                loadingDateMin: selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0],
            })
        }
    }

    async handleOpen() {
    }

    handleClose() {
        const {onClose} = this.props;
        this.setState(this.setInitial(this.props));
        onClose && onClose();
    }

    getInfoCount() {
        const {countBox, countPallet} = this.state;
        const {selectedRows} = this.props;
        const numbers = selectedRows.map(item => item.id);
        const { t } = this.props;

        return (

            <div>
                {t('waybills_label_your-orders')} {numbers.join(', ')} {t('waybills_label_contain')}:
                {countBox > 0 && <div>{countBox} {t('waybills_label_box')}</div>}
                {countPallet > 0 && <div>{countPallet} {t('waybills_label_pallet')}</div>}
            </div>)
    }


    getInfoAvisation() {
        const {countBox, countPallet, loadingDateMin} = this.state;
        const {selectedRows} = this.props;
        const { t } = this.props;

        return (
            <div>
                {t('waybills_label_select-timeslot-for')}:
                {countBox > 0 && <span> {countBox} {t('waybills_label_box')}</span>}
                {countPallet > 0 && countBox > 0 && <span> {t('waybills_label_and')}</span>}
                {countPallet > 0 && <span> {countPallet} {t('waybills_label_pallet')}</span>}
                {<span> {t('waybills_label_on-the')}</span>}
                {<span> {new Date(loadingDateMin).toLocaleDateString()}. </span>}
                {<span>{t('waybills_label_select-day')}</span>}
                {<span>{selectedRows.length} </span>}
                {<span>{selectedRows.length > 1 ? t('waybills_label_orders') : t('waybills_label_orders1')}</span>}:
                {selectedRows.map((i, index) => (
                    <div key={index}>{i.id} : {new Date(i.loadingDate).toLocaleDateString()}</div>
                ))}
            </div>)
    }

    handleOnChangeDto(param, value) {
        const {dto} = this.state;
        let newDto = {...dto};
        newDto[param] = value;
        this.setState({
            dto: newDto,
            errors: []
        })
    }

    onChangeDtoTimeSlot(value) {
        const {dto} = this.state;
        let newDto = {...dto};
		newDto.dateTimeAvisation = value;
        this.setState({
            dto: newDto
        });
        if (
            moment(newDto.dateTimeAvisation.date).format('YYYY-MM-DD') <
            moment().format('YYYY-MM-DD')
        ) {
            this.setState(prevState => {
                const { errors: prevErrors } = prevState;
                if (prevErrors.includes('dateInvalid')) {
                    return { errors: prevErrors };
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('dateInvalid');
                return { errors: errorsNew };
            });
        } else {
            this.setState({
                errors: []
            })
        }
    }

    async onClickChooseTimeAvisation() {
        const {dto, countBox, loadingDateMin} = this.state;
        if (!dto.typeTransport) {
            return this.setState((prevState) => {
                const {errors: prevErrors} = prevState;
                if (prevErrors.includes('typeTransport')) {
                    return {errors: prevErrors};
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('typeTransport');
                return {errors: errorsNew};
            })
        }
        if (dto.typeAssembly === 2 && countBox > 0 && !dto.countPallet) {
            return this.setState((prevState) => {
                const {errors: prevErrors} = prevState;
                if (prevErrors.includes('countPallet')) {
                    return {errors: prevErrors}
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('countPallet')
                return {errors: errorsNew}
            })
        }
        const param = {
            date: `${moment(loadingDateMin).format('YYYY-MM-DD')}`,
            waybillIds: this.props.selectedRows.map(i => i.id),
            weightCapacity: dto.typeTransport,
            driver: dto.driver,
            transportNumber: dto.transportNumber,
            bXTypeLaying: {
                EPCount: dto.countPallet || 0,
                BXPalletizing: dto.typeAssembly === 2
            }
        };
        this.setState({
            loading: true
        });
        const res =await waybillAvisationRegistration(param);
         if (res && res.errors) {
             return;
         }this.setState({
            loading: false,
            step: 1
        })
    }

    async onClickAvisation() {
        const {dto: {dateTimeAvisation}} = this.state;
        const {selectedRows, onAvisation} = this.props;
        if (!dateTimeAvisation.date) {
            return this.setState((prevState) => {
                const {errors: prevErrors} = prevState;
                if (prevErrors.includes('date')) {
                    return {errors: prevErrors};
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('date');
                return {errors: errorsNew};
            })
        }
        if (moment(dateTimeAvisation.date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            return this.setState((prevState) => {
                const {errors: prevErrors} = prevState;
                if (prevErrors.includes('dateInvalid')) {
                    return {errors: prevErrors};
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('dateInvalid');
                return {errors: errorsNew};
            })
        }
        if (!dateTimeAvisation.timeslot) {
            return this.setState((prevState) => {
                const {errors: prevErrors} = prevState;
                if (prevErrors.includes('timeslot')) {
                    return {errors: prevErrors};
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('timeslot');
                return {errors: errorsNew};
            })
        }

        if (this.state.errors.length > 0) {
            return
        }
        const params = {
            waybillIds: selectedRows.map(i => i.id),
            timeslot: {...dateTimeAvisation.timeslot, date: `${moment(dateTimeAvisation.date).format('YYYY-MM-DD')}`}
        };
        this.setState({
            loading: true
        });
        const res = await waybillAvisationSet(params);
        this.setState({
            loading: false
        });
        if (res && res.errors) {
            return;
        }
        onAvisation && onAvisation();
    }

    getLabelCountPallet(countBox) {
        const { t } = this.props;

        return  `${t('waybills_label_count-pallets-for')} ${countBox} ${t('waybills_label_box1')}`
    }

    render() {
        const { t } = this.props;

        const {selectedRows, optionsTypeTransport} = this.props;
        const {dto, countBox, errors, loadingDateMin, step, textCountPallet, loading} = this.state;

        return (
            <Modal
                trigger={this.props.children}
                closeOnDimmerClick={false}
                size="small"
                dimmer="inverted"
                closeIcon
                open={this.props.open}
                onOpen={this.handleOpen.bind(this)}
                onClose={this.handleClose.bind(this)}

            >
                <Modal.Header>{t('waybills_label_aviz-for')} {selectedRows.length} {selectedRows.length > 1 ? t('waybills_label_orders')
                    : t('waybills_label_orders1')}</Modal.Header>
                <Modal.Content>
                    <div className="m-b-10">
                        <Dimmer active={loading} inverted>
                            <Loader inverted/>
                        </Dimmer>
                        {step === 1 &&
                        <>
                            <Message compact info>
                                {this.getInfoAvisation()}
                            </Message>
                            <WbAvizAvailableTimeslot
                                dateArrival={dto.dateTimeAvisation.date || loadingDateMin}
                                deliveryDateMin={this.state.deliveryDateMin}
                                waybillIds={selectedRows.map(i => i.id)}
                                onChange={this.onChangeDtoTimeSlot.bind(this)}
                                errors={errors}
                            />
                        </>
                        }
                        {step === 0 && <>
                            <Message compact info>
                                {this.getInfoCount()}
                            </Message>
                            <Form>
                                <Grid columns='equal'>
                                    {countBox > 0 &&
                                    <Grid.Row>
                                        <GridColumn>
                                            <Form.Field
                                                control={InputDropdown}
                                                label={t('waybills_input_box-assembly-type')}
                                                options={TypeAssemblyOptions}
                                                placeholder={t('waybills_input_box-assembly-type')}
                                                value={dto.typeAssembly}
                                                onChange={(value) => this.handleOnChangeDto('typeAssembly', value)}>
                                            </Form.Field>
                                        </GridColumn>
                                        {dto.typeAssembly === 2 && <GridColumn>
                                            <Form.Input
                                                error={errors.includes('countPallet')}
                                                required={dto.typeAssembly === 2}
                                                label={textCountPallet}
                                                placeholder={textCountPallet}
                                                value={dto.countPallet || ''}
                                                onChange={e => this.handleOnChangeDto('countPallet', Number.parseInt(e.target.value.replace(/[^\d,.]/g, '')))}
                                            />

                                        </GridColumn>
                                        }
                                    </Grid.Row>
                                    }
                                    <Grid.Row>
                                        <GridColumn>
                                            <Form.Field
                                                control={InputDropdown}
                                                required
                                                fluid
                                                error={errors.includes('typeTransport')}
                                                clearable
                                                label={t('waybills_input_transport-type')}
                                                options={optionsTypeTransport}
                                                placeholder={t('waybills_input_transport-type')}
                                                value={dto.typeTransport}
                                                onChange={(value) => this.handleOnChangeDto('typeTransport', value)}>
                                            </Form.Field>
                                        </GridColumn>
                                       {/* <GridColumn>
                                            {false && <Segment>
                                                <div><Header as='h4'><T>Водитель</T> <T>и</T> <T>ТС</T></Header></div>
                                                <Form.Input
                                                    readOnly
                                                    label={'ФИО'.t}
                                                    placeholder={'ФИО'.t}
                                                    value={dto.driver.fullName || ''}
                                                />
                                                <Form.Input
                                                    readOnly
                                                    label={'Телефон'.t}
                                                    placeholder={'Телефон'.t}
                                                    value={dto.driver.phoneNumber || ''}
                                                />
                                                <Form.Input
                                                    readOnly
                                                    label={'Марка ТС'.t}
                                                    placeholder={'Марка ТС'.t}
                                                    value={dto.driver.markTransport || ''}
                                                />
                                                <Form.Input
                                                    readOnly
                                                    label={'Номер ТС'.t}
                                                    placeholder={'Номер ТС'.t}
                                                    value={dto.driver.numberTransport}
                                                />
                                            </Segment>
                                            }
                                        </GridColumn>*/}
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label={t('waybills_input_transport-num')}
                                                placeholder={t('waybills_input_transport-num')}
                                                value={dto.transportNumber}
                                                onChange={(e, {value}) => this.handleOnChangeDto('transportNumber', value)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label={t('waybills_input_driver')}
                                                placeholder={t('waybills_input_driver')}
                                                value={dto.driver}
                                                onChange={(e, {value}) => this.handleOnChangeDto('driver', value)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </>}
                    </div>
                    {errors.includes('typeTransport') && <Message warning>
                        <p>{t('waybills_label_fill-transport-type')}</p>
                    </Message>
                    }
                    {errors.includes('countPallet') && <Message warning>
                        <p>{t('waybills_label_fill-count-pallets')}</p>
                    </Message>
                    }
                    {errors.includes('timeslot') && <Message warning>
                        <p>{t('waybills_label_fill-timeslot')}</p>
                    </Message>
                    }
                    {errors.includes('date') && <Message warning>
                        <p>{t('waybills_label_fill-arrival-date')}</p>
                    </Message>
                    }
                    {errors.includes('dateInvalid') && <Message warning>
                        <p>{t('waybills_label_dates-error')}</p>
                    </Message>
                    }
                </Modal.Content>
                <Modal.Actions>
                    {step === 0 &&
                    <Button color='yellow' onClick={this.onClickChooseTimeAvisation.bind(this)}>{t('waybills_btn_aviz-select-time')}</Button>}
                    {step === 1 &&
                    <Button color='yellow' onClick={this.onClickAvisation.bind(this)}>{t('waybills_btn_aviz')}</Button>}
                </Modal.Actions>
            </Modal>
        )
    }
}

export default withTranslation()(WbAvizModal);
