import React from 'react';
import {
    Container,
    Header,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function ErrorNotAuthorized() {
    const { t } = useTranslation();

    return (
        <>
            <Container text style={{ marginTop: '7em' }}>
                <Header as='h1'>{t('accesses_label_error')}</Header>
                <p>{t('accesses_label_no-access')}</p>
            </Container>
        </>
    );
}