import { useContext } from 'react';
import { ContextLocale } from '../services/context';
import { translate } from '../services/locale';

export default function T({children}) {
    
    const contextLocale = useContext(ContextLocale);

    let onlyChildText = children || 'error';
    
    return translate(onlyChildText, contextLocale.locale);
}