import React, {useState} from 'react';
import {Button, Icon, Message, Modal, Table} from "semantic-ui-react";
import moment from 'moment';
import AddModalComponent from "./AddModalComponent";
import {fieldCompareOptions, fieldsTypeEnum} from "../../api/calculatingFieldsRules";
import {useTranslation} from 'react-i18next';


const TableComponent = ({companyId, rules, getRules, deleteRule, copyRule, getRuleById, createRule, editRule, isHeaderFixed}) => {
    const {t} = useTranslation();

    let [isNew, setIsNew] = useState(false);

    let [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState({
        open: false
    });

    let [deleteProgress, setDeleteProgress] = useState(false);


    const confirmDelete = async () => {
        setDeleteProgress(true);
        try {
            await deleteRule(isDeleteConfirmOpen.id, companyId);
            getRules();
            setIsDeleteConfirmOpen({open: false})
        } finally {
            setDeleteProgress(false);
        }
    };

    const handleDelete = (id) => {
        setIsDeleteConfirmOpen({
            open: true,
            id
        });
    };

    const handleCopy = async (id) => {

        const result = await copyRule(id, companyId);

        getRules();

        setIsNew(result.id);

        setTimeout(() => setIsNew(false), 2000);
    };

    return (
        <>
            <Table celled structured singleLine>
                <Table.Header className={isHeaderFixed && "table-header-fixed"}>
                    <Table.Row>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={3}>{t("waybillautocomplte_label_fillable-field")}</Table.HeaderCell>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={3}>{t("waybillautocomplte_label_field-condition")}</Table.HeaderCell>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={3}>{t("waybillautocomplte_label_condition-type")}</Table.HeaderCell>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={3}>{t("waybillautocomplte_label_target-value")}</Table.HeaderCell>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={3}>{t("users_input_value")}</Table.HeaderCell>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={1} textAlign="center">{t("banners_input_actions")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        rules.map((row, indexRow) => (
                            <React.Fragment key={row.id}>
                                {
                                    row.rules[0] &&
                                    <Table.Row className={isNew === row.id ? 'registry_cell_highlight' : ''}>
                                        <Table.Cell rowSpan={row.rules.length}>{row.targetField.name}</Table.Cell>
                                        <Table.Cell>{row.rules[0].name}</Table.Cell>
                                        <Table.Cell
                                            textAlign='center'>{t(fieldCompareOptions.find(option => option.value === row.rules[0].type).text)}</Table.Cell>
                                        <Table.Cell textAlign='center'>
                                            <div style={{
                                                width: '100px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}><span
                                                title={row.rules[0].fieldType === fieldsTypeEnum.Date ? moment(row.rules[0].values.join(', ')).format('DD.MM.YYYY') : row.rules[0].values.join(', ')}>
                                            {row.rules[0].fieldType === fieldsTypeEnum.Date ? moment(row.rules[0].values.join(', ')).format('DD.MM.YYYY') : row.rules[0].values.join(', ')}
                                            </span>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell textAlign='center'
                                                    rowSpan={row.rules.length}>
                                            {row.targetField.type === fieldsTypeEnum.Date ? moment(row.targetField.value).format('DD.MM.YYYY') : row.targetField.value}</Table.Cell>
                                        <Table.Cell rowSpan={row.rules.length}>
                                            <div className="table-rules-action">
                                                <AddModalComponent indexRule={row.index} id={row.id}
                                                                   getRuleById={getRuleById}
                                                                   createRule={createRule}
                                                                   editRule={editRule}
                                                                   companyId={companyId} fetchData={getRules}>
                                                    <Icon
                                                        name="pencil alternate"
                                                        link
                                                        className="m-l-15"
                                                        title={t("base_btn_edit")}
                                                        size="large"
                                                        color="blue"
                                                        onClick={() => {
                                                        }}
                                                    />
                                                </AddModalComponent>
                                                <Icon
                                                    name="copy outline"
                                                    link
                                                    className="m-l-15"
                                                    title={t("waybillautocomplte_label_copy")}
                                                    size="large"
                                                    color="grey"
                                                    onClick={() => handleCopy(row.id)}
                                                />
                                                <Icon
                                                    name="trash alternate"
                                                    link
                                                    className="m-l-15"
                                                    title={t("base_btn_delete")}
                                                    size="large"
                                                    color="red"
                                                    onClick={() => handleDelete(row.id)}
                                                />
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                }
                                {
                                    row.rules.map((extRow, index) => (
                                        <React.Fragment key={extRow.name}>
                                            {index !== 0 && (
                                                <Table.Row
                                                    className={isNew === row.id ? 'registry_cell_highlight' : ''}>
                                                    <Table.Cell>{extRow.name}</Table.Cell>
                                                    <Table.Cell
                                                        textAlign='center'>{t(fieldCompareOptions.find(option => option.value === extRow.type).text)}</Table.Cell>
                                                    <Table.Cell
                                                        textAlign='center'>
                                                        <div style={{
                                                            width: '100px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            <span
                                                                title={extRow.fieldType === fieldsTypeEnum.Date ? moment(extRow.values.join(', ')).format('DD.MM.YYYY') : extRow.values.join(', ')}>
                                                                {extRow.fieldType === fieldsTypeEnum.Date ? moment(extRow.values.join(', ')).format('DD.MM.YYYY') : extRow.values.join(', ')}
                                                            </span>
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </React.Fragment>
                                    ))
                                }
                            </React.Fragment>
                        ))
                    }
                </Table.Body>
            </Table>
            <Modal open={isDeleteConfirmOpen.open}>
                <Modal.Header>{t("waybillautocomplte_label_delete-rule")}</Modal.Header>
                <Modal.Content>
                    <Message
                        icon="info"
                        info
                        content={<div>
                            <div>{t("waybillautocomplte_label_rule-will-be-deleted")}</div>
                            <div>{t("waybillautocomplte_label_confirm-delete")}</div>
                        </div>}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => setIsDeleteConfirmOpen({open: false})}
                    >
                        {t("base_btn_cancel")}
                    </Button>
                    <Button
                        color="green"
                        loading={deleteProgress}
                        onClick={confirmDelete}
                    >
                        {t("base_btn_delete")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default TableComponent;
