import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Button, Checkbox, Icon, Menu, Message, Modal, Popup, Table} from "semantic-ui-react";
import AddScheduleModal from "./addScheduleModal";
import {copySchedule, deleteSchedule, exportSettings, getSettings} from "../../api/schedules";
import qs from "query-string";
import {CargoTypeOptions, WeekDayOptions} from "../../api/model/Enums";
import {nanoid} from "nanoid";
import iconClearFilters from "../waybills/wbGrid/WbGridHeadersClearFiltersIcon.png";
import useReactRouter from 'use-react-router';
import WbGridFilterStringContains from "../waybills/wbGrid/wbGridFilters/WbGridFilterStringContains";
import WbGridFilterList from "../waybills/wbGrid/wbGridFilters/WbGridFilterList";
import Shield from "../../layout/page/shield/Shield";
import {ContextUser} from "../../services/context";
import UserPermissions from "../../api/model/UserPermissions";
import {useTranslation} from 'react-i18next';

const styleDisabledHeader = {
    background: "#f9fafb",
    cursor: "default",
};

const headers = [
    {
        key: 'viewId',
        text: "schedules_grid_identifier",
        sortable: true,
    },
    {
        key: 'cargoTypes',
        text: "schedules_grid_cargo-type",
        sortable: true,
        source: CargoTypeOptions,
    },
    {
        key: 'chain',
        text: "schedules_grid_cargo-network",
        sortable: true,
    },
    {
        key: 'searchname',
        text: 'graphics_grid_searchname',
        sortable: true,
    },
    {
        key: 'sendingRegion',
        text: "schedules_grid_sending-region",
        sortable: true,
    },
    {
        key: 'destinationRegion',
        text: "schedules_grid_destination-region",
        sortable: true,
    },
    {
        key: 'destinationCity',
        text: "schedules_grid_destination-city",
        sortable: true,
    },
    {
        key: 'deadlineDay',
        text: "schedules_grid_deadline-day",
        sortable: true,
        source: WeekDayOptions,
    },
    {
        key: 'deliveryDay',
        text: "schedules_grid_delivery-day",
        sortable: true,
        source: WeekDayOptions,
    },
    {
        key: 'daysRoad',
        text: "schedules_grid_days-road",
        sortable: true,
    },
    {
        key: 'recommendedTimeFrom',
        text: "schedules_grid_recommended-time-from",
        sortable: true,
    },
    {
        key: 'recommendedTimeTo',
        text: "schedules_grid_recommended-time-to",
        sortable: true,
    },
    {
        key: 'comment',
        text: "schedules_grid_comments",
    },
    {
        key: 'additionalInformationForClient',
        text: "schedules_grid_additional-information-for-client",
    },
    {
        key: 'companies',
        text: "schedules_grid_client",
    },
];

const SchedulesConfig = () => {
    const {t} = useTranslation();

    const context = useContext(ContextUser);

    const user = context && context.current;

    let [openModal, setOpenModal] = useState(false);
    let [selectedIds, setSelectedIds] = useState(new Set());
    let [rows, setRows] = useState([]);
    let [loading, setLoading] = useState(false);
    let [deleteProgress, setdDleteProgress] = useState(false);
    let [columns, setColumns] = useState({one: [], two_x: [], two: [], three: []});
    let [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState({open: false});
    let [isNew, setIsNew] = useState(false);

    let [maxPoints, setMaxPoints] = useState(0);

    const {location, history} = useReactRouter();

    let query = qs.parse(location.search);

    const {sortBy, sortDir} = query;

    function updateQuery(q) {
        query = q;
        history.push('?' + qs.stringify(query));
    }

    const handleSort = clickedColumn => () => {
        let {sortBy, sortDir} = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = 'ascending';
        } else {
            sortDir = sortDir === 'ascending' ? 'descending' : 'ascending';
        }

        updateQuery({...query, sortBy, sortDir});
    };

    function applyFilter(filter, index) {
        const [[key]] = Object.entries(filter);
        const [indexKey] = key.split('.');
        updateQuery({...query, ...filter});
        index !== undefined && updateQuery({...query, [`${indexKey}.index`]: index});
        selectedIds.size && setSelectedIds(new Set());

    }

    function removeFilter(key) {
        updateQuery({...query, [key]: undefined});
        const [keyQ] = key.split('.');
        if (key.includes('points') && Object.keys(query).filter(key => key.includes(keyQ)).length !== 3) {
            updateQuery({...query, [`${keyQ}.index`]: undefined});
        }

        selectedIds.size && setSelectedIds(new Set());
    }


    useEffect(() => {
        //getPointsRequest();
        fetchData();
    }, [location.search]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await getSettings(location.search);

            setRows(result.schedules || []);

            const maxPoints = result.pointsCount;

            setMaxPoints(maxPoints);

            let pointsColumns = [];

            for (let i = 0; i < maxPoints - 1; i++) {
                pointsColumns.push({
                    key: 'deliveryDay',
                    index: i + 1,
                    text: {key: "schedules_text_delivery-day-x", index: i + 2},
                    source: WeekDayOptions,
                    //sortable: true,
                    filterKey: 'points'
                });
                pointsColumns.push({
                    key: 'sendingDay',
                    index: i + 1,
                    text: {key: "schedules_text_sending-day-x", index: i + 2},
                    source: WeekDayOptions,
                    //sortable: true,
                    filterKey: 'points'
                })
            }

            let columnsObj = {
                one: headers.slice(0, 8),
                two_x: [
                    {
                        key: 'deliveryDay',
                        index: 0,
                        text: {key: "schedules_text_delivery-day-x", index: 1},
                        source: WeekDayOptions,
                        //sortable: true,
                        filterKey: 'points'
                    },
                    {
                        key: 'sendingDay',
                        index: 0,
                        text: {key: "schedules_text_sending-day-x", index: 1},
                        source: WeekDayOptions,
                        //sortable: true,
                        filterKey: 'points'
                    },
                ],
                two: [
                    ...pointsColumns,
                ],
                three: headers.slice(8)
            };

            setColumns(columnsObj);
        } finally {
            setLoading(false);
        }
    };

    const headerColumns = useMemo(() => {
        return [...columns.one, ...columns.two_x, ...columns.two, ...columns.three]
    }, [columns]);


    const handleCreateClick = (id) => {
        setOpenModal({open: true, id});
    };

    const handleCloseModal = () => {
        setOpenModal({open: false});
    };

    const deselect = () => {
        if (selectedIds.size) {
            setSelectedIds(new Set())
        } else {
            setSelectedIds(new Set(rows.map(item => item.id)))
        }
    };

    const handleRowSelection = id => {
        const newSelectedIds = new Set(selectedIds);

        newSelectedIds[!selectedIds.has(id) ? "add" : "delete"](id);

        setSelectedIds(newSelectedIds);
    };


    const getColumnsHeader = ({points: rowPoints}) => {
        const cells = [];
        for (let i = 1; i <= columns.two.length / 2; i++) {
            cells.push(<Table.Cell
                colSpan={2}
                textAlign="center"
                key={nanoid()}
                className={"schedule-cell-header"}
            >
                {
                    rowPoints[i] ? rowPoints[i].fmid : ''
                }
            </Table.Cell>)
        }

        return cells;
    };

    const getCell = (column, row) => {
        switch (column.key) {
            case 'deadlineDay':
            case 'sendingDay':
            case 'deliveryDay':
                return t(WeekDayOptions.getText(row[column.key]));
            case 'cargoTypes':
                return t(row[column.key].map(item => CargoTypeOptions.getText(item)).join(', '));
            case 'companies':
                return <div style={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}><span
                    title={row[column.key].isAll ? t("base_label_all")  : row[column.key].ids.join(', ')}>{row[column.key].isAll ? t("base_label_all") : row[column.key].ids.join(', ')}</span>
                </div>;
            case 'recommendedTimeFrom':
            case 'recommendedTimeTo':
                return row[column.key] && row[column.key].slice(0, 5);
            default:
                return <div style={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}><span title={row[column.key] && row[column.key.toString()]}>{t(row[column.key])}</span></div>
        }
    };

    const clearFilters = () => {
        updateQuery({});
        selectedIds.size && setSelectedIds(new Set());
    };

    const clearFilterButton = (
        <span
            className="table-clear-filters"
            onClick={clearFilters}
            style={{paddingTop: '5px', display: 'inline-block', width: '17px'}}>
            <img
                src={iconClearFilters}
                alt="icf"
                style={{height: '13px'}}/>
        </span>
    );

    const getFilter = (key, sources, filterKey, index) => {
        switch (key) {
            case 'viewId':
            case 'chain':
            case 'sendingRegion':
            case 'destinationCity':
            case 'daysRoad':
            case 'comment':
            case 'additionalInformationForClient':
            case 'clients':
                return <WbGridFilterStringContains
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'recommendedTimeTo':
            case 'recommendedTimeFrom':
                return <WbGridFilterStringContains
                    field={key}
                    isTime
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'cargoTypes':
            case 'deadlineDay':
            case 'sendingDay':
            case 'deliveryDay':
                const getPointFilters = (filter) => {
                    let pointFilters = {};

                    for (let key in query) {
                        if (key.includes('points')) {
                            const [key1, key2] = key.split('.');
                            pointFilters = {
                                ...pointFilters,
                                [key1]: {
                                    ...pointFilters[key1],
                                    [key2]: query[key]
                                }
                            }
                        }
                    }
                    return pointFilters;
                };

                const getKey = (keyProp) => {
                    let keyQ;

                    for (let key in query) {
                        if (key.includes('index') && query[key] == index) {
                            [keyQ] = key.split('.')
                        }
                    }


                    return keyQ ? `${keyQ}.${keyProp}` : `points[${Object.keys(getPointFilters()).length}].${keyProp}`;
                };

                return <WbGridFilterList
                    field={filterKey ? getKey(key) : key}
                    applyFilter={(key) => applyFilter(key, index)}
                    removeFilter={removeFilter}
                    options={sources || []}/>;
            default:
                return <div
                    className="filter-flex-container"/>;
        }
    };

    const confirmDelete = async () => {
        setdDleteProgress(true);
        try {
            await deleteSchedule(Array.from(selectedIds));

            setSelectedIds(new Set());

            await fetchData();
        } finally {
            setdDleteProgress(false);
            setIsDeleteConfirmOpen({open: false})
        }

    };

    const handleDeleteClick = () => {
        setIsDeleteConfirmOpen({open: true})
    };

    const handleCopyClick = async () => {
        const result = await copySchedule(Array.from(selectedIds)[0]);

        fetchData();

        setIsNew(result.id);

        setTimeout(() => setIsNew(false), 2000);
    };

    const handleExportClick = async () => {
        let pointsFilter = [];
        let filter = {};

        Object.keys(query).filter(key => !key.includes('points')).forEach(key => {
            filter = {
                ...filter,
                [key]: query[key]
            }
        });

        for (let i = 0; i < maxPoints; i++) {
            const keys = Object.keys(query).filter(key => key.includes(i.toString()));
            if (keys && keys.length) {
                let obj = {};
                keys.forEach(item => {
                    const [k1, k2] = item.split('.');
                    obj = {
                        ...obj,
                        [k2]: query[item]
                    }
                });

                pointsFilter.push(obj)
            }
        }


        const fileInfo = await exportSettings({
            ids: Array.from(selectedIds),
            filter: {
                ...filter,
                points: pointsFilter,
            }
        });


        if (!fileInfo.error) {
            window.open(`/api/file/${fileInfo.id}`, "_blank");
        }
    };

    return (
        <Shield loading={loading} loadingOver={true}>
            <Menu className="waybills-toolbar shd-inset" style={{marginBottom: '0'}} size='small' borderless>
                <Menu.Menu position='right'>
                    <Menu.Item
                        disabled={!selectedIds.size || !user.permissions.includes(UserPermissions.SCHEDULES_EDIT)}
                        onClick={handleDeleteClick}>
                        <Icon name="trash"/>{t("schedules_label_delete-schedule")}
                    </Menu.Item>
                    <Menu.Item
                        disabled={selectedIds.size !== 1 || !user.permissions.includes(UserPermissions.SCHEDULES_EDIT)}
                        onClick={handleCopyClick}>
                        <Icon name="copy"/>{t("schedules_label_copy-schedule")}
                    </Menu.Item>
                    <Menu.Item onClick={() => handleExportClick()}>
                        <Icon name="arrow up"/>{t("schedules_label_export-excel")}
                    </Menu.Item>
                    <Menu.Item disabled={!user.permissions.includes(UserPermissions.SCHEDULES_EDIT)}
                               onClick={() => handleCreateClick()}>
                        <Icon name="plus"/>{t("schedules_label_new-schedule")}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <AddScheduleModal {...openModal} fetchData={fetchData} onClose={handleCloseModal}/>
            <div className="table-wrapper-grid table-wrapper-grid-175">
                <Table
                    celled
                    sortable
                    singleLine
                    structured
                >
                    <Table.Header className="table-header-fixed">
                        <Table.Row>
                            <Table.HeaderCell
                                className="table-first-col table-item-sticky table-registry-select-all"
                                style={{zIndex: "6"}}
                            >
                                <Checkbox
                                    checked={selectedIds.size && selectedIds.size === rows.length}
                                    indeterminate={
                                        selectedIds.size &&
                                        selectedIds.size !== rows.length
                                    }
                                    onChange={deselect}
                                />
                            </Table.HeaderCell>
                            {headerColumns.map(item => (
                                <Table.HeaderCell
                                    className="table-header-fixed__title"
                                    key={item.index !== undefined ? `${item.key}_${item.index}` : item.key}
                                    sorted={item.sortable && sortBy === item.key ? sortDir : null}
                                    style={item.sortable ? null : styleDisabledHeader}
                                    onClick={item.sortable ? handleSort(item.key) : null}
                                >
                                    {typeof item.text === 'object' ? t(item.text.key, {index: item.text.index}) : t(item.text)}
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                        <Table.Row key="filters" style={{height: "37px"}}>
                            <Table.Cell
                                className="table-first-col table-item-sticky table-registry-filter-clear"
                                style={{
                                    backgroundColor: '#f9fafb',
                                    textAlign: 'center',
                                    paddingTop: '5px',
                                    paddingBottom: '5px'
                                }}>
                                <Popup content={t("base_btn_clear-all-filters")} trigger={clearFilterButton}/>
                            </Table.Cell>
                            {headerColumns.map(({key, source, filterKey, index}) => (
                                <Table.Cell
                                    key={key}
                                    sorted={sortBy === key ? sortDir : null}
                                    style={{background: '#fff;'}}
                                    className="table-select-cell table-filter-fixed__title"
                                >
                                    {getFilter(key, source, filterKey, index)}
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            rows.map(row => (
                                <React.Fragment key={row.id}>
                                    <Table.Row style={{cursor: 'pointer'}}
                                               className={isNew === row.id ? 'registry_cell_highlight' : ''}
                                               key={`${row.id}_0`}
                                               active={selectedIds.has(row.id)}>
                                        <Table.Cell
                                            rowSpan={2}
                                            className="table-first-col table-item-sticky"
                                        >
                                            <Checkbox
                                                id={row.id}
                                                checked={selectedIds.has(row.id)}
                                                onChange={(e) => {
                                                    handleRowSelection(row.id)
                                                }}
                                            />
                                        </Table.Cell>
                                        {columns.one.map(column => (
                                            <Table.Cell rowSpan={2}
                                                        key={`${row.id}_${column.key}_${column.index}`}
                                                        onClick={() => handleCreateClick(row.id)}
                                            >
                                                {getCell(column, row)}
                                            </Table.Cell>
                                        ))}
                                        {
                                            columns.two_x.map(column => (
                                                <Table.Cell
                                                    rowSpan={2}
                                                    key={column.index !== undefined ? `${row.id}_${column.key}_${column.index}` : `${row.id}_${column.key}`}
                                                    onClick={() => handleCreateClick(row.id)}
                                                >
                                                    {
                                                        row.points[column.index] ? t(WeekDayOptions.getText(row.points[column.index][column.key])) : ''
                                                    }
                                                </Table.Cell>
                                            ))
                                        }
                                        {getColumnsHeader(row)}
                                        {columns.three.map(column => (
                                            <Table.Cell rowSpan={2}
                                                        key={`${row.id}_${column.key}_${column.index}`}
                                                        onClick={() => handleCreateClick(row.id)}
                                            >
                                                {getCell(column, row)}
                                            </Table.Cell>
                                        ))}
                                    </Table.Row>
                                    <Table.Row
                                        key={`${row.id}_1`}
                                        className={isNew === row.id ? 'registry_cell_highlight' : ''}
                                        style={{cursor: 'pointer'}}
                                        active={selectedIds.has(row.id)}
                                    >
                                        {
                                            columns.two.map(column => (
                                                <Table.Cell
                                                    key={column.index !== undefined ? `${row.id}_${column.key}_${column.index}` : `${row.id}_${column.key}`}
                                                    onClick={() => handleCreateClick(row.id)}
                                                >
                                                    {
                                                        row.points[column.index] ? t(WeekDayOptions.getText(row.points[column.index][column.key])) : ''
                                                    }
                                                </Table.Cell>
                                            ))
                                        }
                                    </Table.Row>
                                </React.Fragment>
                            ))
                        }
                    </Table.Body>
                </Table>
            </div>
            <Modal open={isDeleteConfirmOpen.open}>
                <Modal.Header>{t("schedules_label_deleting-schedules")}</Modal.Header>
                <Modal.Content>
                    <Message
                        icon="info"
                        info
                        content={<div>
                            <div>{t("schedules_label_schedules-selected")}</div>
                            <div>
                                {t("schedules_label_schedules-confirm")}
                            </div>
                        </div>}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => setIsDeleteConfirmOpen({open: false})}
                    >
                        {t("base_btn_cancel")}
                    </Button>
                    <Button
                        color="green"
                        loading={deleteProgress}
                        onClick={confirmDelete}
                    >
                        {t("base_btn_delete")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Shield>
    );
};

export default SchedulesConfig;
