import debounce from 'awesome-debounce-promise';
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { getCompanyOptionsBySearch } from '../../api/companies';
import { toast } from '../../services/toast';
import O from '../Option';
import './InputDropdown.css';
import { useTranslation } from 'react-i18next';


const debounced = debounce((q, handler) => handler(q), 750);

function InputDropdownSearch({
    value: valueInitial,
    options: optionsInitial,
    getOptionsBySearch: getOptionsBySearchOuter,
    onChange,clearable = true,
                                                selectOnBlur,
                                                simple,
    allowAdditions,
                                                wrapSelection,
    placeholder
}) {
    const { t } = useTranslation();

    const [value, setValue] = useState(valueInitial || '');
    const [options, setOptions] = useState(optionsInitial || []);
    const [optionsNew, setOptionsNew] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isFetching, setIsFetching] = useState(false);


    function getOptionsBySearch(searchQuery) {
        //console.log("getOptionsBySearch for: " + searchQuery);

        setIsFetching(true);

        (getOptionsBySearchOuter || getCompanyOptionsBySearch)(searchQuery)
            .then(options => {
                setIsFetching(false);
                setOptionsNew(options || []);
            })
            .catch(err => {
                setIsFetching(false);
                toast.error("getOptionsBySearch error:\r\n" + err.message || JSON.stringify(err || null));
            });
    };

    function handleSearchQueryChange(searchQuery) {
        //console.log("handleSearchQueryChange for: " + searchQuery);

        setSearchQuery(searchQuery);
        if (searchQuery && searchQuery.length > 1) {
            debounced(searchQuery, getOptionsBySearch);
        }
    }

    function handleValueChange(e, { value }) {
        //console.log("handleValueChange for: " + value);

        searchQuery && setSearchQuery('');
        setValue(value);
        const selectedOption = options.find(o => o.key === value);
        const text = (selectedOption && selectedOption.text) || value;
        onChange(value, text);
    };

    function handleAdd(e, { value }) {
        //console.log("handleAdd for: " + value);

        const newOption = new O(value, value);
        setOptions([newOption, ...options]);
    }

    useEffect(() => {

        if (optionsNew && optionsNew.length) {
            const optionsExistingValues = options.map(o => o.value);
            const optionsNewFiltered = optionsExistingValues.length
                ? optionsNew.filter(o => !optionsExistingValues.includes(o.value))
                : optionsNew;
            setOptions([...options, ...optionsNewFiltered]);
        }
    }, [optionsNew]);

    const onBlurInput = () => {
        searchQuery && setSearchQuery('');
    };

    return (
        <Dropdown
            clearable={clearable}
            allowAdditions={allowAdditions}
            simple={simple}
            search
            searchQuery={searchQuery}
            value={value}
            selectOnBlur={selectOnBlur}
            noResultsMessage={t('base_label_no_results')}
            onSearchChange={(e, { searchQuery }) => handleSearchQueryChange(searchQuery)}
            onAddItem={handleAdd}
            onChange={handleValueChange}
            onBlur={onBlurInput}
            className="InputDropdown"
            placeholder={placeholder}
            fluid

            closeOnChange
            selection
            options={options}
            wrapSelection={wrapSelection}

            disabled={isFetching}
            loading={isFetching}
        />
    );

}

export default React.memo(InputDropdownSearch, (prevProps, nextProps) => prevProps.value === nextProps.value && prevProps.options === nextProps.options)

