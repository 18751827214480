import React, {useContext, useState} from 'react';
import {Button, Divider, Header, Item} from 'semantic-ui-react';
import {WaybillTypeEnum} from '../../../api/model/Waybill';
import WaybillPoint from '../../../api/model/WaybillPoint';
import WbPointAddForm from './WbPointAddForm';
import WbPointCardShell from './WbPointCardShell';
import {ContextWb} from '../../../services/context';
import { useTranslation } from 'react-i18next';

const POINTS_LOADING = 'pointsLoading';

export default function WbPointCards({
    title, profile, positions, updateWb, wbId, wbType, wbClientId,
    points, updatePoints, unitsLoadedTotal,
    activeTab, handleTabChange, oppositeDate, pointsAll,  isClient = false
}) {
    const { t } = useTranslation();

    const [isFormOpen, setIsFormOpen] = useState(false);

    const contextWb = useContext(ContextWb);

    const canEdit = contextWb.wb.__canEdit;

    function pointAdd(point) {

        const newItems = [...points, {...point, index: pointsAll.length + 1}];

        if (profile === POINTS_LOADING && point.hubLoading) {

            updateWb({ hubLoading: true, pointsLoading: newItems});

        } else {

            updatePoints(newItems); // shortcut for updateWb
        }

        handleTabChange();
    }

    function getPointRemoveUpdateScope(index) {

        const pointsCloned = [...points];

        const deletable = pointsCloned.splice(index, 1)[0];

        const updateScope = { [profile]: pointsCloned };

        if (profile === POINTS_LOADING) {

            const hubLoading = deletable.isHub;

            if (hubLoading) {

                updateScope.hubLoading = false;

                //updateScope.needPickup = false;
            }

            const validPositions = positions.filter(p => p.pointFromId !== deletable.id);

            if (validPositions.length !== positions.length) {

                updateScope.loadUnitSets = [...validPositions];
            }

        } else {

            const validPositions = positions.filter(p => p.pointToId !== deletable.id);

            if (validPositions.length !== positions.length) {

                updateScope.loadUnitsToOrderMappings = [...validPositions];
            }
        }

        return updateScope;
    }

    function pointRemove(index, skipUpdate) {

        const updateScope = getPointRemoveUpdateScope(index);

        updateWb(updateScope, { skipUpdate, isUpdatePickupType: profile === POINTS_LOADING });
    }

    function pointReplace(index, newPoint) {

        const updateScope = getPointRemoveUpdateScope(index);

        updateScope[profile] = [newPoint, ...updateScope[profile]];

        if (profile === POINTS_LOADING && newPoint.hubLoading) {

            updateScope.hubLoading = true;
        }

        updateWb(updateScope, {isUpdatePickupType: profile === POINTS_LOADING});
    }

    async function pointUpdate(index, point, loadingsChanged) {

        const pointsCloned = [...points];

        pointsCloned.splice(index, 1, point);

        updatePoints(pointsCloned, loadingsChanged);
    }

    const wrapperHeightStyle = {
        height: 'calc(100vh - 320px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: '10px',
        marginTop: '0'
    };

    const isAddEnabled = () => {
        let result = true;

        if (canEdit) {
            result = false;
        }

        const onePointTypes = [WaybillTypeEnum.LTL, WaybillTypeEnum.Rail, WaybillTypeEnum.UrbanDelivery, WaybillTypeEnum.RailLTL];

        if (result && onePointTypes.includes(wbType)) {
            result = (points || []).length === 0;
        }

        if (isClient) {
            result = false
        }

        return result;
    };

    const needCalc = (index) => {
        return (profile === POINTS_LOADING && index === points.length - 1) ||
            (profile === POINTS_LOADING && index === 0);
    };

    const onFormClose = () => {
        setIsFormOpen(false);
    };

    return (
        <>
            <div>
                <Header
                    className="f-l m-b-0"
                    style={{ marginTop: '7px'}}
                    as="h3"
                >{title.t}</Header>
                 <div className="f-r">
                    <Button
                        size="small"
                        disabled={!isAddEnabled()}
                        primary style={{ marginBottom: '10px', marginRight: '0' }}
                        onClick={() => setIsFormOpen(true)}
                    >
                        {t('waybills_btn_add-address')}
                    </Button>
                     <WbPointAddForm
                        wbId={wbId}
                        profile={profile}
                        wbClientId={wbClientId}
                        pointsExistingIds={(points || []).map(p => p.id)}
                        submit={pointAdd}
                        value={new WaybillPoint()}
                        open={isFormOpen}
                        onClose={onFormClose}
                    >
                    </WbPointAddForm>
                </div>
            </div>
            <Divider clearing={true} fitted className="m-b-10" />
            <Item.Group divided style={wrapperHeightStyle}>
                {points.map((p, idx) => <WbPointCardShell
                    key={p.id}
                    isClient={isClient}
                    pointsAll={pointsAll}
                    positions={positions}
                    unitsLoadedTotal={unitsLoadedTotal}
                    updateWb={updateWb}
                    wbId={wbId}
                    wbClientId={wbClientId}
                    wbPoint={p}
                    oppositeDate={oppositeDate}
                    wbType={wbType}
                    profile={profile}
                    pointAdd={pointAdd}
                    remove={pointRemove.bind(null, idx)}
                    pointReplace={pointReplace.bind(null, idx)}
                    update={(point, loadingsChanged) => pointUpdate(idx, point, loadingsChanged)}
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    needCalc={needCalc(idx)}
                />)}
            </Item.Group>
        </>
    );
}
