import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Icon, Image, Menu, Popup} from 'semantic-ui-react';
import {getRegistriesNotification, getUserLinks, getWaybillsNotification} from '../../api/users';
import {ContextUser} from '../../services/context';
import './Drawer2.css';
import {useTranslation} from "react-i18next";

export default withRouter(Drawer2);

function LinkTip({ tip, children }) {
    return (
        <Popup
            content={tip}
            position='right center'
            trigger={children}
        />
    );
}

function Drawer2({ userStartUrl, history, location }) {

    const menuRef = useRef();

    const user = useContext(ContextUser).current;

    const [links, setLinks] = useState([]);
    const [selectedItemName, setSelectedItemName] = useState();
    const [notifications, setNotifications] = useState([]);
    const [isCanScrollBottom, setIsCanScrollBottom] = useState(false);
    const [isCanScrollTop, setIsCanScrollTop] = useState(false);

    function navigate(name) {
        setSelectedItemName(name);
        const url = links.find(l => l.name === name).url;
        url && history.push(url);
    }

    async function fetchLinks() {
        const userToken = user.token;
        const result = await getUserLinks(userToken);
        setLinks(result);
    }

    useEffect(() => {
        fetchLinks();
    }, []);
    useEffect(() => {
        if (links.length) {
            const pathname = location.pathname; // eslint-disable-line
            const selectedLink = links.find(l => pathname.startsWith(l.url));// || links[0];
            selectedLink && setSelectedItemName(selectedLink.name);
        }

        if (location.pathname === '/registries' || location.pathname === '/waybills') {
            getNotifications();
        }

    }, [location.pathname, links]); // eslint-disable-line

    useEffect(() => {
        const intervalId = setInterval(getNotifications, 60 * 1000);

        getNotifications();

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (menuRef && menuRef.current && menuRef.current.scrollHeight - menuRef.current.offsetHeight > 0) {
            setIsCanScrollBottom(true);
        }
    }, [menuRef, menuRef.current, links]);


    useEffect(() => {
        window.addEventListener('resize', resizeWin);
        menuRef && menuRef.current && menuRef.current.addEventListener('scroll', scrollMenu);

        return () => {
            window.removeEventListener('resize', resizeWin);
            menuRef && menuRef.current && menuRef.current.removeEventListener('scroll', scrollMenu)
        }
    }, []);


    const resizeWin = () => {
        setIsCanScrollTop(Boolean(menuRef && menuRef.current && menuRef.current.scrollTop > 0));
        setIsCanScrollBottom(Boolean(menuRef && menuRef.current && menuRef.current.scrollHeight - menuRef.current.offsetHeight > 0));
    };

    const scrollMenu = () => {
        setIsCanScrollBottom(menuRef.current.scrollTop < menuRef.current.scrollHeight - menuRef.current.offsetHeight);
        setIsCanScrollTop(menuRef.current.scrollTop !== 0);
    };


    const getNotifications = async () => {
        const resultR = await getRegistriesNotification();
        const resultW = await getWaybillsNotification();

        let obj = [];

        if (resultR.value) {
            obj.push('/registries')
        }

        if (resultW.value) {
            obj.push('/waybills')
        }

        setNotifications(obj);
    };

    const handleScrollBottom = () => {
        if (menuRef && menuRef.current) {

            const nextScrollPosition = menuRef.current.scrollTop + menuRef.current.offsetHeight / 2;

            menuRef.current.scrollTo(0, nextScrollPosition);
        }
    };

    const handleScrollTop = () => {
        if (menuRef && menuRef.current) {
            const nextScrollPosition = menuRef.current.scrollTop - menuRef.current.offsetHeight / 2;

            menuRef.current.scrollTo(0, nextScrollPosition);
        }
    };

    const {t} = useTranslation();

    return (
        <Menu fluid icon='labeled' vertical inverted color="grey" borderless className="drawer2">
            <Link to="/">
                <Menu.Item
                    link
                    style={{ paddingBottom: "13px", paddingTop: "17px", marginBottom: "7px" }}>

                    <Image src="/img/logo-short.png" />

                </Menu.Item>
            </Link>
            {
                isCanScrollTop && (
                    <Menu.Item
                        className={"drawer2-item"}
                        style={{
                            position: "absolute",
                            top: "50px",
                            zIndex: "10",
                            height: "70px",
                            background: "linear-gradient(180deg, #0E4194 45.1%, rgba(14, 65, 148, 0.01) 107.5%)"
                        }}
                        onClick={handleScrollTop}
                    >
                        <Icon name='angle double up' style={{ margin: "0 !important" }} />
                    </Menu.Item>
                )
            }
            <div ref={menuRef} className="scroll-menu">
                {links.map(item =>
                    <LinkTip key={item.name} tip={t(item.name)}>
                        <Menu.Item
                            link
                            key={item.name}
                            className={item.name === selectedItemName
                                ? "drawer2-item drawer2-item--selected"
                                : "drawer2-item"}
                            name={t(item.name)}
                            onClick={() => navigate(item.name)}
                        >
                            <Icon name={t(item.icon) || 'info'} style={{ margin: "0 !important" }} />
                            {notifications.includes(item.url) && <div className="notification"/>}
                            {/* {item.name === "Адреса" && <Label color='red' circular empty floating style={{top: "-1px"}}/>} */}
                        </Menu.Item>
                    </LinkTip>
                )}
            </div>
            {
                isCanScrollBottom && (
                    <Menu.Item
                        className={"drawer2-item"}
                        style={{
                            position: "absolute",
                            bottom: "40px",
                            background: "linear-gradient(180deg, rgba(14, 65, 148, 0.01) -40%, #0E4194 91.27%)"
                        }}
                        onClick={handleScrollBottom}
                    >
                        <Icon name='angle double down' style={{ margin: "0 !important" }} />
                    </Menu.Item>
                )
            }
            <Menu.Item style={{
                position: "absolute",
                bottom: "0",
                color: "#eeeeee"
            }}>
                <small>7.3</small>
            </Menu.Item>
        </Menu>
    );
}
