import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import withTransition from './withTransition';
import {useTranslation} from "react-i18next";

const icons = {
    info: 'announcement',
    success: 'checkmark',
    error: 'remove',
    warning: 'warning circle'
};

function SemanticToast(props) {
    const { t } = useTranslation();
    const { type, title, description, onClose, onClick } = props;
    const icon = props.icon || icons[type];

    const onDismiss = e => {
        e.stopPropagation();
        onClose();
    };

    return (
        <Message className="no-bullets"
            {...{ [type]: true }}
            onClick={onClick}
            onDismiss={onDismiss}
            header={t(title)}
            list={description.map(text => typeof(text)!=="object" ? t(text) : text)}
            icon={icon}
            floating
        />
    );
}

SemanticToast.propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'error', 'warning']).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.array.isRequired,
    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onClick: PropTypes.func,
    onClose: PropTypes.func
};

SemanticToast.defaultProps = {
    onClick: undefined,
    onClose: undefined
};

export default withTransition(SemanticToast);
