import React from 'react';
import { Table, Checkbox } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import PointGridCell from './PointGridCell';

const PointGridRows = ({ history, rows, keys, onClickItem, isProviders, selectedPoints, setSelectedPoints, isClient, onChange }) => {

    function handleRowSelection(id, rows, e, { checked }) {
        if (e.nativeEvent.shiftKey && selectedPoints.length > 0 && checked) {
            const lastSelectedId = selectedPoints[selectedPoints.length - 1];
            const previousIndex = rows.findIndex(r => r.id === lastSelectedId) || 0;
            const currentIndex = rows.findIndex(r => r.id === id) || 0;

            const idsScope = [];

            if (currentIndex > previousIndex) {
                for (let index = previousIndex; index <= currentIndex; index++) {
                    idsScope.push(rows[index].id);
                }
            } else {
                for (let index = previousIndex; index >= currentIndex; index--) {
                    idsScope.push(rows[index].id);
                }
            }

            setSelectedPoints([...idsScope]);
        }
        else {
            if (checked) {
                setSelectedPoints([...selectedPoints, id]);
            }
            else {
                setSelectedPoints([...selectedPoints.filter(i => i !== id)]);
            }
        }

    }

    const needsTitle = (key) => {
        return ['address', 'companyName'].includes(key);
    };

    return <Table celled fixed singleLine className="table-data-inner" selectable>
        <Table.Body>
            {rows.map(r => {
                return <Table.Row key={r.id}
                    style={{ cursor: 'pointer' }}
                    active={selectedPoints.includes(r.id)}
                >
                    <Table.Cell className="table-first-col">
                        {<Checkbox id={r.id}
                            checked={selectedPoints.includes(r.id)}
                            onChange={handleRowSelection.bind(null, r.id, rows)}
                        />}
                    </Table.Cell>
                    {keys.map(key =>
                        <Table.Cell
                            style={{
                                overflow: key === 'bookmarkType' ? 'visible' : 'hidden',
                                position: 'relative'
                            }}
                            key={key}
                            onClick={() => {
                                if (isProviders) {
                                    return onClickItem(r)
                                } else {
                                    if (key === 'bookmarkType') {
                                        return;
                                    }
                                    const url = isClient ?
                                        `/points/${r.pointId}/company/${r.companyId}/params` :
                                        `/admin/points/${r.id}/location`;
                                    history.push(url);
                                }
                            }}
                            title={needsTitle(key) ? r[key] : ''}
                        >
                            <PointGridCell
                                item={r}
                                keyName={key}
                                onChange={(key, value) => {
                                    onChange({
                                        ...r,
                                        [key]: value
                                    });
                                }}
                            />
                        </Table.Cell>
                    )}
                </Table.Row>;
            })}
        </Table.Body>
    </Table>;
};

export default withRouter(PointGridRows);
