import React from 'react';
import { Label } from 'semantic-ui-react';
import {useTranslation} from "react-i18next";

export default function WaybillStatusShipping({statusShipping, className = ""}) {
    const {t} = useTranslation();

    if (statusShipping) {
        return <Label color="violet" className={`ellipsis ${className}`}> <b>{t(statusShipping)}</b></Label>;
    }
    return null;
}