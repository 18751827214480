import React, {useContext, useEffect, useState} from 'react';
import { Divider, Icon, Label, List } from 'semantic-ui-react';
import {PackTypeDisplayOptions, PackTypeEnum} from '../../../api/model/Enums';
import LoadUnitSet from '../../../api/model/LoadUnitSet';
import AppButtonLink from '../../../components/buttons/AppButtonLink';
import { ContextWb } from '../../../services/context';
import PointCardTabsPositionsLoadSetForm from './WbPointCardTabsPositionsLoadSetForm';
import {getCompaniesIsMixAllowed} from "../../../api/companies";
import { useTranslation } from 'react-i18next';


function PositionSetItem({ p, from, to }) {
    const { t } = useTranslation();

    const spanStyle = {
        minWidth: "60px",
        marginRight: "10px",
        display: "inline-block"
    };
    const spanStyleMini = {
        minWidth: "20px",
        marginRight: "10px",
        display: "inline-block"
    };

    return (
        <>
            <Label color='pink' style={{ minWidth: '75px' }} horizontal>{t('base_label_from')} {from} {t('base_label_to')} {to}</Label>
            {t('waybills_label_type')}: <span style={spanStyle}>{t(PackTypeDisplayOptions.find(o => o.key === p.loadUnitType).text)},</span>
            {t('waybills_label_weight')}: <span style={spanStyleMini}>{p.loadTotalWeigthBruttoKg},</span>
            {p.loadTotalVolumeM3 && <>{t('waybills_label_volume')}: <span style={spanStyle}>{p.loadTotalVolumeM3}</span></>}
        </>
    );
}

export default function PointCardTabsPositionsLoad({isMixAllowed, id, pointId, positions = [], handleDataChange, isClient, pointsAll }) {
    const { t } = useTranslation();

    const contextWb = useContext(ContextWb);


    /*useEffect(() => {
        getIsMixAllowed();
    }, [isMixAllowed])

    function getIsMixAllowed() {
        setIsMixCargoUnitsConfig(isMixAllowed);
    }*/
    async function positionAdd(position) {

        // need to connect position to point
        position.pointFromId = pointId;
        position.pointFromId = pointId;
        position.id = Math.random();

        const newPositions = [...positions]; // copy of loadUnitSets

        newPositions.push(new LoadUnitSet(position));

        await handleDataChange("loadUnitSets", newPositions);
    }

    async function positionRemove(idx) {

        const newPositions = [...positions];

        newPositions.splice(idx, 1);

        await handleDataChange("loadUnitSets", newPositions);
    }

    async function positionUpdate(position) {

        const newPositions = [...positions];

        const target = newPositions.find(p => p.id === position.id);

        Object.assign(target, position);

        await handleDataChange("loadUnitSets", newPositions);
    }

    function getStartIndex() {
        const waybill = contextWb.wb;
        const points = [...waybill.pointsLoading].sort((a, b) =>
            b.positionNumber - a.positionNumber);
        let currentPointIndex = 0;

        points.forEach((item, index) => {
            if (item.id === id) {
                currentPointIndex = index;
            }
        });

        const prevPoints = points.slice(currentPointIndex + 1);

        return prevPoints.reduce((sum, { loadUnitSets }) => {
            return sum + loadUnitSets.reduce((sum, { loadUnitsCount }) => {
                return loadUnitsCount + sum;
            }, 0);
        }, 0);
    }

    let posidx = 0;
	let posIndexCurrent = getStartIndex();

    function isAddButton() {
        if (isMixAllowed) {return true}
        return positions.length === 0
            ||
            (!!positions.find(i => i.loadUnitType === PackTypeEnum.BX || i.loadUnitType === PackTypeEnum.PP) && positions.length < 2);
    }

    function classNameListPoint() {
        let cls = ['point-form'];
        if (isClient) {
            cls.push('pointer-events-none')
        }

        return cls.join(' ')
    }

    return (
        <>
            <div style={{ marginTop: '0', textAlign: "left", marginBottom: '4px' }}>
                <label style={{ fontSize: '13px', fontWeight: "bold" }}
                >
                    {t('waybills_label_cargo-units')}
                </label>
                {isAddButton() && <PointCardTabsPositionsLoadSetForm
                    submit={positionAdd}
					title={t('waybills_btn_add-cargo-units')}
					loadUnitSets={positions}
                    isMixCargoUnitsConfig={isMixAllowed}
                    pointsAll={pointsAll}
                    positions={positions}
                    disabled={isClient}
				>
                    <span className="f-r">
                        <AppButtonLink style={{ fontSize: '12px' }} disabled={isClient}>{t('waybills_btn_add')}</AppButtonLink>
                    </span>
                </PointCardTabsPositionsLoadSetForm>}
            </div>
            <Divider fitted />
            <div className="f-c" style={{ paddingTop: '0' }}>
                <List divided selection className={classNameListPoint()}>
                    {positions.map(p => (
                        <List.Item key={p.id}>
							<PointCardTabsPositionsLoadSetForm
								submit={positionUpdate}
                                isMixCargoUnitsConfig={isMixAllowed}
								value={p}
                                pointsAll={pointsAll}
                                disabled={isClient}
                                positions={positions}
								loadUnitSets={positions}
							>
                                <span>
                                    <PositionSetItem p={p}
                                        from={posIndexCurrent + 1}
                                        to={posIndexCurrent = (posIndexCurrent + p.loadUnitsCount)} />
                                </span>
                            </PointCardTabsPositionsLoadSetForm>
                            <Icon
                                name="trash"
                                disabled={isClient}
                                className="f-r"
                                link style={{ marginTop: '2px' }}
                                onClick={positionRemove.bind(null, posidx++)} />
                        </List.Item>
                    ))}
                </List>
            </div>
        </>
    );
}
