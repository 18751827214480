import React from 'react';
import {withRouter} from 'react-router-dom';
import {Grid, GridColumn, List, Menu, MenuItem} from 'semantic-ui-react';
import {CargoTypeOptions, PickupTypeOptions, ThermoModeOptions} from '../../../api/model/Enums';
import {PointServiceOptions} from '../../../api/model/PointService';
import {WaybillCarTypeOptions, WaybillStatusEnum, WaybillTypeEnum} from '../../../api/model/Waybill';
import CardLoading from './CardLoading';
import CardUnloading from './CardUnloading';
import LoadStatistics from './LoadStatistics';
import PointsVisual from './PointsVisual';
import {Record, RecordData} from './record/Record';
import {pointUpdate} from '../../../api/points';
import O from "../../../components/Option";
import { useTranslation } from 'react-i18next';
import getIndexWbPoints from "../../../services/getIndexWbPoints";

function getRecords(wb, t) {
    const getText = o => (o && o.text) || 'waybills_label_no-data';

    return [
        new RecordData('waybills_grid_num-app-fm', wb.fmid),
        new RecordData('registers_grid_client', wb.clientId),
        new RecordData('waybills_input_provider', wb.providerName),
        new RecordData('waybills_input_type-application', wb.shippingType),
        (wb.shippingType === WaybillTypeEnum.FTL || wb.shippingType === WaybillTypeEnum.PlanReturn) && new RecordData('waybills_grid_carrying-capacity', wb.shippingType === WaybillTypeEnum.FTL ? `${(wb.vehicleCapacity / 1000)} ${t('waybills_label_ton')}` : t('waybills_label_not-applicable')),
        (wb.shippingType === WaybillTypeEnum.Rail || wb.shippingType === WaybillTypeEnum.UrbanDelivery) && new RecordData('waybills_label_type-ts', t(WaybillCarTypeOptions.getText(wb.carType))),
        (wb.shippingType !== WaybillTypeEnum.Rail && wb.shippingType !== WaybillTypeEnum.UrbanDelivery) && new RecordData('waybills_label_temp-condition', wb.shippingType === WaybillTypeEnum.RailLTL ? t(getText([new O(10, 'waybills_label_no-mode')].find(o => o.key === wb.shippingTemperatureCondition)))
            : t(getText(ThermoModeOptions.find(o => o.key === wb.shippingTemperatureCondition)))),
        (wb.shippingType === WaybillTypeEnum.FTL || wb.shippingType === WaybillTypeEnum.PlanReturn) && wb.shippingTemperatureCondition === 60 ?
            new RecordData('waybills_label_temp-condition-client', wb.temperatureCondition ? `${wb.temperatureCondition.from} ${wb.temperatureCondition.to}` : '') : null,
        new RecordData('waybills_input_cargo-type', t(getText(CargoTypeOptions.find(o => o.key === wb.cargoType)))),
        new RecordData('waybills_input_sum', wb.cargoCost),
        new RecordData('waybills_label_delivery-method', t(getText(PickupTypeOptions.find(i => i.value === wb.pickupType)))),
        wb.isPooling && new RecordData('waybills_label_slot-num', wb.slotId)
    ].filter(r => !!r);
}

function WbView({wb, fetchWb}) {
    const { t } = useTranslation();

    function getServiceName(key) {

        const serviceOption = PointServiceOptions.find(s => s.key === key);
        if (serviceOption)
            return serviceOption.title;
        else
            return key;
    }

    async function submitPoint(point) {
        await pointUpdate(point);
        await fetchWb();
    }

    const generalRecords = getRecords(wb, t);

    let newWb = getIndexWbPoints(wb);
    let topBlock = <PointsVisual data={newWb.shippings} pointsLoading={newWb.pointsLoading} pointsUnloading={newWb.pointsUnloading}/>;

    const loadUnitsSetsAll = (wb.pointsLoading || []).reduce((val, point) => val = [...val, ...point.loadUnitSets], []);

    return (
        <>
            {topBlock}
            <Grid className="m-t-0 h-100" divided>
                <GridColumn width={4}>
                    <Menu borderless style={{minHeight: '49px'}}>
                        <Menu.Item><b>{t('waybills_label_general-info')}</b></Menu.Item>
                    </Menu>
                    <div style={{padding: '7px'}}>
                        <List relaxed>
                            {generalRecords.map(r => <Record data={r} key={r.name}/>)}
                        </List>
                    </div>
                </GridColumn>
                <GridColumn width={12}>
                    <Menu borderless style={{marginRight: "7px"}}>
                        <Menu.Item><b>{t('waybills_grid_addresses-and-cargo')}</b></Menu.Item>
                        <Menu.Menu position="right" style={{marginRight: '20px'}}>
                            <MenuItem style={{flex: 'unset'}}>
                                <LoadStatistics wb={wb}/>
                            </MenuItem>
                        </Menu.Menu>
                    </Menu>
                    <div style={{padding: '7px'}}>
                        {(wb.pointsLoading || []).map(point => <CardLoading
                            key={point.id}
                            point={point}
                            getServiceName={getServiceName}
                            submitPoint={submitPoint}
                        />)}
                        {(wb.pointsUnloading || []).map(point => <CardUnloading
                            key={point.id}
                            point={point}
                            getServiceName={getServiceName}
                            submitPoint={submitPoint}
                            loadUnitSets={loadUnitsSetsAll}
                            loadUnitsToOrderMappings={point.loadUnitsToOrderMappings}/>)}
                    </div>
                </GridColumn>
            </Grid>
        </>
    );
}

export default withRouter(WbView);

