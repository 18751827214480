import moment from 'moment';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { RequestTypeOptions } from '../../../api/model/Enums';
import { PointTimeslot } from '../../../api/model/PointTimeslot';
import { RecordData, TableRecord, TableRecordElement } from './record/Record';
import { useTranslation } from 'react-i18next';

const styleNoBorderGreen = {
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    borderTop: '2px solid #21ba45'
};

const getValue = val => val ? ": " + val : "";

const dateFormat = "DD.MM.YYYY";

export default function CardUnloading({point, getServiceName, loadUnitsToOrderMappings = []}) {
    const { t } = useTranslation();

    const noData = `[${t('waybills_label_no-data')}]`;

    const adr = <span>{point.address || noData}</span>;

    const buildContact = (contact) => {
        if (!contact) return null;
        const { name, phone } = contact;
        if (name && phone) return `${name}, ${phone}`;
        if (!name && !phone) return null;
        return `${(name || '')}${phone || ''}`;
    };

    const recipient = new RecordData(t('registers_grid_consignee'), point.companyName || noData);

    const loadDate = new RecordData(t('waybills_label_date-arrival-address'), moment(point.arrivalDatePlan).format(dateFormat) || noData);    // "Дата привоза на FM"
    const loadTime = new RecordData(t('waybills_label_time-arrival-address'), (point.arrivalTimeslotPlan && new PointTimeslot(point.arrivalTimeslotPlan).toString()) || noData);        // "Время привоза на FM"
    const services = new RecordData(t('waybills_label_options'), point.services && point.services.length && point.services.map(s => `${t(getServiceName(s.key))}${getValue(s.value)}`).join('; '));
    const contact = new RecordData(t('waybills_input_contact'), buildContact(point.contact));

    const getOrderName = (orderType) => {
        let result = null;
        if (orderType) {
            var option = RequestTypeOptions.find(o => o.key === orderType);
            if (option)
                result = option.text;
        }
        return result;
    };

    const PositionSet = ({lps}) => (
        <React.Fragment>
            <Table.Row disabled>
                <Table.Cell colSpan="4">{t('waybills_label_positions')}: с {lps.loadUnitPositionStart} по {lps.loadUnitPositionEnd}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell><TableRecord data={new RecordData(t('waybills_grid_recipient-num-order'), lps.recipientOrderNo)} /></Table.Cell>
                <Table.Cell><TableRecord data={new RecordData(t('waybills_grid_num-order-shipper'), lps.shipperOrderNo)} /></Table.Cell>
                <Table.Cell><TableRecord data={new RecordData(t('waybills_grid_num-torg12'), lps.torg12No)} /></Table.Cell>
				<Table.Cell><TableRecord data={new RecordData(t('waybills_grid_order-type'), getOrderName(lps.orderType))} /></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell/>
                <Table.Cell><TableRecord data={new RecordData(t('waybills_grid_weight-kg'), lps.weightKgBrutto)} /></Table.Cell>
                <Table.Cell><TableRecord data={new RecordData(t('waybills_grid_volume-m3'), lps.volumeM3)} /></Table.Cell>
                <Table.Cell><TableRecord data={new RecordData(t('waybills_grid_cost'), lps.cost)} /></Table.Cell>
            </Table.Row>
        </React.Fragment>
    );

    const tableLayout = (
        <Table basic fixed style={styleNoBorderGreen}>
            <Table.Body>
                <Table.Row style={{backgroundColor: '#eeeeee'}}>
                    <Table.Cell><TableRecord data={recipient} /></Table.Cell>
                    {point.comment
                        ? <React.Fragment>
                            <Table.Cell colSpan="2"><TableRecordElement label={t('waybills_grid_delivery-address')}>{adr}</TableRecordElement> </Table.Cell>
                            <Table.Cell><TableRecord data={new RecordData(t('waybills_grid_comment'), point.comment)} /></Table.Cell>
                        </React.Fragment>
                        : <Table.Cell colSpan="3"><TableRecordElement label={t('waybills_grid_delivery-address')}>{adr}</TableRecordElement> </Table.Cell>
                    }
                </Table.Row>
                <Table.Row>
                    <Table.Cell><TableRecord data={loadDate} /></Table.Cell>
                    <Table.Cell><TableRecord data={loadTime} /></Table.Cell>
                    <Table.Cell><TableRecord data={services} /></Table.Cell>
                    <Table.Cell><TableRecord data={contact} /></Table.Cell>
                </Table.Row>

				{loadUnitsToOrderMappings
					.sort((a, b) => a.loadUnitPositionStart - b.loadUnitPositionStart)
					.map(lps => <PositionSet key={lps.id} lps={lps} />)}
            </Table.Body>
        </Table>
    );

    return tableLayout;
}
