import React from 'react';
import { Label } from 'semantic-ui-react';
import {useTranslation} from "react-i18next";

export default function WaybillStatusOrder({statusOrder, className = ""}) {

    const {t} = useTranslation();
    if (statusOrder && statusOrder.text) {
        return <Label key={statusOrder.text} color={statusOrder.color || null} className={className}><b>{t(statusOrder.text)}</b></Label>;
    }
    return null;
}
