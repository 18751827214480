import React, {useContext, useEffect, useMemo, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Divider, Grid, Icon, Segment, Step} from 'semantic-ui-react';
import UserPermissions from '../../../api/model/UserPermissions';
import {WaybillStatusAvizationEnum, WaybillStatusEnum, WaybillTypeEnum} from '../../../api/model/Waybill';
import {getWaybillById} from '../../../api/waybills';
import {getWaybillStatusTexts} from '../../../api/waybillsUtils';
import {LinkInfo} from '../../../layout/navbar/Navbar';
import Shield from '../../../layout/page/shield/Shield';
import {ContextFooter, ContextNavi, ContextUser, ContextWb} from '../../../services/context';
import {StepData} from './StepInfo';
import WbView from './WbView';
import WbViewFooter from './WbViewFooter';
import WbViewHeader from './WbViewHeader';
import WbDocs from '../wbViewDocs/WbDocs';
import './WbViewer.css';
import WbViewHistory from '../wbViewHistory/WbViewHistory';
import WbViewAvisation from '../wbViewAvisation/WbViewAvisation';
import { useTranslation } from 'react-i18next';


const styleStepWithIcon = {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
};

function StepContent({ icon, title }) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Icon name={icon} />
            <Step.Content>
                <Step.Title>
                    {t(title)}
                </Step.Title>
            </Step.Content>
        </React.Fragment>
    );
}

function WbViewer({ match, history }) {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [wb, setWb] = useState({});

    const tabKeyActive = match.params.tab;
    const contextNavi = useContext(ContextNavi);
    const contextUser = useContext(ContextUser);
    const contextFooter = useContext(ContextFooter);
    const canEdit = contextUser.current.permissions.includes(UserPermissions.WAYBILL_EDIT);
    const avisationPermitted = !contextUser.current.permissions.includes(UserPermissions.DISALLOW_AVISATION);
    const wbStatus = getWaybillStatusTexts(wb);
    const isAvisation = useMemo(() => {
        return (
            avisationPermitted &&
            wb.id &&
            [WaybillStatusAvizationEnum.CONFIRMED, WaybillStatusAvizationEnum.FILL_DRIVER].includes(
                wb.avisationStatus,
            ) &&
            [
                WaybillStatusEnum.SUBMITTED,
                WaybillStatusEnum.ON_APPROVAL,
                WaybillStatusEnum.EXECUTING,
                WaybillStatusEnum.CARGO_DELIVERED,
            ].includes(wb.status)
        );
    }, [wb]);

    let wbId = match.params.id;
    let stepIcon = 'info';

    const steps = [
        new StepData(
            () => (
                <WbView
                    key={wbId}
                    wb={wb}
                    fetchWb={fetchWb}
                    statusOrder={wbStatus.statusOrder}
                    statusShipping={wbStatus.statusShipping}
                />
            ),
            'info',
            t('waybills_label_application'),
            stepIcon,
        ),
        new StepData(
            () => <WbViewAvisation wb={wb} id={wbId} />,
            'avisation',
            `${t('waybills_label_app-num')}${wb.fmid || wbId} ${t('waybills_label_included')} ${wb.visitNumber || ''}`,
            'avisation',
        ),
        new StepData(() => <WbDocs wb={wb} />, 'docs', t('waybills_label_documentation'), 'download'),
        new StepData(() => <WbViewHistory wb={wb} />, 'history', t('waybills_label_history'), 'history'),
    ];

    const step = steps.find(s => s.index === tabKeyActive) || {};

    async function fetchWb() {
        setLoading(true);

        let wb = await getWaybillById(wbId);

        wb.__canEdit = (wb.status !== WaybillStatusEnum.DRAFT && canEdit && wb.shippingType !== WaybillTypeEnum.Return)
        || (wb.shippingType === WaybillTypeEnum.Return
                && [WaybillStatusEnum.EXECUTING, WaybillStatusEnum.SUBMITTED, WaybillStatusEnum.ON_APPROVAL, WaybillStatusEnum.CARGO_DELIVERED].includes(wb.status)
                && contextUser.current.permissions.includes(UserPermissions.RETURN_WAYBILLS_EDIT));

        contextNavi.setItems([
            new LinkInfo('waybills_label_my-applications', '/waybills'),
            new LinkInfo({key: "waybills_label_application", index: wb.fmid || wb.id}, '/'),
        ]);

        setWb(wb);

        setLoading(false);

        return wb;
    }

    const stepParams = key => {
        return {
            key: key,
            link: true,
            active: tabKeyActive === key,
            onClick: () => history.replace(`/waybills/${wbId}/view/${key}`),
        };
    };

    const allSteps = useMemo(() => {
        let steps = [
            <Step {...stepParams('info')}>
                <StepContent icon="info" title={t('waybills_label_application')} />
            </Step>,
        ];

        if (wb.id && isAvisation) {
            steps.push(
                <Step {...stepParams('avisation')}>
                    <StepContent icon="clock outline" title={t('waybills_btn_advise')} />
                </Step>,
            );
        }

        if (wb.id && wb.status >= WaybillStatusEnum.ON_APPROVAL) {
            steps.push(
                <Step
                    {...stepParams("docs")}
                    style={styleStepWithIcon}
                >
                    <StepContent icon="download" title={t('waybills_label_documentation')}/>
                </Step>)
        }

                if (wb.returnWaybillId || wb.returnWaybillFMID) {
                    steps.push(
                        <Step
                            key='return'
                            link
                            onClick={() => history.push(`/waybills/${wb.returnWaybillId}/view/info`)}
                        >
                            <StepContent icon="dolly" title={`${t('waybills_label_return')}: ${wb.returnWaybillFMID || wb.returnWaybillId}`} />
                        </Step>
                    )
                }

            steps.push(<Step {...(stepParams("history"))}>
                <StepContent icon="history" title={t('waybills_label_history')} />
            </Step>);

        return steps;

    }, [stepParams, wb, isAvisation]);

    useEffect(() => {
        fetchWb();
    }, [wbId]);

    useEffect(() => {
        contextFooter.setIndicator(
            wb ? (
                <WbViewFooter
                    statusOrder={wbStatus.statusOrder}
                    statusShipping={wbStatus.statusShipping}
                />
            ) : null,
        );
        return () => contextFooter.setIndicator(null);
    }, [wb]);

    return (
        <ContextWb.Provider
            value={{
                wb,
                setLoading,
                setWb,
                fetchWb,
            }}
        >
            <Shield loading={loading} loadingOver={wb.id}>
                <Grid columns={2} className="p-t-0 m-t-0 h-100">
                    <Grid.Row stretched className="p-t-0 p-b-0 m-t-1-negative h-100">
                        <Grid.Column
                            style={{ width: '17%' }}
                            className="p-b-0 p-r-0 m-r-1-negative"
                        >
                            <Step.Group fluid vertical size="tiny" className="m-b-0 steps-grey">
                                <div className="step-wrapper">
                                    {allSteps.map(item => item)}
                                </div>
                            </Step.Group>
                        </Grid.Column>
                        <Grid.Column style={{ width: '83%' }} className="p-l-0 p-b-0 h-100">
                            <Segment
                                style={{ marginRight: '25px', paddingLeft: '24px' }}
                                className="h-100"
                            >
                                <div className="flex-col h-100">
                                    <div>
                                        <WbViewHeader
                                            isAvisation={
                                                isAvisation && tabKeyActive === 'avisation'
                                            }
                                            header={step.text}
                                            statusAnomaly={wbStatus.statusAnomaly}
                                            statusOrder={wbStatus.statusOrder}
                                            statusShipping={wbStatus.statusShipping}
                                            statusAvisation={wbStatus.statusAvisation}
                                        />
                                    </div>
                                    <Divider className="m-t-0" />
                                    <div className="flex-grow scrollable-v">{step.component()}</div>
                                </div>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {/* <Footer Indicator={() => <WbViewFooter statusOrder={wbStatus.statusOrder} statusShipping={wbStatus.statusShipping} />} /> */}
            </Shield>
        </ContextWb.Provider>
    );
}

export default withRouter(WbViewer);
