import React, {useMemo, useState} from 'react';
import {Dropdown, Icon} from 'semantic-ui-react';
import {WaybillStatusAvizationEnum, WaybillStatusEnum,} from '../../../api/model/Waybill';
//import { pointUpdate } from '../../../api/points';
import WbPointAddForm from './WbPointAddForm';
import { useTranslation } from 'react-i18next';

export default function WbPointCardShellMenu({
                                                 wb,
                                                 wbPoint,
                                                 profile,
                                                 pointRemove,
                                                 pointReplace,
                                                 isClient,
                                             }) {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const menuClose = () => setOpen(false);

    const menuOpen = () => setOpen(true);

    const resetId = i => {
        return {...i, id: 0};
    };

    function doPointReplace(point) {
        // copying stuff
        point.arrivalDatePlan = wbPoint.arrivalDatePlan;
        point.arrivalDateFact = wbPoint.arrivalDateFact;
        point.arrivalTimeslotPlan = wbPoint.arrivalTimeslotPlan;
        point.arrivalTimeslotFact = wbPoint.arrivalTimeslotFact;
        point.contact = wbPoint.contact;
        point.comment = wbPoint.comment;

        point.loadUnitSets = (wbPoint.loadUnitSets || []).map(resetId);
        point.loadUnitsToOrderMappings = (wbPoint.loadUnitsToOrderMappings || []).map(resetId);
        point.services = (wbPoint.services || []).map(resetId);

        pointReplace(point);
    }

    function doPointRemove() {
        menuClose();
        pointRemove();
    }

    // async function doPointUpdate(pt) {
    //     menuClose();
    //     await pointUpdate(pt);
    // };

    const onFormClose = () => {
        setIsFormOpen(false);
    };

    const actions = useMemo(() => {
        const actions = [];

        if (
            (!(
                    [
                        WaybillStatusAvizationEnum.CONFIRMED,
                        WaybillStatusAvizationEnum.FILL_DRIVER,
                    ].includes(wb.avisationStatus))
                || (wb.avisationStatus === WaybillStatusAvizationEnum.REQUIRES && wb.status === WaybillStatusEnum.DRAFT)
                && wbPoint.hubLoading
            )
        ) {
            actions.push(
                <Dropdown.Item
                    icon="reply"
                    text={t('waybills_btn_update-address')}
                    onClick={() => {
                        menuClose();
                        setIsFormOpen(true);
                    }}
                />,
            );

            if (wb.avisationStatus !== WaybillStatusAvizationEnum.REQUIRES) {
                actions.push(
                    <Dropdown.Item
                        onClick={doPointRemove}
                        icon="trash alternate outline"
                        text={t('waybills_btn_delete-point')}
                    />,
                );
            }
        }

        /* if (wb.status === WaybillStatusEnum.DRAFT) {
             actions.push(
                 <Dropdown.Item
                     onClick={doPointRemove}
                     icon="trash alternate outline"
                     text="Удалить точку"
                 />,
             );
         }*/

        return actions;
    }, [wb, wbPoint, menuClose, doPointRemove]);
    /* const actions = (
        <>
            {
                !(wb.avisationStatus === WaybillAvisationStatus.CONFIRMED && wbPoint.hubLoading) &&
                <Dropdown.Item icon="reply" text={"Заменить адрес".t} onClick={() => {menuClose(); setIsFormOpen(true);}} />
            }
            {/!* {canEditPoint &&
                <PointForm
                    title={"Редактировать адрес".t}
                    size="small"
                    profile={profile}
                    data={wbPoint.point}
                    submit={doPointUpdate}
                >
                    <Dropdown.Item icon="pencil" text={"Редактировать адрес".t} onClick={menuClose} />
                </PointForm>
            } *!/}
            {wb.status === WaybillStatusEnum.DRAFT && <Dropdown.Item onClick={doPointRemove} icon="trash alternate outline" text="Удалить точку" />}
        </>
    );*/

    const trigger = (
        <Icon
            name="setting"
            size="large"
            link
            className={'icon-pointer'}
            onClick={() => (open ? menuClose() : menuOpen())}
        />
    );

    return (
        <>
            <Dropdown
                trigger={trigger}
                disabled={isClient || !actions.length}
                pointing="top right"
                direction="right"
                onBlur={menuClose}
                closeOnBlur
                //onOpen={menuOpen}
                //onClose={menuClose}
                open={open}
                icon={null}
                className="point-card-shell-header-menu"
            >
                <Dropdown.Menu>{actions.map(item => item)}</Dropdown.Menu>
            </Dropdown>

            <WbPointAddForm
                isEdit
                wbId={wb.id}
                wbPointId={wbPoint.id}
                profile={profile}
                wbClientId={wb.clientId}
                pointsExistingIds={[]}
                submit={doPointReplace}
                hideNew={wb.status !== WaybillStatusEnum.DRAFT}
                value={wbPoint}
                open={isFormOpen}
                onClose={onFormClose}
            ></WbPointAddForm>
        </>
    );
}
