import React from 'react';
import { Form, Grid, GridColumn } from 'semantic-ui-react';
import { DataCell } from './PointViewTabAddrParams';
import PointAddress from '../../../../api/model/PointAddress';
import Point, { PointStatusEnum } from '../../../../api/model/Point';
import "../Points.css";
import { useTranslation } from "react-i18next";


export default function PointViewTabAddr({ point, updatePoint }) {

    const addr = point && point.address;

    const { t } = useTranslation();

    async function updateAddress(prop) {

        const pointChanged = new Point(point);

        pointChanged.address = new PointAddress({ ...point.address, ...prop });
        pointChanged.address.value = pointChanged.address.toString();

        updatePoint(pointChanged);
    }

    async function update(prop) {
        const pointChanged = new Point({ ...point, ...prop });
        updatePoint(pointChanged);
    }

    return (
        <Form>
            <Grid columns='equal' divided='vertically'>
                <Grid.Row className="p-b-15">
                    <GridColumn>
                        <Form.Field>
                            <label>{t("points_input_one_string_address")}</label>
                            <Form.Input
                                placeholder={t("points_iput_one_string_address")}
                                value={addr.value}
                                disabled={true}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>

                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field required>
                            <label>{t("points_text_address_name_in_fm_system")}</label>
                            <Form.Input
                                disabled
                                required
                                placeholder={t("points_text_address_name_in_fm_system")}
                                value={point.fmid}
                                onChange={e => update({ fmid: e.target.value })}
                            />
                        </Form.Field>
                    </GridColumn>
                    <GridColumn>
                        <Form.Field>
                            <label>{t("points_placeholder_zone")}</label>
                            <Form.Input
                                type="number"
                                placeholder={t("points_placeholder_zone")}
                                disabled={true}
                                value={point.zone}
                                onChange={e => update({ zone: +e.target.value })}
                            />
                        </Form.Field>  
                    </GridColumn>
                </Grid.Row>

                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field required>
                            <label>{t("points_input_country")}</label>
                            <Form.Input
                                required
                                disabled
                                placeholder={t("points_input_country")}
                                value={addr.country}
                                onChange={e => updateAddress({ country: e.target.value })}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field required>
                            <label>{t("points_input_locality_with_type")}</label>
                            <Form.Input
                                required
                                disabled
                                placeholder={t("points_placeholder_locality")}
                                value={addr.settlement}
                                onChange={e => updateAddress({ settlement: e.target.value })}
                            />
                        </Form.Field>
                    </GridColumn>
                    <GridColumn>
                        <Form.Field required>
                            <label>{t("points_input_index")}</label>
                            <Form.Input
                                required
                                disabled
                                placeholder={t("points_input_index")}
                                value={addr.postalCode}
                                onChange={e => updateAddress({ postalCode: e.target.value })}
                            />
                        </Form.Field>
                    </GridColumn>
                    <GridColumn>
                        <Form.Field>
                            <label>{t("points_input_street_with_type")}</label>
                            <Form.Input
                                disabled
                                placeholder={t("points_placeholder_street_including_type")}
                                value={addr.streetName}
                                onChange={e => updateAddress({ streetName: e.target.value })}
                            />
                        </Form.Field>
                    </GridColumn>

                </Grid.Row>
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field>
                            <label>{t("points_input_house_number_with_type")}</label>
                            <Form.Input
                                disabled
                                placeholder={t("points_placeholder_house_number_example")}
                                value={addr.streetNo}
                                onChange={e => updateAddress({ streetNo: e.target.value })}
                            />
                        </Form.Field>
                    </GridColumn>
                    <GridColumn>
                        <Form.Field>
                            <label>{t("points_input_office")}</label>
                            <Form.Input
                                disabled
                                placeholder={t("points_placeholder_office_or_apartment")}
                                value={addr.office}
                                onChange={e => updateAddress({ office: e.target.value })}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>

                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field>
                            <label>{t("points_input_geo_lat")}</label>
                            <Form.Input
                                disabled
                                placeholder={t("points_placeholder_street_type")}
                                value={addr.geoLat}
                                onChange={e => updateAddress({ geoLat: e.target.value })}
                            />
                        </Form.Field>
                    </GridColumn>
                    <GridColumn>
                        <Form.Field>
                            <label>{t("points_input_geo_lon")}</label>
                            <Form.Input
                                disabled
                                placeholder={t("points_placeholder_street_type")}
                                value={addr.geoLon}
                                onChange={e => updateAddress({ geoLon: e.target.value })}
                            />
                        </Form.Field> 
                    </GridColumn>
                </Grid.Row>
            </Grid>
        </Form>
    );
}
