import { toast as toastProvider } from '../components/toast/toast';

/**
 * @typedef {number} ToastType
 **/
/**
 * @enum {ToastType}
 */
export const ToastType = {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning'
};

/** 
 * @param {ToastType} toastType
 * @param {string} text
 * @param {boolean} showClose
 * @param {number} timeout
 */
// export function toast(text, toastType = ToastType.SUCCESS, showClose, timeout) {
//     if (toastType === ToastType.ERROR) {
//         toastProvider({
//             type: ToastType.ERROR,
//             title: 'Ошибка',
//             description: text || 'без описания',
//             time: 10000
//         });
//     } else {
//         toastProvider({
//             type: toastType,
//             description: text || 'без описания',
//         });
//     }
// } 

const toArr = text => Array.isArray(text) ? text : [text || 'base_label_no-description'];

function error(text, title) {
    return toastProvider({
        type: ToastType.ERROR,
        title: title || 'base_label_error',
        description: toArr(text),
        time: 180000
    });
}

function success(text, title) {
    return toastProvider({
		type: ToastType.SUCCESS,
		title,
        description: toArr(text),
        time: 5000
    });
}

function info(text, title) {
    return toastProvider({
		type: ToastType.INFO,
		title,
        description: toArr(text),
        time: 10000
    });
}

function warning(text, title) {
    return toastProvider({
		type: ToastType.WARNING,
		title,
        description: toArr(text),
        time: 10000
    });
}

export const toast = {
    success,
    info,
    error,
    warning
};


