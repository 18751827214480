import React from "react";
import {Container, Icon, Label} from "semantic-ui-react";
import {getPermissionName, getRoles, postRole} from "../../../api/usersRoles";
import AppToolbarButton from "../../../components/buttons/AppToolbarButton";
import AppTable from "../../../components/tables/AppTable";
import AppTableHeader from "../../../components/tables/AppTableHeader";
import AppTableToolbar from "../../../components/tables/AppTableToolbar";
import Page from "../../../layout/page/Page";
import UserRoleForm from "./UserRoleForm";
import {LinkInfo} from "../../../layout/navbar/Navbar";
import {ContextNavi} from "../../../services/context";
//import {useTranslation} from 'react-i18next';
import {withTranslation} from "react-i18next";

const headers = [
    new AppTableHeader("id", "users_grid_id"),
    new AppTableHeader("color", "users_grid_color"),
    new AppTableHeader("name", "users_grid_role-name"),
    new AppTableHeader("permissions", "users_grid_accesses")
];

class UsersProfiles extends Page {
    static contextType = ContextNavi;

    componentWillMount() {
        const {t} = this.props;

        if (this.context.setItems) {
            this.context.setItems([new LinkInfo("users_label_user-roles", "/profiles")]);
        }
    }

    fetchData() {
        return getRoles().then(rows => {
            this.setState({rows});
        });
    }

    async addItem(profile) {
        await postRole(profile);
        return this.fetchData();
    }

    async editItem(profile) {
        await postRole(profile);
        return this.fetchData();
    }

    delItem(row) {
        const {t} = this.props;
        alert(t("users_text_deleting-item-x"));
    }

    renderItem(item, key) {
        const {t} = this.props;
        const val = item[key];

        switch (key) {
            case "color":
                return <Label circular color={val} empty key={val}/>;

            case "permissions":
                return val.map(v => (
                    <Label key={v} color="blue" className={"label-permissions"}>
                        {t(getPermissionName(v))}
                    </Label>
                ));

            default:
                return val;
        }
    }

    renderData() {

        const {t} = this.props;

        const {modal} = this.state;

        const editRowAction = (data) => {
            this.setState({
                modal: {
                    open: true,
                    data,
                    isEdit: true,
                    title: t("base_btn_edit") + " " + data.name,
                    submit: this.editItem.bind(this)
                }
            })
        };

        const handleAddClick = () => {
            this.setState({
                modal: {
                    open: true,
                    title: t("users_label_new-role"),
                    submit: this.addItem.bind(this),
                    form: UserRoleForm
                }
            })
        };

        return (
            <div className="bgn-white h-100">
                <UserRoleForm
                    {...modal}
                    title={t("users_label_new-role")}
                    size="small"
                    onClose={() => this.setState({modal: {open: false}})}
                />
                <Container className="h-100 app-table">
                    <div className="flex-col h-100">
                    <AppTableToolbar>
                        <AppToolbarButton icon="plus" onClick={handleAddClick}>{t("users_label_add-role")}</AppToolbarButton>
                    </AppTableToolbar>
                    <div className="flex-grow scrollable-v">
                        <AppTable
                            headers={headers}
                            rows={this.state.rows}
                            renderItem={this.renderItem.bind(this)}
                            onRowClick={editRowAction}
                        />
                    </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withTranslation()(UsersProfiles);
