import React, {useState, useEffect} from 'react';
import {Button, Form, Input, Message, Modal, TextArea} from "semantic-ui-react";
import {rgEditOrders, rgReject} from "../../api/registries";
import {formatNumber, getValuePassZero, restrictionFloat} from "../../services/utils";
import { useTranslation } from 'react-i18next';

const RgRejectModal = ({ open, onClose, fmid, registryNumber, ids, fetchData, isEdit, params: editParams = {} }) => {
    const { t } = useTranslation();

    let [params, setParams] = useState(editParams);

    useEffect(() => {
        open && setParams(editParams);
    }, [open]);

    const handleClose = () => {
        setParams({});
        onClose();
    };

    const handleChange = (e, {value, name}) => {
        setParams(params => ({
            ...params,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            if (isEdit) {
                await rgEditOrders ({
                    ids,
                    ...params,
                })
            } else {
                await rgReject({
                    ids,
                    ...params,
                });
            }

            await fetchData();
        } finally {
            handleClose();
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>{t(isEdit ? 'registers_label_comment-and-sum-edit' : 'registers_label_comment-and-sum-add')}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Message
                        info
                        icon='info'
                        content={isEdit ? <span>{t('registers_text_comment-and-sum-message-top')}</span>
                            : <span>{t('registers_text_not-confirm-message')} <b>{fmid}</b>.
                                {t('registers_text_comment-and-sum-message-bottom')}</span>}
                    />
                    <Form.Field>
                        <label>{t('registers_input_comment')}</label>
                        <TextArea
                            placeholder={t('registers_placeholder_comment')}
                            value={params.comment}
                            maxLength={1000}
                            name="comment"
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t('registers_input_sum')}</label>
                        <Input
                            type="number"
                            min="0"
                            step="0.1"
                            placeholder={t('registers_input_sum')}
                            value={getValuePassZero(params.clientCostWithoutVAT)}
                            onChange={e => handleChange(e, {name: 'clientCostWithoutVAT', value: restrictionFloat(e.target.value, 3)})}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t('base_btn_undo')}</Button>
                <Button color="green" disabled={!params.comment} onClick={handleSubmit}>{t(isEdit ? 'base_btn_save_changes' :'base_btn_save')}</Button>
            </Modal.Actions>
        </Modal>
    )
};

export default RgRejectModal;
