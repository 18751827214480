import React from 'react';
import {Route, Switch} from "react-router";
import RgGrid from "./RgGrid";
import ErrorNotFound from "../ErrorNotFound";
import RgCard from "./RgCard";
import './style.css';


const Registries = () => {
    return (
        <Switch>
            <Route exact path="/registries/:id" component={RgCard} />
            <Route exact path="/registries" component={RgGrid} />
            <Route component={ErrorNotFound} />
        </Switch>
    )
};

export default Registries;
