import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, Modal} from 'semantic-ui-react';
import {PackTypeEnum, PackTypeOptions, WaybillFieldEnum} from '../../../api/model/Enums';
import InputDropdown from '../../../components/inputs/InputDropdown';
import {ContextFieldConfigs, ContextWb} from '../../../services/context';
import {showValue} from '../../admin/companies/companyTabs/companyTabFieldConfig/companyTabUtils';
//import CargoSSCCInputs from './WbPointCardTabsPositionsLoadSetFormSSCC';
import {GetNewSscc} from '../../../api/waybills';
import {formatNumber, getValuePassZero, restrictionFloat} from "../../../services/utils";
import { useTranslation } from 'react-i18next';
import {toast} from "../../../services/toast";

const MAX_WEIGHT = 22000;

export default function PointCardTabsPositionsLoadSetForm({
                                                              isMixCargoUnitsConfig,
                                                              submit,
                                                              title,
                                                              value,
                                                              loadUnitSets,
                                                              children,
                                                              disabled,
                                                              positions = [],
                                                              pointsAll
                                                          }) {
    const { t } = useTranslation();

    const configContext = useContext(ContextFieldConfigs);

    const initData = {
        loadUnitType: '',
        loadUnitsCount: '',
        loadTotalVolumeM3: '',
        loadTotalWeigthBruttoKg: '',
        loadUnitsSSCCNos: ''
    };

    const contextWb = useContext(ContextWb);

    const [open, setOpen] = useState(false);
    const [data, setData] = useState(initData);
    const [canSave, setCanSave] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loadUnitTypeOptions, setLoadUnitTypeOptions] = useState(PackTypeOptions.filter(o => {
        var result = showValue(WaybillFieldEnum.CargoUnitType, o.value, contextWb.wb, configContext);
        return result;
    }));
    const inputRef = useRef();

    useEffect(() => {
        let loadUnitTypeOptionsNew = PackTypeOptions.filter(o => {
            var result = showValue(WaybillFieldEnum.CargoUnitType, o.value, contextWb.wb, configContext);
            return result;
        });

        if (!value) { // adding
            const usedLoadUnitTypes = (loadUnitSets || []).map(lus => lus.loadUnitType);
            const usedLoadUnitTypesUnique = Array.from(new Set(usedLoadUnitTypes));
            loadUnitTypeOptionsNew = loadUnitTypeOptionsNew.filter(o => !usedLoadUnitTypesUnique.includes(o.value));
            if (!isMixCargoUnitsConfig && usedLoadUnitTypesUnique.includes(PackTypeEnum.BX)) {
                loadUnitTypeOptionsNew = loadUnitTypeOptionsNew.filter(i => i.key === PackTypeEnum.PP)
            }
            if (!isMixCargoUnitsConfig && usedLoadUnitTypesUnique.includes(PackTypeEnum.PP)) {
                loadUnitTypeOptionsNew = loadUnitTypeOptionsNew.filter(i => i.key === PackTypeEnum.BX)
            }
            value = {loadUnitType: loadUnitTypeOptionsNew[0] ? loadUnitTypeOptionsNew[0].loadUnitType : null};
        }

        if (!isMixCargoUnitsConfig && pointsAll && pointsAll.length > 1 && value) {
            const pointLoading = pointsAll.filter(i => i.type === 0 && !!i.loadUnitSets.length);

            const loadUnitTypeChoose = pointLoading && pointLoading[0] && pointLoading[0].loadUnitSets ? pointLoading[0].loadUnitSets[0].loadUnitType : null;
            const loadUnitTypeChooseList = pointLoading ? pointLoading.map(value => value.loadUnitSets.map(i => i.loadUnitType)) : null;


            if (!!loadUnitTypeChooseList.length && (!!loadUnitTypeChooseList.filter(i => i.includes(PackTypeEnum.BX) || i.includes(PackTypeEnum.PP)).length)) {
                if (!positions.filter(i => i.loadUnitType === PackTypeEnum.BX || i.loadUnitType === PackTypeEnum.PP).length) {
                    loadUnitTypeOptionsNew = loadUnitTypeOptions.filter(i => i.key === PackTypeEnum.PP || i.key === PackTypeEnum.BX)
                }
            } else {
                loadUnitTypeOptionsNew = loadUnitTypeChoose ? loadUnitTypeOptions.filter(i => i.key === loadUnitTypeChoose) : loadUnitTypeOptions;
            }
        }

        if (!isMixCargoUnitsConfig && positions && !!positions.filter(i => i.loadUnitType === PackTypeEnum.BX || i.loadUnitType === PackTypeEnum.PP).length) {

            if (value && value.loadUnitType === PackTypeEnum.BX) {
                loadUnitTypeOptionsNew = PackTypeOptions.filter(i => i.key === PackTypeEnum.BX);
            }
            if (value && value.loadUnitType === PackTypeEnum.PP) {
                loadUnitTypeOptionsNew = PackTypeOptions.filter(i => i.key === PackTypeEnum.PP);
            }
        }

        setLoadUnitTypeOptions(loadUnitTypeOptionsNew);
    }, [pointsAll]);

    useEffect(() => {
        if (open && positions && positions.length && value) {
            let types = positions.map(v => v.loadUnitType);
            let loadUnitTypeOptionsNew = PackTypeOptions.filter(i => !types.includes(i.key) || i.key === data.loadUnitType);
            setLoadUnitTypeOptions(loadUnitTypeOptionsNew);
        }
    }, [data]);

    function validate(position) {
        let result = false;
        const {loadUnitType} = position;

        result =
            !!position.loadUnitType &&
            position.loadUnitsCount > 0 &&
            position.loadTotalWeigthBruttoKg > 0
        ;
        if (result) {
            result = !position.loadTotalVolumeM3 || position.loadTotalVolumeM3 > 0;
        }

        if (result) {
            // FMCP-900
            // if (!position.loadUnitsSSCCNos) {
            // 	result = true;
            // } else {
            // 	const ssccArr = position.loadUnitsSSCCNos.split(',');
            // 	result = ssccArr.length === position.loadUnitsCount && ssccArr.every(ss => !!ss);
            // }
        }

        return result;
    }

    function onChange(key, val) {
        const valueCloned = {...data};
        valueCloned[key] = val;
        setData(valueCloned);
        setCanSave(validate(valueCloned));
    }

    function onOpen() {
        if (disabled) return;
        const valueNew = !!value ? value : initData;
        setData(valueNew);
        setOpen(true);
    }

    function onClose() {
        setOpen(false);
        setData({});
    }

    function focusInput() {
        inputRef.current && inputRef.current.focus();
    }

    async function syncSSCC() {

        let ssccArr = data.loadUnitsSSCCNos
            ? data.loadUnitsSSCCNos.split(',')
            : [];

        if (ssccArr.length > data.loadUnitsCount) {
            ssccArr = ssccArr.slice(0, data.loadUnitsCount);

            const dataCloned = {...data};
            dataCloned.loadUnitsSSCCNos = ssccArr.toString();
            setData(dataCloned);
            return dataCloned;
        }

        if (ssccArr.length < data.loadUnitsCount) {
            const missingCount = data.loadUnitsCount - ssccArr.length;
            for (let index = 0; index < missingCount; index++) {
                ssccArr.push('');
            }
        }

        const totalRequestedNewSscc = ssccArr.filter(i => !i).length;

        if (totalRequestedNewSscc === 0) {
            return data;
        } else {
            const ssccNewSet = await GetNewSscc(totalRequestedNewSscc);
            for (let index = 0; index < ssccArr.length; index++) {
                const element = ssccArr[index];
                if (!element) {
                    ssccArr[index] = ssccNewSet.shift();
                }
            }
        }

        const dataCloned = {...data};
        dataCloned.loadUnitsSSCCNos = ssccArr.toString();
        setData(dataCloned);
        return dataCloned;
    }

    const isValidWeight = () => {
        let weight = data.loadTotalWeigthBruttoKg;
        pointsAll.map(point => {
            point.loadUnitSets.map(unit => {
                if (unit.id !== data.id) return weight += unit.loadTotalWeigthBruttoKg;
            })
        });
        return weight <= MAX_WEIGHT;
    }

    async function onSave() {
        try {
            setSaving(true);
            if (isValidWeight()) {
                await submit(data);
                if (data.loadUnitType !== PackTypeEnum.PP) {
                    const dataSynced = await syncSSCC();
                    await submit(dataSynced);
                }
            }
        } finally {
            setSaving(false);
            if (!isValidWeight()) toast.error("waybills_label_max-weight-exceeded", '');
            else {
                setCanSave(false);
                onClose();
            }
        }
    }

    return (
        <Modal
            trigger={children}
            centered={true}
            dimmer="inverted"
            size="small"
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header>{title || t('waybills_label_update-cargo-units')}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field required>
                            <label>{t('waybills_input_type-cargo-unit')}</label>
                            <InputDropdown
                                disabled={!loadUnitTypeOptions.length}
                                options={loadUnitTypeOptions}
                                placeholder={t('waybills_input_type-cargo-unit')}
                                value={data.loadUnitType || ''}
                                onChange={val => onChange('loadUnitType', val)}
                            />
                        </Form.Field>
                        <Form.Input
                            required
                            type="number"
                            min="1"
                            label={t('waybills_input_cnt-cargo-unit')}
                            placeholder={t('waybills_input_cnt-cargo-unit')}
                            value={data.loadUnitsCount || ''}
                            onChange={e => onChange('loadUnitsCount', Number.parseInt(e.target.value))}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input
                            required
                            type="number"
                            min="0"
                            step="0.1"
                            label={t('waybills_input_shipping-weight')}
                            placeholder={t('waybills_placeholder_shipping-weight')}
                            value={getValuePassZero(data.loadTotalWeigthBruttoKg)}
                            onChange={e => onChange('loadTotalWeigthBruttoKg', restrictionFloat(e.target.value, 3))}
                            onBlur={(e) =>  onChange('loadTotalWeigthBruttoKg', formatNumber(Number.parseFloat(e.target.value)))}
                        />
                        <Form.Input
                            type="number"
                            min="0"
                            step="0.1"
                            label={t('waybills_input_volume-m3')}
                            placeholder={t('waybills_input_volume-m3')}
                            value={getValuePassZero(data.loadTotalVolumeM3)}
                            onChange={e => onChange('loadTotalVolumeM3', restrictionFloat(e.target.value, 3))}
                            onBlur={(e) =>  onChange('loadTotalVolumeM3', formatNumber(Number.parseFloat(e.target.value)))}
                        />
                    </Form.Group>
                    {
                        /*<CargoSSCCInputs
                            countRaw={data.loadUnitType !== PackTypeEnum.PP ? data.loadUnitsCount : null}
                            valueRaw={data.loadUnitsSSCCNos || ''}
                            updateSSCC={val => onChange("loadUnitsSSCCNos", val.toString())}
                            focusInput={focusInput}
                        >
                            <Form.Field>
                                <label>{t('waybills_input_nums-sscc')}</label>
                                <Input
                                    disabled={!data.loadUnitsCount || data.loadUnitType === PackTypeEnum.PP}
                                    placeholder={t('waybills_input_nums-sscc')}
                                    value={data.loadUnitsSSCCNos || ''}
                                    onChange={e => onChange('loadUnitsSSCCNos', /\D/i.test(e.target.value) ? e.target.value.replace(/[A-Za-zА-Яё~Ёа-я-+*!/"№;%:?*<>.|\\()_!'@#%$^&=\`]/i, '') : e.target.value)}
                                    ref={inputRef}
                                />
                            </Form.Field>

                        </CargoSSCCInputs>*/
                    }
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>{t('base_btn_cancel')}</Button>
                <Button primary disabled={!canSave} onClick={onSave} loading={saving}>{t('base_btn_save')}</Button>
            </Modal.Actions>
        </Modal>
    );
}
