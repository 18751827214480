import React from 'react';
import { Grid, GridRow, GridColumn, Header } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import PointViewHeaderActions from './PointViewHeaderActions';

export default function PointViewHeader({ point, backToList, fetchData, setLoading, isClient, companyId }) {

    const { t } = useTranslation();

    const header = point.address
        ? point.address.value
            ? point.address.value
            : point.address.toString()
        : t("points_text_address_not_set");

    const styleRow = {
        minHeight: '85px',
        paddingBottom: "4px"
    };

    const getCompanyName = () => {
        return point.companyName;
    };

    return (
        <Grid verticalAlign='middle'>
            <GridRow style={styleRow}>
                <GridColumn width={11} className="p-t-5">
                    <Header>
                        <Header.Content >
                            {header} <span style={{ color: "#a9a9aa", fontSize: "smaller", fontWeight: "normal" }}>
                                {getCompanyName()}
                            </span>
                        </Header.Content>
                    </Header>
                </GridColumn>
                <GridColumn width={5} textAlign="right">
                    <PointViewHeaderActions
                        point={point}
                        backToList={backToList}
                        fetchData={fetchData}
                        setLoading={setLoading}
                        isClient={isClient}
                        companyId={companyId}
                    />
                </GridColumn>
            </GridRow>
        </Grid>
    );
}
