import React from 'react';
import Dropzone from 'react-dropzone';
import { Button, Modal, Form } from 'semantic-ui-react';
import { pointsImport, pointsImportClient } from '../../../../api/points';
import { withTranslation } from "react-i18next";
import InputDropdown from '../../../../components/inputs/InputDropdown';
import './PointsFormImport.css';

const ImportStatus = {
    START: 1,
    FILES_SELECTED: 2,
    IMPORTING: 3,
    SUCCESS: 4, // not used -> redirecting on success
    ERROR: 5,
};

const initial = {
    open: false,
    status: ImportStatus.START,
    acceptedFiles: null,
    autoexport: false,
    errors: ""
};


class PointsFormImport extends React.Component {

    

    state = {
        ...initial
    };

    handleOpen = () => this.setState({ open: true })
    handleClose = () => this.setState({ ...initial })

    onDrop(acceptedFiles) {
        this.setState({ status: ImportStatus.FILES_SELECTED, acceptedFiles });
    }

    async import() {

        const { acceptedFiles, autoexport } = this.state;
        const { isClient, selectedCompany } = this.props;

        var formData = new FormData();

        formData.append('files', acceptedFiles[0]);

        const importMethod = isClient ? pointsImportClient : pointsImport;

        this.setState({ status: ImportStatus.IMPORTING }, async () => {

            try {

                await importMethod(formData, selectedCompany);

                this.setState({ ...initial }, this.props.refresh);

            } catch (error) {

                this.handleClose();
            }

        });
    }

    render() {
        const { t } = this.props;
        const { status, acceptedFiles, autoexport, errors } = this.state;
        const { isClient, companyOptions, selectedCompany, setSelectedCompany } = this.props;

        const fileUrl = isClient ? 'points_import_client.xlsx ' : 'points_import.xlsx';

        const statusInitial = (
            <div>
                {isClient && companyOptions.length > 1 && <Form.Field>
                    <label>{t("points_input_select_company")}</label>
                    <InputDropdown
                        placeholder={t("points_input_select_company")}
                        options={companyOptions}
                        value={selectedCompany}
                        onChange={val => setSelectedCompany(val)}
                    />
                </Form.Field>}
                <Dropzone
                    onDrop={this.onDrop.bind(this)}
                    multiple={false}
                    className='dropzone'
                >
                {t("points_text_drag_file_with_addresses")}
                </Dropzone>
                <div className="m-t-15">
                    <a className="ui positive button"
                        href={`/files/${fileUrl}`}
                        target="_blank">
                        <i aria-hidden="true" className="download icon"></i>{t("points_text_download_example_for_import")}</a>
                </div>
            </div>
        );

        const statusFilesSelected = <div>
            <p>{acceptedFiles && acceptedFiles.length && acceptedFiles[0].name}</p>
        </div>;
        const statusLoading = <p>{t("points_text_file_loading")}</p>;
        const statusError = <p>{t("points_text_import_error"), errors}</p>;

        return (
            <Modal trigger={this.props.children}
                dimmer="inverted" size="small" closeIcon
                open={this.state.open}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
            >
                <Modal.Header>{t("points_text_import_from_file")}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {status === ImportStatus.START
                            ? statusInitial
                            : status === ImportStatus.FILES_SELECTED
                                ? statusFilesSelected
                                : status === ImportStatus.IMPORTING
                                    ? statusLoading
                                    : status === ImportStatus.SUCCESS
                                        ? ""
                                        : statusError
                        }
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button content={t("base_btn_cancel")} onClick={this.handleClose} />
                    <Button loading={status === ImportStatus.IMPORTING}
                        icon='check'
                        disabled={status !== ImportStatus.FILES_SELECTED && !!selectedCompany}
                        positive
                        content={t("points_btn_import_addresses")}
                        onClick={this.import.bind(this)} />
                </Modal.Actions>
            </Modal>
        );
    }
};

export default withTranslation()(PointsFormImport);
