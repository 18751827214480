export function getValuePassZero(val) {
    return val === 0 ? 0 : val || '';
}

export function getFloatOrString(val) {
    // x.0x enter cases (0 collapsing)
    if (isNaN(val) || val === 'NaN') {
        return '';
    }
    const result = Number.parseFloat(val);
    if (!(/0{2,},?/.test(val)) && result.toString() !== val) {
        return val;
    }
    return result;
}

export const formatNumber = (number) => {
    if (isNaN(number)) {
        return null
    }
    if (number % 1 !== 0) {
        return number.toFixed(3).replace(/0+$/, '');
    }
    return number;
};

export const restrictionFloat = (val, len = 2, minVal, maxVal, prevVal) => {
    if (!val) return null;

    let value = val.match(/[\d,.]/g) ? val.match(/[\d,.]/g).join('') : val;

    value = value.replace(/[^\d,.]/g, '').replace(',', '.');

    if (value.includes('.')) {
        const [v1, v2] = value.split('.');

        value = `${v1}.${v2.substr(0, len)}`;
    }

    if (minVal && maxVal && (parseFloat(value) < minVal || parseFloat(value) > maxVal)) value = prevVal || '';

    return value
};

export const restrictionInt = (val, minVal, maxVal, prevVal = '') => {
    if (!val) return null;

    let value = val.match(/[+\-\d]/g) ? val.match(/[+\-\d]/g).join('') : val;

    value = value.replace(/[^+\-\d]/, '');


    if (minVal && maxVal && (parseInt(value) < minVal || parseInt(value) > maxVal)) value = prevVal || '';

    return value;
};

export const restrictionOnlyNumber = (val) => {
    if (!val) return null;

    return val.replace(/[^\d]/g, '');
};

export const copyTextFromEvent = (data) => {
    navigator.clipboard && navigator.clipboard.writeText(data.target.textContent.replace(/ +/g, ' ').trim());
};
