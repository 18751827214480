import { PointService } from "./PointService"; // eslint-disable-line
import { PointTimeslot } from "./PointTimeslot";
import {WaybillStatusAvizationEnum} from "./Waybill"; // eslint-disable-line



export default class WaybillPoint {

    /** @param {WaybillPoint} dto */
    constructor(dto = {}) {
        this.id = dto.id;

        this.pointId = dto.pointId;

        this.avisationStatus  = dto.avisationStatus;

        this.clientId = dto.clientId;

        this.canCopy = dto.canCopy;

        this.address = dto.address; //

        this.companyName = dto.companyName; // for

        this.companyLegalForm = dto.companyLegalForm;

        this.companyLegalName = dto.companyLegalName;

        this.type = dto.type;

        // MAIN
         /** @type {string} YYYY-MM-DD*/
        this.arrivalDatePlan = dto.arrivalDatePlan;

        /** @type {string} YYYY-MM-DD*/
        this.arrivalDateFact = dto.arrivalDateFact;

        /**  @type {PointTimeslot|undefined} */
        this.arrivalTimeslotPlan = dto.arrivalTimeslotPlan;

        /**  @type {PointTimeslot|undefined} */
        this.arrivalTimeslotFact = dto.arrivalTimeslotFact;

        /** @type {PointContact|undefined} */
        this.contact = dto.contact;

        /** @type {string} */
        this.comment = dto.comment;


        // CARGO
        /** @type {LoadUnitSet[] | undefined} */
        this.loadUnitSets = dto.loadUnitSets || [];

        /** @type {LoadUnitsToOrderMapping[] | undefined} */
        this.loadUnitsToOrderMappings = dto.loadUnitsToOrderMappings || [];


        // SERVICES
        /** @type {PointService[]|undefined} */
        this.services = dto.services;
    }
}
