import O from "../../../../../../components/Option";
import { getText } from "../../../../../../api/model/Enums";

export const EnumMinutes = {
	M00 : 0,
	M15 : 15,
	M30 : 30,
	M45 : 45
};

export const EnumMinutesOptions = [
	new O(EnumMinutes.M00, "00"),
	new O(EnumMinutes.M15, "15"),
	new O(EnumMinutes.M30, "30"),
	new O(EnumMinutes.M45, "45"),
];

EnumMinutesOptions.getText = getText;