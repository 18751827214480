import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import ActionMenu from '../../../components/buttons/ActionMenu';
import {Button, Confirm, Dropdown, Icon} from 'semantic-ui-react';
import {ContextUser, ContextWb} from '../../../services/context';
import {
    cancelWaybills,
    copyWaybill,
    resendWaybills,
    waybillDeleteSoftById,
    waybillExport,
    wbIsEdit
} from '../../../api/waybills';
import {
    nonDeleteErrorAvisStatusSet,
    nonDeleteErrorStatusSet,
    WaybillStatusEnum,
    WaybillTypeEnum
} from '../../../api/model/Waybill';
import UserPermissions from '../../../api/model/UserPermissions';
import {reservationCancel} from '../../../api/waybillAviz';
import { useTranslation } from 'react-i18next';
import WbProcessing from "../common/WbProcessing";


function WbViewHeaderActions({ history, isAvisation }) {
    const { t } = useTranslation();

    const contextWb = useContext(ContextWb);
    const contextUser = useContext(ContextUser);
    const wb = contextWb.wb;
    const wbId = wb.id;

    let [confirm, setConfirm] = useState({open: false});

    let [isEdit, setIsEdit] = useState(true);

    const editable = !!wb.__canEdit && !wb.isPooling && isEdit;

    useEffect(() => {
        getIsEdit();
    }, []);

    const getIsEdit = async () => {
        const result = await wbIsEdit(wbId);

        setIsEdit(result && result.value)
    };

    const nonCancellableStatusSet = [
        WaybillStatusEnum.EXECUTING,
        WaybillStatusEnum.DRAFT,
        WaybillStatusEnum.CARGO_DELIVERED,
        WaybillStatusEnum.CANCELLED,
    ];

    const editClientWaybillStatusSet = [
        WaybillStatusEnum.SUBMITTED,
        WaybillStatusEnum.EXECUTING,
        WaybillStatusEnum.ON_APPROVAL,
    ];

    const cancellable = !nonCancellableStatusSet.includes(wb.status) && !wb.isPooling && wb.type !== WaybillTypeEnum.Return || (
        wb.type === WaybillTypeEnum.Return && contextUser.current.permissions.includes(UserPermissions.WAYBILL_SOFT_DELETE)
            && [...editClientWaybillStatusSet, WaybillStatusEnum.CARGO_DELIVERED].includes(wb.status)
    );

    const canResend = wb.status === WaybillStatusEnum.SUBMITTED && contextUser.current.permissions.includes(UserPermissions.WAYBILLS_RESEND);

    const canCopy = wb.canCopy;

    const canEdit = wb.status === WaybillStatusEnum.DRAFT;

    function gotoEditor() {
        history.push(`/waybills/${wbId}/edit`);
    }

    function gotoClientEditor() {
        history.push(`/waybills/client/${wbId}/edit`, {isEditTorg12: true});
    }

    async function waybillExportClick(event, data) {
        if (event.ctrlKey) {
            window.open(`/api/waybill/${wbId}/export/json`, '_blank');
        } else {
            var fileInfo = await waybillExport([wbId]);
            if (!fileInfo.error) {
                window.open(`/api/file/${fileInfo.id}`, '_blank');
            }
        }
    }

    async function gotoClientDeleteErroneous() {
        await waybillDeleteSoftById(wbId);
        history.push(`/waybills/`);
    }

    async function createCopy() {
        contextWb.setLoading(true);

        try {
            const newId = await copyWaybill(wbId);

            contextWb.setLoading(false);

            history.push(`/waybills/${newId}/edit`);
        } catch (err) {
            contextWb.setLoading(false);
        }
    }

    async function cancel() {
        try {
            contextWb.setLoading(true);
            await cancelWaybills([wbId]);
            await contextWb.fetchWb();
        } catch (error) {
            contextWb.setLoading(false);
        }
    }

    async function refresh() {
        try {
            contextWb.setLoading(true);
            await contextWb.fetchWb();
        } catch (error) {
            contextWb.setLoading(false);
        }
    }

    const editClientWaybill = ((editClientWaybillStatusSet.includes(wb.status)
        || ([WaybillStatusEnum.CARGO_DELIVERED].includes(wb.status) && isEdit)) && wb.shippingType !== WaybillTypeEnum.Return)
    || (wb.shippingType === WaybillTypeEnum.Return && [...editClientWaybillStatusSet, WaybillStatusEnum.CARGO_DELIVERED].includes(wb.status) && contextUser.current.permissions.includes(UserPermissions.RETURN_WAYBILLS_EDIT));

    const onClickCancelReservation = () => {
        setConfirm({
            open: true,
            onConfirm: () => {
                setConfirm({open: false});
                confirmCancelReservation();
            },
            content: t('waybills_label_cancel-reservation-question')
        })
    };

    const confirmCancelReservation = async () => {
        contextWb.setLoading(true);
        try {
            await reservationCancel(wb.reservationId);
            history.push('/waybills');
        } finally {
            contextWb.setLoading(false);
        }
    };

    return (
        <>
            {isAvisation ? (
                contextWb.wb.canCancelReservation && <Button onClick={onClickCancelReservation}>
                    <Icon name="dont" />
                    {t('waybills_btn_cancel-reservation')}
                </Button>
            ) : (
                <ActionMenu>
                    {/* {canHaveAvisation && <Dropdown.Item onClick={avizo}><T>Авизовать</T></Dropdown.Item>}*/}
                    <Dropdown.Item onClick={waybillExportClick}>
                        {t('base_btn_export')}
                    </Dropdown.Item>
                    <Dropdown.Item disabled={!canCopy} onClick={createCopy}>
                        {t('base_btn_copy')}
                    </Dropdown.Item>
                    {editable && (
                        <Dropdown.Item disabled={!canEdit} onClick={gotoEditor}>
                            {t('base_btn_edit')}
                        </Dropdown.Item>
                    )}
                    {editClientWaybill && (
                        <Dropdown.Item disabled={!canEdit} onClick={gotoClientEditor}>
                            {t('waybills_btn_edit-torg12-num')}
                        </Dropdown.Item>
                    )}
                    {!wb.isPooling &&
                        contextUser.current.permissions.includes(
                            UserPermissions.WAYBILL_SOFT_DELETE,
                        ) &&
                        nonDeleteErrorStatusSet.includes(wb.status) &&
                        nonDeleteErrorAvisStatusSet.includes(wb.avisationStatus) && (
                            <Dropdown.Item onClick={gotoClientDeleteErroneous}>
                                {t('waybills_btn_del-erroneous')}
                            </Dropdown.Item>
                        )}
                    <WbProcessing selectedRows={[{id: wbId}]} isResend refresh={refresh}>
                        <Dropdown.Item disabled={!canResend}>
                            {t('waybills_btn_resending-to-1c')}
                        </Dropdown.Item>
                    </WbProcessing>
                </ActionMenu>
            )}
            <Confirm
                open={confirm.open}
                cancelButton={t('base_btn_cancel')}
                content={confirm.content}
                onCancel={() => setConfirm({open: false})}
                onConfirm={confirm.onConfirm}
            />
        </>
    );
}

export default withRouter(WbViewHeaderActions);
