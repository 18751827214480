import React, {useEffect, useState} from 'react';
import {
    copyRuleApi,
    createRuleApi,
    deleteRuleApi,
    getRulesApi,
    getRulesListApi,
    saveRuleApi
} from "../../../../api/calculatingFieldsRules";
import TableComponent from "../../../autocomplete/TableComponent";
import {Button, Grid, Icon} from "semantic-ui-react";
import AddModalComponent from "../../../autocomplete/AddModalComponent";
import { useTranslation } from "react-i18next";

const CalculatingFieldsRules = ({match}) => {
    let [rules, setRules] = useState([]);

    const { t } = useTranslation();


    useEffect(() => {
        getRules();
    }, []);


    const getRules = async () => {
        const result = await getRulesListApi(match.params.id);

        setRules(result)

    };

    const confirmDelete = async (id, companyId) => {
        return await deleteRuleApi(companyId, id);
    };

    const handleCopy = async (id, companyId) => {

        return await copyRuleApi(companyId, id);

    };

    const handleGetRuleById = async (id, companyId) => {
        return await getRulesApi(companyId, id);
    };

    const handleRuleEdit = async (id, params, companyId) => {
        return await saveRuleApi(companyId, id, params);
    };

    const handleRuleCreate = async (params, companyId) => {
        return await createRuleApi(companyId, params);
    };

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column align="right">
                        <AddModalComponent indexRule={rules.length + 1} companyId={match.params.id} fetchData={getRules}
                                           getRuleById={handleGetRuleById}
                                           createRule={handleRuleCreate}
                                           editRule={handleRuleEdit}
                        >
                            <Button primary>
                                <Icon name="plus"/>
                                {t("waybillautocomplte_btn_add_rule")}
                            </Button>
                        </AddModalComponent>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <TableComponent companyId={match.params.id}
                            rules={rules}
                            getRules={getRules}
                            deleteRule={confirmDelete}
                            getRuleById={handleGetRuleById}
                            copyRule={handleCopy}
                            createRule={handleRuleCreate}
                            editRule={handleRuleEdit}
            />
        </>
    )
};

export default CalculatingFieldsRules;
