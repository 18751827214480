import React from 'react';
import { Grid, GridColumn, GridRow, Header } from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';

export default function CompanyTabHead({ data, fetchData, backToList }) {
    const {t} = useTranslation();

    const header = data.name || t("users_grid_no-name");

    const styleRow = {
        minHeight: '95px',
    };

    return (
        <Grid verticalAlign='middle'>
            <GridRow style={styleRow}>
                <GridColumn width={11} className="p-t-5">
                    <Header>
                        <Header.Content >
                            {header} <span style={{ color: "#a9a9aa", fontSize: "smaller", fontWeight: "normal" }}>{data.name || ""}</span>
                        </Header.Content>
                    </Header>
                </GridColumn>
                <GridColumn width={5} textAlign="right">
                    {/* <PointViewHeaderActions
                        point={point}
                        backToList={backToList}
                        fetchData={fetchData}
                        setLoading={setLoading} /> */}
                </GridColumn>
            </GridRow>
        </Grid>
    );
}