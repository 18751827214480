import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorNotFound from '../ErrorNotFound';
import './Waybills.css';
import WbEdit from './wbEdit/WbEdit';
import WbGrid from './wbGrid/WbGrid';
import WbViewer from './wbView/WbViewer';
import Downloads from "../downloads";

export function Waybills() {

    return (
        <Switch>
            <Route exact path='/waybills/:id/edit' component={WbEdit} />
            <Route exact path='/waybills/client/:id/edit' render={(props) => (<WbEdit {...props} isClient={true} />)} />
            <Route exact path='/waybills/:id/view/:tab' component={WbViewer} />
            <Route exact path='/waybills' component={WbGrid} />
            <Route component={ErrorNotFound} />
        </Switch>
    );
}
