import React from 'react';
import { Popup } from 'semantic-ui-react';
import ProgressVisual from '../../common/progressVisual/ProgressVisual';

const WbProgressPopup = ({data, children}) => (

    <Popup trigger={children} flowing hoverable hideOnScroll>
        <div>
            <ProgressVisual data={data} centerLine="40" step="210" />
        </div>
    </Popup>
);

export default WbProgressPopup;