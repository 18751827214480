import { PointTimeslot } from "./PointTimeslot"; // eslint-disable-line

export class PointDays
{
    /** @param {PointDays} dto */
    constructor(dto = {}) {

        /** @type {PointTimeslot} */
        this.mon = dto.mon;
        
        /** @type {PointTimeslot} */
        this.tue = dto.tue;
        
        /** @type {PointTimeslot} */
        this.wed = dto.wed;
        
        /** @type {PointTimeslot} */
        this.thu = dto.thu;
        
        /** @type {PointTimeslot} */
        this.fri = dto.fri;
        
        /** @type {PointTimeslot} */
        this.sat = dto.sat;
        
        /** @type {PointTimeslot} */
        this.sun = dto.sun;
    }
}