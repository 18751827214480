import React from 'react';
import {Form, Input} from 'semantic-ui-react';
import InputDropdown from '../../../components/inputs/InputDropdown';
import {CompanyStatusOptions} from '../../../api/model/Company';
import {useTranslation} from 'react-i18next';

export default function CompanyForm({data, setData, isNew = false, errors = []}) {

    const update = prop => setData({...data, ...prop});
    const {t} = useTranslation();

    return (
        <Form>
            <Form.Group widths="equal">
                <Form.Field required>
                    <label>{t('users_input_title')}</label>
                    <Form.Input
                        error={errors.includes('name')}
                        required
                        disabled
                        placeholder={t('users_input_title')}
                        value={data.name}
                        onChange={e => update({name: e.target.value})}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>{t("users_input_inn-client")}</label>
                    <Form.Input
                        error={errors.includes('inn')}
                        required
                        disabled
                        placeholder={t("users_input_inn-client")}
                        value={data.inn || ''}
                        onChange={e => update({inn: e.target.value})}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field
                    error={errors.includes('fmid')}
                    width={8}>
                    <label className='label-with-start'>{t("users_label_search-name")}</label>
                    <Input
                        required
                        disabled
                        placeholder={t("users_label_search-name")}
                        value={data.fmid}
                        onChange={e => update({ fmid: e.target.value })}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>{t("users_input_sap")}</label>
                    <Form.Input
                        error={errors.includes('idSAP')}
                        required
                        disabled
                        placeholder={t("users_input_sap")}
                        value={data.idSAP || ''}
                        onChange={e => update({idSAP: e.target.value})}
                    />
                </Form.Field>
                <Form.Field>
                    <label>{t("users_input_ogrn")}</label>
                    <Form.Input
                        placeholder={t("users_input_ogrn")}
                        disabled
                        value={data.ogrn || ''}
                        onChange={e => update({ogrn: e.target.value})}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Field>
                <label>{t('users_input_legal-address')}</label>
                <Form.Input
                    placeholder={t('users_input_legal-address')}
                    disabled
                    value={data.regAddress || ''}
                    onChange={e => update({regAddress: e.target.value})}
                />
            </Form.Field>
            <Form.Field>
                <label>{t('users_input_actual-address')}</label>
                <Form.Input
                    placeholder={t('users_input_actual-address')}
                    disabled
                    value={data.postAddress || ''}
                    onChange={e => update({postAddress: e.target.value})}
                />
            </Form.Field>
            
            {data.fmid && (
                <Form.Field>
                    <label>{t("users_label_status")}</label>
                    <InputDropdown
                        placeholder={t("users_label_status")}
                        options={CompanyStatusOptions}
                        value={data.statusId}
                        onChange={val => update({statusId: val})}
                    />
                </Form.Field>
            )}

        </Form>
    );

}
