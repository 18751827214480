import qs from 'query-string';
import React, {useContext} from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { PointStatusEnum } from '../../../../api/model/Point';
import { pointCreateAccepted } from '../../../../api/points';
import { useTranslation } from "react-i18next";
import PointsFormExportExcel from '../../../_shared/pointsFormExportExcel/PointsFormExportExcel';
import PointForm from '../pointForm/PointForm';
import PointsFormImport from '../pointsFormImport/PointsFormImport';
import {ContextUser} from "../../../../services/context";
import UserPermissions from "../../../../api/model/UserPermissions";

export default function PointsToolbar({
    location,
    updateRows,
    companyOptions,
    selectedCompany,
    setSelectedCompany,
    canExport,
    selectedPoints
}) {
    const contextUser = useContext(ContextUser);

    const { t } = useTranslation();

    async function createItem(item, options = {}) {

        let { companyName } = item;

        if (companyName && companyName.length) {
            companyName = companyName.trim();
            if (!'"“”\'«'.includes(companyName[0])) {
                companyName = '"' + companyName;
            }
            if (!'"“”\'»'.includes(companyName[companyName.length - 1])) {
                companyName = companyName + '"';
            }
        }

        item.companyName = companyName;
        const point = await pointCreateAccepted(item);
        if (point) {
            await updateRows();
        }
    };

    return (
        <Menu className="waybills-toolbar shd-inset" style={{ marginBottom: '0' }} size='small' borderless>
            <Menu.Menu position='right'>
                {
                    contextUser.current.permissions.includes(UserPermissions.CREATING_POINTS) &&
                    <PointForm
                        key="newPointForm"
                        searchName
                        title={t("points_label_new_address")}
                        size="small"
                        exportOnSaveCheck
                        data={{ statusId: PointStatusEnum.ACCEPTED }}
                        submit={createItem}
                        needFindSimilar
                        companyOptions={companyOptions}
                    >
                        <Menu.Item>
                            <Icon name="plus" />
                            {t("points_label_new_address")}
                        </Menu.Item>
                    </PointForm>
                }

                {/*<PointsFormImport
                    isClient={false}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    companyOptions={companyOptions}
                    refresh={updateRows}
                >
                    <Menu.Item>
                        <Icon name="arrow down" />
                        <T>Импорт адресов</T>
                    </Menu.Item>
                </PointsFormImport>*/}
                {canExport && <PointsFormExportExcel isClient={false} selectedPoints={selectedPoints}>
                    <Menu.Item>
                        <Icon name="arrow up" />
                        {t("points_label_export_addresses_into_excel")}
                    </Menu.Item>
                </PointsFormExportExcel>}
            </Menu.Menu>
        </Menu>
    );
}
