import React from 'react';
import { withRouter } from "react-router-dom";

import './WbGridFilter.css'; // provides for other filters as well
import UrlFilter from './models/UrlFilter';
import FilterMenuCondition from './FilterMenuCondition';
import FilterInputClearButton from './FilterInputClearButton';
import FilterInputDate from './FilterInputDate';
import moment from 'moment';
import FilterInputDatePeriod from "./FilterInputDatePeriod";
import { withTranslation } from 'react-i18next';

class WbGridFilterDatePeriod extends React.Component {

    constructor(props){
        super(props);

        const urlFilter = new UrlFilter(this.props.location.search, this.props.field);

        this.state = {
            condition: urlFilter.condition,
            value: urlFilter !== '' ? urlFilter.value : '',
            valueIsValid: true
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.location.search !== this.props.location.search) {
            const urlFilter = new UrlFilter(this.props.location.search, this.props.field);

            this.setState({
                value: urlFilter !== '' ? urlFilter.value : ''
            })
        }
    }

    onCloseFilterDatePeriod() {
        const {value} = this.state;
        if (value) {
            this.props.applyFilter({
                [this.props.field]: value
            });
        } else {
            this.props.removeFilter(this.props.field);
        }
    };

    handleInputClearClick() {
        this.props.removeFilter(this.props.field);
    };

    onInputChange({name, value}) {
        if (value) {
            this.setState({value});

        } else {
            this.setState({
                value: ''
            });
        }
    }

    render() {
        const { t } = this.props;

        const { value, valueIsValid, condition } = this.state;

        const bgnClass = this.state.value
            ? "filter-flex-right filter-flex-right-active"
            : "filter-flex-right";

        return (
            <div
                className="filter-flex-container"
                style={{ backgroundColor: value ? valueIsValid ? '#ffface' : '#fff6f6' : null }}>
                <div className="filter-flex-center">
                    <FilterInputDatePeriod
                        onChange={this.onInputChange.bind(this)}
                        value={this.state.value}
                        onClose={this.onCloseFilterDatePeriod.bind(this)}
                        name={t('waybills_input_select-period')}/>
                </div>
                {
                    this.state.value
                        ? (
                            <div className={bgnClass}>
                                <FilterInputClearButton
                                    handleInputClearClick={this.handleInputClearClick.bind(this)} />
                            </div>
                        )
                        : null
                }
            </div>
        );
    }
}

export default withRouter(withTranslation()(WbGridFilterDatePeriod));
