import React, { useContext, useState } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { ContextUser } from '../../services/context';
import { useTranslation } from 'react-i18next';

const labelStyle = {
    paddingTop: "0.41em",
    paddingBottom: "0.41em"
};

class CompanyInfo {
    constructor(text, title) {
        this.text = text;
        this.title = title;
    }
}

export default function NavbarUserContext() {
    const { t } = useTranslation();

    const contextUser = useContext(ContextUser);
    const [user, setUser] = useState(contextUser.current);

    const name = user ? user.name : t('base_btn_entry').l;
    const userCompanies = (user.companies || []).map(c => new CompanyInfo(c, c));
    const isAllCompanies = user.isAllCompanies || false;

    contextUser.setUser = user => setUser(user);
    contextUser.setCompanies = companies => {
        const userUpdated = { ...user };
        userUpdated.companies = companies;
        setUser(userUpdated);
    };
    contextUser.setIsCompaniesAll = isAllCompanies => {
        const userUpdated = { ...user };
        userUpdated.isAllCompanies = isAllCompanies;
        setUser(userUpdated);
    };


    let companiesCollapsed;
    if (userCompanies.length > 3) {
        companiesCollapsed = userCompanies.slice(0, 3);
        const rest = userCompanies.slice(3).map(c => c.text);
        const restTitle = rest.join(', ');
        companiesCollapsed.push(new CompanyInfo("...", restTitle));
    } else {
        companiesCollapsed = userCompanies;
    }
    return (
        <React.Fragment>
            <Icon name='user' fitted />&nbsp;&nbsp;{name}&nbsp;&nbsp;
            {isAllCompanies ? <Label style={labelStyle} title={t('base_label_all-companies')} color="teal">{t('base_label_all-companies')}</Label> : companiesCollapsed.map(c =><Label key={c.title} style={labelStyle} title={c.title} color="teal">{c.text.trunc(13, true)}</Label>)}
        </React.Fragment>
    );

}
