import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import './ActionMenu.css';
import { useTranslation } from 'react-i18next';

export default function ActionMenu({children}) {
    const { t } = useTranslation();

    return (
        <Dropdown 
            direction='left'
            text={t('registers_label_actions')}
            icon='cogs' 
            floating 
            labeled 
            button 
            className='icon dropdown-actionmenu' 
            closeOnChange
        >
            <Dropdown.Menu>
                <Dropdown.Header content={t('accesses_label_available-operations')} />
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
}