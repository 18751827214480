import React from 'react';
import { Label } from 'semantic-ui-react';
import {useTranslation} from "react-i18next";

export default function WbViewFooter({statusOrder, statusShipping}) {
    const { t } = useTranslation();

    const statusLabelOrder = statusOrder && statusOrder.text
        ? <Label color={statusOrder.color || null}><b>{t(statusOrder.text)}</b></Label>
        : null;
    
    const statusLabelShipping = statusShipping
        ? <Label color="violet" className="ellipsis m-l-10"> <b>{t(statusShipping)}</b></Label>
        : null;

    return (
        <>
            {statusLabelOrder}
            {statusLabelShipping}
        </>
    );
}