import React, {useMemo} from 'react';
import { Dropdown } from 'semantic-ui-react';
import './InputDropdown.css';
import { useTranslation } from 'react-i18next';
import {LoadCapacityOptions} from "../../api/model/Point";


export default function InputDropdownMultiple(props) {
    const { t } = useTranslation();

    const { value, disabled, required, options, placeholder } = props;

    const handleChange = (e, { value }) => {
        props.onChange(value);
    };

    const renderLabel = label => ({
        color: 'blue',
        content: `${label.text}`
    });

    const optionsTranslate = useMemo(() => {
        return options.map(bto => ({
            ...bto,
            text: t(bto.text)
        }))
    }, [options, t]);

    return (
        <Dropdown
            className="InputDropdown"
            noResultsMessage={t('base_label_no_results')}
            placeholder={t(placeholder)}
            fluid
            required={required}
            disabled={disabled}
            multiple={true}
            value={value}
            onChange={handleChange}
            closeOnChange
            selection
            options={optionsTranslate || []}
            renderLabel={renderLabel}
            />
    );
}

