import moment from "moment";

export function normalizeTime(val) {

    if (!val) return val;

    const valLen = val.length;

    const lastChar = val.slice(-1);

    // remove non digits
    if (isNaN(parseInt(lastChar, 10))) {
        return val.substr(0, valLen - 1);
    }

    switch (valLen) {
        case 1:
            break;

        case 2:
            const firstDigit = Number.parseInt(val[0]);

            if (firstDigit === 2 && Number.parseInt(lastChar) > 3) {
                return val.substr(0, valLen - 1);
            } else if (firstDigit > 2) {
                return `0${firstDigit}:${lastChar}`;
            }

            break;
        case 3:

            if (Number.parseInt(lastChar) > 5) {
                return val.substr(0, valLen - 1);
            }

            if (!val.includes(':')) {

                const valFixed = [...val];

                valFixed.splice(2, 0, ':');

                val = valFixed.join('');
            }

            break;
        case 4:
            break;
        case 5:
            if (!new moment(val, 'HH:mm', true).isValid())
                val = "";
            break;

        default:
            if (valLen > 5) {
                val = val.substr(0, 5);
            }
            break;
    }



    return val;
}
