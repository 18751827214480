import React from 'react';
import Dropzone from 'react-dropzone';
import {Button, Form, Grid, GridColumn, GridRow, Header, Icon, Message, Modal} from 'semantic-ui-react';
import {importWaybills, waybillImportExel} from '../../../../../api/waybills';
import T from '../../../../../components/Translate';
import './index.css';
import {PickupTypeOptions} from "../../../../../api/model/Enums";
import { withTranslation } from 'react-i18next';

const ImportStatus = {
    START: 1,
    FILES_SELECTED: 2,
    IMPORTING: 3,
    SUCCESS: 4, // not used -> redirecting on success
    ERROR: 5,
    SENDING: 6,
    SENDING_ERROR: 7,
    SENDING_SUCCESS: 8,
};


class WbsImportForm extends React.Component {

    state = {
        open: false,
        status: ImportStatus.START,
        acceptedFiles: null,
        errors: "",
        pickupChoiceComplete: false,
        sendProgress: false,
    };

    handleOpen = () => this.setState({open: true})
    handleClose = () => this.setState({open: false, status: ImportStatus.START}, this.props.refresh)

    onDrop(acceptedFiles) {
        this.setState({status: ImportStatus.FILES_SELECTED, acceptedFiles});
    }

    async import() {
        var formData = new FormData();

        formData.append('files', this.state.acceptedFiles[0]);

        this.setState({status: ImportStatus.IMPORTING}, async () => {

            try {
                // const result = await waybillImport(formData);
                // if (result.errors) {
                //     this.setState({ errors: result.errors, status: ImportStatus.ERROR });
                // } else {
                //     this.setState({ open: false, status: ImportStatus.SUCCESS }, this.props.refresh);
                // }

                const result = await waybillImportExel(formData);

                this.setState({
                    status: ImportStatus.SENDING, waybills: {
                        ...result
                    }
                })

                //this.setState({ open: false }, this.props.refresh);

            } catch (error) {

                //this.setState({ status: ImportStatus.ERROR });
                this.handleClose();
            }


        });
    }

    setPickupTypes = (id, value) => {
        this.setState(prevState => ({
            ...prevState,
            waybills: {
                ...prevState.waybills,
                send: prevState.waybills.send.map(item => item.id === id ? {...item, pickupType: value} : item)
            }
        }))
    };

    send = async () => {
        return await importWaybills(this.state.waybills.send);
    };

    registerWaybill = async () => {
        this.setState({sendProgress: true});
        const resultSend = await this.send();

        this.setState({resultMessages: [...(resultSend.map(x=>x.message))], sendProgress: false});

        if (resultSend.filter(x=>!x.isSuccess).length > 0) {
            this.setState({status: ImportStatus.SENDING_ERROR})
        } else {
            this.setState({status: ImportStatus.SENDING_SUCCESS})
        }

    };

    render() {
        const { t } = this.props;

        const {status, acceptedFiles, errors, waybills = {}, resultMessages = [], sendProgress} = this.state;

        const statusInitial = (
            <div>
                {/* <div>
                    <a className="ui positive button"
                        href="/files/Заявка.xlsm"
                        target="_blank">
                        <i aria-hidden="true" className="download icon"></i>Скачать пример файла для импорта заявок</a>
                </div> */}
                <Dropzone
                    onDrop={this.onDrop.bind(this)}
                    multiple={false}
                    accept=".xlsx,.xlsm"
                    className='dropzone'
                >{t('waybills_label_drag-file')}</Dropzone>
            </div>
        );

        const statusFilesSelected = <div><p>{acceptedFiles && acceptedFiles.length && acceptedFiles[0].name}</p></div>;
        const statusLoading = <p>{t('waybills_label_loading-files')}</p>;
        const statusError = <p>{t('waybills_label_import-error')}: {errors}</p>;
        const processingNegative = (
            <React.Fragment>
                <Header>{t('waybills_label_reg-error')} <Icon name="frown outline" /></Header>
                {resultMessages.map(text => <p>{text}</p>)}
            </React.Fragment>
        );

        const processingPositive = (
            <React.Fragment>
                <Header>{t('waybills_label_reg-success')}<Icon name="smile outline" /></Header>
                {resultMessages.map(text => <p>{text}</p>)}
            </React.Fragment>
        );

        const statusSending = <Form>
            <Message
                info
                icon="info"
                content={<span>{t('waybills_label_if-ready-click-btn')} <b>{t('waybills_btn_send')}</b>{t('waybills_label_if-no-click-btn')}<b>{t('base_btn_cancel')}</b></span>}
            />
            <Form.Field>
                <b>{t('waybills_label_delivery-method-question')}</b>
            </Form.Field>
            <div className="m-t-15 scrollable">

                <Grid verticalAlign="middle" divided='vertically'>
                    <GridRow className="p-b-0">
                        <GridColumn width={2}>{t('waybills_grid_application')}</GridColumn>
                        <GridColumn width={3}>{t('waybills_grid_consignee')}</GridColumn>
                        <GridColumn width={4}>{t('waybills_grid_address')}</GridColumn>
                        <GridColumn width={7}>{t('waybills_grid_delivery-method')}</GridColumn>
                    </GridRow>
                    {[...(waybills.send || [])].map(r =>
                        <GridRow className="p-b-0 p-t-0">
                            <GridColumn width={2}>{r.id}</GridColumn>
                            <GridColumn width={3}>{r.consignee}</GridColumn>
                            <GridColumn width={4}>{r.address && r.address.toString()}</GridColumn>
                            <GridColumn width={7}>
                                {r.isHub && <Form.Select fluid
                                                         options={PickupTypeOptions}
                                                         placeholder={t('waybills_placeholder_delivery-method')}
                                                         value={r.pickupType}
                                                         onChange={(e, {value}) => this.setPickupTypes(r.id, value)}
                                />}
                            </GridColumn>
                        </GridRow>
                    )}
                </Grid>

            </div>
        </Form>;

        return (
            <Modal trigger={this.props.children}
                   dimmer="inverted" size="small" closeIcon
                   open={this.state.open}
                   onOpen={this.handleOpen}
                   onClose={this.handleClose}
            >
                <Modal.Header>{t('waybills_label_import-from-file')}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {/*{status === ImportStatus.START
                            ? statusInitial
                            : status === ImportStatus.FILES_SELECTED
                                ? statusFilesSelected
                                : status === ImportStatus.IMPORTING
                                    ? statusLoading
                                    : status === ImportStatus.SUCCESS
                                        ? ""
                                        : statusError
                        }*/}
                        {status === ImportStatus.START && statusInitial}
                        {status === ImportStatus.FILES_SELECTED && statusFilesSelected}
                        {status === ImportStatus.IMPORTING && statusLoading}
                        {status === ImportStatus.ERROR && statusError}
                        {status === ImportStatus.SENDING && statusSending}
                        {status === ImportStatus.SENDING_ERROR && processingNegative}
                        {status === ImportStatus.SENDING_SUCCESS && processingPositive}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {
                        status !==ImportStatus.SENDING_SUCCESS && status !== ImportStatus.SENDING_ERROR
                        && <Button content={t('base_btn_cancel')} onClick={this.handleClose}/>
                    }
                    {
                        status === ImportStatus.SENDING
                            ? <Button
                                primary
                                disabled={!(waybills.send || []).filter(sr => sr.isHub).every(sr => sr.pickupType > 0)}
                                content={t('waybills_btn_send')}
                                loading={sendProgress}
                                onClick={this.registerWaybill}
                            />
                            : status === ImportStatus.SENDING_SUCCESS || status === ImportStatus.SENDING_ERROR ?
                            <Button onClick={this.handleClose}>{t('base_btn_close')}</Button>
                            : <Button loading={status === ImportStatus.IMPORTING}
                                      icon='check'
                                      disabled={status !== ImportStatus.FILES_SELECTED}
                                      positive
                                      content={t('waybills_label_import-reqs')}
                                      onClick={this.import.bind(this)}/>
                    }
                </Modal.Actions>
            </Modal>
        );
    }
};

export default withTranslation()(WbsImportForm)
