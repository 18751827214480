import {del, get, post, put} from '../services/http';
import O from '../components/Option'

export const fieldsTypeEnum = {
    Int: 0,
    Double: 1,
    String: 2,
    Enum: 3,
    Date: 4,
    Time: 5,
};

export const fieldCompareEnum = {
    Equal: 0,
    NotEqual: 1,
    Greater: 2,
    Less: 3,
    GreaterEqual: 4,
    LessEqual: 5,
    Contains: 6,
    NotContains: 7
};

export const fieldCompareOptions = [
    new O(fieldCompareEnum.Equal, "waybillautocomplte_enum_equal"),
    new O(fieldCompareEnum.NotEqual, "waybillautocomplte_enum_not-equal"),
    new O(fieldCompareEnum.Greater, "waybillautocomplte_enum_greater"),
    new O(fieldCompareEnum.Less, "waybillautocomplte_enum_less"),
    new O(fieldCompareEnum.GreaterEqual, "waybillautocomplte_enum_greater-equal"),
    new O(fieldCompareEnum.LessEqual, "waybillautocomplte_enum_less-equal"),
    new O(fieldCompareEnum.Contains, "waybillautocomplte_enum_contains"),
    new O(fieldCompareEnum.NotContains, "waybillautocomplte_enum_not-contains"),
];

export const getRulesListApi = async (companyId) => {
    return await get(`companies/${companyId}/autocomplete`);
};

export const getStandardAutocompleteListApi = async () => {
    return await get(`companies/standard-autocomplete`);
};

export const getFieldsApi = async () => {
    const result = await get(`companies/autocomplete/fields/target`);

    return  result.map(item => ({
        ...item,
        key: item.key,
        value: item,
        text: item.field.name
    }))
};


export const getRulesApi = async (companyId, id) => {

    return await get(`companies/${companyId}/autocomplete/${id}`);
};

export const getStandardAutocompleteApi = async (id) => {

    return await get(`companies/standard-autocomplete/${id}`);
};

export const getFieldsFromRulesApi = async () => {
    return await get(`companies/autocomplete/fields/rule`);
};

export const createRuleApi = async (companyId, params) => {
    return await post(`companies/${companyId}/autocomplete`, params)
};

export const createStandardAutocompleteApi = async (params) => {
    return await post(`companies/standard-autocomplete`, params)
};

export const saveRuleApi = async (companyId, id, params) => {
    return await put(`companies/${companyId}/autocomplete/${id}`, params)
};

export const saveStandardAutocompleteApi = async (id, params) => {
    return await put(`companies/standard-autocomplete/${id}`, params)
};

export const deleteRuleApi = async (companyId, id)=> {
    return await del(`companies/${companyId}/autocomplete/${id}`)
};

export const deleteStandardAutocompleteApi = async (id)=> {
    return await del(`companies/standard-autocomplete/${id}`)
};

export const copyRuleApi = async (companyId, id) => {
    return await post(`companies/${companyId}/autocomplete/${id}/copy`)
};


export const copyStandardAutocompleteApi = async (id) => {
    return await post(`companies/standard-autocomplete/${id}/copy`)
};

