import {del, get, post, put} from '../services/http';
import qs from "query-string";

export const createSchedule = async(params) => {
    return await post(`schedules`, params);
};

export const createCheck = async (params) => {
    return await post(`schedules/check`, params);
};

export const editCheck = async (id, params) => {
    return await post(`schedules/${id}/check`, params);
};

export const editSchedule = async(id, params) => {
    return await put(`schedules/${id}`, params);
};

export const getSettings = async (searchStr) => {
    return await get(`schedules/settings${searchStr}`)
};

export const getSchedule = async (id) => {
    return await get(`schedules/settings/${id}`)
};

export const getChainOptionsRequest = async (name, value = {}) => {
    const str = '?' + qs.stringify({
        name,
        currentPointType: value.pointType,
        currentCompanyName: value.companyName
    });
    return await get(`schedules/chains${str}`);
};

export const getSearchnamesOptionsRequest = async (params) => {
    const str = '?' + qs.stringify(params);

    return await get(`schedules/searchnames${str}`);
};

export const getSendingregionsOptionsRequest = async (region, currentRegion) => {
    const str = '?' + qs.stringify({
        region,
        currentRegion
    });

    return await get(`schedules/sendingregions${str}`);
};

export const getRegionsOptionsRequest = async (region) => {

    return await get(region ? `regions?name=${region}` : 'regions');
};

export const getCityOptionsRequest = async (region, name) => {

    return await get(name ? `regions/${region}/cities?name=${name}` : `regions/${region}/cities`);
};

export const deleteSchedule = async (ids) => {
    //return Promise.all(ids.map(id => del(`schedules/${id}`)))

    const getQueryString = (ids) => {
        var esc = encodeURIComponent;
        return ids
            .map(k => esc('ids') + '=' + esc(k))
            .join('&');
    };

    const str = '?' + getQueryString(ids);

    return del(`schedules${str}`)
};

export const copySchedule = async (id) => {
    return await post(`schedules/${id}/copy`)
};

export const getSchedulesView = async (params) => {
    return await post(`schedules/view`, params)
};

export const getFilters = async (region) => {
    const str = '?' + qs.stringify({destinationRegions: region});

    return await post(`schedules/view/filters`, {destinationRegions: region})
};

export const exportSettings = async (params) => {
    return await post('schedules/settings/export', params)
};

export const exportViews = async (params) => {
    return await post('schedules/view/export', params)
};
