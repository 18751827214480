import React, {useContext, useState} from "react";
import {getWaybillsColumns, setWaybillColumns} from "../api/waybills";
import {locales} from "./locale";
import { useTranslation } from 'react-i18next';
import {changeLanguage} from "../api/users";

export const ContextApp = React.createContext({});

export const ContextLocale = React.createContext();
export function ContextLocaleProvider({children}) {
    const [locale, setLocale] = useState(localStorage.getItem('i18nextLng'));

    async function setAndSaveLocale(localeNew, i18n) {
        i18n.changeLanguage(localeNew);
        setLocale(localeNew);

        await changeLanguage(localeNew);
    }

    return (
        <ContextLocale.Provider value={{
            locale,
            setLocale: setAndSaveLocale
        }}>
            {children}
        </ContextLocale.Provider>
    );
}

export const ContextUser = React.createContext({
    current: null,
    setUser: () => { throw new Error("setUser: uninitialised contextWb"); },
    setCompanies: () => { throw new Error("setCompanies: uninitialised contextWb"); },
    setIsCompaniesAll: () => {throw new Error("setIsCompaniesAll: uninitialised contextWb"); }
});

export const ContextNavi = React.createContext({
    setItems: () => { }
});

export const ContextWb = React.createContext({
    wb: null,
    update: () => { throw new Error("update: uninitialised contextWb"); },
    save: () => { throw new Error("save: uninitialised contextWb"); },
    wbErrors: []
});



export const ContextFieldConfigs = React.createContext({
    configs: [],
    update: () => { throw new Error("update: uninitialised contextWb"); },
    save: () => { throw new Error("save: uninitialised contextWb"); },
});


export const ContextWaybill = React.createContext();
export function ContextWaybillProvider({children, loadingOver}) {
    const { t } = useTranslation();

    const [wb, setWb] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    async function fetchWb() {
        alert(t('base_label_dev-func'));
    }
    // if there is no context - we should be showing ErrorNotFound
    return (
        <ContextGridSettings.Provider value={{ wb, setWb, fetchWb }}>
            {children}
        </ContextGridSettings.Provider>
    );
}

export const ContextGridSettings = React.createContext();
export function ContextGridSettingsProvider({children}) {
    const userContext = useContext(ContextUser);
    const userCurrent = userContext.current;

    const [columns, setColumns] = useState(getWaybillsColumns(userCurrent.email, setup => setWaybillColumns(setup, userCurrent.email)));


    function setAndSaveColumns(setup) {
        setColumns(setWaybillColumns(setup, userCurrent.email)); // save
    }

    return (
        <ContextGridSettings.Provider value={{
            columns,
            setColumns: setAndSaveColumns
        }}>
            {children}
        </ContextGridSettings.Provider>
    );
}

export const ContextFooter = React.createContext();
export function ContextFooterProvider({children}) {
    const [indicator, setIndicator] = useState(null);

    return (
        <ContextFooter.Provider value={{
            indicator,
            setIndicator
        }}>
            {children}
        </ContextFooter.Provider>
    );
}
