import React, {useContext, useEffect, useState} from 'react';
import {Grid, GridColumn, GridRow, Icon, Segment, Step} from 'semantic-ui-react';
import {Route, Switch} from 'react-router-dom';
import {getCompanyById} from '../../../api/companies';
import {LinkInfo} from '../../../layout/navbar/Navbar';
import Shield from '../../../layout/page/shield/Shield';
import {ContextNavi, ContextUser} from '../../../services/context';
import {StepData} from '../../waybills/wbView/StepInfo';
import CompanyTabHead from './companyTabs/CompanyTabHead';
import CompanyTabOApi from './companyTabs/CompanyTabOApi';
import CompanyTabProps from './companyTabs/CompanyTabProps';
import CompanyTabsCargoRestrictions from './companyTabs/CompanyTabCargoRestrictions';
import CompanyTabFieldConfig from './companyTabs/companyTabFieldConfig/CompanyTabFieldConfig';
import CompanyTabCuttoffBlock from './companyTabs/CompanyTabCutoffBlock';
import CompanyTabCuttoffRules from './companyTabs/CompanyTabCutoffRules';
import CompanyTabCutoffUpdateBlock from './companyTabs/CompanyTabCutoffUpdateBlock';
import ErrorNotFound from '../../ErrorNotFound';
import CompanyTabAddressExceptions from "./companyTabs/CompanyTabAddressExceptions";
import CompanyTabCutoffUpdateRules from "./companyTabs/CompanyTabCutoffUpdateRules";
import CalculatingFieldsRules from "./companyTabs/CalculatingFieldsRules";
import UserPermissions from "../../../api/model/UserPermissions";
import {useTranslation} from 'react-i18next';

export default function CompanyTabs({ match, history }) {
    const contextNavi = useContext(ContextNavi);
    const context = useContext(ContextUser);
    const {t} = useTranslation();

    const user = context && context.current;

    const [data, setData] = useState();
    const steps = [
        new StepData(null, "props", t('users_grid_properties'), "info"),
        new StepData(null, "oapi", t('users_grid_open-api'), "feed"),
        new StepData(null, "cutoffBlock", t('users_grid_renewal-of-registration'), "clock"),
        new StepData(null, "cutoffRules",  t('users_grid_registration-rules'), "clock"),
        new StepData(null, "cutoffUpdateBlock", t('users_grid_renewal-upgrades'), "clock"),
        new StepData(null, "cutoffUpdateRules",  t('users_grid_upgrades-rules'), "clock"),
        new StepData(null, "restrictions", t("users_grid_capacity-limit"), "truck"),
        new StepData(null, "field-config", t("users_grid_setting-fields"), "cogs"),
        new StepData(null, "address-exceptions", t("users_grid_address-exclusions"), "exclamation"),
    ];
    if (user.permissions.includes(UserPermissions.AUTOCOMPLETE)) {
        steps.push(new StepData(null, "calculating-fields-rules", t("users_grid_auto-calculating-fields"), "calculator"))
    }
    const tabKeyActive = match.params.tab;
    const styleNoBorderYellow = {
        paddingTop: '15px',
        borderTop: '2px solid #fbbd08',
    };

    async function fetchData() {
        const companyId = match.params.id;
        const company = await getCompanyById(companyId);
        setData(company);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (data) {
            contextNavi.setItems([
                new LinkInfo("users_label_companies", "/admin/companies"),
                new LinkInfo(data.name, `/admin/companies/${data.id}`),
            ]);
        }
    }, [data]);

    return (
        <Shield loading={!data} loadingOver={data && data.id}>
            <Grid columns={2} className="p-t-0 m-t-0 h-100">
                <GridRow stretched className="p-t-0 p-b-0 m-t-1-negative">
                    <Grid.Column className="p-b-0 p-r-0 m-r-1-negative" style={{ width: "16%" }}>
                        <Step.Group fluid vertical size='tiny' className="m-b-0 steps-grey">
                            <div className="step-wrapper">
                                {steps.map((s) => (
                                    <Step
                                        key={s.index}
                                        active={tabKeyActive === s.index}
                                        link
                                        onClick={() => history.push(`/admin/companies/${data.fmid}/${s.index}`)}
                                    >
                                        <Icon name={s.icon} />
                                        <Step.Content>
                                            <Step.Title>{s.text}</Step.Title>
                                        </Step.Content>
                                    </Step>
                                ))}
                            </div>
                        </Step.Group>
                    </Grid.Column>
                    <Grid.Column className="editor-rightcol p-l-0 p-b-0" style={{width: "84%"}}>
                        <Segment style={{ marginRight: '25px', paddingLeft: '25px' }}>
                            <CompanyTabHead
                                data={data}
                                fetchData={fetchData}
                            />
                            <div style={{
                                overflowY: 'hidden',
                                overflowX: 'hidden',
                            }}>
                                <div style={styleNoBorderYellow} >
                                    <div className="scrollable-v m-t-10" style={{ height: 'calc(100vh - 200px)' }}>
                                        <Grid>
                                            <Grid.Row>
                                                <GridColumn width={16}>
                                                    <Switch>
                                                        <Route path='/admin/companies/:id/props' exact>
                                                            <CompanyTabProps data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/oapi' exact>
                                                            <CompanyTabOApi data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/cutoffBlock' exact>
                                                            <CompanyTabCuttoffBlock data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/cutoffRules'>
                                                            <CompanyTabCuttoffRules data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/cutoffUpdateBlock'>
                                                            <CompanyTabCutoffUpdateBlock data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/cutoffUpdateRules'>
                                                            <CompanyTabCutoffUpdateRules data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/restrictions' exact>
                                                            <CompanyTabsCargoRestrictions data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/field-config' exact>
                                                            <CompanyTabFieldConfig data={data} setData={setData} match={match} history={history} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/address-exceptions' exact>
                                                            <CompanyTabAddressExceptions data={data} setData={setData} match={match} history={history} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/calculating-fields-rules' exact>
                                                            <CalculatingFieldsRules data={data} setData={setData} match={match} history={history} />
                                                        </Route>
                                                        <Route component={ErrorNotFound} />
                                                    </Switch>
                                                </GridColumn>
                                            </Grid.Row>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </Segment>
                    </Grid.Column>
                </GridRow>
            </Grid>
        </Shield >

    );
}
