import React from 'react';
import PropTypes from 'prop-types';
import { Popup, Menu } from 'semantic-ui-react';
import {withTranslation} from "react-i18next";

class FilterMenuList extends React.Component {

    state = {
        open: false
    }

    componentDidMount() {
        this.props.handleExternalClick(this.handleBtnClick.bind(this));
    }

    componentWillUnmount() {
        this.props.handleExternalClick(null);
    }

    handleExternalClick() {
        this.setState({
            open: true
        });
    }

    handleBtnClick() {
        this.setState({
            open: !this.state.open
        });
    }

    handleOptionClick = (e, o) => {
        this.setState({
            open: !this.state.open
        });
        this.props.handleOptionClick(o.field);
    };

    handleCloseEvent(event) {
        if (event.type === 'click') {
            this.setState({
                open: false
            });
        }
    }

    render() {
        const {t} = this.props;
        const menu = (
            <Menu secondary vertical style={{width: 'inherit'}}>
                {this.props.options.map(o => (
                    <Menu.Item
                        key={o.key}
                        name={t(o.text)}
                        field={o.key}
                        onClick={this.handleOptionClick.bind(this)}>
                        {t(o.text)}
                    </Menu.Item>
                ))}
            </Menu>
        );

        const btn = (
            <div onClick={this.handleBtnClick.bind(this)}>
                <i aria-hidden="true" className="dropdown icon fitted" />
            </div>
        );

        return (
            <Popup
                trigger={btn}
                content={menu}
                open={this.state.open}
                onClose={this.handleCloseEvent.bind(this)}
                position="bottom right"
                hoverable={true}
                basic
                verticalOffset={-9}
            />
        );
    }

}

FilterMenuList.propTypes = {
    options: PropTypes.array.isRequired,
    handleOptionClick: PropTypes.func.isRequired
};

export default withTranslation()(FilterMenuList);


