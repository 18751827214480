import React from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Header
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function ErrorNotFound(props) {
    const { t } = useTranslation();

    return (
        <>
            <Container text style={{ paddingTop: '7em' }}>
                <Header as='h1'>{t('base_label_404')}</Header>
                <p>{t('base_label_link-to-home')}<Link to="/">{t('base_label_homepage')}</Link></p>
            </Container>
        </>
    );
}