import O from '../components/Option';
import {get, post} from '../services/http';
import UserPermissions from './model/UserPermissions';


export const UserRolesEnum = {
    ADMIN: 1,
    CLIENT: 2,
    COORDINATOR_CS: 3,
    SPECIALIST_CS: 4,
    ADMIN_CS: 5,
    COMMERCE: 6
};

export async function getRoles() {
    const result = await get("roles");
    return result;
}

export async function getRoleById(id) {
    const result = await get(`roles/id/${id}`);
    return result;
}

/** @param {number} key  */
export function getPermissionName(key) {
    switch (key) {
        case UserPermissions.WAYBILLS: return "users_enum_waybills";
        case UserPermissions.COMPANY_POINTS: return "users_enum_company_points";
        case UserPermissions.WAYBILL_EDIT: return "users_enum_waybills_edit";
		case UserPermissions.COMPANIES: return "users_enum_companies";
		case UserPermissions.CREATING_COMPANIES: return "users_enum_creating_clients";
		case UserPermissions.PROVIDERS: return "users_enum_providers";
        case UserPermissions.POINTS: return "users_enum_points";
        case UserPermissions.CREATING_POINTS: return "users_enum_creating_point";
        case UserPermissions.CREATING_MY_POINTS: return "users_enum_creating_my_point";
        case UserPermissions.USERS: return "users_enum_users";
        case UserPermissions.USERPROFILES: return "users_enum_userprofiles";
        case UserPermissions.WAYBILL_SOFT_DELETE: return "users_enum_waybill_deleting";
        case UserPermissions.GETTING_NOTIFICATIONS: return "users_enum_getting_notifications";
        case UserPermissions.FMID_ADDRESS: return "users_enum_point_name_in_fm_system";
        case UserPermissions.REGISTRIES: return "users_enum_registries";
        case UserPermissions.COMPLETION_REGISTRIES: return "users_enum_completion_registry";
        case UserPermissions.BANNERS: return "users_enum_banners";
        case UserPermissions.BANNER_NOTIFICATIONS: return "users_enum_banners_notofications";
        case UserPermissions.REGISTRIES_NOTIFICATIONS: return "users_enum_registries_notifications";
        case UserPermissions.REGISTRIES_DELETE: return "users_enum_registries_delete";
        case UserPermissions.AUTOCOMPLETE: return "users_enum_autocomplete";
        case UserPermissions.STANDARD_AUTOCOMPLETE: return "users_enum_standard_autocomplete";
        case UserPermissions.SCHEDULES: return "users_enum_schedules";
        case UserPermissions.SCHEDULES_EDIT: return "users_enum_schedules_editing";
        case UserPermissions.SCHEDULES_SETTINGS_VIEAW: return "users_enum_schedules_setting_view";
        case UserPermissions.RETURN_WAYBILLS_EDIT: return "users_enum_return_waybills_edit";
        case UserPermissions.DISALLOW_AVISATION: return "users_enum_disallow_avisation";
        case UserPermissions.ALLOW_UPDATE_TRANSLATIONS: return "users_enum_allow_update_translations";
        case UserPermissions.WAYBILL_STATUS_NOTIFICATIONS: return "users_enum_waybill_status_notifications";
        case UserPermissions.WAYBILLS_RESEND: return "users_enum_resending-to-1c";
        case UserPermissions.NEW_WAYBILLS_NOTIFICATION: return "users_enum_new_waybill_notification";
        default: return key;
    }
}

export function getPermissionOptions() {
    const permissionOptions = Object.keys(UserPermissions)
        .map(k => new O(UserPermissions[k], getPermissionName(UserPermissions[k])));

    return permissionOptions;
}

export async function postRole(dto) {

	return dto.id
		? await post(`roles/${dto.id}/update`, dto)
		: await post(`roles/create`, dto);
}

export async function delRole(id) {

	throw new Error("not implemented");
}

