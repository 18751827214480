import React, {useContext, useEffect, useState} from 'react';
import {ContextNavi, ContextUser} from "../../services/context";
import {LinkInfo} from "../../layout/navbar/Navbar";
import Shield from "../../layout/page/shield/Shield";
import {Tab} from "semantic-ui-react";
import SchedulesList from "./schedulesList";
import SchedulesConfig from "./schedulesConfig";
import UserPermissions from "../../api/model/UserPermissions";
import {useTranslation} from 'react-i18next';

const Schedules = ({match, history, location}) => {
    const contextNavi = useContext(ContextNavi);
    const context = useContext(ContextUser);

    const user = context && context.current;

    const [data, setData] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        contextNavi.setItems([new LinkInfo("schedules_label_graphics", "/schedules")]);
    }, []);

    const panes = [];

    if (user.permissions.includes(UserPermissions.SCHEDULES) || user.permissions.includes(UserPermissions.SCHEDULES_SETTINGS_VIEAW)) {
        panes.push({menuItem: t("schedules_label_graphics"), render: () => <SchedulesList data={data} setData={setData}/>})
    };

    if (user.permissions.includes(UserPermissions.SCHEDULES_EDIT) || user.permissions.includes(UserPermissions.SCHEDULES_SETTINGS_VIEAW)) {
        panes.push({
            menuItem: t("schedules_label_setting-graphic"),
            render: () => <SchedulesConfig data={data} setData={setData}/>
        })
    };

    const handleTabChange = (e, {activeIndex}) => {
        setActiveIndex(activeIndex);
    };

    const fetchData = () => {
    };

    return (
        <Shield> {/*loading={!data} loadingOver={data && data.id}*/}
            <Tab
                panes={panes}
                activeIndex={activeIndex}
                onTabChange={handleTabChange}
            />
        </Shield>
    )
};

export default Schedules;
