import { WaybillStatusOptions, WaybillStatusEnum, RouteStatusOptions, WaybillStatusAnomalyOptions, WaybillStatusAvizationOptions } from "./model/Waybill";


export function getWaybillStatusTexts(wb) {

    let statusOrder = null;

    let statusAnomaly = [];

    let statusShipping = '';

    let statusAvisation = '';

    if (wb.id) {

        statusOrder = WaybillStatusOptions.find(s => s.key === wb.status);
        statusAvisation = WaybillStatusAvizationOptions.find(s => s.key === wb.avisationStatus);

        if (wb.statusAnomaly && statusOrder && (statusOrder.key === WaybillStatusEnum.CARGO_DELIVERED || statusOrder.key === WaybillStatusEnum.EXECUTING)) {
            statusAnomaly = WaybillStatusAnomalyOptions.filter(s => wb.statusAnomaly.indexOf(s.key) !== -1);
        }
        if (statusOrder && statusOrder.key !== WaybillStatusEnum.DRAFT) {

            if (statusOrder.key === WaybillStatusEnum.EXECUTING) {

                if (wb.statusShipping) {

                    const statusShippingOption = RouteStatusOptions.find(ss => ss.key === wb.statusShipping);

                    if (statusShippingOption) {
                        statusShipping = statusShippingOption.text;
                    }
                }
            }
        }
    }

    return {
        statusOrder,
        statusShipping,
        statusAnomaly,
        statusAvisation
    };
}
