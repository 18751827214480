import {del, get, post} from '../services/http';

export const getAll = async (search) => {
    const registries = await get(`registries/list${search}`);
    return registries
};

export const getCard = async (id, search) => {
    const card = await get(`registries/${id}${search}`);

    return card;
};

export const rgConfirm = async (id) => {
    await post(`registries/confirmOrder/${id}`)
};

export const rgConfirmOrders = async (ids) => {
    await post(`registries/confirmOrders`, {ids})
};

export const rgReject = async (params) => {
    await post('registries/rejectOrders', params);
};

export const rgInWork = async (id) => {
    await post(`registries/${id}/work`)
};

export const rgEditOrders = async (params) => {
    await post('registries/editOrders', params);
};

export const rgSendFM = async (id) => {
    await post(`registries/${id}/send`)
};

export const rgCompleted = async (ids) => {
    await post(`registries/complete`, {ids});
};

export const rgDelete = async (ids) => {
    await del(`registries`, {ids});
};

export const rgExportToExcel = async (id, ids, filter) => {
    return await post(`registries/${id}/export`, {ids, filter})
};

export const exportToExcel = async (ids, filter) => {
    return await post(`registries/export`, {ids, filter})
};
