import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router";
import {Button, Divider, Form, Grid, Header, Icon, Table} from "semantic-ui-react";
import InputDropdown from "../../../../components/inputs/InputDropdown";
import {WaybillTypeOptions} from "../../../../api/model/Waybill";
import CutoffUpdateRuleAdd from "./CutoffUpdateRuleAdd";
import {CargoTypeOptions, CompanyTypeOptions, CutoffDayOptions} from "../../../../api/model/Enums";
import RowDeleteAction from "./companyTabCutoffs/_shared/RowDeleteAction";
import {deleteCompanyCutoffUpdateRule, getCompanyCutoffsUpdate} from "../../../../api/companyCutoffs";
import {useTranslation} from 'react-i18next';

const CompanyTabCutoffUpdateRules = withRouter(({match}) => {
    const pathBase = "/admin/companies/:id/:tab";

    return (
        <>
            <Switch>
                <Route
                    path={`${match.url}/:type`} exact
                    render={props => <CompanyTabCutoffsUpdateRulesContext pathBase={match.url}
                                                                          companyName={match.params.id}
                                                                          pathBasePtn={pathBase} {...props} />}
                />
                <Route path={`${pathBase}`} exact>
                    <Redirect to={`${match.url}/LTL`}/>
                </Route>
            </Switch>
        </>
    )
});

const CompanyTabCutoffsUpdateRulesContext = ({match, history, pathBase, companyName}) => {
    const waybillType = (match.params.type || 'LTL');
    const {t} = useTranslation();

    let [rows, setRows] = useState([]);

    function updateWaybillType(waybillTypeNew) {
        history.push(`${pathBase}/${waybillTypeNew}`);
    }

    function Row({row}) {
        return (
            <CutoffUpdateRuleAdd fetchData={fetchData} companyId={companyName} type={match.params.type} id={row.id}>
                <Table.Row>
                    <Table.Cell>{t(CutoffDayOptions.getText(row.cutoffDay))}</Table.Cell>
                    <Table.Cell>{row.time ? row.time.slice(0, 5) : ''}</Table.Cell>
                    <Table.Cell>{t(CargoTypeOptions.getText(row.cargoType))}</Table.Cell>
                    <Table.Cell>{t(CompanyTypeOptions.getText(row.pointType))}</Table.Cell>
                    <Table.Cell textAlign="center"><RowDeleteAction {...{
                        row,
                        rows,
                        setRows,
                        removeAsync
                    }} /></Table.Cell>
                </Table.Row>
            </CutoffUpdateRuleAdd>
        );
    }

    const removeAsync = async (row) => {
        await deleteCompanyCutoffUpdateRule(companyName, row.id)
    };

    const fetchData = async () => {
        const res = await getCompanyCutoffsUpdate(companyName, match.params.type);

        setRows(res || []);
    };

    useEffect(() => {
        fetchData();
    }, [match.params.type]);

    const onAdd = () => {
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Header className="m-b-10">{t("users_label_list-of-app-registration")}</Header>
                    <Divider className="m-t-0"/>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>{t("users_label_application-type")}</label>
                                <InputDropdown
                                    options={WaybillTypeOptions}
                                    value={waybillType}
                                    onChange={updateWaybillType}
                                />
                            </Form.Field>
                            <Form.Field/>
                        </Form.Group>
                    </Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column align="right">
                                <CutoffUpdateRuleAdd fetchData={fetchData} companyId={companyName}
                                                     type={match.params.type}>
                                    <Button primary>
                                        <Icon name="plus"/>
                                        {t("users_grid_add-rule")}
                                    </Button>
                                </CutoffUpdateRuleAdd>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Table celled className="cutoffUpdateRuleTable">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3}>{t("users_grid_cutoff-date")}</Table.HeaderCell>
                                <Table.HeaderCell width={3}>{t("users_grid_cutoff-time")}</Table.HeaderCell>
                                <Table.HeaderCell width={3}>{t("users_grid_cargo-type")}</Table.HeaderCell>
                                <Table.HeaderCell width={3}>{t("users_grid_loading-point-type")}</Table.HeaderCell>
                                <Table.HeaderCell width={1} textAlign="center"/>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rows.map((r, i) => <Row key={i} row={r}/>)}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default CompanyTabCutoffUpdateRules;
