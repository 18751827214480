import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

const translationRU = require('./baseTranslationRU.json');
const  translationUK = require( './baseTranslationUK.json');

// the translations
const resources = {
    'ru-RU': {
        translation:
            {...translationRU}
    },
    'uk-UA': {
        translation: {...translationUK}
    }
};

i18n.use(LanguageDetector)
    .use(XHR)
    .use(initReactI18next)
    .init({
        // we init with resources
        fallbackLng: ["ru-RU", "uk-UA"],
        lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "uk-UA",
        debug: false,
        /*resources,*/
        // have a common namespace used around the full app
         ns: ['translations'],
         defaultNS: 'translations',
         backend: {
             loadPath: '/api/translations/?culture={{lng}}',

         },
        keySeparator: false, // we use content as keys
        react: {
            wait: true,
            defaultTransParent: 'div', // a valid react element - required before react 16
        },
    });

export default i18n;
