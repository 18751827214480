import React, { useContext, useState } from 'react';
import { Button, Dimmer, Form, Grid, Icon, Loader, Modal, Step } from 'semantic-ui-react';
import { PointTypeEnum } from '../../../../api/model/Point';
import PointAddress, { replaceAbbrs } from '../../../../api/model/PointAddress';
import { PointDays } from '../../../../api/model/PointDays';
import { PointTimeslot } from '../../../../api/model/PointTimeslot';
import UserPermissions from '../../../../api/model/UserPermissions';
import { getSimilarPoint } from '../../../../api/points';
import { useTranslation } from "react-i18next";
import { ContextUser } from '../../../../services/context';
import PointFormSimilarModal from './PointFormSimilarModal';
import PointFormStep1 from './PointFormStep1';
import PointFormStep2 from './PointFormStep2';
import PointFormStep3 from './PointFormStep3';

const stepNames = [
    "points_label_location",
    "points_label_company",
    "points_label_properties"
];

const days = new PointDays({
    mon: new PointTimeslot("08:00-20:00"),
    tue: new PointTimeslot("09:00-20:00"),
    wed: new PointTimeslot("00:00"),
    thu: new PointTimeslot("10:00-21:00"),
    fri: new PointTimeslot("10:00-17:00")
});

const tabDadataName = "points_label_find_address";

const defaultData = {
    dockCapacity: [2000,5000,10000,20000],
    dockPosition: 0,
    pointType: 0
};


function clearSettlement(settlement) {
    return (settlement || '')
        .replace(/д /g, '')
        .replace(/д\. /g, '')
        .replace(/г\. /g, '')
        .replace(/г /g, '')
        .split(',')[0];
};

function clearStreetName(streetName) {
    return (streetName || '')
        .replace(/пер. /g, '')
        .replace(/пер /g, '')
        .replace(/пл. /g, '')
        .replace(/пл /g, '')
        .replace(/ул. /g, '')
        .replace(/ул /g, '')
        .replace(/у. /g, '')
        .replace(/у /g, '');
};

function clearStreetNo(streetNo) {
    return (streetNo || '')
        .replace(/д\. /g, '')
        .replace(/д /g, '')
        .replace(/к\. /g, '')
        .replace(/к /g, '')
        .replace(/стр\. /g, '')
        .replace(/стр /g, '');
};


export default function PointForm(props) {
    const { t } = useTranslation();

    const { onSimilarSubmit, needFindSimilar, fromWaybill, companyOptions = [], selectedCompany, setSelectedCompany, isClient } = props;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeItem, setActiveItem] = useState(0);
    const [data, setData] = useState({ ...props.data, ...defaultData });
    const [exportOnSave, setExportOnSave] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [isSimilarModalOpen, setIsSimilarModalOpen] = useState(false);
    const [isSimilarLoading, setIsSimilarLoading] = useState(false);
    const [similarPoints, setSimilarPoints] = useState([]);
    const contextUser = useContext(ContextUser);
    const [addressDadata, setAddressDadata] = useState(data.address || {});
    const [addressManual, setAddressManual] = useState(data.address || {});
    const [activeAddressTab, setActiveAddressTab] = useState(t(tabDadataName));


    const hasPermissions = contextUser.current.permissions.includes(UserPermissions.POINTS);

    const styleContent = {
        height: '555px'
    };

    function resetForm() {
        setSelectedCompany && setSelectedCompany(null);
        setActiveItem(0);
        setData({ ...props.data, ...defaultData });
        setValidationErrors([]);
        setSimilarPoints([]);
        setExportOnSave(true);
        setLoading(false);
        setAddressDadata({ ...props.data, ...defaultData });
        setAddressManual({ ...props.data, ...defaultData });
    };

    function getValidationErrors() {
        const validationErrors = [];

        if (!data.companyName) {
            validationErrors.push('companyName');
        }
        // this validation applies to new addresses only as we do not habe fiaslevel in db
        // instead we can check steet no and fias as of now
        // if (!data.address || !data.address.fias_level || data.address.fias_level < 8) {
        //     validationErrors.push("address");
        // }
        if (!data.address || !data.address.fias || !data.address.streetNo) {
            validationErrors.push("address");
        }

        if (exportOnSave || hasPermissions) {
            if (!data.fmid) {
                validationErrors.push("fmid");
            }
        }
        const contact = (data.contacts && data.contacts.length && data.contacts[0]) || null;

        if (contact) {
            if (!contact.name) {
                validationErrors.push("contactName");
            }
        }

        return validationErrors;
    };

    function getStep1ValidationErrors() {
        const address = activeAddressTab === t(tabDadataName) ?
            addressDadata :
            addressManual;

        const res = [];

        if (exportOnSave || hasPermissions) {
            if (!props.isClient && !fromWaybill && !data.fmid) {
                res.push('fmid');
            }
        }

        if (!address.country) res.push('country');
        if (!address.settlement) res.push('settlement');
        if (!address.postalCode) res.push('postalCode');

        return res;
    };

    function getStep2ValidationErrors() {
        const res = [];

        if (!data.companyName) res.push('companyName');
        if (isClient && !selectedCompany) res.push('selectedCompany');
        return res;
    };

    function getStep3ValidationErrors() {
        const res = [];

        if (!data.dockCapacity.length) res.push('dockCapacity');
        return res;
    };

    function submit() {

        setLoading(true);

        let { fmid, pointName, pointType } = data;

        if (pointType === PointTypeEnum.FM && !pointName) {
            pointName = fmid;
        }

        let newData = {
            ...data,
            pointName
        };

        const address = replaceAbbrs(newData.address || {});

        const value = new PointAddress(address).toString();

        newData = {
            ...newData,
            address: {
                ...address,
                value
            }
        };

        props.submit(newData, { exportOnSave })
            .then(result => {
                if (result === false) {
                    setLoading(false);
                } else {
                    setOpen(false);
                    resetForm();
                }
            });
    };

    function update(props) {
        let currentData = data || {};
        const newState = { ...currentData, ...props };
        setData(newState);

        if (validationErrors && validationErrors.length) {
            const validationErrors = getValidationErrors();
            setValidationErrors(validationErrors);
        }
    };

    function submitSimilar(point) {
        onSimilarSubmit(point);
        setOpen(false);
        setIsSimilarModalOpen(false);
        setActiveItem(0);
        setData({ ...props.data, ...defaultData });
        setSimilarPoints([]);
        resetForm();
    };

    function onAddressTabChange(id) {
        setValidationErrors([]);
    };

    const showSimilarOnStep2 = isClient && companyOptions.length !== 1;

    function renderForm() {
        const ve = validationErrors;

        if (!data.address && !data.days) {
            data.days = days;
        }

        const step1El = (<PointFormStep1
            ve={ve}
            data={data}
            hasPermissions={hasPermissions}
            update={update}
            onAddressTabChange={onAddressTabChange}
            addressDadata={addressDadata}
            setAddressDadata={setAddressDadata}
            addressManual={addressManual}
            setAddressManual={setAddressManual}
            activeAddressTab={activeAddressTab}
            setActiveAddressTab={setActiveAddressTab}
            fromWaybill={fromWaybill}
            isClient={props.isClient}
        />);

        const step2El = (<PointFormStep2
            data={data}
            update={update}
            ve={ve}
            hasPermissions={hasPermissions}
            isClient={props.isClient}
            companyOptions={companyOptions}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
        />);

        const step3El = (<PointFormStep3
            ve={ve}
            data={data}
            update={update}
            hasPermissions={hasPermissions}
            isClient={props.isClient}
        />);

        const stepElements = [
            step1El,
            step2El,
            step3El
        ];

        const canGoStep2 = (
            !getStep1ValidationErrors().length
        );

        const canGoStep3 = (
            !getStep2ValidationErrors().length
        );

        return (
            <div>
                <Step.Group size='tiny' widths={3} style={{ marginBottom: 30 }} className="point-form-tab-panes">
                    <Step
                        active={activeItem === 0}
                        onClick={() => setActiveItem(0)}
                    >
                        <Step.Content>
                            <Step.Title>{t(stepNames[0])}</Step.Title>
                        </Step.Content>
                    </Step>
                    <Step
                        active={activeItem === 1}
                        onClick={() => setActiveItem(1)}
                        disabled={!canGoStep2}
                    >
                        <Step.Content>
                            <Step.Title>{t(stepNames[1])}</Step.Title>
                        </Step.Content>
                    </Step>
                    <Step
                        active={activeItem === 2}
                        onClick={() => setActiveItem(2)}
                        disabled={!canGoStep3}
                    >
                        <Step.Content>
                            <Step.Title>{t(stepNames[2])}</Step.Title>
                        </Step.Content>
                    </Step>
                </Step.Group>
                <Form>
                    {stepElements[activeItem]}
                </Form>

                <PointFormSimilarModal
                    isOpen={isSimilarModalOpen}
                    onOpen={() => setIsSimilarModalOpen(true)}
                    onClose={() => setIsSimilarModalOpen(false)}
                    onSimilarSubmit={onSimilarSubmit}
                    submitSimilar={submitSimilar}
                    similarPoints={similarPoints}
                    onCancel={() => {
                        setActiveItem(showSimilarOnStep2 ? 2 : 1);
                        setIsSimilarModalOpen(false);
                    }}
                    fromWaybill={fromWaybill}
                />
            </div>
        );
    };

    async function findSimilarBeforeStep() {
        if (needFindSimilar) {
            const { address = {} } = data;
            const { settlement, streetName, streetNo } = address;
            setIsSimilarLoading(true);
            const query = `${clearSettlement(settlement)} ${clearStreetName(streetName)} ${clearStreetNo(streetNo)}`;
            const pointsSuggestions = await getSimilarPoint(encodeURIComponent(query));
            if (pointsSuggestions.length) {
                setSimilarPoints(pointsSuggestions);
                setIsSimilarModalOpen(true);
            } else {
                setActiveItem(activeItem + 1);
            }
            setIsSimilarLoading(false);
        } else {
            setActiveItem(activeItem + 1);
        }
    };

    function prevStep() {
        setActiveItem(activeItem - 1);
    };

    async function nextStep() {
        if (activeItem === 0) {
            const errors = getStep1ValidationErrors();
            setValidationErrors(errors);
            console.log('errors', errors);
            if (errors.length) {
                return;
            }
            if (!showSimilarOnStep2) {
                findSimilarBeforeStep();
            } else {
                setActiveItem(activeItem + 1);
            }
        } else if (activeItem === 1) {
            const errors = getStep2ValidationErrors();
            setValidationErrors(errors);
            if (errors.length) {
                return;
            }
            if (showSimilarOnStep2) {
                findSimilarBeforeStep();
            } else {
                setActiveItem(activeItem + 1);
            }
        }
    };

    function lastStep() {
        const errors = getStep3ValidationErrors();
        setValidationErrors(errors);
        if (errors.length) {
            return;
        }
        submit();

    }

    function onFormClose() {
        resetForm();
        setOpen(false);
    };

    return (
        <Modal
            trigger={props.children}
            centered={false}
            dimmer="inverted"
            size={props.size || "large"}
            closeIcon
            open={open}
            onOpen={() => setOpen(true)}
            onClose={onFormClose}
            closeOnEscape={!loading}
            closeOnDimmerClick={false}
        >
            <Modal.Header>{props.title || t("base_btn_add")}</Modal.Header>
            <Modal.Content style={styleContent}>
                <Dimmer.Dimmable dimmed={loading}>
                    <Dimmer active={loading} inverted>
                        <Loader></Loader>
                    </Dimmer>
                    {renderForm(data)}
                </Dimmer.Dimmable>
            </Modal.Content>
            <Modal.Actions>
                <Grid>
                    <Grid.Column floated='left'>
                        {activeItem !== 0 && <Button
                            icon labelPosition='left'
                            onClick={prevStep}
                        >
                            <Icon name="arrow left"></Icon>
                            {t("base_btn_back")}
                        </Button>}
                    </Grid.Column>

                    <Grid.Column floated='right' width={8}>
                        <Button
                            onClick={onFormClose}
                            className="m-r-12"
                        >
                            {t("base_btn_cancel")}
                        </Button>
                        {activeItem !== 2 ?
                            <Button
                                primary
                                onClick={() => nextStep(activeItem + 1)}
                                loading={isSimilarLoading}
                            >
                                {t("base_btn_onwards")}
                                <Icon name="arrow right"></Icon>
                            </Button> :
                            <Button
                                positive
                                loading={loading}
                                onClick={() => lastStep()}
                            >
                                {t("points_btn_create_address")}
                            </Button>
                        }
                    </Grid.Column>
                </Grid>
            </Modal.Actions>
        </Modal>
    );
};

