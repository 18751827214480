import React, {useContext, useEffect, useState} from 'react';
import AppTableHeader from "../../../components/tables/AppTableHeader";
import {withRouter} from "react-router-dom";
import qs from "query-string";
import {getProviders} from "../../../api/providers";
import Shield from "../../../layout/page/shield/Shield";
import PointGridHeader from "../points/pointsGrid/PointGridHeader";
import PointGridRows from "../points/pointsGrid/PointGridRows";
import PointsScrollPreloader from "../../_shared/pointsScrollPreloader/PointsScrollPreloader";
import {ContextNavi} from "../../../services/context";
import {LinkInfo} from "../../../layout/navbar/Navbar";
import AppTableToolbar from "../../../components/tables/AppTableToolbar";
import AppToolbarButton from "../../../components/buttons/AppToolbarButton";
import ProviderFormModal from "./providerFormModal/ProviderFormModal";
import {getCompaniesList} from "../../../api/companies";
import {Checkbox, Container, Menu, Table} from "semantic-ui-react";
import PointGridCell from "../points/pointsGrid/PointGridCell";
import {useTranslation} from 'react-i18next';

const headers = [
    new AppTableHeader("legalName", "users_grid_supplier-legal-name"),
    new AppTableHeader("fmid", "users_grid_name-fm"),
    new AppTableHeader("clientName", "users_grid_client"),
    new AppTableHeader("status", "users_grid_status"),
];
export default withRouter(Providers);

function Providers({location}) {
    const {t} = useTranslation();

    const contextNavi = useContext(ContextNavi);
    const [loaded, setLoaded] = useState(false);
    const [companiesList, setCompanyOptions] = useState([])
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedPoints, setSelectedPoints] = useState([]);
    const keys = headers.map(h => h.key);

    async function fetchData(query) {
        setLoading(true);
        try {
            const stringQuery = `?${qs.stringify(query)}`;
            let result = await getProviders(stringQuery);
            return result;
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    };

    async function onProviderChange(data) {
    };

    async function updateRows() {
        const query = qs.parse(location.search, {ignoreQueryPrefix: true});
        const rows = await fetchData(query);
        setRows(rows);
    };

    useEffect(() => {
        updateRows();
    }, [location.search]);
    useEffect(() => {
        contextNavi.setItems([new LinkInfo("users_label_suppliers", "/providers")]);
        (async () => {
            const options = await getCompaniesList();
            setCompanyOptions(options.map(i => ({
                key: i.fmid,
                text: i.name,
                value: i.fmid
            })));
        })();
    }, []);

    function handleRowSelection(id, rows, e, {checked}) {
        if (e.nativeEvent.shiftKey && selectedPoints.length > 0 && checked) {
            const lastSelectedId = selectedPoints[selectedPoints.length - 1];
            const previousIndex = rows.findIndex(r => r.id === lastSelectedId) || 0;
            const currentIndex = rows.findIndex(r => r.id === id) || 0;

            const idsScope = [];

            if (currentIndex > previousIndex) {
                for (let index = previousIndex; index <= currentIndex; index++) {
                    idsScope.push(rows[index].id);
                }
            } else {
                for (let index = previousIndex; index >= currentIndex; index--) {
                    idsScope.push(rows[index].id);
                }
            }

            setSelectedPoints([...idsScope]);
        }
        else {
            if (checked) {
                setSelectedPoints([...selectedPoints, id]);
            }
            else {
                setSelectedPoints([...selectedPoints.filter(i => i !== id)]);
            }
        }

    }
    const styleCell = {
        overflow: 'hidden',
        position: 'relative'
    };
    return (
        <div className="bgn-white h-100">
            <Container className="h-100 app-table">
                <Shield loading={loading && !loaded} loadingOver={false}>
                    <div style={{minHeight: '53px'}}>
                        <AppTableToolbar>
                            <AppTableToolbar.Left>
                            </AppTableToolbar.Left>
                            <AppTableToolbar.Right>
                                <ProviderFormModal
                                    key="newProviderForm"
                                    title={t("users_label_adding-supplier")}
                                    isAdd
                                    size="small"
                                    CompaniesList={companiesList}
                                    close={updateRows}
                                >
                                    <AppToolbarButton icon="plus">{t("users_label_add-supplier")}</AppToolbarButton>
                                </ProviderFormModal>
                            </AppTableToolbar.Right>
                        </AppTableToolbar>
                    </div>
                    <div className="table-wrapper">

                        <div className="table-scroll">
                            <PointGridHeader
                                isCheckbox={false}
                                headers={headers}
                                hasRows={rows.length}
                                selectedPoints={selectedPoints || []}
                                setSelectedPoints={setSelectedPoints}
                            />
                        </div>
                        <div className="table-scroll points-table">
                            <Table celled fixed singleLine className="table-data-inner" selectable>
                                <Table.Body>
                                    {rows.map(r => {
                                        return <Table.Row key={r.id}
                                                          style={{cursor: 'pointer'}}
                                                          active={selectedPoints.includes(r.id)}
                                        >
                                         {/*   <Table.Cell className="table-first-col">
                                                {<Checkbox id={r.id}
                                                           checked={selectedPoints.includes(r.id)}
                                                           onChange={handleRowSelection.bind(null, r.id, rows)}
                                                />}
                                            </Table.Cell>*/}
                                            {keys.map(key =>
                                                <ProviderFormModal
                                                    key={`updateProviderForm-${key}`}
                                                    title={t("users_label_edit-supplier")}
                                                    size="small"
                                                    CompaniesList={companiesList}
                                                    close={updateRows}
                                                    id={r.id}
                                                >
                                                    <Table.Cell
                                                        style={styleCell}
                                                        key={key}
                                                        title={''}
                                                    >

                                                        <PointGridCell
                                                            item={r}
                                                            keyName={key}
                                                        />

                                                    </Table.Cell>
                                                </ProviderFormModal>
                                            )}
                                        </Table.Row>;
                                    })}
                                </Table.Body>
                            </Table>
                            <PointsScrollPreloader {...{location, rows, fetchData, setRows}} />
                        </div>
                    </div>
                </Shield>
            </Container>
        </div>
    )

};
