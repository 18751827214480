import React from 'react';
import { Table } from "semantic-ui-react";
import CheckSelector from './selectors/CheckSelector';

export default function AppTableRow(props) {

    const { headers, row, renderItem, actions, selection, selectedIds, handleRowSelection, onRowClick } = props;

    const actionsCell = (
        <Table.Cell key="actions" textAlign="right" className="app-table-action">
            {actions && actions.map(a => a(row))}
        </Table.Cell>
    );

    const handleRowClick = (e, id) => {
        if (handleRowSelection) {
            const passThru = ["LABEL", "INPUT"];
            if (passThru.includes(e.target.nodeName)) {
                e.stopPropagation();
            } else {
                handleRowSelection(row.id);
            }
        }
    };    

    const style = {
        cursor: onRowClick ? 'pointer' : 'inherit'
    };

    return (
        <Table.Row key={row.id}
            style={style}
            className="app-table-body-row"
            onClick={onRowClick ? () => onRowClick(row) : handleRowClick}
            active={selection && selectedIds.includes(row.id)}>
            {selection
                ? <CheckSelector
                    id={row.id}
                    selectedIds={selectedIds}
                    handleRowSelection={handleRowSelection} />
                : null
            }
            {headers.map(h => (
                <Table.Cell key={h.key}>
                    {renderItem ? renderItem(row, h.key) : row[h.key]}
                </Table.Cell>
            ))}
            {actions && actions.length ? actionsCell : null}
        </Table.Row>
    );
}
