import React, {useContext, useState, useEffect} from 'react';
import {Route} from 'react-router-dom';
import {Button, Container, Form, Segment} from 'semantic-ui-react';
import {userLogin} from "../../api/users";
import {saveUserContext, clearUserContext} from '../../services/auth';
import {ContextUser} from '../../services/context';
import LanguageMenu from './LanguageMenu';
import './Login.css';
import ResetPassword from './resetPassword/ResetPassword';
import {checkForBrowserRecommendation} from '../../services/ua';
import {useTranslation} from 'react-i18next';

export function Login() {
    const {t} = useTranslation();

    const contextUser = useContext(ContextUser);

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [passwordHash, setPasswordHash] = useState('');
    const [error, setError] = useState('');


    async function login(history) {

        setLoading(true);

        const loginResult = await userLogin(email, passwordHash);

        if (loginResult) {

            if (loginResult.error) {

                clearUserContext();

                setLoading(false);

                setError(loginResult.error);

			} else {

                saveUserContext(loginResult);

                contextUser.current = loginResult;

                history.push(loginResult.startUrl);
            }

        } else {

            setLoading(false);

            setError(t("accesses_label_technical-error"));
        }
    }

    useEffect(() => {
        clearUserContext();
        checkForBrowserRecommendation();
    }, []);


    return (
        <div className="login-bgn">
            <Container>
                <LanguageMenu/>
            </Container>
            <div className="ui text container login-wrapper">
                <Segment raised className="login-segment login-segment-transparent">
                    <div className="login-logo login-logo-transparent">
                        <img src="/img/logo.png" title="Fm logistic" alt="Fm logistic"/>
                    </div>
                    <div className="login-descr">
                        {t("accesses_label_forgot-password")}
                    </div>
                    <div className="login-form">
                        <Form>
                            <Form.Field>
                                <Form.Input
                                    fluid
                                    icon='user'
                                    iconPosition='left'
                                    placeholder='E-mail'
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </Form.Field>
                            <ContextUser.Consumer>
                                {(value) => (
                                    <Form.Field style={{marginTop: '1.5em'}}>
                                        <Form.Input
                                            //disabled={this.state.loading}
                                            fluid
                                            icon='lock'
                                            iconPosition='left'
                                            placeholder={t("accesses_placeholder_password")}
                                            type='password'
                                            onChange={e => setPasswordHash(e.target.value)}
                                        />
                                    </Form.Field>
                                )}
                            </ContextUser.Consumer>
                            <div className="login-error login-center"
                                 style={{visibility: error ? "visible" : "hidden"}}>
                                {error}
                            </div>
                            <div className="login-buttons login-center" style={{paddingTop: '5px !important'}}>
                                <Route render={({history}) => (
                                    <Button
                                        primary
                                        loading={loading}
                                        disabled={loading}
                                        type='submit'
                                        style={{minWidth: '150px', minHeight: '40px'}}
                                        onClick={() => login(history)}><i
                                        className="sign-in icon"></i> {t('accesses_btn_come-in')}&nbsp;</Button>
                                )}/>
                            </div>
                        </Form>
                        <div className="login-links login-center">
                            <ResetPassword email={email}>
                                <span style={{
                                    color: 'white',
                                    cursor: 'pointer'
                                }}>{t('accesses_label_forgot-password')}</span>
                            </ResetPassword>
                            {/* <a href="/user/register" floated='left' style={{marginLeft: '20px'}}>{'Регистрация'.l}</a> */}
                        </div>
                    </div>
                </Segment>
            </div>
        </div>
    );

}
