import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Divider, Form, Grid, Header, Icon, Input} from 'semantic-ui-react';
import {
    companyUpdateRulesOffUntilUpdate,
    companyUpdateRulesUpdate,
    getCompanyUpdateRulesOffUntilUpdate,
    getCompanyUpdateRulesUpdate
} from '../../../../api/companies';
import InputDate from '../../../../components/inputs/InputDate';
import {normalizeTime} from "../../../../services/normalizeTime";
import {useTranslation} from 'react-i18next';

export default withRouter(CompanyTabCutoffUpdateBlock);

function CompanyTabCutoffUpdateBlock({match, history}) {
    const [cutoffsOff, setCutoffsOff] = useState(false);
    const [cutoffsOffTimeoutOn, setCutoffsOffTimeoutOn] = useState(false);
    const [cutoffsOffTimeoutDate, setCutoffsOffTimeoutDate] = useState();
    const [cutoffsOffTimeoutTime, setCutoffsOffTimeoutTime] = useState();
    const disabledDays = [{before: moment().toDate()}];
    const timeoutDisabled = !(cutoffsOff && cutoffsOffTimeoutOn);
    const {t} = useTranslation();

    async function toggleCutoffs() {
        const prev = cutoffsOff;
        setCutoffsOff(!prev);
        try {
            if (prev === true && cutoffsOffTimeoutOn) {
                // offing -> need to off timeout
                await toggleCutoffsOffTimeout();
            }

            await companyUpdateRulesUpdate(match.params.id, !prev);
        } catch (error) {
            setCutoffsOff(prev);
        }
    }

    async function toggleCutoffsOffTimeout() {
        const prev = cutoffsOffTimeoutOn;
        setCutoffsOffTimeoutOn(!prev);
        try {
            if (prev === true) {
                // swithing off
                await companyUpdateRulesOffUntilUpdate(match.params.id, null);
                setCutoffsOffTimeoutDate(null);
            } else {
                //const dateNow = moment().add(1, 'hour').toDate();
                await companyUpdateRulesOffUntilUpdate(match.params.id, cutoffsOffTimeoutDate);
            }
        } catch (error) {
            setCutoffsOffTimeoutOn(prev);
            throw error;
        }
    }

    async function cutoffsOffUntilUpdate(prev, next) {
        const data = next ? moment(next).format('YYYY-MM-DD HH:mm') : null;
        try {
            setCutoffsOffTimeoutDate(next);
            await companyUpdateRulesOffUntilUpdate(match.params.id, data);
        } catch (error) {
            setCutoffsOffTimeoutDate(prev);
        }
    }

    async function handleDateChange(val) {
        const prev = cutoffsOffTimeoutDate;
        setCutoffsOffTimeoutDate(val);
        await cutoffsOffUntilUpdate(prev, val);
    }

    function handleTimeChange(e, {value}) {
        const val = value && normalizeTime(value);
        setCutoffsOffTimeoutTime(val);
    }

    const sendTime = async () => {
        if (!cutoffsOffTimeoutTime || cutoffsOffTimeoutTime.length === 5) {
            const date = cutoffsOffTimeoutTime && moment(moment(cutoffsOffTimeoutDate).format('YYYY-MM-DD') + ' ' + cutoffsOffTimeoutTime);
            await cutoffsOffUntilUpdate(cutoffsOffTimeoutDate, date);
        }
    };

    const handleClearTime = async () => {
        setCutoffsOffTimeoutTime('');
        await cutoffsOffUntilUpdate(cutoffsOffTimeoutDate, null);
    };

    async function fetchCutoffs() {
        const companyId = match.params.id;
        const timeoutDate = await getCompanyUpdateRulesOffUntilUpdate(companyId);
        const hasValue = !!(timeoutDate && timeoutDate.value);
        if (hasValue) {
            const timeoutDateTimeParsedMoment = moment(timeoutDate.value, "YYYY-MM-DD HH:mm");
            if (moment().isAfter(timeoutDateTimeParsedMoment)) {
                setCutoffsOff(false);
                await companyUpdateRulesUpdate(match.params.id, false);
                setCutoffsOffTimeoutDate(null);
                setCutoffsOffTimeoutTime(null);
                await companyUpdateRulesOffUntilUpdate(match.params.id, null);
                setCutoffsOffTimeoutOn(false);
            } else {
                const timeoutDateTimeParsed = timeoutDateTimeParsedMoment.toDate();
                setCutoffsOffTimeoutDate(timeoutDateTimeParsed);
                setCutoffsOffTimeoutTime(moment(timeoutDateTimeParsed).format("HH:mm"));
                const cutoffsOffRes = await getCompanyUpdateRulesUpdate(companyId);
                setCutoffsOff(cutoffsOffRes);
                setCutoffsOffTimeoutOn(hasValue);
            }
        } else {
            const cutoffsOffRes = await getCompanyUpdateRulesUpdate(companyId);
            setCutoffsOff(cutoffsOffRes.value);
        }
    }

    useEffect(() => {
        fetchCutoffs();
    }, []);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Header className="m-b-10">{t("users_label_ext-app-updates")}</Header>
                    <Divider className="m-t-0"/>
                    <Form size="small">
                        <Form.Checkbox label={t("users_grid_renewal-upgrades")} checked={cutoffsOff} onChange={toggleCutoffs}/>
                        <Form.Group widths="equal">
                            <Form.Checkbox label={t("users_grid_renewal-upgrades-to")} checked={cutoffsOffTimeoutOn}
                                           onChange={toggleCutoffsOffTimeout} disabled={!cutoffsOff}/>
                            <Form.Field disabled={timeoutDisabled}>
                                <label>{t("users_label_date")}</label>
                                <InputDate
                                    icon="calendar outline"
                                    placeholder={t("users_placeholder_date-of-inclusion")}
                                    position="bottom center"
                                    onChange={handleDateChange}
                                    value={cutoffsOffTimeoutDate}
                                    disabledDays={disabledDays}
                                />
                            </Form.Field>
                            <Form.Field disabled={timeoutDisabled}>
                                <label>{t("users_label_time")}</label>
                                {/*<InputTime*/}
                                {/*	format="HH:mm"*/}
                                {/*	placeholder="HH:mm"*/}
                                {/*	// defaultValue={moment()}*/}
                                {/*	value={cutoffsOffTimeoutDate ? moment(cutoffsOffTimeoutDate) : null}*/}
                                {/*	onChange={handleTimeChange}*/}
                                {/*/>*/}
                                <Input
                                    placeholder="HH:mm"
                                    value={cutoffsOffTimeoutTime}
                                    icon={<Icon name='delete' link onClick={handleClearTime}/>}
                                    onChange={handleTimeChange}
                                    onBlur={sendTime}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

}

