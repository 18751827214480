import {CargoTypeOptions, PackTypeOptions, ThermoModeOptions, WaybillFieldEnum} from '../../../../../api/model/Enums';
import {LoadCapacityOptions} from '../../../../../api/model/Point';
import {
    BodyCapacityOptions,
    WaybillCarTypeOptions,
    WaybillTypeEnum,
    WaybillTypeOptions
} from '../../../../../api/model/Waybill';
import {useTranslation} from "react-i18next";

export const fieldNames = {
    1: 'shippingType',
    2: 'shippingTempCondition',
    3: 'cargoType',
    4: 'cargoUnitType',
    5: 'vehicleCapacity'
};

export const fieldNamesLocale = {
    1: 'users_grid_application-type',
    2: 'users_grid_temp',
    3: 'users_grid_cargo-type',
    4: 'users_grid_type-cargo-units',
    5: 'users_grid_lifting-capacity'
};

export const valueOptionsDict = {
    [WaybillFieldEnum.ShippingType]: WaybillTypeOptions.filter(i => i.key !== WaybillTypeEnum.Return),
    [WaybillFieldEnum.ShippingTempCondition]: ThermoModeOptions,
    [WaybillFieldEnum.CargoType]: CargoTypeOptions,
    [WaybillFieldEnum.CargoUnitType]: PackTypeOptions,
    [WaybillFieldEnum.VehicleCapacity]: LoadCapacityOptions,
    [WaybillFieldEnum.CarType]: WaybillCarTypeOptions,
    [WaybillFieldEnum.BodyCapacity]: BodyCapacityOptions,
};

export const fieldValueCasters = {
    [WaybillFieldEnum.ShippingType]: (value) => {


        if (value === null) {
            return value;
        }
        return ({
            0: 'LTL',
            1: 'FTL',
           /* 2: 'Rail',
            3: 'RailLTL',*/
            6: 'UrbanDelivery',
            LTL: 'LTL',
            FTL: 'FTL',
            /*Rail: 'Rail',
            RailLTL: 'RailLTL',*/
            UrbanDelivery: 'UrbanDelivery',
        }[value]);
    },
    [WaybillFieldEnum.ShippingTempCondition]: (value) => value && Number.parseInt(value),
    [WaybillFieldEnum.CargoType]: (value) => value && Number.parseInt(value),
    [WaybillFieldEnum.CargoUnitType]: (value) => value,
    [WaybillFieldEnum.BodyCapacity]: (value) => value,
    [WaybillFieldEnum.VehicleCapacity]:  (value) => value && Number.parseInt(value),
    [WaybillFieldEnum.CarType]:  (value) => value && Number.parseInt(value)
};

export const dependencyMatrix = {
    [WaybillFieldEnum.CargoUnitType]: [
        WaybillFieldEnum.ShippingType,
        WaybillFieldEnum.ShippingTempCondition,
        WaybillFieldEnum.CargoType
    ],
    [WaybillFieldEnum.ShippingType]: [
        WaybillFieldEnum.ShippingType,
        WaybillFieldEnum.ShippingTempCondition,
        WaybillFieldEnum.CargoType
    ],
    [WaybillFieldEnum.CargoType]: [
        WaybillFieldEnum.ShippingType,
        WaybillFieldEnum.ShippingTempCondition
    ],
    [WaybillFieldEnum.ShippingTempCondition]: [
        WaybillFieldEnum.ShippingType,
        WaybillFieldEnum.CargoType
    ],
    [WaybillFieldEnum.VehicleCapacity]: [
        //WaybillFieldEnum.ShippingType,
        WaybillFieldEnum.ShippingTempCondition
    ],
    [WaybillFieldEnum.CarType]: [
        WaybillFieldEnum.CargoType
    ],
    [WaybillFieldEnum.Provider]: [
        WaybillFieldEnum.ShippingType
    ],
    [WaybillFieldEnum.BodyCapacity]: [

    ]
};

export const enumValueToCode = (field, value) => {
    if (field === WaybillFieldEnum.ShippingType) {
        return { LTL: 0, FTL: 1, /*Rail: 2, RailLTL: 3,*/ UrbanDelivery: 6 }[value];
    }
    return value;
};

export const getFieldValueName = (field, value) => {
    const values = valueOptionsDict[field];
    const caster = fieldValueCasters[field];
    return values.filter((item) => {
        return item.value === caster(value);
    })[0].text;
};


const getWbValue = (fieldName, wb) => {
    if (fieldName === 'shippingTempCondition') {
        return wb.shippingTemperatureCondition;
    }
    return wb[fieldName];
};

export const showField = (field, wb, fieldConfigs) => {
    const deps = dependencyMatrix[field];
    const configs = fieldConfigs.filter((item) => {
        return item.targetField === field && item.targetFieldValue === null &&
            deps.every((dep) => {
                const caster = fieldValueCasters[dep];
                const name = fieldNames[dep];
                return item[name] === null || caster(item[name]) === getWbValue(name, wb);
            });
    }).sort((item1, item2) => {
        const keys1 = Object.keys(item1).filter((key) => {
            return item1[key] !== null;
        });
        const keys2 = Object.keys(item2).filter((key) => {
            return item2[key] !== null;
        });
        const value = keys1.length - keys2.length;
        return item2.isVisible ? -value : value;
    });

    if (!configs.length) {
        return true;
    }
    return configs.every((item) => item.isVisible);
};

export const hasCargoUnitType = (wb, type) => {
    return wb.pointsLoading.some(({ loadUnitSets }) => {
        return loadUnitSets.some(({ loadUnitType }) => {
            return loadUnitType === type;
        });
    });
};

export const showValue = (field, value, wb, fieldConfigs) => {
    const deps = dependencyMatrix[field];
    const caster = fieldValueCasters[field];
    const configs = fieldConfigs.filter((item) => {
        return item.targetField === field &&
            caster(item.targetFieldValue) === value &&
            deps.every((dep) => {
                const caster = fieldValueCasters[dep];
                const name = fieldNames[dep];
                return item[name] === null || caster(item[name]) === getWbValue(name, wb);
            });
    });


    if (!configs.length) {
        return true;
    }
    const hasValue = field === WaybillFieldEnum.CargoUnitType ?
        hasCargoUnitType(wb, value) :
        caster(getWbValue(fieldNames[field], wb)) === value;

    return configs.every((item) => {
        return item.isVisible;
    }) || hasValue;
};
