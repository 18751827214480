import React from 'react';
import {Label} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const WaybillStatusAvisation = ({statusAvisation, className}) => {
    const {t} = useTranslation();

    if (statusAvisation && statusAvisation.text) {
        return <Label color={statusAvisation.color || null} className={className}><b>{t(statusAvisation.text)}</b></Label>;
    }
    return null;
};

export default WaybillStatusAvisation;
