import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, GridColumn } from 'semantic-ui-react';
import { PackTypeEnum } from '../../../api/model/Enums';
import "./WbEditPoints.css";
import CargoTotals from './WbEditPointsTotals';
import WbPointCards from './WbPointCards';
import {WaybillTypeEnum} from "../../../api/model/Waybill";
import { withTranslation } from 'react-i18next';

const tabMainName = 'waybills_label_basic-info';

class WbEditPoints extends React.Component {

    state = {
        activeTab: tabMainName
    };

    handleTabChange(tab) {
        this.setState({ activeTab: tab || tabMainName });
    }

    calcTotals(loadUnitSets) {
        let totalPallets = 0, totalBoxes = 0, totalWeight = 0, totalVolume = 0;
        let totalOversizedPallets = 0, totalEmptyPallets = 0, totalFinPallets = 0, totalHighPallets = 0, totalLongBox = 0;

        if (loadUnitSets && loadUnitSets.length) {

            loadUnitSets.forEach(p => {
                if (p.loadUnitType === PackTypeEnum.EP) {
                    totalPallets = totalPallets + (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.BX) {
                    totalBoxes = totalBoxes + (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.BT) {
                    totalFinPallets = totalFinPallets + (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.OT) {
                    totalOversizedPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.PP) {
                    totalEmptyPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.HP) {
                    totalHighPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.LB) {
                    totalLongBox += (Number.parseInt(p.loadUnitsCount) || 0);
                }
                p.loadTotalWeigthBruttoKg && (totalWeight = totalWeight + Number.parseFloat(p.loadTotalWeigthBruttoKg));
                p.loadTotalVolumeM3 && (totalVolume = totalVolume + Number.parseFloat(p.loadTotalVolumeM3));
            });
        }

        return { totalPallets, totalBoxes, totalWeight, totalVolume, totalOversizedPallets, totalHighPallets, totalLongBox, totalEmptyPallets, totalFinPallets };
    }

    getArrivalDate = (points) => points && points[points.length - 1] && points[points.length - 1].arrivalDatePlan;

    render() {
        const { t } = this.props;

        const { dto, updateData } = this.props;

        const loadUnitsToOrderMappings = (dto.pointsUnloading || []).reduce((val, p) => val = val.concat(p.loadUnitsToOrderMappings), []);

        const loadUnitSets = (dto.pointsLoading || []).reduce((val, p) => val.concat(p.loadUnitSets), []);

        const unitsLoadedTotal = (loadUnitSets || []).reduce((val, p) => val + (p.loadUnitsCount || 0), 0);

        const calc = this.calcTotals(loadUnitSets);

        const pointsAll= dto.pointsLoading.map(item => ({
            ...item,
            isLoading: true
        })).concat(dto.pointsUnloading.map(item => ({
            ...item,
            isLoading: false
        })));

        const header = (
            <div className="totalsStyleTop">
                <CargoTotals
                    totalPallets={calc.totalPallets}
                    totalBoxes={calc.totalBoxes}
                    totalWeight={calc.totalWeight}
                    totalVolume={calc.totalVolume}
                    totalOversizedPallets={calc.totalOversizedPallets}
                    totalEmptyPallets={calc.totalEmptyPallets}
                    totalFinPallets={calc.totalFinPallets}
                    totalHighPallets={calc.totalHighPallets}
                    totalLongBox={calc.totalLongBox}
                />
            </div>
        );

        const firstLoadingDate = this.getArrivalDate(dto.pointsLoading);
        const firstUnloadingDate = this.getArrivalDate(dto.pointsUnloading);

        const {isClient = false} = this.props;

        const loadUnloadTables = (
            <Grid columns={2} divided>
                <Grid.Row>
                    <GridColumn>
                        <WbPointCards
                            wbId={dto.id}
                            wbType={dto.shippingType}
                            wbClientId={dto.clientId}
                            pointsAll={pointsAll}
                            points={dto.pointsLoading}
                            oppositeDate={firstUnloadingDate}
                            updatePoints={(pointsLoading, loadingsChanged) => updateData({ pointsLoading }, {loadingsChanged})}
                            updateWb={updateData}

                            profile="pointsLoading"

                            title={t('waybills_grid_loading')}
                            addTitle={t('waybills_grid_add-address-loading')}

                            positions={loadUnitSets}
                            unitsLoadedTotal={unitsLoadedTotal}

                            activeTab={this.state.activeTab}
                            handleTabChange={this.handleTabChange.bind(this)}

                            isClient={isClient}
                        />
                    </GridColumn>
                    <GridColumn>
                        <WbPointCards
                            wbId={dto.id}
                            wbType={dto.shippingType}
                            wbClientId={dto.clientId}
                            pointsAll={pointsAll}
                            points={dto.pointsUnloading}
                            oppositeDate={firstLoadingDate}
                            updatePoints={pointsUnloading => updateData({ pointsUnloading }, { unloadingsChanged: true })}
                            updateWb={updateData}
                            profile="pointsUnloading"

                            title={t('waybills_grid_unloading')}
                            addTitle={t('waybills_grid_add-address-unloading')}

                            positions={loadUnitsToOrderMappings}
                            unitsLoadedTotal={unitsLoadedTotal}

                            activeTab={this.state.activeTab}
                            handleTabChange={this.handleTabChange.bind(this)}

                            isClient={isClient}
                        />
                    </GridColumn>
                </Grid.Row>
            </Grid>
        );

        return (
            <Grid divided>
                <GridColumn width={16} className="p-t-5">
                    <Grid.Row>
                        {header}
                    </Grid.Row>
                    <Grid.Row>
                        {loadUnloadTables}
                    </Grid.Row>
                </GridColumn>
            </Grid>
        );
    }
}

export default withRouter(withTranslation()(WbEditPoints));
