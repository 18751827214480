import React, { useState, useContext, useEffect } from 'react';
import {Button, Message, Modal} from 'semantic-ui-react';
import WbGridTable from '../wbGrid/WbGridTable';
import {ContextNavi, ContextGridSettings, ContextFooter, ContextWb} from '../../../services/context';
import {getAll, getWaybillsInVisit} from '../../../api/waybills';
import Shield from '../../../layout/page/shield/Shield';
import useReactRouter from 'use-react-router'
import qs from "query-string";
import {reservationAdd} from "../../../api/waybillAviz";
import WbAvizModal from "../wbGrid/WbAvizModal";
import {LoadCapacityOptions} from "../../../api/model/Point";
import moment from "moment";
import { toast } from "../../../services/toast";
import { WaybillStatusEnum, WaybillAvisationStatus } from '../../../api/model/Waybill';
import { useTranslation } from 'react-i18next';

const availableStatuses = [
    WaybillStatusEnum.DRAFT,
    WaybillStatusEnum.SUBMITTED,
    WaybillStatusEnum.ON_APPROVAL,
    WaybillStatusEnum.EXECUTING,
].join(",");

const availableAvisationStatuses = [
    WaybillAvisationStatus.REQUESTED,
].join(",");

const WbAddModal = ({ open, onClose, clientId, reservationId, setWaybillsWithVisit, setIsOpenAvisModal, loadingPointIdFm}) => {
    const { t } = useTranslation();

    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const { location, history } = useReactRouter();

    const contextGridSettings = useContext(ContextGridSettings);
    const contextWb = useContext(ContextWb);
    const headers = contextGridSettings.columns
        .filter(c => !c.hide)
        .sort(function(a, b) {
            return a.order - b.order;
        });

    async function fetchData() {
        setLoading(true);
        try {
            const str = `avisationStatus=${availableAvisationStatuses}&clientId=${clientId}&status=${availableStatuses}&loadingPointIdFm=${loadingPointIdFm}`;
            let searchStr = location.search ? `${location.search}&${str}` : `?${str}`;
            const result = await getAll(searchStr);
            setRows(result);
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    }

    const handleClose = () => {
        history.push('?' + qs.stringify({}));
        setSelectedIds([]);
        onClose();
    };

    useEffect(() => {
        return () => {
            history.push('?' + qs.stringify({}));
        }
    }, []);

    useEffect(() => {
        fetchData();

    }, [location.search]);


    const add = async () => {
        const selectedRows = rows.filter(row => selectedIds.includes(row.id));
        if (moment(selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0]).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            toast.error(t('waybills_label_error-advise-dates'));
            return;
        }
        contextWb.setLoading(true);
        try {
            const ids = await reservationAdd(reservationId, {ids: selectedIds});
            handleClose();

            if (ids && ids.length) {
                const res = await getWaybillsInVisit(ids);
                setWaybillsWithVisit(res);
                setIsOpenAvisModal(true);
            }
        } finally {
            contextWb.setLoading(false);
        }

    };

    return (
        <Modal
            closeOnDimmerClick={false}
            size="large"
            dimmer="inverted"
            closeIcon
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>
                {t('waybills_label_add-req-to-exising-booking')}
            </Modal.Header>
            <Modal.Content>
                <Shield loading={loading} loadingOver={loaded}>
                    <Message info>
                        {t('waybills_label_select-requires-aviz')}
                    </Message>
                    <div className={'add_grid'}>
                        <WbGridTable
                            setLoading={setLoading}
                            headers={headers}
                            rows={rows}
                            setRows={setRows}
                            selectedIds={selectedIds || []}
                            selectIds={setSelectedIds}
                            getRowsIds={() => rows.map(r => r.id)}
                        />
                    </div>
                    <Button className="m-t-10" disabled={!selectedIds.length} onClick={add}>{`${t('waybills_btn_add')} ${selectedIds.length || ''} ${t('waybills_btn_booking-req')}`}</Button>
                </Shield>
            </Modal.Content>
        </Modal>
    );
};

export default WbAddModal;
