import React, { useState } from 'react';
import { Table, Icon } from 'semantic-ui-react';

import PointGridCell from '../../admin/points/pointsGrid/PointGridCell';
import AppTableHeader from '../../../components/tables/AppTableHeader';
import { PointBookmarkTypeColor, PointBookmarkTypeName } from '../../../api/model/Point';

export const headers = [
    new AppTableHeader("companyName", 'waybills_label_company', 6),
    new AppTableHeader("address", 'waybills_label_address', 10)
];

const WbPointAddFormBookmarks = ({ list, onClick }) => {
    const [selected, setSelected] = useState(-1);
    
    const hadleClick =  async(data, index) => {
        setSelected(index);
        onClick(data);
    };

    const getTitle = (key, item) => {
        switch(key) {
            case 'address': return item[key];
            case 'bookmarkType': return PointBookmarkTypeName[item.bookmarkType];
            default: return '';
        }
    };

    const getStyle = (index) => ({
        cursor: 'pointer',
        background: index === selected ? "rgba(0, 0, 0, 0.05)" : "initial"
    });

    return <div className="table-wrapper">
        <div className="table-scroll point-form-table m-t-5" style={{ height: 190 }}>
            <Table selectable fixed singleLine basic='very'>
                <Table.Body>
                    {list.map((item, index) => {
                        return <Table.Row 
                            key={index}
                            style={getStyle(index)}
                            onClick={() => {
                                hadleClick(item, index);
                            }}
                        >
                            {headers.map(({ key, width }) => {
                                return <Table.Cell
                                    key={key}
                                    title={getTitle(key, item)}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                    width={width}
                                >
                                    {key === 'companyName' &&
                                        <Icon
                                            name="bookmark outline"
                                            color={PointBookmarkTypeColor[item.bookmarkType]}
                                            title={getTitle('bookmarkType', item)}
                                        />
                                    }
                                    <PointGridCell
                                        keyName={key}
                                        item={item}
                                        disabled={true}
                                    />
                                </Table.Cell>;
                            })}
                        </Table.Row>;
                    })}
                </Table.Body>
            </Table>
        </div>
    </div>;
};

export default WbPointAddFormBookmarks;