import React from 'react';
import { Modal } from 'semantic-ui-react';

export default class ModalProxy extends React.Component {

    state = {
        data: this.props.data || {},
        dataValidationErrors: {},
        open: false,
        loading: false
    }

    handleOpen() {
        this.setState({ open: true });
    }
    
    handleClose() {
        this.setState({ open: false });
    }

    updateState(props) {
        this.setState({ ...props });
    }

    updateData(prop) {
        const newData = { ...this.state.data, ...prop };
        this.setState({ data: newData });
    }

    render() {
        const { data, dataValidationErrors, open, loading } = this.state;
        const trigger = this.props.children;
        const ModalContent = this.props.content;

        return (
            <Modal
                trigger={trigger}
                centered
                dimmer="inverted"
                size={"small"}
                closeIcon
                open={open}
                onOpen={this.handleOpen.bind(this)}
                onClose={this.handleClose.bind(this)}
                closeOnEscape={!loading}
                closeOnDimmerClick={!loading}
            >
                <ModalContent
                    updateState={this.updateState.bind(this)}
                    updateData={this.updateData.bind(this)}
                    data={data}
                    dataValidationErrors={dataValidationErrors}
                    close={this.handleClose.bind(this)}
                    loading={loading}
                    onOk={this.props.onOk}
                />
            </Modal>
        );
    }
}