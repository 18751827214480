import React, {useEffect, useState} from 'react';
import moment from "moment";
import Timeslot from "../../../components/timeslot/Timeslot";
import {Form, Grid, GridColumn, Message} from "semantic-ui-react";
import InputDate from "../../../components/inputs/InputDate";
import {getAvisationTimeslot, getAvisDates} from "../../../api/waybills";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const WbAvizAvailableTimeslot = ({dateArrival, timeslot = null, deliveryDateMin, onChange, waybillIds, errors}) => {
    const { t } = useTranslation();

    const [dto, setDto] = useState({
        date: dateArrival,
        timeslot: timeslot
    });

    const [maxDate, setMaxDate] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [warnings, setWarnings] = useState([]);

    const [valuesListTimeSlot, setValuesListTimeSlot] = useState([]);

    useEffect(() => {
        getMinAndMaxDates();
    }, []);


    const getMinAndMaxDates = async () => {
        try {
            const result = await getAvisDates(waybillIds);

            setMaxDate(result.max);
            setMinDate(result.min);
            setWarnings(result.warnings);

        } catch (e) {
            console.log('error', e);
        }
    };


    const [isOpenTimeSlot, setIsOpenTimeSlot] = useState(false);

    async function getTimeSlotValue() {
        if (!dto.date) {
            return;
        }
        const param = {
            waybillIds,
            date: `${moment(dto.date).format('YYYY-MM-DD')}`
        };
        const res = await getAvisationTimeslot(param);
        res && setValuesListTimeSlot(res);
    }

    useEffect(() => {
        getTimeSlotValue();
    }, [dto.date]);

    const handleDateChange = (val, name) => {
        let newDto = {...dto};
        newDto[name] = val;
        if (name === 'date') {
            newDto['timeslot'] = null
        }
        setDto(newDto);
        onChange && onChange(newDto)
    };

    const handleDateChangeTimeSlot = (val) => {
        let newDto = {...dto};
        newDto.timeslot = val;
        newDto.date = val && val.date ? new Date(val.date.slice(6, 10), val.date.slice(3, 5) - 1, val.date.slice(0, 2)) : dto.date;
        setDto(newDto);
        onChange && onChange(newDto)
    };

    return (
        <>
            <Form>
                <Grid columns='equal'>
                    <Grid.Row>
                        <GridColumn>
                            <Form.Field error={errors.includes('date') || errors.includes('dateInvalid')}>
                                <label className="display-inline-block">{t('waybills_label_date-arrival')}</label>
                                <InputDate
                                    icon="calendar outline"
                                    placeholder={t('waybills_label_date-arrival')}
                                    position="bottom center"
                                    onChange={val => handleDateChange(val, 'date')}
                                    value={dto.date}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    onFocus={getMinAndMaxDates}
                                />
                            </Form.Field>
                        </GridColumn>
                        <GridColumn>
                            <Form.Field>
                                <Timeslot
                                    errors={errors}
                                    name={t('waybills_input_timeslot')}
                                    required
                                    placeholder={t('waybills_placeholder_time')}
                                    value={dto.timeslot}
                                    visitDate={dateArrival}
                                    valuesList={valuesListTimeSlot || []}
                                    showPopup={setIsOpenTimeSlot}
                                    isShow={isOpenTimeSlot}
                                    onChange={(e, {value}) => handleDateChangeTimeSlot(value)}
                                    loadTimeSlot={() => setIsOpenTimeSlot(true)}
                                />
                            </Form.Field>
                        </GridColumn>
                    </Grid.Row>
                </Grid>
            </Form>
            {
                warnings && warnings.length
                    ? <Message warning>
                        <Message.Header>
                            {t('waybills_label_error-dates-not-match')}
                        </Message.Header>

                        {t('waybills_text_change-date')}
                        <br/>
                        {warnings.map(item => <><Link to={`/waybills/${item.waybillId}/view/info`} target="_blank">{item.waybillId}</Link>: {new Date(item.date).toLocaleDateString()}</>)}
                    </Message>
                    : null
            }
        </>
    )
};

export default WbAvizAvailableTimeslot;
