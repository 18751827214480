import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UsersIndex from './UserIndex';
import UserFormEdit from './UserFormEdit';

export default function Users(props) {

    return (
        
        <Switch>
            <Route path='/admin/users' exact component={UsersIndex} />
            <Route path='/admin/users/:id' exact component={UserFormEdit} />
        </Switch>
    );

}