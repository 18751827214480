import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './services/serviceWorker';
import './index.css';
import App from './App';
import './translations/i18n';
import {Dimmer, Loader} from "semantic-ui-react";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
	<BrowserRouter basename={baseUrl}>
        <Suspense
            fallback={
                <Dimmer active inverted>
                    <Loader size="huge">Loading</Loader>
                </Dimmer>
            }
        >
            <App />
        </Suspense>
	</BrowserRouter>,
	rootElement
);

serviceWorker.unregister();
