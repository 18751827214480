import React, {useEffect, useMemo, useState} from 'react';
import {Button, Dropdown, Form, Input, Message, Modal, TextArea} from "semantic-ui-react";
import InputDropdown from "../../components/inputs/InputDropdown";
import {CargoTypeOptions, WeekDayOptions} from "../../api/model/Enums";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import SchedulesConstructor from "../../components/schedulesConstructor";
import {nanoid} from "nanoid";
import {getPointsFromSchedule} from "../../api/points";
import {getCompaniesFromSchedule} from "../../api/companies";
import SelectClientMulti from "../../components/inputs/SelectClientMulti";
import {normalizeTime} from "../../services/normalizeTime";
import {restrictionOnlyNumber} from "../../services/utils";
import InputDate from "../../components/inputs/InputDate";
import {
    createSchedule,
    editSchedule,
    getChainOptionsRequest,
    getCityOptionsRequest,
    getRegionsOptionsRequest,
    getSchedule,
    getSearchnamesOptionsRequest,
    getSendingregionsOptionsRequest
} from "../../api/schedules";
import {useTranslation} from 'react-i18next';

const AddScheduleModal = ({open, onClose, id, fetchData}) => {

    let [form, setForm] = useState({});
    let [items, setItems] = useState([{nanoId: nanoid()}]);
    let [points, setPoints] = useState([]);
    let [companies, setCompanies] = useState([]);
    let [isDubleSave, setIsDubleSave] = useState({open: false});

    let [saveLoader, setSaveLoader] = useState(false);

    let [chainOptions, setChainOptions] = useState([]);
    let [chainSearch, setChainSearch] = useState('');

    let [searchnameOptions, setSearchnameOptions] = useState([]);
    let [searchnameSearch, setSearchnameSearch] = useState('');

    let [sendingregionsOptions, setSendingregionsOptions] = useState([]);
    let [sendingregionsSearch, setSendingregionsSearch] = useState('');

    let [regionsOptions, setRegionsOptions] = useState([]);
    let [regionsSearch, setRegionsSearch] = useState('');

    let [cityOptions, setCityOptions] = useState([]);
    let [citySearch, setCitySearch] = useState('');
    const {t} = useTranslation();

    const clearSearch = () => {
        setChainSearch('');
        setSearchnameSearch('');
        setSendingregionsSearch('');
        setRegionsSearch('');
        setCitySearch('');
    };


    const handleClose = () => {
        setForm({});
        setItems([{nanoId: nanoid()}]);
        onClose();
        fetchData();
        clearSearch();
    };

    useEffect(() => {
        if (open) {
            getPointsRequest();
            getChainOptions();
            getSearchNameOptions();
            getSendingregionsOptions();
            getRegionsOptions();
            //getCityOptions();
            id && getFormRequest();
        }
    }, [open]);

    useEffect(() => {
        getChainOptions();
    }, [chainSearch, form.chain]);

    useEffect(() => {
        getSearchNameOptions();
    }, [searchnameSearch, form.chain, form.searchnamePointId]);

    useEffect(() => {
        getSendingregionsOptions();
    }, [sendingregionsSearch, form.sendingRegion]);

    useEffect(() => {
        getRegionsOptions();
    }, [regionsSearch]);

    useEffect(() => {
        getCityOptions();
    }, [citySearch, form.destinationRegion]);

    const getChainOptions = async () => {
        const {chains = []} = await getChainOptionsRequest(chainSearch, form.chain);
        setChainOptions([
            {
                key: 'null',
                value: {},
                text: t("schedules_label_other-consignees"),
            },
            ...chains.map(i => ({
                key: nanoid(),
                value: {
                    companyName: i.companyName,
                    pointType: i.pointType,
                    name: i.name,
                },
                text: i.name
            }))
        ])
    };

    const getSearchNameOptions = async () => {
        const {searchnames = []} = await getSearchnamesOptionsRequest({
            searchname: searchnameSearch,
            ...form.chain,
            currentSearchnamePointId: form.searchnamePointId,
        });

        setSearchnameOptions(searchnames.map(i => ({
            ...i,
            key: i.pointId,
            value: i.pointId,
            text: i.searchname,
        })))
    };

    const getSendingregionsOptions = async () => {
        const {regions = []} = await getSendingregionsOptionsRequest(sendingregionsSearch, form.sendingRegion);

        setSendingregionsOptions(regions.map(i => ({
            key: i,
            value: i,
            text: i,
        })))
    };

    const getRegionsOptions = async () => {
        if (!form.searchnamePointId) {
            const {regions = []} = await getRegionsOptionsRequest(regionsSearch);

            setRegionsOptions(regions.map(i => ({
                key: nanoid(),
                value: i.name,
                text: i.name,
                id: i.id,
                postalCodes: i.postalCodes,
            })))
        }
    };

    const getCityOptions = async () => {
        if (form.destinationRegion && !form.searchnamePointId) {
            const {cities = []} = await getCityOptionsRequest((regionsOptions.find(i => i.value === form.destinationRegion) || {}).id, citySearch);

            setCityOptions(cities.map(i => ({
                key: nanoid(),
                value: i.name,
                text: i.name,
            })))
        }
    };

    const isDisabledSaveBtn = useMemo(() => {
        let isDisabled = false;

        const requiredFields = ['chain', 'cargoTypes', 'companies',
            'sendingRegion', 'destinationRegion', 'deadlineDay', 'deliveryDay', 'daysRoad'];

        requiredFields.forEach(field => {
            if (form[field] === undefined || form[field] === null || form[field] === '') isDisabled = true
        });

        if (form.cargoTypes && !form.cargoTypes.length) isDisabled = true;
        if (form.companies && !(form.companies.isAll || form.companies.ids.length)) isDisabled = true;

        if (
            items.find(
                item =>
                    !item.pointId ||
                    item.deliveryDay === undefined ||
                    item.sendingDay === undefined
            )
        )
            isDisabled = true;

        return isDisabled;
    }, [form, items]);

    const getFormRequest = async () => {
        const result = await getSchedule(id);

        let form = {};

        Object.keys(result).forEach(key => {
            if (key === 'chain' && result[key] === null) {
                form = {
                    ...form,
                    chain: {}
                }
            } else if (key === 'searchname') {
                form = {
                    ...form,
                    searchnamePointId: result[key] && result[key].pointId
                }
            } else if (key !== points) {
                form = {
                    ...form,
                    [key]: result[key]
                }
            }
        });

        setForm(form);
        setItems(result.points);
    };

    const getPointsRequest = async () => {
        const points = await getPointsFromSchedule();

        setPoints(points.map(point => ({key: point.id, text: point.fmid, value: point.id})));
    };

    const getCompaniesRequest = async (page = 1, search = '', isSearch) => {
        let companiesNext = await getCompaniesFromSchedule(page, search);

        companiesNext = companiesNext.map(company => ({key: company.fmid, text: company.fmid, value: company.fmid}));

        setCompanies([...(!isSearch ? companies : []), ...companiesNext])
    };

    const handleChange = (e, {value, name}) => {
        let valueObj = {
            [name]: value
        };

        clearSearch();

        if (name === 'chain') {
            valueObj = {
                ...valueObj,
                searchnamePointId: null,
                destinationRegion: '',
                destinationCity: '',
                destinationPostalCodes: []
            };
        }

        if (name === 'searchnamePointId') {
            if (value) {
                const search = searchnameOptions.find(i => i.value === value) || {};
                valueObj = {
                    ...valueObj,
                    destinationRegion: '',
                    destinationCity: search.settlement,
                    destinationPostalCodes: search.postalCodes
                }
            } else {
                valueObj = {
                    ...valueObj,
                    destinationRegion: '',
                    destinationCity: '',
                    destinationPostalCodes: []
                }
            }
        }

        if (name === 'destinationRegion') {
            valueObj = {
                ...valueObj,
                destinationCity: ''
            };

            if (value) {
                valueObj = {
                    ...valueObj,
                    destinationPostalCodes: (regionsOptions.find(i => i.value === value) || {}).postalCodes
                };
            } else {
                valueObj = {
                    ...valueObj,
                    destinationPostalCodes: []
                };
            }
        }

        if (name === 'sendingRegion') {
            console.log('11', value, points)
            setItems(prevState => {
                const list = [...prevState];
                list[0] = {
                    ...list[0],
                    pointId: (points.find(i => i.text === value) || {}).value,
                };

                return list
            })
        }

        setForm(form => ({
            ...form,
            ...valueObj,
        }))
    };

    const handleChangeViewPeriod = (e, {value, name}) => {
        setForm(form => ({
            ...form,
            showPeriod: {
                ...form.showPeriod,
                [name]: value,
            }
        }))
    };

    const mapData = () => {
        return {
            ...form,
            chain: form.chain && Object.keys(form.chain).length ? form.chain : null,
            points: items,
        }
    };

    const dubleConfirmCheck = async (number) => {
        setIsDubleSave({
            open: true,
            number,
            cancel: () => {
                setIsDubleSave({
                    open: false,
                })
            },
            confirm: () => {
                setIsDubleSave({
                    open: false,
                });
                handleSave();
            }
        });
    };

    /*const handleCreate = async () => {
        setSaveLoader(true);
        try {
            let checkResult;
            if (id) {
                checkResult = await editCheck(id, mapData());
            } else {
                checkResult = await createCheck(mapData())
            }
            if (!checkResult) {
                handleSave()
            } else {
                dubleConfirmCheck(checkResult)
            }

        } finally {
            setSaveLoader(false)
        }
    };
*/
    const handleSave = async () => {
        setSaveLoader(true);
        try {
            if (!id) {
                await createSchedule(mapData());
            } else {
                await editSchedule(id, mapData());
            }

            handleClose();
        } finally {
            setSaveLoader(false)
        }
    };

    return (
        <Modal
            centered={false}
            open={open}
            closeOnDimmerClick={false}
            onClose={handleClose}
        >
            <Modal.Header>{id ? t("schedules_label_edit-graph") : t("schedules_label_add-graph")}</Modal.Header>
            <Modal.Content scrolling>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field required>
                            <label>{t("schedules_label_region-dispatch")}</label>
                            <Dropdown
                                fluid
                                name="sendingRegion"
                                onChange={handleChange}
                                onSearchChange={(e, {searchQuery}) => setSendingregionsSearch(searchQuery)}
                                options={sendingregionsOptions}
                                placeholder={t("schedules_label_value")}
                                search
                                clearable
                                searchQuery={sendingregionsSearch}
                                selection
                                value={form.sendingRegion}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>{t("schedules_label_network")}</label>
                            <Dropdown
                                fluid
                                name="chain"
                                onChange={handleChange}
                                onSearchChange={(e, {searchQuery}) => setChainSearch(searchQuery)}
                                options={chainOptions}
                                placeholder={t("schedules_label_value")}
                                search
                                clearable
                                searchQuery={chainSearch}
                                selection
                                value={form.chain}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>{t("schedules_label_searchname")}</label>
                            <Dropdown
                                fluid
                                name="searchnamePointId"
                                onChange={handleChange}
                                onSearchChange={(e, {searchQuery}) => setSearchnameSearch(searchQuery)}
                                options={searchnameOptions}
                                placeholder={t("schedules_label_value")}
                                search
                                clearable
                                searchQuery={searchnameSearch}
                                selection
                                value={form.searchnamePointId}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>{t("users_label_delivery-region")}</label>
                            {
                                form.searchnamePointId
                                    ?
                                    <Input disabled value={form.destinationRegion}/>
                                    : <Dropdown
                                        fluid
                                        name="destinationRegion"
                                        onChange={handleChange}
                                        onSearchChange={(e, {searchQuery}) => setRegionsSearch(searchQuery)}
                                        options={regionsOptions}
                                        placeholder={t("schedules_label_value")}
                                        search
                                        clearable
                                        disabled={form.searchnamePointId}
                                        searchQuery={regionsSearch}
                                        selection
                                        value={form.destinationRegion}
                                    />
                            }
                        </Form.Field>
                        <Form.Field>
                            <label>{t("schedules_label_delivery-city")}</label>
                            {
                                form.searchnamePointId
                                ? <Input disabled value={form.destinationCity} />
                                :  <Dropdown
                                        fluid
                                        name="destinationCity"
                                        onChange={handleChange}
                                        onSearchChange={(e, {searchQuery}) => setCitySearch(searchQuery)}
                                        options={cityOptions}
                                        placeholder={t("schedules_label_value")}
                                        search
                                        clearable
                                        disabled={!form.destinationRegion}
                                        searchQuery={citySearch}
                                        selection
                                        value={form.destinationCity}
                                    />
                            }
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal" style={{alignItems: "flex-end"}}>
                        <Form.Field>
                            <Form.Group widths="equal">
                                <Form.Field required>
                                    <label>{t("users_placeholder_client")}</label>
                                    <SelectClientMulti
                                        options={companies}
                                        value={form.companies}
                                        multiple
                                        placeholder={t("schedules_label_value")}
                                        fetchData={getCompaniesRequest}
                                        onClose={() => setCompanies([])}
                                        onChange={val => handleChange(null, {value: val, name: "companies"})}
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label>{t("users_grid_cargo-type")}</label>
                                    <InputDropdown
                                        options={CargoTypeOptions}
                                        value={form.cargoTypes}
                                        multiple
                                        placeholder={t("schedules_label_value")}
                                        onChange={val => handleChange(null, {value: val, name: "cargoTypes"})}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form.Field>
                        <Form.Field>
                            <label>{t("schedules_label_recommended-time-window")}</label>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <label>{t("schedules_label_from")}</label>
                                    <Input
                                        value={form.recommendedTimeFrom ? form.recommendedTimeFrom.slice(0, 5) : ''}
                                        name="recommendedTimeFrom"
                                        maxLength={200}
                                        placeholder={t("schedules_label_value")}
                                        onChange={(e, {value, name}) => handleChange(e, {
                                            value: normalizeTime(value),
                                            name
                                        })}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("schedules_label_by")}</label>
                                    <Input
                                        value={form.recommendedTimeTo ? form.recommendedTimeTo.slice(0, 5) : ''}
                                        name="recommendedTimeTo"
                                        maxLength={200}
                                        placeholder={t("schedules_label_value")}
                                        onChange={(e, {value, name}) => handleChange(e, {
                                            value: normalizeTime(value),
                                            name
                                        })}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field required>
                            <label>{t("schedules_label_deadline-place-app")}</label>
                            <InputDropdown
                                options={WeekDayOptions}
                                value={form.deadlineDay}
                                placeholder={t("schedules_label_value")}
                                onChange={val => handleChange(null, {value: val, name: "deadlineDay"})}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>{t("schedules_label_day-delivery-consignee")}</label>
                            <InputDropdown
                                options={WeekDayOptions}
                                value={form.deliveryDay}
                                placeholder={t("schedules_label_value")}
                                onChange={val => handleChange(null, {value: val, name: "deliveryDay"})}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>{t("schedules_label_day-on-the-way")}</label>
                            <Input
                                value={form.daysRoad}
                                name="daysRoad"
                                maxLength={200}
                                placeholder={t("schedules_label_value")}
                                onChange={(e, {value, name}) => handleChange(e, {
                                    value: restrictionOnlyNumber(value),
                                    name
                                })}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Divider/>
                <SchedulesConstructor
                    items={items}
                    points={points}
                    onChange={setItems}
                />
                <Divider/>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>{t("schedules_label_comment")}</label>
                            <TextArea
                                value={form.comment}
                                name="comment"
                                onChange={handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>{t("schedules_label_additional-info-client")}</label>
                            <TextArea
                                value={form.additionalInformationForClient}
                                name="additionalInformationForClient"
                                onChange={handleChange}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <label>{t("banners_label_show-period")}</label>
                        <Divider/>
                    </Form.Field>
                    <Form.Group>
                        <Form.Field>
                            <label>{t("banners_input_begin")}</label>
                            <InputDate
                                icon="calendar outline"
                                position="bottom center"
                                onChange={value =>
                                    handleChangeViewPeriod(null, {name: "startView", value})
                                }
                                value={form.showPeriod && form.showPeriod.startView}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>{t("banners_input_inclusive-up-to")}</label>
                            <InputDate
                                icon="calendar outline"
                                position="bottom center"
                                onChange={value =>
                                    handleChangeViewPeriod(null, {name: "endView", value})
                                }
                                value={form.showPeriod && form.showPeriod.endView}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t("base_btn_undo")}</Button>
                <Button primary loading={saveLoader} disabled={isDisabledSaveBtn}
                        onClick={handleSave}>{id ? t("base_btn_save") : t("base_btn_add")}</Button>
            </Modal.Actions>
            <Modal open={isDubleSave.open}>
                <Modal.Header>{t("schedules_label_check-for-duplicates")}</Modal.Header>
                <Modal.Content>
                    <Message
                        icon="info"
                        info
                        content={<div>
                            <div>{t("schedules_text_graphic-block1")} ${isDubleSave.number} {t("schedules_text_graphic-block2")}</div>
                            <div>
                                {t("schedules_text_confirm-creation")}
                            </div>
                        </div>}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={isDubleSave.cancel}
                    >
                        {t("base_btn_cancel")}
                    </Button>
                    <Button
                        color="green"
                        onClick={isDubleSave.confirm}
                    >
                        {t("base_btn_add")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Modal>
    );
};

export default AddScheduleModal;
