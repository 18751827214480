import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Divider, Icon, Menu, Message } from 'semantic-ui-react';
import { getWaybillDocsInitial, getWaybillDocsClosing } from '../../../api/waybillDocs';
import GalleryItem from '../../../components/gallery/GalleryItem';
import { useTranslation } from 'react-i18next';


function DocsCards({ hasDocsChecking, checkMessage, docs, id }) {
    const { t } = useTranslation();

    const styleWrapper = { padding: '7px' };
	const styleHidden = {
		visibility: 'hidden',
		opacity: '0',
		marginTop: '-85px',
		transition: 'visibility 0s 1s, opacity 1s linear, margin-top 1s linear'
	};

	return (
		<div style={styleWrapper}>
			<div style={hasDocsChecking ? null : styleHidden}>
				<Message warning icon>
					<Icon name='circle notched' loading />
					<Message.Content>
						<Message.Header>{t('waybills_label_checking')}</Message.Header>
						<p>{checkMessage}</p>
					</Message.Content>
				</Message>
			</div>
			{!hasDocsChecking && docs.length > 0 &&
				<Card.Group className="m-t-5">{docs.map(doc => <GalleryItem {...doc} key={doc.header} id={id} />)}</Card.Group>
			}
			{!hasDocsChecking && docs.length === 0 &&
				<Message info icon="info" header={t('waybills_label_doc-not-available')} content={t('waybills_label_please-try-again-later')} />
			}
		</div>
	);
}

function WbDocs({ wb }) {
    const { t } = useTranslation();

	const [docsInitial, setDocsInitial] = useState([]);
	const [hasDocsInitialChecking, setHasDocsInitialChecking] = useState(true);
	const [docsClosing, setDocsClosing] = useState([]);
	const [hasDocsClosingChecking, setHasDocsClosingChecking] = useState(true);

	useEffect(() => {
		async function fetchDocsInitial() {
			try {
				const dto = await getWaybillDocsInitial(wb.id);
				setDocsInitial(dto || []);
			} finally {
				setHasDocsInitialChecking(false);
			}

		}
		async function fetchDocsClosing() {
			try {
				const dto = await getWaybillDocsClosing(wb.id);
				setDocsClosing(dto || []);
			} finally {
				setHasDocsClosingChecking(false);
			}

		}

		Promise.all([fetchDocsInitial(), fetchDocsClosing()]);
	}, [wb.id]);

	return (
		<>
			<Menu borderless>
				<Menu.Item><b>{t('waybills_label_primary-docs')}</b></Menu.Item>
			</Menu>
			<DocsCards
				hasDocsChecking={hasDocsInitialChecking}
				checkMessage={t('waybills_label_checking-primary-docs')}
				docs={docsInitial}
				id={wb.id}
			/>
			<Divider hidden />
			<Menu borderless>
				<Menu.Item><b>{t('waybills_label_supporting-docs')}</b></Menu.Item>
			</Menu>
			<DocsCards
				hasDocsChecking={hasDocsClosingChecking}
				checkMessage={t('waybills_label_checking-closing-docs')}
				docs={docsClosing}
				id={wb.id}
			/>
		</>
	);
}

export default withRouter(WbDocs);
