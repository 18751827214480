import React from 'react';
import { Segment } from 'semantic-ui-react';
import ProgressVisual from '../common/progressVisual/ProgressVisualNew';

export default function PointsVisual({data, pointsLoading, pointsUnloading}) {

    return (
        <Segment clearing={true} textAlign="center" placeholder style={{ minHeight: '95px', padding: '7px' }}>
            <div>
                <ProgressVisual data={data} pointsLoading={pointsLoading} pointsUnloading={pointsUnloading}/>
            </div>
        </Segment>
    );
}