import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { ContextLocale } from '../../services/context';
import {useTranslation} from "react-i18next";
import {getLanguages} from "../../api/users";

export default function LanguageSelector() {
    const contextLocale = useContext(ContextLocale);
    const [options, setOptions] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getOptions();
    }, []);

    const getOptions = async () => {
        const result = await getLanguages();
        setOptions(result.map(item => ({
            key: item.name,
            value: item.name,
            text: item.nativeName,
        })))
    };

    function handleChange (e, { value }) {
        contextLocale.setLocale(value, i18n);
        //setValue(value);
    }

    return (
        <Dropdown
            item
            onChange={handleChange}
            options={options}
            basic
            value={i18n.language}
        />
    );

}
