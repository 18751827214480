import O from '../components/Option';
import {get, post, upload} from '../services/http';
import Point from './model/Point'; // eslint-disable-line

export class Od extends O {
    constructor(id, text, description) {
        super(id, text);
        this.description = description;
    }
}

export async function getPoints(search = '') {
    const result = await get(`points${search}`);
    return result;
}

export async function getPointsFromSchedule() {
    const result = await get(`points/by/type/fm`);
    return result;
}

export async function getPointById(id) {
    const result = await get(`point/id/${id}`);
    return new Point(result);
}

export async function getPointOptionById(id) {
    const result = await get(`point/id/${id}/options`);
    return result;
}

export async function getPointsBySearch(term, companyName) {
    const result = await get(`points/search/${term}/${companyName}`);
    return result.map(p => new Point(p));
}

export async function getPointsBy(searchKey, searchVal, search = '') {
    const result = await get(`points/by/${searchKey}/${searchVal}${search}`);
    return result;
}

export async function getSimilarPoint(search = '') {
    const result = await get(`points/similar/${search}`);
    return result;
}

export async function getPointsByCompanyFMID(companyName) {
    const result = await get(`points/company/${companyName}`);
    return result.map(p => new Point(p));
}

export async function getPointsRecent(companyName, wbPointId) {
    const result = await get(`points/recent/${companyName}${wbPointId ? '?pointId=' + wbPointId : ''}`);
    return result.map(p => new Point(p));
}

export async function pointDelete(id) {
    try {
        return await get(`point/${id}/delete`);
    } catch (error) {
        return false;
    }
}

export async function pointUpdate(dto) {
    let result = null;
    let updateUrl =`point/update/${dto.id}`;
    try {
        result = await post(updateUrl, dto);
    } catch (error) {

    }
    return result;
}

export async function pointAddressPropUpdate(pointId, propName, propValue) {
    const updateUrl = `point/${pointId}/update/${propName}`;
    const result = await post(updateUrl, { value: propValue });
    return result;
}

/** @param {Point} dto */
export async function pointCreateAccepted(dto) {
    let url = "point/create/register";
    try {
        return await post(url, dto);
    } catch (error) {
        return null;
    }
}

/** @param {Point} dto */
export async function pointCreate(dto, exportOnSave) {

    let url = "point/create";
    try {
        return await post(url, dto);
    } catch (error) {
        return null;
    }
}

export async function pointsActivate(ids) {
    const result = await post(`point/activate`, ids);
    return result;
}

export async function pointsExportCount() {
    const result = await get(`point/export/count`);
    return result;
}

export async function pointsExportNext(count = 1) {
    const result = await get(`point/export/next/${count}`);
    return result;
}

export async function pointsExport(id) {
    const result = await get(`point/export/${id}`);
    return result;
}

export async function pointsExportReport(id) {
    const result = await get(`point/export/${id}/report`);
    return result;
}

export async function pointsImport(formData) {
    var result = await upload(`point/import/excel`, formData);
    return result;
}

export async function pointsImportClient(formData, company) {
    var result = await upload(`point/import/client/excel?company=${company}`, formData);
    return result;
}

export async function pointCommentCreate(pointId, text) {
    var result = await post(`point/${pointId}/comment`, {text});
    return result;
}

export async function pointsExportExcel(query = "", selectedPoints) {
    var result = await post(`points/export/excel${query}`, selectedPoints);
    return result;
}

export async function pointsExportJson(query = '', selectedPoints) {
    var result = await post(`points/export/json`, selectedPoints);
    return result;
}

export async function pointsExportExcelClient(query = '', selectedPoints, selectedExport =[]) {
    const newFormatSelectedExport = selectedExport.map(i => i.forExport);
    const result = await post(`points/exportclient/excel${query}`, newFormatSelectedExport);
    return result;
}

export async function hasPointForExport(query = '') {
    var result = await get(`points/hasexport${query}`);
    return result;
}

export async function hasPointForExportClient(query = '') {
    var result = await get(`points/hasexport/client${query}`);
    return result;
}


export async function getPointOptionsFmidBySearch(fmid, companyName) {
    const result = await get(`points/search/fmid/${fmid}/${companyName}`);

    return result;
}
