import React, {useContext, useEffect, useState} from 'react';
import {
    copyStandardAutocompleteApi,
    createStandardAutocompleteApi,
    deleteStandardAutocompleteApi,
    getStandardAutocompleteApi,
    getStandardAutocompleteListApi,
    saveStandardAutocompleteApi
} from "../../api/calculatingFieldsRules";
import TableComponent from "./TableComponent";
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi} from "../../services/context";
import Shield from "../../layout/page/shield/Shield";
import {Icon, Menu} from "semantic-ui-react";

import AddModalComponent from "./AddModalComponent";
import {useTranslation} from 'react-i18next';

const AutocompleteList = ({match}) => {
    const {t} = useTranslation();

    const contextNavi = useContext(ContextNavi);

    const [rules, setRules] = useState([]);

    useEffect(() => {
        const title = "waybillautocomplte_label_standard-rules";
        contextNavi.setItems([new LinkInfo(title, "/autocomplete")]);

        getRules();
    }, []);

    const getRules = async () => {
        const result = await getStandardAutocompleteListApi();

        setRules(result);
    };

    const confirmDelete = async (id) => {
        return await deleteStandardAutocompleteApi(id);
    };

    const handleCopy = async (id) => {

        return await copyStandardAutocompleteApi(id);

    };

    const handleGetRuleById = async (id) => {
        return await getStandardAutocompleteApi(id);
    };

    const handleRuleEdit = async (id, params) => {
        return await saveStandardAutocompleteApi(id, params);
    };

    const handleRuleCreate = async (params) => {
        return await createStandardAutocompleteApi(params);
    };

    return (
        <Shield loading={false} loadingOver={false}>
            <Menu
                className="waybills-toolbar shd-inset"
                style={{marginBottom: "0"}}
                size="small"
                borderless
            >
                <Menu.Menu position="right">
                    <AddModalComponent indexRule={rules.length + 1} fetchData={getRules}
                                       getRuleById={handleGetRuleById}
                                       createRule={handleRuleCreate}
                                       editRule={handleRuleEdit}
                    >
                    <Menu.Item>
                        <Icon color="blue" name="add"/>
                        {t("users_grid_add-rule")}
                    </Menu.Item>
                    </AddModalComponent>
                </Menu.Menu>
            </Menu>
            <div className="table-wrapper-grid table-wrapper-grid-130">
            <TableComponent isHeaderFixed
                            companyId={match.params.id}
                            rules={rules}
                            getRules={getRules}
                            deleteRule={confirmDelete}
                            getRuleById={handleGetRuleById}
                            copyRule={handleCopy}
                            createRule={handleRuleCreate}
                            editRule={handleRuleEdit}
            />
                </div>
        </Shield>
    )
};

export default AutocompleteList;
