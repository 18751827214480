import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Divider, Dropdown, Form, Header, Input, Modal} from 'semantic-ui-react';
import {
    CargoTypeOptions,
    PickupTypeEnum,
    PickupTypeOptions,
    ThermoModeOptions,
    WaybillFieldEnum
} from '../../../api/model/Enums';
import {LoadCapacityOptions} from '../../../api/model/Point';
import UserPermissions from '../../../api/model/UserPermissions';
import {
    BodyCapacityOptions,
    LoadCapacityEnum,
    WaybillAvisationStatus,
    WaybillCarTypeOptions,
    WaybillStatusEnum,
    WaybillTypeEnum,
    WaybillTypeOptions
} from '../../../api/model/Waybill';
import {getMinCapacity} from '../../../api/tonnage';
import InputDropdown from '../../../components/inputs/InputDropdown';
import {ContextUser} from '../../../services/context';
import {showField, showValue,} from '../../admin/companies/companyTabs/companyTabFieldConfig/companyTabUtils';
import {ProviderStatusEnum} from '../../../api/model/Provider';
import {getProvidersCommonCompanies} from "../../../api/companies";
import {getCompanyOptionsBySearchUser} from "../../../api/users";
import {restrictionInt} from "../../../services/utils";
import WbPointAddForm from "./WbPointAddForm";
import { useTranslation } from 'react-i18next';

const stl1 = {
    backgroundImage: 'linear-gradient(to right, rgba(0, 255, 102, 0.12), white)',
};

/** @param {Waybill} dto */
export default function WbEditMain({
                                       dto = {},
                                       updateData,
                                       cargoRestrictions,
                                       fieldConfigs,
                                       isClient = false,
                                   }) {
    const { t } = useTranslation();

    const isFTL = dto.shippingType === WaybillTypeEnum.FTL;
    const isRail = dto.shippingType === WaybillTypeEnum.Rail || dto.shippingType === WaybillTypeEnum.UrbanDelivery;
    const isRailLTL = dto.shippingType === WaybillTypeEnum.RailLTL;
    const isPlanReturn = dto.shippingType === WaybillTypeEnum.PlanReturn;
    const contextUser = useContext(ContextUser);
    const user = contextUser.current;
    const canEdit = user.permissions.includes(UserPermissions.WAYBILL_EDIT);
    const isDraft = dto.status === WaybillStatusEnum.DRAFT;
    const isWbHubMoscow =
        dto.pointsLoading && dto.pointsLoading.some(pl => pl.crossDockWithActivity || pl.avisationByEmail);
    const canChangePickupType = canEdit && !isDraft && isWbHubMoscow;

    const [providersList, setProvidersList] = useState([]);
    const [clientIdOld, setClientIdOld] = useState('');
    const [shippingTypePre, setShippingTypePre] = useState('');
    const [dtoOld, setDtoOld] = useState(dto);
    const [temperatureCondition, setTemperatureCondition] = useState(dto.temperatureCondition);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isChangePickupType, setIsChangePickupType] = useState(false);

    const [companies, setCompanies] = useState([]);

    const mounted = useRef();

    useEffect(() => {
        setTemperatureCondition(dto.temperatureCondition);
    }, [dto.temperatureCondition]);

    function getThermoOptions() {
        if (isRailLTL) {
            return ThermoModeOptions.filter(o => o.key === 10).map(item => ({
                ...item,
                text: t('waybills_label_no-mode'),
            }))
        } else if (isFTL || isPlanReturn) {
            if (dto.vehicleCapacity && dto.vehicleCapacity !== LoadCapacityEnum.C20000) {
                return ThermoModeOptions.filter(o => o.key !== 30 && o.key !== 50);
            } else return ThermoModeOptions
        } else {
            return ThermoModeOptions.filter(o => o.key !== 60);
        }
    }

    function getWaybillTypeOptions() {
        return dto.shippingType === WaybillTypeEnum.Return ? WaybillTypeOptions : WaybillTypeOptions.filter(item => item.key !== WaybillTypeEnum.Return);
    }

    async function getProvidersInOptions() {
        const res = await getProvidersCommonCompanies(dto.clientId);
        setProvidersList(
            res
                .filter(i => i.status === ProviderStatusEnum.ACTIVE /*|| i.id === dto.providerId*/)
                .map(i => ({
                    key: i.id,
                    text: i.name,
                    value: i.id,
                })),
        );
    }

    useEffect(() => {
        setCompanies(dto.__optionsCompany);
    }, [dto.__optionsCompany]);

    useEffect(() => {
        (async function () {
            try {
                if (dto.clientId) {
                    await getProvidersInOptions();
                }
            } catch (e) {
                /* updateData({providerId: null});
                 await updateData({providerName: null});*/
                /*setProvidersList([]);*/
            }
        })();
    }, [dto.clientId]);

    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
            let needToUpdateData = false;
            let dataToClear = {};
            if (dto.vehicleCapacity) {
                dataToClear.vehicleCapacity = null;
                needToUpdateData = true;
            }
            if (dto.bodyCapacity) {
                dataToClear.bodyCapacity = null;
                needToUpdateData = true;
            }
            if (needToUpdateData && dto.shippingType) {
                updateData(dataToClear);
            }
        }

    }, [dto.shippingType]);

    useEffect(() => {
        setClientIdOld(dto.clientId);
        setShippingTypePre(dto.shippingType);
    }, []);

    let timerId;

    useEffect(() => {
        if (
            (dto.providerId &&
                !!providersList.length &&
                !providersList.filter(i => i.key === dto.providerId).length) ||
            (clientIdOld && clientIdOld !== dto.clientId && !providersList.length)
        ) {
            if (dto.providerId === null) {
                timerId = setTimeout(() => setClientIdOld(dto.clientId), 500);
                return;
            }
            (async function () {
                try {
                    clearTimeout(timerId);
                    await updateData({providerId: null});
                    timerId = setTimeout(() => setClientIdOld(dto.clientId), 500);
                } catch (e) {
                    /* updateData({providerId: null});
                     await updateData({providerName: null});*/
                    /*setProvidersList([]);*/
                }
            })();
        }
    }, [dto, providersList]);

    function getCapacityOptions() {
        if ((isFTL || isPlanReturn) && (dto.shippingTemperatureCondition === 30 || dto.shippingTemperatureCondition === 50)) {
            return LoadCapacityOptions.filter(o => o.key === LoadCapacityEnum.C20000);
        } else {
            return LoadCapacityOptions;
        }
    }

    function onChangeClientId(clientId) {
        (async function () {
            try {
                await updateData({clientId});
                await setProvidersList([]);
            } catch (e) {
            }
        })();
    }

    function searchTextAndDescription(options, query) {
        const q = query.toLowerCase();
        const qterms = q.split(' ');
        const q0 = qterms[0];
        return options.length
            ? options.filter(
                opt =>
                    (opt.text && opt.text.toLowerCase().includes(q0)) ||
                    (opt.description && opt.description.toLowerCase().includes(q0)),
            )
            : [];
    }

    async function handleClientSearchChange(e, {searchQuery}) {

        searchQuery = searchQuery && searchQuery.trim();
        const hasSearch = searchQuery && searchQuery.length > 2;

        if (hasSearch || searchQuery.length === 0) {
            let CompanyOptions = await getCompanyOptionsBySearchUser(searchQuery);
            //const existingIds = this.state.addresses.map(a => a.id);
            CompanyOptions = unique(dto.__optionsCompany.concat(CompanyOptions));

            if (CompanyOptions.length) {
                setCompanies(CompanyOptions);
            }
        }
    }

    function unique(arr) {
        let result = [];
        let resId = [];

        for (let item of arr) {
            if (!resId.includes(item.key)) {
                resId.push(item.key);
                result.push(item);
            }
        }

        return result;
    }

    async function handleClientClick() {
    }

    const updateCost = (value) => {
        if (value.includes('.')) {
            const [v1, v2] = value.split('.');

            return `${v1}.${v2.substr(0, 2)}`
        }

        return value
    };

    const handleChangeTemperatureCondition = (e, {value, name}) => {
        setTemperatureCondition(prev => ({
            ...prev,
            [name]: restrictionInt(value),
        }));
    };

    const minCapacity = getMinCapacity(dto, cargoRestrictions);
    const capacityOptions = getCapacityOptions().filter(o => o.value >= minCapacity);


    const resetId = i => {
        return {...i, id: 0};
    };

    const wbPoint = useMemo(() => {
        return dto.pointsLoading.find(pl => pl.crossDockWithActivity || pl.avisationByEmail) || {}
    }, [dto]);

    function doPointReplace(point) {
        // copying stuff
        point.arrivalDatePlan = wbPoint.arrivalDatePlan;
        point.arrivalDateFact = wbPoint.arrivalDateFact;
        point.arrivalTimeslotPlan = wbPoint.arrivalTimeslotPlan;
        point.arrivalTimeslotFact = wbPoint.arrivalTimeslotFact;
        point.contact = wbPoint.contact;
        point.comment = wbPoint.comment;

        point.loadUnitSets = (wbPoint.loadUnitSets || []).map(resetId);
        point.loadUnitsToOrderMappings = (wbPoint.loadUnitsToOrderMappings || []).map(resetId);
        point.services = (wbPoint.services || []).map(resetId);

        pointReplace(point, !(point.crossDockWithActivity || point.avisationByEmail) ? {pickupType: PickupTypeEnum.NOT_SPECIFIED} : null);
    }

    function pointReplace(newPoint, scope) {
        const index = dto.pointsLoading.findIndex(pl => pl.crossDockWithActivity || pl.avisationByEmail);

        const updateScope = getPointRemoveUpdateScope(index);

        updateScope["pointsLoading"] = [newPoint, ...updateScope["pointsLoading"]];

        if (newPoint.hubLoading) {

            updateScope.hubLoading = true;
        }

        updateData({...updateScope, ...scope});
    }

    function getPointRemoveUpdateScope(index) {

        const pointsCloned = [...dto.pointsLoading];

        const deletable = pointsCloned.splice(index, 1)[0];

        const updateScope = {pointsLoading: pointsCloned};


        const hubLoading = deletable.isHub;

        if (hubLoading) {

            updateScope.hubLoading = false;

            //updateScope.needPickup = false;
        }

        const validPositions = dto.pointsLoading.filter(p => p.pointFromId !== deletable.id);

        if (validPositions.length !== dto.pointsLoading.length) {

            updateScope.loadUnitSets = [...validPositions];
        }


        return updateScope;
    }

    return (
        <>
            <Header style={{marginTop: '-1px'}}>
                {t('waybills_label_general-info')}
            </Header>
            <Form>
                <Form.Field required>
                    <label>{t('waybills_input_client')}</label>
                    <Dropdown
                        noResultsMessage={t('base_label_no_results')}
                        selection
                        search={searchTextAndDescription}
                        value={dto.clientId || ''}
                        onSearchChange={handleClientSearchChange}
                        onChange={(e, {value}) => onChangeClientId(value)}
                        onClick={handleClientClick}
                        className="InputDropdown"
                        placeholder={t('waybills_input_client')}
                        fluid
                        closeOnChange
                        options={companies || []}
                    />
                </Form.Field>
                <Divider hidden/>
                {showField(WaybillFieldEnum.Provider, dto, fieldConfigs) && <Form.Field
                    control={InputDropdown}
                    label={t('waybills_input_provider')}
                    clearable
                    options={providersList}
                    placeholder={t('waybills_input_provider')}
                    value={dto.providerId}
                    onChange={providerId => updateData({providerId: providerId === '' ? null : providerId})}
                    disabled={isClient}
                />}
                <Form.Field required control={InputDropdown}
                            label={t('waybills_input_type-application')}
                            style={stl1}
                            options={getWaybillTypeOptions().filter(({value}) =>
                                showValue(WaybillFieldEnum.ShippingType, value, dto, fieldConfigs)
                            )}
                            placeholder={t('waybills_input_type-application')}
                            value={dto.shippingType}
                            onChange={shippingType => updateData({shippingType})}
                            disabled={isClient || dto.shippingType === WaybillTypeEnum.Return}>
                </Form.Field>
                <Divider hidden/>
                {isRail && <Form.Field required control={InputDropdown}
                                       label={t('waybills_input_type-ts')}
                                       options={WaybillCarTypeOptions.filter(({value}) =>
                                           showValue(WaybillFieldEnum.CarType, value, dto, fieldConfigs)
                                       )}
                                       placeholder={t('waybills_input_type-ts')}
                                       value={dto.carType}
                                       onChange={carType => updateData({carType})}
                                       disabled={isClient}
                />}
                {(isFTL || isPlanReturn) && showField(WaybillFieldEnum.VehicleCapacity, dto, fieldConfigs) &&
                <Form.Field control={InputDropdown}
                            label={t('waybills_input_carrying-ts')}
                            options={capacityOptions.filter(({value}) =>
                                showValue(WaybillFieldEnum.VehicleCapacity, value, dto, fieldConfigs)
                            )}
                            clearable
                            placeholder={t('waybills_input_carrying-ts')}
                            value={dto.vehicleCapacity}
                            onChange={vehicleCapacity => updateData({vehicleCapacity})}
                            disabled={isClient}
                />
                }
                {(isFTL || isPlanReturn) && showField(WaybillFieldEnum.BodyCapacity, dto, fieldConfigs) &&
                <Form.Field
                    control={InputDropdown}
                    label={t('waybills_input_body-volume')}
                    options={BodyCapacityOptions.filter(({value}) =>
                        showValue(WaybillFieldEnum.BodyCapacity, value, dto, fieldConfigs)
                    )}
                    clearable
                    placeholder={t('waybills_input_body-volume')}
                    value={dto.bodyCapacity}
                    onChange={bodyCapacity => updateData({bodyCapacity})}
                    disabled={isClient}
                />
                }
                {!isRail && showField(WaybillFieldEnum.ShippingTempCondition, dto, fieldConfigs) && <Form.Field
                    required
                    control={InputDropdown}
                    label={t('waybills_input_temp-condition')}
                    options={getThermoOptions().filter(({value}) =>
                        showValue(WaybillFieldEnum.ShippingTempCondition, value, dto, fieldConfigs)
                    )}
                    clearable
                    placeholder={t('waybills_input_temp-condition')}
                    value={dto.shippingTemperatureCondition}
                    onChange={shippingTemperatureCondition => updateData({shippingTemperatureCondition})}
                    disabled={isClient}
                />}
                {
                    Boolean((isFTL || isPlanReturn) && dto.shippingTemperatureCondition === 60) &&
                    <Form.Field>
                        <label>{t('waybills_label_temp-condition-client')}</label>
                        <Form.Group widths={2}>
                            <Form.Field required>
                                <label>{t('waybills_input_from')}</label>
                                <Input
                                    value={temperatureCondition ? temperatureCondition.from : ''}
                                    type="text" pattern="[0-9]*"
                                    name="from"
                                    onChange={handleChangeTemperatureCondition}
                                    onBlur={() => updateData({temperatureCondition})}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{t('waybills_input_to')}</label>
                                <Input
                                    value={temperatureCondition ? temperatureCondition.to : ''}
                                    name="to"
                                    type="text" pattern="[0-9]*"
                                    onChange={handleChangeTemperatureCondition}
                                    onBlur={() => updateData({temperatureCondition})}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form.Field>
                }
                <Divider hidden/>
                {showField(WaybillFieldEnum.CargoType, dto, fieldConfigs) && <Form.Field
                    required
                    control={InputDropdown}
                    label={t('waybills_input_cargo-type')}
                    options={CargoTypeOptions.filter(({value}) =>
                        showValue(WaybillFieldEnum.CargoType, value, dto, fieldConfigs)
                    )}
                    placeholder={t('waybills_input_product-type')}
                    value={dto.cargoType}
                    onChange={cargoType => updateData({cargoType})}
                    disabled={isClient}
                />}
                <Form.Input
                    required
                    label={t('waybills_input_sum')}
                    placeholder={t('waybills_input_sum')}
                    value={dto.cargoCost || ''}
                    onChange={e => updateData({cargoCost: updateCost(e.target.value.replace(/[^\d,.]/g, '').replace(',', '.'))})}
                    disabled={isClient}
                />
                {canChangePickupType && (
                    <Form.Field disabled={isClient}>
                        <label>
                            {t('waybills_label_delivery-method')}
                        </label>
                        <InputDropdown
                            disabled={
                                !(
                                    (dto.avisationStatus === WaybillAvisationStatus.REQUESTED
                                        || dto.avisationStatus === WaybillAvisationStatus.NONE) &&
                                    dto.status !== WaybillStatusEnum.CANCELLED
                                )
                            }
                            options={PickupTypeOptions}
                            placeholder={t('waybills_placeholder_delivery-method')}
                            value={dto.pickupType}
                            onChange={pickupType => {
                                if (dto.pickupType && dto.pickupType !== pickupType) {
                                    setIsChangePickupType(true);
                                }
                                updateData({pickupType})
                            }}
                        />
                    </Form.Field>
                )}
            </Form>
            <WbPointAddForm
                profile="pointsLoading"
                isEdit
                wbId={dto.id}
                wbClientId={dto.clientId}
                pointsExistingIds={[]}
                wbPointId={wbPoint.id}
                submit={doPointReplace}
                hideNew={dto.status !== WaybillStatusEnum.DRAFT}
                value={wbPoint}
                open={isFormOpen}
                onClose={() => setIsFormOpen(false)}
            />
            <Modal
                closeOnDimmerClick={false}
                size="small"
                dimmer="inverted"
                closeIcon
                open={isChangePickupType}
                onClose={() => setIsChangePickupType(false)}>
                <Modal.Header>
                    {t('waybills_label_change-delivery-method')}
                </Modal.Header>
                <Modal.Content>
                    <div>{t('waybills_label_changed-delivery-method')}</div>
                    <div>{t('waybills_label_question-change-address')}<b>{wbPoint.address}?</b></div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setIsChangePickupType(false)}>
                        {t('base_btn_cancel')}
                    </Button>
                    <Button primary onClick={() => {
                        setIsFormOpen(true);
                        setIsChangePickupType(false);
                    }}>
                        {t('waybills_btn_change-address')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}
