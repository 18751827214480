import React from 'react';
import { Icon } from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';

export default function RowDeleteAction({row, rows, setRows, removeAsync}) {
    const {t} = useTranslation();
	function remove() {
		removeAsync(row);

		const index = rows.indexOf(row);
		const rowsUpdated = [...rows];
		rowsUpdated.splice(index, 1);
		setRows(rowsUpdated);
	}

	return (
		<Icon fitted link title={t("users_label-delete-setting")} name="delete" onClick={remove} size="large" />
	);
};