import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, Menu, Segment } from 'semantic-ui-react';
import { withTranslation } from "react-i18next";
import O from '../../../../components/Option';
import { PointTimeslot } from '../../../../api/model/PointTimeslot';
import { PointDays } from '../../../../api/model/PointDays';

const WeekDisplayMap = {
    mon: "base_enum_mon",
    tue: "base_enum_tue",
    wed: "base_enum_wed",
    thu: "base_enum_thu",
    fri: "base_enum_fri",
    sat: "base_enum_sat",
    sun: "base_enum_sun"
};

const styleInputHours = {
    maxWidth: '80px'
};
const styleLabelHours = {
    fontSize: '14px'

};

const styleDayOff = {
    color: 'red'
};
const styleDayOn = {
    color: 'inherit'
};

class PointFormDays extends React.Component {
    
    static propTypes = {
        days: PropTypes.shape(PointDays),
        onChange: PropTypes.func.isRequired
    }

    state = {
        activeDayKey: 'mon'
    }

    daysOptions = Object.keys(WeekDisplayMap)
        .map(k => new O(k, WeekDisplayMap[k]));

    hoursTypeOptions = [
        new O(0, "points_text_closed"),
        new O(1, "points_text_24_hours"),
        new O(2, "points_text_hours_of_work"),
    ];

    handleTabClick(key, e, item) {
        this.setState({ activeDayKey: key });
    }

    updateDayType(activeDayKey, e, item) {
        
        if (item.checked) {
            const daysCloned = { ...(this.props.days || new PointDays()) };

            // eslint-disable-next-line default-case
            switch (item.value) {
                case 0:
                    daysCloned[activeDayKey] = null;
                    break;
                case 1:
                    daysCloned[activeDayKey] = new PointTimeslot("00:00");
                    break;
                case 2:
                    daysCloned[activeDayKey] = new PointTimeslot("09:00-23:00");
                    break;
            }

            this.props.onChange(daysCloned); // this would fire one global onChange!
        }
    }

    updateHours(activeDayKey, hoursKey, e) {
        
        const daysCloned = { ...(this.props.days || new PointDays()) };
        const day = daysCloned[activeDayKey] || (daysCloned[activeDayKey] = new PointTimeslot());
        day[hoursKey] = e.target.value || null;

        this.props.onChange(daysCloned); // this would fire one global onChange!
    }

    render() {
        const { t } = this.props;

        const { days = new PointDays() } = this.props;

        const { activeDayKey } = this.state;

        const activeDay = days[activeDayKey];

        const activeDayStatusKey = activeDay
            ? activeDay.to
                ? 2
                : 1
            : 0;
        
        const hoursDisabled = activeDayStatusKey < 2;

        const containerStyle = {
            marginLeft: "1px",
            marginRight: "1px"
        };

        return (
            <div style={containerStyle}>
                <Form.Field label={t("points_label_work_hours")} className="m-0" />
                <Menu attached="top" widths={7} className="m-0">
                    {this.daysOptions
                        .map(o =>
                            <Menu.Item
                                style={days[o.key] ? styleDayOn : styleDayOff }
                                key={o.key}
                                name={t(o.text)}
                                active={activeDayKey === o.key}
                                onClick={this.handleTabClick.bind(this, o.key)}
                            />
                        )
                    }
                </Menu>
                <Segment attached="bottom">
                    <Form.Group inline className="m-b-0" >
                        {
                            this.hoursTypeOptions.map(hours =>
                                <Form.Radio
                                    key={hours.key}
                                    label={t(hours.text).t}
                                    value={hours.key}
                                    checked={activeDayStatusKey === hours.key}
                                    onChange={this.updateDayType.bind(this, activeDayKey)}
                                />)
                        }
                        <Form.Field inline>
                            <label style={styleLabelHours}>&nbsp;{t("points_label_from")}</label>
                            <Input
                                disabled={hoursDisabled}
                                placeholder={t("points_label_from")}
                                style={styleInputHours}
                                value={(activeDay && activeDay.from) || ''}
                                onChange={this.updateHours.bind(this, activeDayKey, "from")}
                            />
                        </Form.Field>
                        <Form.Field inline>
                            <label style={styleLabelHours}>{t("points_label_to")}</label>
                            <Input
                                disabled={hoursDisabled}
                                placeholder={t("points_label_to")}
                                style={styleInputHours}
                                value={(activeDay && activeDay.to) || ''}
                                onChange={this.updateHours.bind(this, activeDayKey, "to")}
                            />
                        </Form.Field>
                    </Form.Group>
                </Segment>
            </div>
        );
    }
}

export default withTranslation()(PointFormDays);