import React, {useEffect, useState} from 'react';
import {fieldsTypeEnum} from "../../api/calculatingFieldsRules";
import InputDropdown from "../inputs/InputDropdown";
import {Input} from "semantic-ui-react";
import {restrictionFloat, restrictionInt} from "../../services/utils";
import InputDate from "../inputs/InputDate";
import { useTranslation } from 'react-i18next';
import O from '../Option';
import {normalizeTime} from "../../services/normalizeTime";

const
    Fields = ({ value, onChange, indexRule, multiple, disabled, fieldOptions = {}, isConstructor, itemKey }) => {
    
    const {values: source = [], type, maxLength, maxFractionalLength, maxValue, minValue} = (fieldOptions || {});

        const { t } = useTranslation();

        let [options, setOptions] = useState( value ? value.map(value => ({text: value, value})) : []);

        useEffect(() => {
            setOptions(value ? value.map(value => ({text: value, value})) : []);
        }, [itemKey]);

        const onAdd = (e, {value}) => {
            setOptions(options => [...options, {text: value, value}])
        };

    switch (type) {
        case fieldsTypeEnum.String:

            if (isConstructor) {
                return <InputDropdown
                    placeholder={t("waybillautocomplte_placeholder_value")}
                    options={options}
                    search
                    selection
                    fluid
                    multiple
                    handleAdd={onAdd}
                    value={value}
                    maxLength={maxLength}
                    disabled={disabled}
                    onChange={val => onChange(val, 'values', indexRule)}
                />;
            }
            return <Input
                placeholder={t("waybillautocomplte_placeholder_value")}
                value={value && value[0] ? value[0] : ''}
                maxLength={maxLength}
                disabled={disabled}
                onChange={(e, {value: val}) => onChange([val], 'values', indexRule)}
            />;
        case fieldsTypeEnum.Int:
            return <Input
                placeholder={t("waybillautocomplte_placeholder_value")}
                value={value && value[0] ? value[0] : ''}
                maxLength={maxLength}
                disabled={disabled}
                onChange={(e, {value: val}) => onChange([restrictionInt(val, minValue, maxValue, value && value[0])], 'values', indexRule)}
            />;
        case fieldsTypeEnum.Double:
            return <Input
                placeholder={t("waybillautocomplte_placeholder_value")}
                maxLength={maxLength}
                value={value && value[0] ? value[0] : ''}
                disabled={disabled}
                onChange={(e, {value: val}) => onChange([restrictionFloat(val, maxFractionalLength, minValue, maxValue, value && value[0])], 'values', indexRule)}
            />;
        case fieldsTypeEnum.Date:
            return <InputDate
                icon="calendar outline"
                placeholder={t("waybillautocomplte_placeholder_value")}
                position="bottom center"
                disabled={disabled}
                onChange={val => onChange([val], 'values', indexRule)}
                value={value && value[0]}
            />;
        case fieldsTypeEnum.Time:
            return <Input
                fluid
                placeholder={t("waybillautocomplte_placeholder_value")}
                position="bottom center"
                value={value && value[0]}
                disabled={disabled}
                onChange={e => onChange([normalizeTime(e.target.value)], 'values', indexRule)}
            />;
        case fieldsTypeEnum.Enum:
            return <InputDropdown
                options={source.map(item => new O(item.key, item.name))}
                value={multiple ? value : (value && value[0])}
                multiple={multiple}
                placeholder={t("waybillautocomplte_placeholder_value")}
                disabled={disabled}
                onChange={(val) => onChange(multiple ? val : [val], 'values', indexRule)}
            />;
        default: return <Input
            placeholder={t("waybillautocomplte_placeholder_value")}
            value={value && value[0] ? value[0] : ''}
            disabled={disabled}
            onChange={(e, {value: val}) => onChange([val], 'values', indexRule)}
        />
    }
};

export default Fields;
