import React from 'react';
import {Container, Label} from 'semantic-ui-react';
import {companyCreate, getCompanies, getCompaniesBySearch} from '../../../api/companies';
import {CompanyStatusOptions} from '../../../api/model/Company';
import AppToolbarButton from '../../../components/buttons/AppToolbarButton';
import TableSearch from '../../../components/miscs/TableSearch';
import O from '../../../components/Option';
import AppTable from "../../../components/tables/AppTable";
import AppTableHeader from "../../../components/tables/AppTableHeader";
import AppTableToolbar from '../../../components/tables/AppTableToolbar';
import {LinkInfo} from '../../../layout/navbar/Navbar';
import Page from '../../../layout/page/Page';
import {ContextNavi, ContextUser} from '../../../services/context';
import CompanyFormNew from './CompanyFormNew';
import UserPermissions from "../../../api/model/UserPermissions";
import {withTranslation} from "react-i18next";

const headers = [
    //new AppTableHeader("id", "Search Name"),
    new AppTableHeader("name", 'users_grid_title', 6),
    new AppTableHeader("statusId", 'users_grid_status'),
    new AppTableHeader("sap", 'users_grid_sap'),
    new AppTableHeader("inn", 'users_grid_inn'),
    new AppTableHeader("usersCount", 'users_grid_user-title'),
    //new AppTableHeader("postAddress", "Адрес"),
];

class Companies extends Page {
    static contextType = ContextNavi;

    componentWillMount() {
        if (this.context.setItems) {
            this.context.setItems([
                new LinkInfo("users_label_clients", "/companies"),
            ]);
        }
    }

    async fetchData() {
        this.setState({rows: await getCompanies()});
    }

    async create(data) {
        try {
            await companyCreate(data);
            await this.fetchData();
        } catch (error) {
            // there can be validation errors
            return false;
        }
    }

    delItem(id) {
        alert('deleting item: ' + id);
    }

    getCompanyStatusColor(statusId) {
        switch (statusId) {
            case 0:
                return "blue";
            case 1:
                return null;
            default:
                return statusId;
        }
    }

    renderItem(item, key) {
        const val = item[key];
        const {t} = this.props;
        switch (key) {
            case 'statusId':
                const option = CompanyStatusOptions.find(o => o.key === val);

                return <Label
                    key={key}
                    color={this.getCompanyStatusColor(val)}>
                    {option && t(option.text)}
                </Label>;

            default:
                return val;
        }
    }

    async handleSearch(searchKey, searchValue) {
        const {t} = this.props;
        let rows;
        let hasSearch = false;

        if (searchValue) {

            if (searchValue.length < 3) {
                alert(t('users_label_error-length'));
                return;
            }

            rows = await getCompaniesBySearch(searchKey, searchValue);
            hasSearch = true;
        } else {
            rows = await getCompanies();
        }

        this.setState({rows, hasSearch});
    }

    handleItemDoubleClick(id) {
        const {t} = this.props;
        throw new Error(t('users_label_error-functional'));
    }

    renderData() {
        const {t} = this.props;
        return (
            <div className="bgn-white h-100">
                <Container className="h-100">
                    <div className="flex-col h-100">
                        <div style={{minHeight: '53px'}}>
                            <AppTableToolbar>
                                <AppTableToolbar.Left>
                                    <TableSearch
                                        setState={this.setState.bind(this)}
                                        handleSearch={this.handleSearch.bind(this)}
                                        hasSearch={this.state.hasSearch}
                                        searchKey={this.state.searchKey}
                                        options={[
                                            new O("name", t("users_grid_title")),
                                            new O("inn", t("users_grid_inn")),
                                        ]}
                                    />
                                </AppTableToolbar.Left>
                                <AppTableToolbar.Right>
                                    <ContextUser.Consumer>
                                        {
                                            user => {
                                                if (user.current.permissions.includes(UserPermissions.CREATING_COMPANIES)) {
                                                    return <CompanyFormNew fetchData={this.fetchData.bind(this)}>
                                                        <AppToolbarButton
                                                            icon="plus">{t('users_label_add-company')}</AppToolbarButton>
                                                    </CompanyFormNew>
                                                }
                                                return null;
                                            }
                                        }
                                    </ContextUser.Consumer>
                                </AppTableToolbar.Right>
                            </AppTableToolbar>
                        </div>
                        <div className="flex-grow scrollable-v">
                            <AppTable
                                headers={headers}
                                rows={this.state.rows}
                                onRowClick={c => this.props.history.push(`/admin/companies/${c.fmid}/props`)}
                                renderItem={this.renderItem.bind(this)}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withTranslation()(Companies);
