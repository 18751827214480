import {debounce} from 'awesome-debounce-promise';
import React, {useContext, useEffect, useState} from 'react';
import {Grid, GridColumn, Icon, Segment, Step} from 'semantic-ui-react';
import Point, {PointStatusEnum} from '../../../../api/model/Point';
import PointAddress from '../../../../api/model/PointAddress';
import {getPointById, pointUpdate} from '../../../../api/points';
import {getPointClientById} from '../../../../api/pointsClient';
import { useTranslation } from "react-i18next";
import {LinkInfo} from '../../../../layout/navbar/Navbar';
import Shield from '../../../../layout/page/shield/Shield';
import {ContextFooter, ContextNavi} from '../../../../services/context';
import {StepData} from "../../../waybills/wbView/StepInfo";
import './PointView.css';
import PointViewHeader from './PointViewHeader';
import PointViewMap from './PointViewMap';
import PointViewAddr from "./PointViewTabAddr";
import PointViewAddrParams from './PointViewTabAddrParams';
import PointViewComments from './PointViewTabComments';

const saveState = debounce(pt => pointUpdate(pt), 1200);

export function PointView({ match, history, isClient }) {

    const { t } = useTranslation();

    const [point, setPoint] = useState(new Point());
    const [isLoading, setIsLoading] = useState(true);

    const contextNavi = useContext(ContextNavi);
    const contextFooter = useContext(ContextFooter);

    const tabKeyActive = match.params.tab;
    const companyId = match.params.companyId;

    const styleNoBorderYellow = {
        borderTop: '2px solid #fbbd08',
    };

    async function fetchData() {
        const pointId = match.params.id;
        const point = companyId
            ? await getPointClientById(pointId, companyId)
            : await getPointById(pointId);

        setPoint(point);
        setIsLoading(false);

        contextNavi.setItems([
            new LinkInfo("points_label_address", `${isClient ? '' : '/admin'}/points`),
            new LinkInfo(point.address.value || new PointAddress(point.address).toString(), `${isClient ? '' : '/admin'}/points/${point.id}`),
        ]);
    }

    function backToList() {
        const url = isClient ? '/points' : '/admin/points';
        history.push(url);
    }

    function updatePoint(pointChаnged, save = true) {
        setPoint(pointChаnged);
        save && saveState(pointChаnged);
    }

    let steps = [
        new StepData(() => <PointViewAddrParams
            point={point}
            companyId={companyId}
            updatePoint={updatePoint}
            isClient={isClient}
            />,
            "params",
            t("points_text_company_and_parameters"),
            "info"
        ),
        new StepData(() => <PointViewComments
            isClient={isClient}
            point={point}
            updatePoint={(point) => setPoint(point)} />
            ,
            "comments",
            t("points_text_messages"),
            "commenting"
        )
    ];

    if (!isClient || point.statusId === PointStatusEnum.NEEDS_CORRECTION) {
        steps = [
            new StepData(() => <PointViewAddr point={point} updatePoint={updatePoint} />, "location", t("points_text_location"), "map marker alternate"),
            ...steps
        ];
    }

    const stepActive = steps.find(s => s.index === tabKeyActive);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Shield loading={isLoading} loadingOver={point.id}>
            <Grid columns={2} className="m-t-0 h-100">
                <Grid.Row stretched className="p-t-0 p-b-0 m-t-1-negative h-100">
                    <Grid.Column className="p-b-0 p-r-0 m-r-1-negative" style={{ width: "16%" }}>
                        <Step.Group fluid vertical size='tiny' className="m-b-0 steps-grey">
                            <div className="step-wrapper">
                                {steps.map((s) => (
                                    <Step
                                        key={s.index}
                                        active={tabKeyActive === s.index}
                                        link
                                        onClick={() => {
                                            const url = isClient ?
                                                `/points/${point.id}/company/${companyId}/${s.index}` :
                                                `/admin/points/${point.id}/${s.index}`;
                                            history.push(url);
                                        }}
                                    >
                                        <Icon name={s.icon} />
                                        <Step.Content>
                                            <Step.Title>{t(s.text)}</Step.Title>
                                        </Step.Content>
                                    </Step>
                                ))}
                            </div>
                        </Step.Group>
                    </Grid.Column>
                    <Grid.Column className="editor-rightcol p-l-0 h-100" style={{width: "84%"}}>
                        <Segment className="h-100 p-b-0 flex-col" style={{
                            marginRight: '25px',
                            paddingLeft: "20px",
                            paddingRight: "15px",
                            }}
                        >
                            <PointViewHeader
                                companyId={companyId}
                                isClient={isClient}
                                point={point}
                                setLoading={setIsLoading}
                                fetchData={fetchData}
                                backToList={backToList}
                            />

                            <div className="scrollable-v m-t-10 p-t-15 h-100 flex-grow" style={styleNoBorderYellow}>
                                <Grid className="h-100">
                                    <GridColumn width={15}>
                                        {stepActive.component()}
                                    </GridColumn>
                                </Grid>
                            </div>

                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Shield >
    );
}
