import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import { getUserLinks } from '../../api/users';
import { ContextNavi } from '../../services/context';
import './Navbar.css';
import LanguageSelector from './NavbarLanguageSelector';
import NavbarPath from './NavbarPath';
import UserContext from './NavbarUserContext';
import { withTranslation } from 'react-i18next';

export class LinkInfo {
    constructor(name, url, accessKey, icon) {
        /** @type {string} */
        this.name = name;

        this.url = url;
        this.accessKey = accessKey;
        this.icon = icon;
    }
}

class Navbar extends Component {

    state = {
        sidebarVisible: false,
        items: [new LinkInfo("", "/")],
        setItems: (items) => this.setItems(items),
        links:[]
    };

    setItems = (items) => {
        const {t} = this.props;
        this.setState({ items });
        if (items && items.length)
            document.title = "FMCP " +
                (typeof items[items.length - 1].name === 'object' ?
                    t(items[items.length - 1].name.key, {index: t(items[items.length - 1].name.index)})
                    : t(items[items.length - 1].name));
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { items } = this.state;
        if (this.props.t !== prevProps.t) {
            this.setItems(items)
        }
    }

    handleItemClick = (e, { name }) => {
        this.props.history.push(name);
    }

    handleSidebarClick = () => {
        this.setState({ sidebarVisible: !this.state.sidebarVisible });
    }

    handleRouteClick = (link) => {
        this.setState({ sidebarVisible: false });
        this.props.history.push(link.url);
    };

    componentWillMount() {
        if (this.context) {
            this.context.setItems = this.state.setItems.bind(this);
        }

        const userToken = this.props.user.token;
        getUserLinks(userToken).then(links => this.setState({ links }));
    }

    componentWillUnmount() {
        if (this.context) {
            this.context.setItems = null;
        }
    }

    render() {
        const { t } = this.props;

        const { items } = this.state;

        return (
            <>
                <Menu size='small' borderless className="app-header">
                    <Menu.Item style={{ paddingLeft: '20px' }}>
                        <NavbarPath items={items} />
                    </Menu.Item>
                    <Menu.Menu position="right">
                        <Menu.Item key="/user/profile" name="/user/profile">
                            <UserContext />
                        </Menu.Item>
                        <LanguageSelector />
                        <Menu.Item
                            name="/login"
                            key="/login"
                            title={t('base_btn_exit')}
                            onClick={this.handleItemClick}> <Icon name='sign-out' fitted />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>

            </>
        );
    }
}

export default withRouter(withTranslation()(Navbar));

Navbar.contextType = ContextNavi;

