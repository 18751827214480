import React from 'react';
import PropTypes from 'prop-types';
import { Popup, Menu } from 'semantic-ui-react';
import { filterOptions } from './models/filterOptions';
import {withTranslation} from "react-i18next";

class FilterMenuCondition extends React.Component {

    state = {
        conditionMenuIsOpen: false,
        conditionActive: this.props.condition,
        conditionActiveIcon: filterOptions.find(o => o.key === this.props.condition).icon,
    };

    handleConditionMenuOpenEvent(event, popup) {
        this.setState({
            conditionMenuIsOpen: true
        });
    }

    handleConditionMenuCloseEvent(event, popup) {
        if (event.type === 'click') {
            this.setState({
                conditionMenuIsOpen: false
            });
        }
    }

    handleConditionMenuItemClick = (e, o) => {
        let icon = filterOptions.filter(i => i.key === o.name)[0].icon;
        this.setState({
            conditionMenuIsOpen: false,
            conditionActive: o.name,
            conditionActiveIcon: icon
        }, () => this.props.onChange(this.state.conditionActive));
    };

    render() {

        const { t } = this.props;
        const {
            conditionActive,
            conditionActiveIcon,
            conditionMenuIsOpen,
        } = this.state;

        const conditionMenuTrigger = (<div>{conditionActiveIcon}</div>);

        const conditionMenu = (
            <Menu secondary vertical>
                {filterOptions.map(o => (
                    <Menu.Item
                        key={o.key}
                        name={o.key}
                        active={conditionActive === o.key}
                        onClick={this.handleConditionMenuItemClick.bind(this)}
                    >
                        {o.icon}&nbsp;{t(o.text)}
                    </Menu.Item>
                ))}
            </Menu>
        );

        return (
            <Popup
                trigger={conditionMenuTrigger}
                content={conditionMenu}
                on='click'
                open={conditionMenuIsOpen}
                onOpen={this.handleConditionMenuOpenEvent.bind(this)}
                onClose={this.handleConditionMenuCloseEvent.bind(this)}
                position='bottom left'
            />
        );
    }
}

FilterMenuCondition.propTypes = {
    condition: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default withTranslation()(FilterMenuCondition);