// eslint-disable-next-line no-unused-vars
import { RequestTypeEnum } from "./Enums";
// eslint-disable-next-line no-unused-vars
import { PackTypeEnum } from "./Enums";
import Persistable from "./Persistable";

export default class LoadUnitSet extends Persistable {
    
    /** @param {LoadUnitSet} dto */
    constructor(dto) {
        super();

        /** @type {string} */
        this.id = dto.id; // || this.getId2(); fe only

        /** @type {string} */
        this.pointFromId = dto.pointFromId;  // is used for export

        /** @enum {PackTypeEnum} */
        this.loadUnitType = dto.loadUnitType;

        /** @type {number} */
        this.loadUnitsCount = dto.loadUnitsCount;

        /** @type {number[] | undefined} */
        this.loadUnitsSSCCNos = dto.loadUnitsSSCCNos;

        /** @type {number} */
        this.loadTotalWeigthBruttoKg = dto.loadTotalWeigthBruttoKg;

        /** @type {number} */
        this.loadTotalVolumeM3 = dto.loadTotalVolumeM3;
    }
}