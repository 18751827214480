import React, {useContext, useEffect, useState} from 'react';
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi} from "../../services/context";
import useReactRouter from "use-react-router";
import {changeActiveBanner, getAll} from "../../api/banners";
import {RegistrieStatusOptions} from "../../api/model/registries";
import {Button, Checkbox, Grid, Icon, Label, Menu, Table} from "semantic-ui-react";
import Shield from "../../layout/page/shield/Shield";
import CreateBannerModal from "./createBannerModal";
import moment from "moment";
import {useTranslation} from 'react-i18next';

const noRows = (text) => (
    <div className="no-rows-wrapper">
        <div className="no-rows">{text}</div>
    </div>
);

const headers = [
    {
        key: 'createdOn',
        text: 'banners_grid_created'
    },
    {
        key: 'title',
        text: 'banners_grid_topic'
    },
    {
        key: 'period',
        text: 'banners_grid_show-period'
    },
    {
        key: 'text',
        text: 'banners_grid_text-banner'
    },
    {
        key: 'buttonText',
        text: 'banners_grid_text-btn'
    },
    {
        key: 'url',
        text: 'banners_grid_url'
    },
    {
        key: 'clicks',
        text: 'banners_grid_click-number'
    },
    {
        key: 'isActive',
        text: 'banners_grid_active'
    },
];

const BannersList = () => {
    const contextNavi = useContext(ContextNavi);
    const {t} = useTranslation();

    useEffect(() => {
        contextNavi.setItems([new LinkInfo("banners_label_link", "/banners")]);
    }, []);

    let [rows, setRows] = useState([]);
    let [loaded, setLoaded] = useState(false);
    let [loading, setLoading] = useState(true);
    let [isOpenCreateModal, setIsOpenCreateModal] = useState({
        open: false
    });


    const fetchData = async (noLoader) => {
        setLoading(!noLoader);
        try {
            const result = await getAll();

            setRows(result);
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const getCell = (key, val, id) => {
        switch (key) {
            case "createdOn":
                if (!val) {
                    return null;
                }

                const stillUtc = moment.utc(val).toDate();
                const local = moment(stillUtc)
                    .local()
                    .format('DD.MM.YYYY HH:mm');

                return local;
            case "text":
            case "title":
            case "buttonText":
                return <div style={{
                    width: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}><span title={val && val.toString()}>{val}</span></div>
            case "isActive":
                return <Checkbox toggle style={{cursor: 'pointer'}} checked={val}
                                 onChange={(e, {checked}) => handleChangeActive(id, checked)}/>;

            default:
                return val;
        }
    };

    const handleChangeActive = async (id, val) => {
        await changeActiveBanner(id, val);
        fetchData();
    };

    const handleEdit = (id) => {
        setIsOpenCreateModal({
            open: true,
            id,
        })
    };

    const handleNew = () => {
        setIsOpenCreateModal({
            open: true,
        })
    };

    const styleDisabledHeader = {
        background: '#f9fafb',
        cursor: 'default'
    };

    return (
        <Shield loading={loading} loadingOver={loaded}>
            <Menu className="waybills-toolbar shd-inset" style={{marginBottom: '0'}} size='small' borderless>
                <Menu.Menu position='right'>
                    <Menu.Item onClick={handleNew}>
                        <Icon name="plus"/> {t('banners_input_add-banner')}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <div className="table-wrapper-grid table-wrapper-grid-130 table-banners">
                <Table celled sortable singleLine>
                    <Table.Header className="table-header-fixed">
                        <Table.Row>
                            {headers.map(item => (
                                <Table.HeaderCell
                                    className="table-select-cell table-header-fixed__title"
                                    key={item.key}
                                    style={styleDisabledHeader}
                                >
                                    {t(item.text)}
                                </Table.HeaderCell>
                            ))}
                            <Table.HeaderCell
                                className="table-select-cell table-banners-header-fixed__title-actions"
                                style={styleDisabledHeader}
                            >
                                {t('banners_input_actions')}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {
                        rows.length
                            ? <Table.Body>
                                {
                                    rows.map(row => (
                                        <Table.Row
                                            key={row.id}
                                        >
                                            {
                                                headers.map(column => (
                                                    <Table.Cell key={`${column.key}_${row.id}`}>
                                                        {
                                                            getCell(column.key, row[column.key], row.id)
                                                        }
                                                    </Table.Cell>
                                                ))
                                            }
                                            <Table.Cell className="table-banners-action-sticky">
                                                <Icon
                                                    name="pencil"
                                                    style={{cursor: 'pointer'}}
                                                    link
                                                    title={t("base_btn_edit")}
                                                    size="large"
                                                    color="blue"
                                                    onClick={() => handleEdit(row.id)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                            : <div className="table-empty"/>
                    }

                </Table>

                {!rows.length && noRows(t('banners_label_no-rows'))}
            </div>
            <CreateBannerModal {...isOpenCreateModal} onClose={() => setIsOpenCreateModal({open: false})}
                               fetchData={fetchData}/>
        </Shield>
    )
};

export default BannersList;
