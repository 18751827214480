import React, { useState } from 'react';
import UserForm from './UserForm';
import { postUser } from '../../../api/users';
import { Modal, Button } from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';

export default function UserFormNew({
	children,
	fetchData,
	companyOptions = [],
	roleOptions = []
}) {

    const {t} = useTranslation();
    const centered = true;
    const size = "small";

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const isValid = validateEmail(data.email) && data.roleId && data.firstName;

	function validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	  }

    async function submit() {
        try {
            setLoading(true);
			await postUser(data);
			fetchData && fetchData();
        } catch(error) {
            // there can be validation errors
            return false;
        } finally {
            setLoading(false);
            onCloseModal();
		}
	}

	function update(prop) {
        const userUpdated = { ...data, ...prop };

        //setErrors([]);
        setData(userUpdated);
    }

    function onCloseModal() {
        setData({});
        setOpen(false)
    }

    return (
        <Modal
            trigger={children}
            centered={centered}
            dimmer="inverted"
            size={size}
            closeIcon
            open={open}
            onOpen={() => setOpen(true)}
            onClose={onCloseModal}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header>{t("users_label_new-user")}</Modal.Header>
            <Modal.Content>
				<UserForm
					data={data}
					setData={setData}
					isEdit={false}
					update={update}
					companyOptions={companyOptions}
                    profileOptions={roleOptions}
				/>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={onCloseModal}
                    disabled={loading}>{t("base_btn_cancel")}</Button>
                <Button
                    positive
                    disabled={loading || !isValid}
                    onClick={submit} >{t("base_btn_save")}</Button>
            </Modal.Actions>
		</Modal>
	);
}
