import React, {useState, useEffect} from "react";
import {Form, Input, Popup, Grid, Icon, Button} from "semantic-ui-react";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import './timeslot.css';
import { useTranslation } from 'react-i18next';

const Timeslot = ({name, placeholder, className, isDisabled, value = {}, visitDate, valuesList = [], showPopup, isShow, onChange, loadTimeSlot, errors}) => {
    const { t } = useTranslation();

    const [valueInput, setValueInput] = useState("");
    const [valueResult, setValueResult] = useState(null);
    const [val, setValue] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!value) {
            setValueInput("");
            setValueResult(null);

            return;
        }

        if (value.start && value.end && visitDate) {
            setValueInput(`${value.start.slice(0,5)} - ${value.end.slice(0,5)}`);
            setValueResult(value);
        } else {
            setValueInput("");
            setValueResult(null);
        }

    }, [value]);

    const onClickTime = (e, {name, value}) => {
        //onChange && onChange(e, {name, value}
        setValue(value);
        setValueInput(`${value.start.slice(0,5)} - ${value.end.slice(0,5)}`);
        handleClose(e, {
            name, value: { ...value }
        })
    };

    const isSelected = (item) => {
        if (!val) return;
        let selected;
        let valueRes = val;
        delete valueRes.visitDate;

        if (valueInput === null) {
            return false;
        }

        selected = JSON.stringify(valueRes) === JSON.stringify(item);

        return selected;

    };

    const itemTimeClassName = (item) => {
        const selected = isSelected(item);
        const className = ["c-time-slot-list__item_time"];

        if (selected) {
            className.push("c-time-slot-list__item_time_selected");
        }
        return className.join(" ");
    };

    const getLabelClass = isDisabled ? "label-disabled" : null;

    const Content = () => {
        if (!visitDate) {
            return (
                (<Grid centered divided>
                    <Label className="avization-label"
                           color={"blue"}>{!visitDate ? t('waybills_label_no-date-selected') : ""}</Label>
                </Grid>)
            );
        }
        if (valuesList && valuesList.length > 0) {
            return (
                <Grid centered divided columns={valuesList.length} className={"c-time-slot-list"}>
                    {valuesList.map((item, key) => (
                        <Grid.Column key={key} textAlign={"center"} className={"c-time-slot-list__item"}>
                            <div className={"c-time-slot-list__item_date"}>
                                {item.date}
                            </div>
                            {item.timeSlots.length === 0 ?
                                <Grid className="avization-empty" stretched centered>
                                    <Grid.Column verticalAlign="middle">
                                        <Label className="avization-label" color={"blue"}>{t('waybills_label_no-data1')}</Label>
                                    </Grid.Column>
                                </Grid>
                                :
                                item.timeSlots.map((time, index) => (
                                    <Grid.Row key={index}
                                              className={itemTimeClassName({
                                                  date: item.date,
                                                  start: time.start,
                                                  end: time.end
                                              })}
                                              onClick={(e) => onClickTime(e, {
                                                  name, value: {
                                                      date: item.date,
                                                      start: time.start,
                                                      end: time.end
                                                  }
                                              })}>
                                        <Grid.Column style={{whiteSpace: "nowrap"}}>
                                            {time.start.slice(0, 5)} - {time.end.slice(0, 5)}
                                        </Grid.Column>
                                    </Grid.Row>
                                ))
                            }
                        </Grid.Column>
                    ))}
                </Grid>
            );
        } else {
            return (
                <Grid centered divided>
                    <Label className="avization-label" color={"blue"}>{t('waybills_label_no-data-temporarily')}</Label>
                </Grid>
            );
        }
    };

    const handleClose = (e, { name, value }) => {
        if (value.start && value.end) {
            onChange && onChange(e, {name, value });
        }
        showPopup(false);
        setValue(null);
    };

    const onClickIconClear = (e, {name, value}) => {
        e.stopPropagation();

        if (isOpen) setIsOpen(false);

        setValueInput("");
        setValueResult("");

        onChange && onChange(e, {name, value});
    };

    const loadTimes = (e, {name, value}) => {
        e.stopPropagation();

        loadTimeSlot(e, {name, value});
    };

    return (
        <Form.Field error={errors.includes('timeslot')}>
            <label className={getLabelClass}>{name.t}</label>
            <Popup
                trigger={
                    <div className={"c-time-slot"}>
                        <Input className={`${className} c-time-slot_input`}
                               readOnly
                               error={errors.includes('timeslot')}
                               disabled={isDisabled}
                               placeholder={placeholder}
                               value={valueInput}/>
                        {valueInput && <Icon name='delete' className={"c-time-slot__clear"}  onClick={(e) => { onClickIconClear(e, {name, value: null}); }}/>}
                    </div>}
                content={<Content/>}
                on={"click"}
                open={isShow}
                className={"c-time-slot__popup"}
                size={"large"}
                disabled={isDisabled}
                onClose={(e) => handleClose(e, {
                    name, value: { ...val, visitDate }
                })}
                onOpen={ (e) => loadTimes(e, {name, value: visitDate})}
                position={"bottom center"}
            />
        </Form.Field>
    );
};

export default Timeslot;
