import moment from 'moment';
import React from 'react';
import { Form, Header, Popup } from 'semantic-ui-react';
import { waybillPointDateCal } from '../../../api/waybills';
import { withTranslation } from 'react-i18next';

class WbPointCardTabsMainDateCalc extends React.Component {

    state = {
        open: false,
        loading: false,
        dates: [],
        error: null,
        selectedDate: null
    };

    handleDateChange(selectedDate) {
        const result = moment(selectedDate, "DD.MM.YYYY").toDate();

        this.setState({
            selectedDate,
            open: false
        }, () => this.props.onChange(result));
    }

    async handleOnOpen() {
        
        this.setState({ open: true, loading: true, dates: [] }, async () => {

            var data = await waybillPointDateCal(this.props.wbId, this.props.wbPointId);

            this.setState({ dates: data.dates, error: data.error, loading: false });
        });
    }
    
    submit() {

        const result = moment(this.state.selectedDate, "DD.MM.YYYY").toDate();

        this.props.onChange(result);

        this.setState({ open: false, loading: false }); //, () => 
    }


    render() {

        const { t } = this.props;

        const { loading, dates = [], selectedDate, error } = this.state;

        const msg = error || t('waybills_label_no-data');

        const hasDates = dates && dates.length > 0;

        return (
            <Popup
                trigger={this.props.children}
                hideOnScroll
                on='focus'
                open={this.state.open}
                size="small"
                position="bottom left"
                onOpen={this.handleOnOpen.bind(this)}
                onClose={() => this.setState({ open: false })}
            >
                {/* <Popup.Header as="h5">Расчет даты</Popup.Header> */}
                <Popup.Content>
                    <Form className="m-b-0" style={{ width: '200px', minHeight: '100px' }} loading={loading}>
                        <Header as="h5">{t('waybills_label_calc-date')}</Header>
                        {
                            loading || hasDates ? null : <p>{msg}</p>
                        }
                        {
                            hasDates && dates.map(date =>
                                <Form.Field key={date}>
                                    <Form.Radio
                                        label={date}
                                        value={date}
                                        checked={selectedDate === date}
                                        onChange={this.handleDateChange.bind(this, date)}
                                    />
                                </Form.Field>)
                        }
                        
                    </Form>
                </Popup.Content>
            </Popup>
        );
    }
}

export default withTranslation()(WbPointCardTabsMainDateCalc);