import React from 'react';
import { withRouter } from "react-router-dom";
import qs from 'query-string';
import { Popup, Menu } from 'semantic-ui-react';
import { filterOptions } from './models/filterOptions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import UrlFilter from "./models/UrlFilter";
import {withTranslation} from "react-i18next";

const iconClear = "close icon fitted";
const separator = '%';

class WbGridFilterString extends React.Component {

    constructor(props) {
        super(props);

        const q = (qs.parse(this.props.location.search)[this.props.field] || '').split(separator);
        const val = q[0];
        const type = q[1] || 'e';

        this.state = {
            filterMenuIsOpen: false,
            filterActiveIcon: filterOptions.find(o => o.key === type).icon,
            filterTypeActive: type,
            value: val,
            icon: val ? iconClear: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.location.search !== this.props.location.search) {
            const urlFilter = new UrlFilter(this.props.location.search, this.props.field);

            this.setState({
                value: urlFilter !== '' ? urlFilter.value : ''
            })
        }
    }

    handleFilterTypeClick = (e, o) => {
        let icon = filterOptions.filter(i => i.key === o.name)[0].icon;
        this.setState({
            filterTypeActive: o.name,
            filterActiveIcon: icon,
            filterMenuIsOpen: false
        }, () => {
            if (this.state.value) {
                this.handleFilterApply(this.state.value);
            }
        });
    };

    handleFilterApply = val => {
        let res = '';
        if (!val) {
            this.setState({
                value: '',
                icon: null,
            });
            this.props.removeFilter(this.props.field);
        }
        else
        {
            res = val + separator + this.state.filterTypeActive;
            this.props.applyFilter({
                [this.props.field]: res
            });
        }
    }

    handleValueChangeDebounced = AwesomeDebouncePromise(this.handleFilterApply, 1500);
    handleValueChange = async(val) => {
        this.setState({
            value: val,
            icon: val ? iconClear: null
        });
        await this.handleValueChangeDebounced(val);
    }

    handleClearFilterClick() {
        if (this.state.value) {
            this.setState({
                value: '',
                icon: null,
            });
            this.props.removeFilter(this.props.field);
        }
    };

    handleCloseEvent(event, popup) {
        if (event.type === 'click') {
            this.setState({
                filterMenuIsOpen: false
            });
        }
    }

    handleOpenEvent(event, popup) {
        this.setState({
            filterMenuIsOpen: true
        });
    }

    render() {

        const { t } = this.props;

        const { filterTypeActive } = this.state;

        const trigger = (<span className="filter-flex-left filter-flex-left-hoverable">{this.state.filterActiveIcon}</span>);

        const menu = (
            <Menu secondary vertical>
                {filterOptions.map(o => (
                    <Menu.Item key={o.key} name={o.key} active={filterTypeActive === o.key} onClick={this.handleFilterTypeClick}>
                        {o.icon}&nbsp;{t(o.text)}
                    </Menu.Item>
                ))}
            </Menu>
        );

        return (
            <div className="filter-flex-container"
                style={{ backgroundColor: this.state.value ? '#ffface' : null }}>
                <Popup
                    trigger={trigger}
                    on='click'
                    open={this.state.filterMenuIsOpen}
                    onOpen={this.handleOpenEvent.bind(this)}
                    onClose={this.handleCloseEvent.bind(this)}
                    content={menu}
                    position='bottom left'
                />
                <span className="filter-flex-center">
                    <input
                        className="filter-date-input noXButton"
                        style={ { backgroundColor: this.state.value ? '#ffface' : null } }
                        type="text"
                        onChange={e => this.handleValueChange(e.target.value)}
                        value={this.state.value} />
                </span>
                {this.state.value ? <span
                    className="filter-flex-right filter-flex-right-active"
                    onClick={this.handleClearFilterClick.bind(this)}>
                    <i aria-hidden="true" className={this.state.icon} />
                </span> : null}
            </div>
        );
    }
}

export default withTranslation()(withRouter(WbGridFilterString));
