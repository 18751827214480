import React from 'react';
import './index.css';

const cube1 = {
    width: '15px',
    height: '15px',
    backgroundColor: 'red',
    animation: 'cubemove 1.8s infinite ease-in-out',
    WebkitAnimation: 'cubemove 1.8s infinite ease-in-out'
};

const cube2 = {
    ...cube1,
    WebkitAnimationDelay: '-0.9s',
    animationDelay: '-0.9s'
};

const Spinner = (props) => {


    const spinnerEl = (
        <div className="loading-overlay loading-offset">
            <div className="loading-overlay-inner">
                <div className="loading-spinner">
                    <div className="cube1" style={cube1} />
                    <div className="cube2" style={cube2} />
                </div>
            </div>
        </div>
    );

    return props.show ? spinnerEl : null;
};

export default Spinner;
