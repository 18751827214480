export const WaybillStepStatusEnum = {
    PENDING: 0,
    EXECUTING: 1,
    DONE: 2
};

export default class WaybillStep {
    constructor(name, status, car, driver) {
        this.name = name;
        this.status = status;
        this.car = car;
        this.driver = driver;
    }
}