// extensions
if (Object.assign) {
    // ie does not support assign and make portal crash

    if (!String.prototype.padStart) {
        Object.assign(String.prototype, {
            padStart(targetLength, padString) {
                targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
                padString = String(typeof padString !== 'undefined' ? padString : ' ');
                if (this.length >= targetLength) {
                    return String(this);
                } else {
                    targetLength = targetLength - this.length;
                    if (targetLength > padString.length) {
                        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
                    }
                    return padString.slice(0, targetLength) + String(this);
                }
            }
        });
    }

    Object.assign(String.prototype, {
        trunc(n, useDots = false) {
            return (this.length > n) ? this.substr(0, n - 1) + (useDots ? '...' : '&hellip;') : this.toString();
        }
    });
}



