import { get, post, put, del } from '../services/http';

export async function getProviders(search = '') {
    const result = await get(`providers${search}`);
    return result;
}

export async function getProviderId(id) {
    const result = await get(`providers/${id}`);
    return result;
}

export async function providerCreate(dto) {
    const result = await post(`providers/create`, dto);
    return result;
}

export async function providerUpdate(dto) {
    const result = await post(`providers/update`, dto);
    return result;
}
