import {LinkInfo} from '../layout/navbar/Navbar';
import {get, post, put} from '../services/http';
import User from './model/User'; // eslint-disable-line
import UserContext from './model/UserContext';
import UserPermissions from './model/UserPermissions';


const userLinks = [
    new LinkInfo("users_label_my-applications", '/waybills', [UserPermissions.WAYBILLS], "box"),
    new LinkInfo("users_label_my-addresses", '/points', [UserPermissions.COMPANY_POINTS], "map marker alternate"),
    new LinkInfo("users_label_registries", '/registries', [UserPermissions.REGISTRIES], "table"),
    new LinkInfo("users_label_addresses", '/admin/points', [UserPermissions.POINTS], "map"),
	new LinkInfo("users_label_clients", '/admin/companies', [UserPermissions.COMPANIES], "building"),
	new LinkInfo("users_label_suppliers", '/admin/providers', [UserPermissions.PROVIDERS], "building outline"),
	new LinkInfo("users_label_constructor", '/autocomplete', [UserPermissions.STANDARD_AUTOCOMPLETE], "cogs"),
    new LinkInfo("users_label_users", '/admin/users', [UserPermissions.USERS], "users"),
    new LinkInfo("users_label_accesses", '/admin/profiles', [UserPermissions.USERPROFILES], "key"),
    new LinkInfo("users_label_banners", '/banners', [UserPermissions.BANNERS], "bullhorn"),
    new LinkInfo("users_label_schedules", '/schedules', [UserPermissions.SCHEDULES, UserPermissions.SCHEDULES_SETTINGS_VIEAW], "calendar alternate"),
    new LinkInfo("users_label_translate", '/translate', [UserPermissions.ALLOW_UPDATE_TRANSLATIONS], "keyboard outline"),
];

export async function getUserLinks() {

    try {
        const role = await get("user/role");

        if (!role)
            throw new Error('role is null');

        const permissions = role.permissions || [];

        const links = userLinks.filter(l => l.accessKey.some(i => permissions.includes(i)));

        return links;

    } catch (error) {

        return [];
    }
}

export const getRole = async () => {
    return await get("user/role");
};

export async function userLogin(login, password) {
    // scheme
    // send l & p over https
    // get token
    // apply token on subsequent requests
    const res = await post("user/login", {
        email: login,
        password: password
    });

    const context = res
        ? new UserContext(res)
        : null;

    return context;
}

export const getLanguages = async () => {
    return await get(`translations/languages`);
};

export async function getUsers() {

    const users = await get('users');

    return users;
}

export async function getUserCurrent() {

    const user = await get(`user/current`);

    return user;
}

export async function getUserById(id) {

    const user = await get(`user/id/${id}`);

    return user;
}

export async function getUserByIdWithOptions(id) {

    const user = await get(`user/id/${id}/options`);

    return user;
}

export async function getUserCompanyOptions() {

    const user = await get(`user/options/company`);

    return user;
}


/** @param {User} dto */
export async function updateUser(dto) {

    const result = await post(`user/update`, dto);

    return result;
}

/** @param {User} dto */
export async function createUser(dto) {

    const result = await post(`user/create`, dto);

    return result;
}

/** @param {User} dto */
export async function postUser(dto) {

    return dto.id ? await updateUser(dto) : await createUser(dto);
}

export async function getUserBySearch(searchKey, searchValue) {
    const searchQuery = {
        key: searchKey,
        value: searchValue
    };
	const result = await post(`users/search`, searchQuery);
	return result;
}

export async function userPasswordReset(email) {

    var result = await get(`user/${email}/password/reset`);
    return result;
}

export async function userFirstRunClear() {
    var result = await get(`user/firstrun/clear`);
    return result;
}

export async function getCompanyOptionsBySearchUser(term) {
    const searchResult = await get(`user/options/company/${term}`);

    return searchResult;
}

export async function getRegistriesNotification() {
    return await get('registries/notification');
}

export async function getWaybillsNotification() {
    return await get('waybills/notification');
}

export async function viewWaybillsNotification(id) {
    return await get(`waybills/${id}/notification/view`);
}

export const changeLanguage = async (languageKey) => {
    return await put('user/defaultLanguage', {languageKey})
};


