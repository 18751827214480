import React from 'react';
import { Statistic } from 'semantic-ui-react';
import { PackTypeEnum } from '../../../api/model/Enums';
import { useTranslation } from 'react-i18next';

export default function LoadStatistics({ wb }) {
    const { t } = useTranslation();

    let totalPallets = 0, totalBoxes = 0, totalWeight = 0, totalVolume = 0;
    let totalEmptyPallets = 0, totalOversizedPallets = 0, totalFinPallets = 0, totalHigPallets = 0, totalLongBox = 0;

    const loadUnitSets = (wb.pointsLoading || []).reduce((val, p) => val = val.concat(p.loadUnitSets), []);

    if (loadUnitSets && loadUnitSets.length) {
        loadUnitSets.forEach(p => {
            if (p.loadUnitType === PackTypeEnum.EP) {
                totalPallets = totalPallets + (Number.parseInt(p.loadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.BT) {
                totalFinPallets = totalFinPallets + (Number.parseInt(p.loadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.BX) {
                totalBoxes = totalBoxes + (Number.parseInt(p.loadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.OT) {
                totalOversizedPallets += (Number.parseInt(p.loadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.PP) {
                totalEmptyPallets += (Number.parseInt(p.loadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.HP) {
                totalHigPallets += (Number.parseInt(p.loadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.LB) {
                totalLongBox += (Number.parseInt(p.loadUnitsCount) || 0);
            }
            p.loadTotalWeigthBruttoKg && (totalWeight = totalWeight + Number.parseFloat(p.loadTotalWeigthBruttoKg));
            p.loadTotalVolumeM3 && (totalVolume = totalVolume + Number.parseFloat(p.loadTotalVolumeM3));
        });
    }

    const formatNumber = (number) => {
        if (number % 1 !== 0) {
            return number.toFixed(3).replace(/0+$/, '');
        }
        return number;
    };

    return (
        <Statistic.Group size="mini" style={{ margin: 0 }}>
            {totalPallets ? <Statistic horizontal style={{ marginBottom: 0 }}>
                <Statistic.Value>{totalPallets}</Statistic.Value>
                <Statistic.Label>{t('waybills_label_eur')}</Statistic.Label>
            </Statistic> : null}
            {totalFinPallets ? <Statistic horizontal style={{ marginBottom: 0 }}>
                <Statistic.Value>{totalFinPallets}</Statistic.Value>
                <Statistic.Label>{t('waybills_label_fin')}</Statistic.Label>
            </Statistic> : null}
            {totalEmptyPallets ? <Statistic horizontal>
                <Statistic.Value>{totalEmptyPallets}</Statistic.Value>
                <Statistic.Label>{t('waybills_label_empty-pallets-up-to-15')}</Statistic.Label>
            </Statistic> : null}
            {totalOversizedPallets ? <Statistic horizontal>
                <Statistic.Value>{totalOversizedPallets}</Statistic.Value>
                <Statistic.Label>{t('waybills_label_oversize-pallets')}</Statistic.Label>
            </Statistic> : null}
            {totalHigPallets ? <Statistic horizontal>
                <Statistic.Value>{totalHigPallets}</Statistic.Value>
                <Statistic.Label>{t('waybills_label_amer')}</Statistic.Label>
            </Statistic> : null}
            {totalLongBox ? <Statistic horizontal>
                <Statistic.Value>{totalLongBox}</Statistic.Value>
                <Statistic.Label>{t('waybills_label_large-boxes')}</Statistic.Label>
            </Statistic> : null}
            {totalBoxes ? <Statistic horizontal>
                <Statistic.Value>{totalBoxes}</Statistic.Value>
                <Statistic.Label>{t('waybills_label_boxes')}</Statistic.Label>
            </Statistic> : null}
            <Statistic horizontal>
                <Statistic.Value>{totalWeight && formatNumber(totalWeight)}</Statistic.Value>
                <Statistic.Label>{t('waybills_label_kg-brutto1')}</Statistic.Label>
            </Statistic>
            {totalVolume ? <Statistic horizontal>
                <Statistic.Value>{totalVolume && formatNumber(totalVolume)}</Statistic.Value>
                <Statistic.Label>{t('waybills_label_volume-m3-1')}</Statistic.Label>
            </Statistic> : null}
        </Statistic.Group>
    );
}
