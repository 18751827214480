export default class UserContext {

    constructor(dto) {

		this.error = dto.error;

        this.token = dto.token;

        this.email = dto.email;

        this.name = dto.userName;

        this.roleId = dto.userRole;

        this.startUrl = dto.startUrl;

        this.firstRun = dto.firstRun;

        this.permissions = dto.permissions;

        this.companies = dto.companies;
    }
}