import React from 'react';
import {Button, Modal} from 'semantic-ui-react';
import {userPasswordReset} from '../../../api/users';
import T from '../../../components/Translate';
import {withTranslation} from 'react-i18next';

class ResetPassword extends React.Component {

    state = {
        open: false,
        isLoading: false,
        isDone: false
    }

    handleOpen = () => this.setState({open: true});

    handleClose = () => this.setState({open: false});

    resetPassword() {
        this.setState({isLoading: true}, async () => {

            const result = await userPasswordReset(this.props.email);

            this.setState({isLoading: false, isDone: true, result});

        });
    }

    render() {
        const { t } = this.props;
        const {open, isLoading, isDone, result} = this.state;
        const resetPasswordText = t('accesses_label_reset-password-label') + this.props.email || '';
        const resetPasswordResult = result ? result.errors : t("accesses_label_reset-password-success");

        return (
            <Modal
                open={open}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                closeOnEscape={!isLoading}
                closeOnDimmerClick={!isLoading}
                trigger={this.props.children}
            >
                <Modal.Header>{t("accesses_label_reset-password")}</Modal.Header>
                <Modal.Content>{isLoading ? t('accesses_label_reset-password') : isDone ? resetPasswordResult : resetPasswordText}</Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.handleClose.bind(this)}
                        disabled={isLoading}>{isDone ? t("base_btn_close") : t("base_btn_cancel")}</Button>
                    {!isDone &&
                    <Button
                        negative
                        icon="exclamation triangle"
                        content={t("users_label_reset-password")}
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.resetPassword.bind(this)}
                    />
                    }
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withTranslation()(ResetPassword)