import UserPermissions from "./UserPermissions";

export default class PointColumn {
    /**
     * @param {string} key
     * @param {string} text
     * @param {number} width
     * @param {boolean} [hide]
     */
    constructor(key, text, hide = false, width = null, permission = null) {
        this.key = key;
        this.text = text;
        this.width = width;
        this.hide = hide;
        this.permission = permission
    }
}

export const pointColumnsSet = [
    new PointColumn("client", "points_enum_binding", false),
    new PointColumn("address", "points_label_address", false),
    new PointColumn("companyName", "points_label_company", false),
    new PointColumn("pointType", "points_label_company_type", false),
    new PointColumn("fmid", "points_text_address_name_in_fm_system", true, null, UserPermissions.FMID_ADDRESS),
    new PointColumn("companySearchName", "points_placeholder_name_for_fast_search", false, 3),
    new PointColumn("bookmarkType", "points_enum_favorite", false)
];
