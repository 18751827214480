import React from 'react';
import { Feed, Icon } from 'semantic-ui-react';
import { useTranslation } from "react-i18next"

export default function HistoryItem({ date, userName, title, text, canComment, icon = "check" }) {

	const { t } = useTranslation();

	const newway = (
		<Feed.Event>
			<Feed.Label icon={icon} />
			<Feed.Content>
				<Feed.Summary>
					{title}
					<Feed.User className="m-l-5">{userName}</Feed.User>
					<Feed.Date>{date}</Feed.Date>
				</Feed.Summary>
				<Feed.Extra text>{text}</Feed.Extra>
				{canComment && 
					<Feed.Meta>
						<Feed.Like><Icon name='edit' />{t("base_btn_add_comment")}</Feed.Like>
					</Feed.Meta>
				}
			</Feed.Content>
		</Feed.Event>
	);

	/* <Feed.Event>
        <Feed.Label icon='lightning' />
        <Feed.Content>
            <Feed.Date>25.01.2019</Feed.Date>
            <Feed.Summary><a href="##">Иван Петров</a> перевел заявку <a href="/waybills/2dcc3ed2-11eb-4377-af9e-14782baa1ca9">122322323</a> с статус исполняется.</Feed.Summary>
            <Feed.Meta>
                <Feed.Like><Icon name='edit' />добавить комментарий</Feed.Like>
            </Feed.Meta>
        </Feed.Content>
    </Feed.Event>
    <Feed.Event>
        <Feed.Label icon='lightning' />
        <Feed.Content date='26.01.2019' summary="Вы поодтвердили адрес ОООКРОМКА" />
	</Feed.Event> */

	return newway;
}
