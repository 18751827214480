import React from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox } from 'semantic-ui-react';
import './CheckSelector.css';


export default function CheckSelector(props) {

    return (
        <Table.Cell className="CheckSelector table-first-col">
            <Checkbox id={props.id}
                checked={props.selectedIds.includes(props.id)}
                onChange={e => props.handleRowSelection(props.id, e.target.checked)} />
        </Table.Cell>
    );
}

CheckSelector.propTypes = {
    id: PropTypes.any.isRequired,
    selectedIds: PropTypes.array.isRequired,
    handleRowSelection: PropTypes.func.isRequired
};

