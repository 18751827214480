import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import {
    WaybillStatusAnomalyOptions,
    WaybillStatusAvizationOptions,
    WaybillStatusEnum,
    WaybillStatusOptions, WaybillTypeOptions,
} from '../../../api/model/Waybill';
import {Icon, Label, List, Popup, Progress} from 'semantic-ui-react';
import {WaybillStepStatusEnum} from '../../../api/model/WaybillStep';
import WbProgressPopup from './wbProgressPopup/WbProgressPopup';
import {PickupTypeEnum} from '../../../api/model/Enums';
import {downloadFilePod} from "../../../api/waybillDocs";
import downloadFile from "../../../services/downloadFile";
import {servicesConst} from "../../../api/model/PointService";
import { useTranslation } from 'react-i18next';


const needPickupIconStl = {
    fontSize: '15px',
    verticalAlign: 'text-bottom',
    marginLeft: '3px',
    color: '#a09a9a',
    float: 'right',
};

const typeColors = {
    LTL: 'teal',
    FTL: 'violet',
    Rail: 'olive',
    RailLTL: 'blue',
    Return: 'orange',
    PlanReturn: 'red',
};

const formatCost = function(value) {
    if (Intl.NumberFormat)
        //return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', currencyDisplay: 'symbol' }).format(value);
        return new Intl.NumberFormat('ru-RU', {
            style: 'decimal',
            minimumFractionDigits: 0,
        }).format(value);
    //return new Intl.NumberFormat('ru-RU').format(value);
    else return value; // если браузер не поддерживает этот объект, оставляем все как есть, без обработки
};

export function DriverAutoInfo({val, isDouble, keyName}) {
    const maxWidth = 150;
    const isMoreTwoLinesValue = 1.5;
    const refDriverAuto = useRef(null);

    const onHover = (isHover) => {
        //удлинение ячейки FMCPU-236
        if (refDriverAuto.current) {
            let col = document.getElementById(`dr-auto-info_${keyName}`);
            const widthColumn = col.getBoundingClientRect().width;
            col.style.minWidth = isHover ? widthColumn + 'px' : 'auto'; //фикс изменения ширины столбца при наведении, когда прочие поля в столбце содержат короткое значение

            let maxWidthText = document.documentElement.clientWidth - refDriverAuto.current.getBoundingClientRect().x+20;
            let isMoreTwoLines = (refDriverAuto.current.scrollWidth/maxWidthText).toFixed(1) >= isMoreTwoLinesValue;
            let hover = isHover && (refDriverAuto.current.scrollWidth !== refDriverAuto.current.clientWidth);

            refDriverAuto.current.className = `table-driver-auto-info ${hover ? `table-driver-auto-info_full` : ''}`;
            refDriverAuto.current.style.width = hover ? `calc(${refDriverAuto.current.scrollWidth}px + 3px)` : 'auto';
            refDriverAuto.current.style.left = hover && isMoreTwoLines ? `-${refDriverAuto.current.scrollWidth - maxWidthText + 35}px` : '-1px'; //сдвиг влево на кол-во пикселей, достаточных для вывода текста
            refDriverAuto.current.style.maxWidth = !hover ? `${maxWidth}px` : `calc(100vw - ${refDriverAuto.current.getBoundingClientRect().x+20}px)`; //максимальное удлинение ячейки вправо, чтобы она влезла в экран
            refDriverAuto.current.style.whiteSpace = (hover && refDriverAuto.current.getBoundingClientRect().width > maxWidth) ? 'pre-line' : 'nowrap';
        }
    };

    const value = (val[isDouble ? 1 : 0] && !/^\s+$/.test(val[isDouble ? 1 : 0])) ? val[isDouble ? 1 : 0] : '-';

    return <div className='table-driver-auto-info'
                ref={refDriverAuto}
                onMouseEnter={()=>onHover(true)}
                onMouseLeave={()=>onHover(false)}>
            {value}
        </div>;
}

export default function WbGridRowElement({el: key, data = {}, canEdit, isDouble}) {
    const { t } = useTranslation();

    const id = data.id;
    const isEditable = data.status === WaybillStatusEnum.DRAFT;
    const val = data[key];

    function handleDocumentDownload() {
        alert('downloading!');
    }

    const handleDownload = async () => {
        const result = await downloadFilePod(data.id);

        downloadFile(result);
    };

    switch (key) {
        case 'fmid':
            const draftRef = isEditable // (isEditable || canEdit)
                ? '/edit'
                : '/view/info';

            let linkClass = 'table-link ';

            if (isEditable) {
                linkClass = data.isValid
                    ? (linkClass += 'table-link-green')
                    : (linkClass += 'table-link-grey');
            }
            return (
                <>
                    <div className={linkClass}>{val}</div>
                    {data.status !== WaybillStatusEnum.DRAFT &&
                    data.pickupType === PickupTypeEnum.FM && (
                        <Icon
                            style={needPickupIconStl}
                            name="truck"
                            title={t('waybills_label_pickup-by-fm')}
                        />
                    )}
                    {data.isPooling && (
                        <Icon style={needPickupIconStl} name="cube" title={t('waybills_label_pooling')}/>
                    )}
                </>
            );
        case 'type':
            return (
                <>
                    <Label color={typeColors[val]} style={{ textAlign: 'center' }}>
                        {t(WaybillTypeOptions.getText(val))}
                    </Label>
                </>
            );
        case 'totalWeight':
        case 'cost':
            //return <div style={{ textAlign: 'right', paddingRight: "10px"}}>{formatCost(val)}</div>;
            return val ? (
                <div style={{ textAlign: 'right', width: '53px' }}>{t(formatCost(val))}</div>
            ) : null;
        //return formatCost(val);

        case 'totalUnits':
            if (val) {
                return (
                    <React.Fragment>
                        {Object.keys(val).map(key => (
                            <span
                                key={key}
                                style={{
                                    textAlign: 'right',
                                    width: '35px',
                                    display: 'inline-block',
                                }}
                            >
                                {val[key]} <span style={{ color: '#9e9e9e' }}>{t(key)}</span>&nbsp;
                            </span>
                        ))}
                    </React.Fragment>
                );
            }
            return null;

        case 'status':
            let o = WaybillStatusOptions.find(o => o.key === val);

            return o ? (
                <Label
                    color={o.color}
                    style={{ textAlign: 'center', marginRight: '5px' }}
                    title={t(o.text)}
                >
                    {t(o.text)}
                </Label>
            ) : (
                <Label style={{ textAlign: 'center', marginRight: '5px' }}>{t(val)}</Label>
            );
        case 'statusAnomaly':
            let items = WaybillStatusAnomalyOptions.filter(o => val && val.indexOf(o.key)!==-1);

            return items.length !== 0 ? (
                <>
                    {
                        items.map(item =>
                            <Label
                                key={item.text}
                                color={item.color}
                                style={{textAlign: 'center', marginRight: '5px'}}
                                title={item.text}
                            >
                                {t(item.text)}
                            </Label>)
                    }
                </>

            ) : null;
        case 'avisationStatus':
            const statusAvization = WaybillStatusAvizationOptions.find(o => o.key === val);
            return statusAvization
                ? (
                    <Label
                        color={statusAvization.color}
                        style={{ textAlign: 'center', marginRight: '5px' }}
                        title={t(statusAvization.text)}
                    >
                        {t(statusAvization.text)}
                    </Label>
                )
                : (
                    <Label style={{ textAlign: 'center', marginRight: '5px' }}>{t(val)}</Label>
                );

        case 'readiness':
            const showRoute = [
                WaybillStatusEnum.EXECUTING,
                WaybillStatusEnum.CARGO_DELIVERED,
            ].includes(data.status);

            const isCompleted = [
                WaybillStatusEnum.CARGO_DELIVERED,
            ].includes(data.status);

            const progress = isCompleted
                ? 100
                : val && val.length
                ? parseInt(
                      ((val.filter(pt => pt.pointStatus === WaybillStepStatusEnum.DONE).length ||
                          0) /
                          val.length) *
                          100,
                  )
                : 0;

            return showRoute ? (
                <WbProgressPopup data={val}>
                    <Progress
                        value={progress}
                        size="small"
                        progress="value"
                        color={isCompleted ? 'blue' : 'green'}
                    />
                </WbProgressPopup>
            ) : (
                <Progress value={0} size="small" progress="value" color="red" />
            );

        case 'loadingDate':
        case 'deliveryDate':
            return val ? moment(val).format('DD.MM.YYYY HH:mm') : null;
        case 'shipto':
            let index = 0;
            let locations =
                val &&
                val.map(a => {
                    return (
                        <React.Fragment key={index++}>
                            {a.settlement},{' '}
                            <span style={{ color: 'lightgrey' }}>{a.streetName}</span>
                        </React.Fragment>
                    );
                });
            return (
                <div
                    style={{
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <span title={val && val.toString()}>{locations}</span>
                </div>
            );

        case 'refs':
            return (
                <div
                    style={{
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <span title={val && val.toString()}>{t(val)}</span>
                </div>
            );

        case 'torg12No':
            return (
                <div
                    style={{
                        width: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <span title={val && val.toString()}>{t(val)}</span>
                </div>
            );

        case "document":
            return data.document
                ? <div className="a-underline-hover" onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    handleDownload();
                }}>
                    {t('waybills_label_download-pod')}
                </div> : null;
        /*return data.document
            ? <a download
                className="a-underline-hover"
                target="_blank"
                rel="noopener noreferrer"
                href={`/api/waybills/${data.id}/docs/pod/download`}
                onClick={e => {
                    e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                }}><T>Скачать POD</T></a>
            : null;
                }}><T>Скачать POD</T></a>
            : null;*/
        case "document_bak":
            const canHaveDocs = data.status >= WaybillStatusEnum.EXECUTING;
            const fileIcon = 'file';
            return canHaveDocs && val && val.loaded ? (
                <Popup
                    hoverable
                    trigger={
                        <span className="table-document" onClick={handleDocumentDownload}>
                            <Icon name="attach" style={{ fontSize: '14px' }} />
                            {val.date}
                            {/* {moment(val.date).format("DD.MM.YYYY")} */}
                        </span>
                    }
                >
                    <Popup.Header className="m-b-10">{t('waybills_label_docs')}</Popup.Header>
                    <Popup.Content>
                        <List divided verticalAlign="middle" relaxed>
                            <List.Item>
                                <List.Icon name={fileIcon} />
                                <List.Content>
                                    <List.Header as="a">{t('waybills_label_waybill')}</List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name={fileIcon} />
                                <List.Content>
                                    <List.Header as="a" href="http://google.com" target="_blank">
                                        {t('waybills_label_cargo-label')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name={fileIcon} />
                                <List.Content>
                                    <List.Header as="a">{t('waybills_label_forwarding_receipt')}</List.Header>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Popup.Content>
                </Popup>
            ) : null;
        case 'consignee':
            return <span title={val}>{val}</span>;
        case "services":
            if (!val || !val.length) return null;
            const obj = val.map(item => `${t(servicesConst()[item.serviceType.toString()])}${item.value ? ': ' : ''}${item.value || ''}`);
            const str = obj && obj.length
                ? obj.join("; ")
                : null;

            return <div style={{
                width: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}><span title={str}>{str}</span></div>;
        case 'reservation':
            return <div>
                {val && val.deliveryDate ? `${new Date(val.deliveryDate).toLocaleDateString()} ${val.start && val.start.slice(0,5)} - ${val.end && val.end.slice(0,5)}` : ""}
            </div>;
        case 'driver':
        case 'phoneNumber':
        case 'trailer':
        case 'trailerDimensions':
        case 'trailerWeight':
        case 'truck':
        case 'truckDimensions':
        case 'truckWeight':
            return <DriverAutoInfo val={val} isDouble={isDouble} keyName={key}/>;
        default:
            return val;
    }
}
