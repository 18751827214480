import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {getByKey, save} from "../../api/translate";

const TranslateCard = ({children, translateKey, columns = [], loadList}) => {
        const {t} = useTranslation();
        let [open, setOpen] = useState(false);
        let [form, setForm] = useState({});
        let [loading, setLoading] = useState(false);

        const handleOpen = () => {
            setOpen(true);
            featchData();
        };

        const handleClose = () => {
            setOpen(false);
            setForm({});
        };

        const featchData = async () => {

            const result = await getByKey(translateKey);

            let form = {};
            columns.forEach(item => {
                form = {
                    ...form,
                    [item.value]: (result.find(i => i.languageKey === item.value) || {}).value
                }
            });

            setForm(form);

        };

        const handleChange = (e, {name, value}) => {
            setForm(form => ({
                ...form,
                [name]: value
            }))
        };

        const handleSave = async () => {
            setLoading(true);
            try {
                const data = {
                    key: translateKey,
                    phrases: columns.map(item => ({
                        languageKey: item.value,
                        value: form[item.value]
                    }))
                };
                await save(data);

                handleClose();
                loadList();
            } finally {
                setLoading(false);
            }
        };


        return (
            <Modal
                trigger={children}
                open={open}
                closeOnDimmerClick={false}
                onOpen={handleOpen}
                onClose={handleClose}
            >
                <Modal.Header>{translateKey}</Modal.Header>
                <Modal.Content>
                    <Form>
                        {
                            columns.map(column => (
                                <Form.Field>
                                    <label>
                                        {column.name}
                                    </label>
                                    <Input
                                        name={column.value}
                                        value={form[column.value]}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                            ))
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleClose}>{t('base_btn_cancel')}</Button>
                    <Button primary loading={loading} onClick={handleSave}>{t('base_btn_save')}</Button>
                </Modal.Actions>
            </Modal>
        );
    }
;

export default TranslateCard;
