import { get, post, put, del } from '../services/http';


export async function getCompanies() {
	const result = await get("companies");
	return result;
}

export async function getCompaniesFromSchedule(page, search) {
    const result = await get(`companies/search/fmid?take=20&skip=${(page - 1) * 20}${search ? '&fmid=' + search : ''}`);
    return result;
}

export async function getCompaniesIsMixAllowed(id) {
	const result = await get(`companies/${id}/mix`);
	return result.mixAllowed;
}

export async function getCompaniesIsMixAllowedUpdate(id, data) {
    const param = {mixAllowed: data};
	const result = await post(`companies/${id}/update/mix`, param);
	return result;
}

export async function getCompanyById(id) {
	const result = await get(`companies/id/${id}`);
	return result;
}

export async function getCompaniesList() {
	const result = await get(`companies/selector`);
	return result;
}

export async function getCompanyByIds(ids = []) {
    const result = await post(`companies/ids`, ids);
    return result;
}

export async function getCompaniesBySearch(key, term) {
    const searchQuery = {
        key: key,
        term: term
    };
	const result = await post(`companies/search`, searchQuery);
	return result;
}

export async function getCompanyOptionsBySearch(term) {
	const searchResult = await get(`companies/search/${term}/options`);

    return searchResult;
}

export async function companyCreate(dto) {
    const result = await post(`companies/add`, dto);
    return result;
}

export async function companyUpdate(fmid, dto) {
    const result = await post(`companies/${fmid}/update`, dto);
    return result;
}

export async function companyUpdateOApi(fmid, dto) {
    const result = await post(`companies/${fmid}/update/oapi`, dto);
    return result;
}

export async function getCompanyPoints(filter = '') {
    const result = await get(`companypoints/search${filter}`);
    return result;
}

export async function companyPointAdd(companyId, pointId, searchName) {
    let companyIdNew = companyId;
    if (typeof companyId === 'string') {
        companyIdNew = [companyId];
    }

    const params = {
        companyId: companyIdNew,
        pointId,
        companyPointSearchName: searchName

    };
    const result = await post(`companypoints`, params);
    return result;
}

export async function companyPointRemove(companyId, pointId) {
    const result = await del(`companypoints?companyId=${companyId}&pointId=${pointId}`);
    return result;
}

export async function companyPointUpdate(companyId, pointId, data) {
    const result = await put(`companypoints?companyId=${companyId}&pointId=${pointId}`, data);
    return result;
}

export async function getCompanyCargoRestrictions(companyId) {
    const result = await get(`companies/cargo-restrictions?companyId=${companyId}`);
    return result;
}

export async function companyCargoRestrictionsCreate(companyId, data) {
    const result = await post(`companies/cargo-restrictions?companyId=${companyId}`, data);
    return result;
}

export async function companyCargoRestrictionsUpdate(id, data) {
    const result = await put(`companies/cargo-restrictions?id=${id}`, data);
    return result;
}

export async function getCompanyFieldConfigs(companyId) {
    const result = await get(`companies/field-configs?companyId=${companyId}`);
    return result;
}

export async function companyFieldConfigCreate(companyId, data) {
    const result = await post(`companies/field-configs?companyId=${companyId}`, data);
    return result;
}

export async function companyFieldConfigUpdate(id, data) {
    const result = await put(`companies/field-configs?id=${id}`, data);
    return result;
}

export async function companyCutoffsOffUpdate(companyId, data) {
    const result = await post(`companies/${companyId}/cutoffs/off`, { value: data });
    return result;
}

export async function getCompanyCutoffsOff(companyId) {
    const result = await get(`companies/${companyId}/cutoffs/off`);
    return result;
}

// export async function companyCutoffsOffUntilOnUpdate(companyId, data) {
//     const result = await post(`companies/${companyId}/cutoffs/off/until/on`, { value: data });
//     return result;
// }

// export async function getCompanyCutoffsOffUntilOn(companyId) {
//     const result = await get(`companies/${companyId}/cutoffs/off/until/on`);
//     return result;
// }

export async function companyCutoffsOffUntilUpdate(companyId, data) {
    const result = await post(`companies/${companyId}/cutoffs/off/until`, { value: data });
    return result;
}

export async function getCompanyUpdateRulesOffUntilUpdate(companyId) {
    const result = await get(`companies/${companyId}/cutoffs/update/off/until`);
    return result;
}

export async function getCompanyUpdateRulesUpdate(companyId) {
    const result = await get(`companies/${companyId}/cutoffs/update/off`);
    return result;
}

export async function companyUpdateRulesOffUntilUpdate(companyId, data) {
    const result = await post(`companies/${companyId}/cutoffs/update/off/until`, { value: data });
    return result;
}

export async function companyUpdateRulesUpdate(companyId, data) {
    const result = await post(`companies/${companyId}/cutoffs/update/off`, { value: data });
    return result;
}

export async function getCompanyCutoffsOffUntil(companyId) {
    const result = await get(`companies/${companyId}/cutoffs/off/until`);
    return result;
}

export async function getProvidersCommonCompanies(companyId) {
    const result = await get(`companies/${companyId}/providers`);
    return result;
}

export const getAddressExceptions = async (id) => {
    return await get(`companies/${id}/exceptions`);
};

export const addAddressException = async (params) => {
    return await post(`companies/exceptions`, params)
};

export const deleteAddressExceptions = async (id) => {
    return await del(`companies/exceptions/${id}`)
};
