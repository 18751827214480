import qs from 'query-string';
import React, {useState, useRef} from 'react';
import ScrollPreloader from '../../../components/miscs/ScrollPreloader';

export default function PointsScrollPreloader({
    location,
    fetchData,
    setRows,
    rows
}) {
    const pageSize = 70;
    const page = useRef(0);
    const needScroll = rows.length >= pageSize && rows.length % pageSize === 0;
    
    const [scrollLoading, setScrollLoading] = useState(false);

    async function onBottomVisible() {
        if (scrollLoading) {
            return;
        }
        const nextPage = page.current + 1;
        const filter = qs.parse(location.search);

        filter.page = nextPage;

        setScrollLoading(true);
        const nextRows = await fetchData(filter);
        
        if (nextRows && nextRows.length) {
            const allRows = rows.concat(nextRows);

            setRows(allRows);
            page.current = nextPage;
        }
        setScrollLoading(false);
    };

    return needScroll && <ScrollPreloader onVisible={onBottomVisible} continuous />;
}