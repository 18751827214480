import {get, post} from '../services/http';

export async function getReservationApi(id) {
    const result = await get(`avisation/reservation/${id}`);
    return result;
}

export async function waybillAvisationSaveTransport(dto, reservationId) {
    const response = await post(`avisation/reservation/${reservationId}/transport`, dto);

    return response;
}

export async function reservationDelete(waybillId, reservationId) {
    const response = await post(`avisation/reservation/${reservationId}/waybill/${waybillId}/remove`);

    return response;
}


export async function reservationAdd(reservationId, ids) {
    const response = await post(`avisation/reservation/${reservationId}/waybill/add`, ids);

    return response;
}


export async function reservationCancel(reservationId) {
    const response = await post(`avisation/reservation/${reservationId}/cancel`);

    return response;
}
