import {Oc} from "./Waybill";

export const RegistryStatusEnum = {
    NEW: 0,
    IN_PROCESS: 1,
    IN_PROCESS_CLIENT: 10,
    IN_PROCESS_FM: 11,
    AWAITING_EDIT_FM: 20,
    COMPLETED: 30,
};

export const RegistryOrderStatusEnum = {
    CONFIRMED: 0,
    REJECTED: 1,
    CHANGED_FM: 2,
};

export const RegistryOrderStatusOptions = [
    new Oc(RegistryOrderStatusEnum.CONFIRMED, "registers_enum_confirmed", 'green'),
    new Oc(RegistryOrderStatusEnum.CHANGED_FM, "registers_enum_changed-fm", "orange"),
    new Oc(RegistryOrderStatusEnum.REJECTED, "registers_enum_rejected", "red"),
];

export const RegistrieStatusOptions = [
    new Oc(RegistryStatusEnum.NEW, "registers_enum_new", "teal"),
    new Oc(RegistryStatusEnum.IN_PROCESS, "registers_enum_in-progress", "yellow"),
    new Oc(RegistryStatusEnum.IN_PROCESS_CLIENT, "registers_enum_in-progress-client", "yellow"),
    new Oc(RegistryStatusEnum.IN_PROCESS_FM,"registers_enum_in-progress-fm", "orange"),
    new Oc(RegistryStatusEnum.AWAITING_EDIT_FM, "registers_enum_awaiting-edit-fm", "blue"),
    new Oc(RegistryStatusEnum.COMPLETED, "registers_enum_completed", "green"),
];

export const RegistrieStatusOptionsFromFilter = [
    new Oc(RegistryStatusEnum.NEW, "registers_enum_new", "teal"),
    new Oc(RegistryStatusEnum.IN_PROCESS_CLIENT, "registers_enum_in-progress-client", "yellow"),
    new Oc(RegistryStatusEnum.IN_PROCESS_FM, "registers_enum_in-progress-fm", "orange"),
    new Oc(RegistryStatusEnum.COMPLETED, "registers_enum_completed", "green"),
];
