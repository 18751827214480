import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import ErrorNotAuthorized from '../pages/ErrorNotAuthorized';
import {ContextUser} from '../services/context';


export function Authorized(props) {

	const context = useContext(ContextUser);

    const user = context && context.current;

    if (!user.permissions || !user.permissions.length) {

        return <Redirect to="/login" />;
    }

    const isAuthorized = typeof props.permission === 'object' ? props.permission.some(i => user.permissions.includes(i)) : user.permissions.includes(props.permission);

    const { component: Component, ...rest } = props;

    if (isAuthorized) {

        return <Route {...rest} render={ props => <Component {...props} />} />;

    } else {

        return <Route {...rest} render={ props => <ErrorNotAuthorized location={props.location} />} />;
    }
}
