import React, {useMemo} from 'react';
import {Button, Form, Grid, Icon} from "semantic-ui-react";
import InputDropdown from "../inputs/InputDropdown";
import {fieldCompareOptions} from "../../api/calculatingFieldsRules";
import O from '../../components/Option';
import Fields from "./fields";
import { useTranslation } from "react-i18next";
import {nanoid} from "nanoid";

const RulesConstructor = ({items, fields: fieldsFromRulesProps = [], disabled, onChange}) => {

    const { t } = useTranslation();

    const onDelete = (index) => {
        const list = items.filter((item, itemIndex) => itemIndex !== index)
        onChange(list.length ? list : [{
            nanoId: nanoid(),
            name: '',
            values: [''],
            type: ''
        }]);
    };

    const onAdd = () => {
        onChange([...items, {nanoId: nanoid()}])
    };

    const handleChange = (value, name, index) => {
        const list = [...items];
        if (name === 'key') {
            list[index] = {
                nanoId: list[index].nanoId,
                [name]: value,
                type: '',
                values: []
            };
        } else {
            list[index] = {
                ...list[index],
                [name]: value,
            };
        }
        onChange(list);
    };


    const fieldsFromRules = useMemo(() => fieldsFromRulesProps.map(item => new O(item.key, item.field.name)), [fieldsFromRulesProps]);

    const options = (item) => (fieldsFromRulesProps.find(field => field.key === item.key) || {}).field || {};


    return (
        <>
            <Form>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Form.Field required>
                                <label>{t("waybillautocomplte_grid_condition_by_field")}</label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Field required>
                                <label>{t("waybillautocomplte_grid_condition_type")}</label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Field required>
                                <label>{t("waybillautocomplte_grid_target_value")}</label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={1}>
                            <Button icon onClick={() => onAdd()}>
                                <Icon name="add"/>
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
            <Grid>
                {
                    items.map((item, index) => (
                        <Grid.Row key={item.nanoId}>
                            <Grid.Column width={5}>
                                <Form>
                                    <Form.Field>
                                        <InputDropdown
                                            options={fieldsFromRules}
                                            disabled={disabled}
                                            value={item.key}
                                            placeholder={t("waybillautocomplte_placeholder_value")}
                                            onChange={(val) => handleChange(val, 'key', index)}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Form>
                                    <Form.Field>
                                        <InputDropdown
                                            placeholder={t("waybillautocomplte_placeholder_choose")}
                                            value={item.type}
                                            disabled={disabled}
                                            options={item.key && fieldCompareOptions.filter(option => options(item).rules.includes(option.value))}
                                            onChange={(val) => handleChange(val, 'type', index)}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Form>
                                    <Form.Field>
                                        <Fields
                                            fieldOptions={options(item)}
                                            itemKey={item.key}
                                            value={item.values}
                                            disabled={disabled}
                                            multiple
                                            isConstructor
                                            onChange={handleChange}
                                            indexRule={index}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={1}>
                                <Button icon onClick={() => onDelete(index)}>
                                    <Icon name="trash alternate"/>
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    ))
                }
            </Grid>
        </>
    )
};

export default RulesConstructor;
