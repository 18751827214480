import {get, post, put} from '../services/http';

export const getList = async (filterString) => {
    return await get(`translations/admin${filterString ? filterString : ''}`);
};

export const getByKey = async (key) => {
    return await get(`translations/${key}/admin`);
};

export const save = async (data) => {
    return await put(`translations`, data);
};

export const getEnums = async () => {
    return await get(`translations/admin/sections`);
};
