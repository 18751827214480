const headers1 = [
    {
        key: 'foreignId',
        text: 'registers_grid_rg-num',
        sortable: false,
    },
    {
        key: 'orderSequence',
        text: 'registers_grid_order-sequence',
        sortable: true
    },
    {
        key: 'loadingDate',
        text: 'registers_grid_load-date',
        sortable: true
    },
    {
        key: 'number',
        text: 'registers_grid_order-num',
        sortable: false,
    },
    {
        key: 'torg12No',
        text: 'registers_grid_torg12num',
        sortable: true,
    },
    {
        key: 'consignor',
        text: 'registers_grid_consignor',
        sortable: true,
    },
    {
        key: 'loadingCity',
        text: 'registers_grid_load-city',
        sortable: false
    },
    {
        key: 'unloadingCity',
        text: 'registers_grid_unload-city',
        sortable: false
    },
    {
        key: 'consignee',
        text: 'registers_grid_consignee',
        sortable: true
    },

    {
        key: 'epCount',
        text: 'registers_grid_ep-count',
        sortable: false,
    },
    {
        key: 'lpCount',
        text: 'registers_grid_lp-count',
        sortable: false,
    },
    {
        key: 'bxCount',
        text: 'registers_grid_bx-count',
        sortable: false,
    },
    {
        key: 'lbCount',
        text: 'registers_grid_lb-count',
        sortable: false,
    }
];

const headersLoadUnits = [
    {
        key: 1,
        text: 'registers_grid_bt',
        sortable: false
    },
    {
        key: 2,
        text: 'registers_grid_bx',
        sortable: false
    },
    {
        key: 3,
        text: 'registers_grid_ep',
        sortable: false
    },
    {
        key: 4,
        text: 'registers_grid_lp',
        sortable: false
    },
    {
        key: 5,
        text: 'registers_grid_ot',
        sortable: false
    },
    {
        key: 6,
        text: 'registers_grid_pp',
        sortable: false
    },
    {
        key: 7,
        text: 'registers_grid_hp',
        sortable: false
    },
];

const headers1Ext = [
    {
        key: 'weight',
        text: 'registers_grid_weight',
        sortable: false
    },
    {
        key: 'volume',
        text: 'registers_grid_volume',
        sortable: false
    },
    {
        key: 'clientDimensionalWeight',
        text: 'registers_grid_client-dimensional-volume',
        sortable: false
    },
    {
        key: 'fmDimensionalWeight',
        text: 'registers_grid_fm-dimensional-volume',
        sortable: false
    },
    {
        key: 'cargoType',
        text: 'registers_grid_cargo-type',
        sortable: false,
    },
];

const headersTrip = [
    {
        key: 'trip1Number',
        text: 'registers_grid_trip1num',
    },
    {
        key: 'trip1TransportType',
        text: 'registers_grid_transport-type',
    },
    {
        key: 'trip1Driver',
        text: 'registers_grid_trip1driver',
    },
    {
        key: 'trip1Transport',
        text: 'registers_grid_trip1transport',
    },
    {
        key: 'trip2Number',
        text: 'registers_grid_trip2num',
    },
    {
        key: 'trip2TransportType',
        text: 'registers_grid_transport-type',
    },
    {
        key: 'trip2Driver',
        text: 'registers_grid_trip2driver',
    },
    {
        key: 'trip2Transport',
        text: 'registers_grid_trip2transport',
    },
    {
        key: 'loadingComment',
        text: 'registers_grid_load-comment',
        sortable: false
    },
    {
        key: 'unloadingComment',
        text: 'registers_grid_unload-comment',
        sortable: false
    },
];

export const headersComment = [
    {
        key: 'loadingComment',
        text: 'registers_grid_load-comment',
        sortable: false
    },
    {
        key: 'unloadingComment',
        text: 'registers_grid_unload-comment',
        sortable: false
    },
];

const headersServices = [
    {
        key: 103,
        text: 'registers_grid_unload-additional-point'
    },
    {
        key: 104,
        text: 'registers_grid_unload-additional-city'
    },
    {
        key: 113,
        text: 'registers_grid_additional-point'
    },
    {
        key: 114,
        text: 'registers_grid_additional-point'
    },
    {
        key: 200,
        text: 'registers_grid_excessive-time-plan'
    },
    {
        key: 201,
        text: 'registers_grid_working-time-tr',
        title: 'registers_grid_working-time-tr-title',
    },
    {
        key: 202,
        text: 'registers_grid_prr'
    },
    {
        key: 203,
        text: 'registers_grid_loaders'
    },
    {
        key: 204,
        text: 'registers_grid_repalletizing'
    },
    {
        key: 205,
        text: 'registers_grid_repalletizing'
    },
    {
        key: 206,
        text: 'registers_grid_parking'
    },
    {
        key: 207,
        text: 'registers_grid_prr'
    },
    {
        key: 208,
        text: 'registers_grid_excessive-time'
    },
    {
        key: 209,
        text: 'registers_grid_prr'
    },
    {
        key: 210,
        text: 'registers_grid_forwarding'
    },
    {
        key: 211,
        text: 'registers_grid_forwarding'
    },
    {
        key: 212,
        text: 'registers_grid_forwarding'
    },
    {
        key: 213,
        text: 'registers_grid_thermographer'
    },
    {
        key: 214,
        text: 'registers_grid_conics'
    },
    {
        key: 215,
        text: 'registers_grid_belts-spacers'
    },
    {
        key: 216,
        text: 'registers_grid_empty-pallets'
    },
    {
        key: 217,
        text: 'registers_grid_forwarding'
    },
    {
        key: 218,
        text: 'registers_grid_customs-clearance'
    },
    {
        key: 220,
        text: 'registers_grid_other'
    },
    {
        key: 221,
        text: 'registers_grid_preparation-tsd'
    },
    {
        key: 401,
        text: 'registers_grid_return'
    },
    {
        key: 402,
        text: 'registers_grid_return'
    },
    {
        key: 505,
        text: 'registers_grid_railway-others'
    },
    {
        key: 506,
        text: 'registers_grid_railway-international'
    },
    {
        key: 601,
        text: 'registers_grid_delivery-empty-pallets'
    },
    {
        key: 602,
        text: 'registers_grid_providing-empty-pallets'
    },
    {
        key: 700,
        text: 'registers_grid_distance-from-mkad'
    },
    {
        key: 701,
        text: 'registers_grid_tr-reserve'
    },
    {
        key: 702,
        text: 'registers_grid_security-escort-cargo'
    },
    {
        key: 703,
        text: 'registers_grid_storage'
    },
    {
        key: 704,
        text: 'registers_grid_cargo-insurance'
    },
    {
        key: 706,
        text: 'registers_grid_add-pack-lathing'
    },
    {
        key: 707,
        text: 'registers_grid_add-pack-box'
    },
    {
        key: 708,
        text: 'registers_grid_spec-eq-unload'
    },
    {
        key: 709,
        text: 'registers_grid_discount-additional-cargo'
    },
    {
        key: 710,
        text: 'registers_grid_city-pass'
    },
    {
        key: 711,
        text: 'registers_grid_platon'
    },
    {
        key: 712,
        text: 'registers_grid_entrance-ttk'
    },
    {
        key: 713,
        text: 'registers_grid_entrance-sk'
    },
    {
        key: 730,
        text: 'registers_grid_delivery-weekends'
    },
    {
        key: 731,
        text: 'registers_grid_submission-defined-time'
    },
    {
        key: 732,
        text: 'registers_grid_load-weekends'
    },
    {
        key: 733,
        text: 'registers_grid_unload-weekends'
    },
    {
        key: 734,
        text: 'registers_grid_load-no-work-time'
    },
    {
        key: 735,
        text: 'registers_grid_unload-avisation'
    },
    {
        key: 740,
        text: 'registers_grid_scoring'
    },
    {
        key: 741,
        text: 'registers_grid_oversized'
    },
    {
        key: 742,
        text: 'registers_grid_overweight'
    },
    {
        key: 743,
        text: 'registers_grid_fuel-surcharge'
    },
    {
        key: 800,
        text: 'registers_grid_pretension',
    },
];

const headers2 = [
    {
        key: 'costWithoutVAT',
        text: 'registers_grid_cost-without-vat',
    },
    {
        key: 'costWithVAT',
        text: 'registers_grid_cost-with-vat',
    },
];

const headers3 = [
    {
        key: 'additionalServices',
        text: 'registers_grid_additional-services',
    },
    {
        key: 'temperatureCondition',
        text: 'registers_grid_temperature-condition',
        sortable: false,
    },
    {
        key: 'clientComment',
        text: 'registers_grid_comment-client',
    },
    {
        key: 'clientCostWithoutVAT',
        text: 'registers_grid_cost-without-vat-client',
    },
    {
        key: 'state',
        text: 'registers_grid_state',
    },
];

export {headers1, headersLoadUnits, headers1Ext, headersTrip, headersServices, headers2, headers3};
