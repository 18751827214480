import React, {useState} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Form, Grid, GridColumn, GridRow, Header, Icon, Loader, Modal, Radio} from 'semantic-ui-react';
import {PickupTypeOptions} from '../../../api/model/Enums';
import {executeWaybills, resendWaybills} from '../../../api/waybills';
import { useTranslation } from 'react-i18next';

const WbSteps = {
    INITIAL: 0,
    PICKUP_CHOICE: 1,
    SENDING: 2,
    SUCCESS: 3,
    ERROR: 4,
};

const WbProcessingNegative = ({resultMessages, isResend}) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {!isResend && <Header>{t('waybills_label_reg-error')} <Icon name="frown outline"/></Header>}
            {resultMessages && resultMessages.map(text => <p dangerouslySetInnerHTML={{ __html: typeof text === 'object' ? t(text.key, {index: text.index || '', error: text.error || ''}) : text }} />)}
        </React.Fragment>
    );
}

const WbProcessingPositive = ({resultMessages, isResend}) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {!isResend && <Header>{t('waybills_label_reg-success')}<Icon name="smile outline"/></Header>}
            {resultMessages && resultMessages.map(text => <p dangerouslySetInnerHTML={{ __html: typeof text === 'object' ? t(text.key, {index: text.index || '', error: text.error || ''}) : text }} />)}
        </React.Fragment>
    );
}


const WbProcessingChooseCollection = ({selectedRows, pickupTypes, setPickupTypes}) =>
{
    const { t } = useTranslation();

    return (<Form>
        <Form.Field>
            <b>{t('waybills_label_delivery-method-question')}</b>
        </Form.Field>
        <div className="m-t-15 scrollable">

            <Grid verticalAlign="middle" divided='vertically'>
                <GridRow className="p-b-0">
                    <GridColumn width={2}>{t('waybills_grid_application')}</GridColumn>
                    <GridColumn width={3}>{t('waybills_grid_consignee')}</GridColumn>
                    <GridColumn width={4}>{t('waybills_grid_address')}</GridColumn>
                    <GridColumn width={7}>{t('waybills_grid_delivery-method')}</GridColumn>
                </GridRow>
                {selectedRows.map(r =>
                    <GridRow className="p-b-0 p-t-0">
                        <GridColumn width={2}>{r.id}</GridColumn>
                        <GridColumn width={3}>{r.consignee}</GridColumn>
                        <GridColumn width={4}>{r.shipto && r.shipto.toString()}</GridColumn>
                        <GridColumn width={7}>
                            <Form.Select fluid
                                         options={PickupTypeOptions}
                                         placeholder={t('waybills_placeholder_delivery-method')}
                                         value={pickupTypes[r.id]}
                                         onChange={(e, {value}) => setPickupTypes(r.id, value)}
                            />
                        </GridColumn>
                    </GridRow>
                )}
            </Grid>

        </div>
    </Form>)
}

function WbProcessingChooseCollectionInner({pickupType, setPickupType}) {
    const { t } = useTranslation();

    return (
        <Form>
            <Form.Field>
                {/* Выбрано: <b>{options.find(o => o.value === pickupType).text}</b> */}
                <b>{t('waybills_label_delivery-method-question')}</b>
            </Form.Field>
            {PickupTypeOptions.map(o =>
                <Form.Field key={o.key}>
                    <Radio
                        label={t(o.text)}
                        name='radioGroup'
                        value={o.value}
                        checked={pickupType === o.value}
                        onChange={(e, {value}) => setPickupType(value)}
                    />
                </Form.Field>
            )}
        </Form>
    );
}

function WbProcessing({dto, selectedRows, refresh, history, children, lastSavePromise, sendToFm, isResend = false}) {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(0);

    const [pickupTypeInner, setPickupTypeInner] = useState((dto && dto.pickupType) || 0); // is required to rerender radio block
    const [pickupChoiceComplete, setPickupChoiceComplete] = useState(false);
    const [resultMessages, setResultMessages] = useState([]);

    const isEditorView = !!dto;

    const selectedHubRows = isEditorView ? [] : selectedRows.filter(sr => sr.crossDockWithActivity || sr.hubWithOldAvization);

    function handleClose() {
        switch (step) {
            case WbSteps.SUCCESS:
            case WbSteps.SUCCESS_AVIZ:
                if (isEditorView) {
                    history.push(`/waybills`);
                } else {
                    refresh();
                }
                //break;

            default:
                setOpen(false);
                //break;
        }
    }

    function handleOpen() { // entry point
        setResultMessages([]);

        if (shouldChoosePickup() && !isResend) {
            setStep(WbSteps.PICKUP_CHOICE);
        } else {
            if (lastSavePromise) {
                lastSavePromise.then(res => {
                    isResend ? resendWaybill() : registerWaybill();
                });
            } else {
                isResend ? resendWaybill() : registerWaybill();
            }
        }

        setOpen(true);
    }

    function handlePickupChoice(id, value) {

        if (isEditorView) {
            dto.pickupType = value;
            setPickupTypeInner(value);
            setPickupChoiceComplete(true);
        } else {
            selectedHubRows.find(sr => sr.id === id).pickupType = value;
            if (selectedHubRows.every(sr => sr.pickupType > 0)) {
                setPickupChoiceComplete(true);
            }
        }
    }

    function shouldChoosePickup() {
        if (isEditorView) {
            return dto.hubLoading && dto.pointsLoading.some(pl => pl.crossDockWithActivity || pl.avisationByEmail);
        } else {
            return selectedHubRows.length > 0;
        }
    }

    async function registerWaybill() {
        if (sendToFm) {
            sendToFm(pickupTypeInner);
        } else {
            setStep(WbSteps.SENDING);

            let result = null;

            result = await executeWaybills(selectedRows || [dto]);

            setResultMessages(result.messages);

            if (result.hasError) {
                setStep(WbSteps.ERROR);
            } else {
                setStep(WbSteps.SUCCESS);
            }
        }
    }


    async function resendWaybill() {
        setStep(WbSteps.SENDING);
        try {
            let result = await resendWaybills(selectedRows.map(sr => sr.id) || []);
            console.log(result)
            setResultMessages(result.messages);
            setStep(WbSteps.SUCCESS);
        } catch (e) {
            setStep(WbSteps.ERROR);
        }
    }

    function getContent() {
        switch (step) {
            case WbSteps.INITIAL:
                return null;
            case WbSteps.PICKUP_CHOICE:
                return isEditorView
                    ? <WbProcessingChooseCollectionInner
                        pickupType={pickupTypeInner}
                        setPickupType={(value) => handlePickupChoice(dto.id, value)}
                    />
                    : <WbProcessingChooseCollection
                        selectedRows={selectedHubRows}
                        pickupTypes={selectedHubRows.map(shr => ({[shr.id]: shr.pickupType}))}
                        setPickupTypes={handlePickupChoice}
                    />
                    ;
            case WbSteps.SENDING:
                return <Loader indeterminate>{t('waybills_label_data-processing')}</Loader>;
            case WbSteps.SUCCESS:
                return <WbProcessingPositive resultMessages={resultMessages} isResend={isResend}/>;
            case WbSteps.ERROR:
                return <WbProcessingNegative resultMessages={resultMessages} isResend={isResend}/>;
            default:
                throw new Error(t('waybills_label_id-view-error'));
        }
    }

    function getContentActions() {
        switch (step) {
            case WbSteps.PICKUP_CHOICE:
                return <>
                    <Button
                        content={t('base_btn_cancel')}
                        onClick={handleClose}
                    />
                    <Button
                        primary
                        disabled={!pickupChoiceComplete || step === WbSteps.SENDING}
                        content={t('waybills_btn_send')}
                        onClick={registerWaybill}
                    />
                </>;
            default:
                return <Button
                    onClick={handleClose}
                    content={t('base_btn_close')}
                />;
        }
    }

    return (
        <Modal
            trigger={children}
            dimmer="inverted"
            size="small"
            closeOnEscape={false}
            closeOnDimmerClick={false}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            <Modal.Header>{t('waybills_label_sending')}</Modal.Header>
            <Modal.Content style={{minHeight: '220px'}}>
                {getContent()}
            </Modal.Content>
            <Modal.Actions>
                {getContentActions()}
            </Modal.Actions>
        </Modal>
    );

}

export default withRouter(WbProcessing);
