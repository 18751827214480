import React, { useContext } from "react";
import {
  Divider,
  Icon,
  Label,
  List,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Segment,
  Grid
} from "semantic-ui-react";
import LoadUnitsToOrderMapping from "../../../api/model/LoadUnitsToOrderMapping";
import AppButtonLink from "../../../components/buttons/AppButtonLink";
import { ContextWb } from "../../../services/context";
import WbPointCardTabsPositionsUnloadForm from "./WbPointCardTabsPositionsUnloadForm";
import { useTranslation } from 'react-i18next';

export default function PointCardTabsPositionsUnload({
  pointId,
  positions = [],
  handleDataChange,
  unitsLoadedTotal,
  isClient
}) {
    const { t } = useTranslation();

    const contextWb = useContext(ContextWb);
  const wb = contextWb.wb;
  const points = wb.pointsUnloading;

  async function positionAdd(position) {
    position.pointToId = pointId;
    position.id = Math.random();
    const newPositions = [...positions];
    newPositions.push(new LoadUnitsToOrderMapping(position));
    await handleDataChange("loadUnitsToOrderMappings", newPositions);
  }

  async function positionRemove(id) {
    const newPositions = [...positions];
    const targetIndex = newPositions.findIndex(p => p.id === id);
    newPositions.splice(targetIndex, 1);
    handleDataChange("loadUnitsToOrderMappings", newPositions);
  }

  async function positionUpdate(position) {
    const newPositions = [...positions];
    const target = newPositions.find(p => p.id === position.id);
    Object.assign(target, position);
    handleDataChange("loadUnitsToOrderMappings", newPositions);
  }

  const positionsPointScope = positions;
  let positionsPointScopeIndex = 0;

  const spanStyleMini = {
    minWidth: "20px",
    marginRight: "10px",
    display: "inline-block"
  };

  const spanStyle = {
    marginLeft: "10px",
    marginRight: "4px",
    display: "inline-block"
  };

  return (
    <>
      <div style={{ marginTop: "0", textAlign: "left", marginBottom: "4px" }}>
        <label style={{ fontSize: "13px", fontWeight: "bold" }}>
            {t('waybills_label_cargo-units-applications')}
        </label>
        <WbPointCardTabsPositionsUnloadForm
          wb={wb}
          points={points}
          header={t('waybills_label_add-nums')}
          isAdding
          unitsLoadedTotal={unitsLoadedTotal}
          pointId={pointId}
          submit={positionAdd}
          disabled={isClient}
        >
          <span className="f-r">
            <AppButtonLink style={{ fontSize: "12px" }} disabled={isClient}>
                {t('waybills_btn_add')}
            </AppButtonLink>
          </span>
        </WbPointCardTabsPositionsUnloadForm>
      </div>
      <Divider fitted />
      <div className="f-c" style={{ paddingTop: "0" }}>
        <Table basic="very" selectable className="table-unloads">
          <TableBody>
            {positionsPointScope.map((p, index) => (
              <WbPointCardTabsPositionsUnloadForm
                wb={wb}
                key={index}
                points={points}
                pointId={pointId}
                header={t('waybills_label_update-cargo-units')}
                unitsLoadedTotal={unitsLoadedTotal}
                value={p}
                submit={positionUpdate}
                isClient={isClient}
              >
                <Table.Row
                  key={positionsPointScopeIndex++}
                  className="mouse-pointer"
                >
                  {/* style={{ width: '80px' }} */}
                  <TableCell style={{ width: "90px" }}>
                    <Label color="pink" style={{ minWidth: "75px" }} horizontal>
                      c {p.loadUnitPositionStart || 1} по{" "}
                      {p.loadUnitPositionEnd || p.loadUnitsCount}
                    </Label>
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: "200px",
                      paddingLeft: "0",
                      fontSize: "14px"
                    }}
                  >
                    <div style={{ overflow: "hidden" }} className="ellipsis">
                      {p.shipperOrderNo && "WNO: " + p.shipperOrderNo + ";"}
                      {p.recipientOrderNo && "CNO: " + p.recipientOrderNo}
                      {p.weightKgBrutto && (
                        <>
                          ,<span style={spanStyle}>{t('waybills_label_weight')}:</span>
                          <span>{p.weightKgBrutto}</span>
                        </>
                      )}
                      {p.volumeM3 && (
                        <>
                          ,<span style={spanStyle}>{t('waybills_label_volume')}:</span>
                          <span>{p.volumeM3}</span>
                        </>
                      )}
                    </div>
                  </TableCell>
                  {/* style={{ width: '50px' }} */}
                  <TableCell
                    style={{
                      verticalAlign: "baseline",
                      width: "50px",
                      fontSize: "14px"
                    }}
                  >
                    <Icon
                      name="trash"
                      className="f-r"
                      link
                      onClick={positionRemove.bind(null, p.id)}
                    />
                  </TableCell>
                </Table.Row>
              </WbPointCardTabsPositionsUnloadForm>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
