const iconDefault = "question circle outline";
const iconEdit = "info";

export default class StepInfo {
    constructor(id, name, state, isEdit, icon) {
        this.id = id;
        this.key = id;
        this.name = name;
        this.state = state;
        this.icon = icon || (isEdit ? iconEdit : iconDefault);
    }
}

export class StepData {
    constructor(component, index, text, icon, disabled) {
        this.component = component;
        this.index = index;
        this.text = text;
        this.icon = icon || iconDefault; //|| (isEdit ? iconEdit : iconDefault);
        this.disabled = disabled;
    }
}