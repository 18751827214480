import O from '../../components/Option';

export const CompanyStatusEnum = {
    ACTIVE: 0,
    BLOCKED: 1
};

export const CompanyStatusOptions = [
    new O(CompanyStatusEnum.ACTIVE, "client_enum_active"),
    new O(CompanyStatusEnum.BLOCKED, "client_enum_blocked"),
];

export const LegalFormOptions = [
    new O('ООО', "client_enum_ooo"),
    new O('АО', "client_enum_ao"),
    new O('ОАО', "client_enum_oao"),
    new O('ЗАО', "client_enum_zao"),
    new O('ПАО', "client_enum_pao"),
    new O('ИП', "client_enum_ip"),
    new O('ПК', "client_enum_pk"),
    new O('ТОО', "client_enum_too"),
    new O('ЧП', "client_enum_chp"),
    new O('ОДО', "client_enum_odo"),
    new O('ФГУП', "client_enum_fgup"),
];

export default class Company {

    constructor(dto) {

        /** @type {number} */
        this.fmid = dto.fmid;   // searchName компании

		/** @type {string} */
		this.shipperName = dto.shipperName;

        /** @type {string} */
        this.name = dto.name;   

        /** @enum {CompanyStatusEnum} */
        this.statusId = dto.statusId || CompanyStatusEnum.ACTIVE;

        /** @type {string} */
        this.INN = dto.INN;     

        /** @type {string} */
        this.OGRN = dto.OGRN;

        /** @type {string} */
        this.regDate = dto.regDate;

        /** @type {string} */ 
		this.regAddress = dto.regAddress;   

		/** @type {string} */ 
		this.regAddressIndex = dto.regAddressIndex;   

        /** @type {string} */
        this.postAddress = dto.postAddress;

        /** @type {boolean} */
        this.postAddressEqRegAddress = dto.postAddressEqRegAddress;
    }
}