import React from 'react';
import './ProgressVisual.css';
import {WaybillStepStatusEnum} from '../../../../api/model/WaybillStep';
import moment from "moment";
import { useTranslation } from 'react-i18next';


export default function ProgressVisual({data = [], step, centerLine}) {
    const { t } = useTranslation();

    const getLen = (text) => {
        return (text && Math.min(20, text.length) * 6) || 10;
    };

    if (data.length) {

        let counter = 0;
        const firstOffset = 60;
        const labelOffset = 20;
        const carYOffset = 60;

        return (
            <svg
                width={(data.length - 1) * 200 + 150}
                height="90"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <g id="cigreen">
                        <circle stroke="green" fill="green" r="10"/>
                    </g>
                    <g id="cigrey">
                        <circle stroke="grey" fill="grey" r="10"/>
                    </g>
                </defs>


                {data.map((i, idx) => (
                    <g key={idx}>

                        {i.lineStatus != null && <React.Fragment>
                            <line
                                y1={centerLine}
                                x1={(step * counter) + firstOffset}
                                y2={centerLine}
                                x2={(step * (counter + 1)) + firstOffset}
                                strokeWidth="1"
                                stroke={i.lineStatus > WaybillStepStatusEnum.PENDING
                                    ? "green"
                                    : "grey"}
                            />
                            <foreignObject
                                y={carYOffset}
                                x={(step * (counter) + firstOffset)}
                                width="210"
                                height="40">
                                <div className="car-outer">
                                    <div className="car-inner">
                                        <div className="car-text">{i.lineCar}</div>
                                        <div className="car-text">{i.lineDriver}</div>
                                    </div>
                                </div>
                            </foreignObject>
                        </React.Fragment>}

                        <text
                            className="point-font"
                            y={labelOffset}
                            x={(step * counter) + firstOffset}
                            dx={-(getLen(i.pointName) / 2)}
                            textAnchor="start"
                            textLength={getLen(i.pointName)}
                        >
                            {i.pointName.trunc(22, true)}
                        </text>
                        {
                            (idx === (data.length - 1)) &&
                            <foreignObject
                                y={carYOffset}
                                x={(step * counter) + firstOffset - 25}
                                width="100"
                                height="40">
                                <div className="point-date">
                                        {i.unloadingDateDeparture ? i.unloadingDateDeparture : null}
                                </div>
                            </foreignObject>
                        }
                        <use
                            y={centerLine}
                            x={(step * counter++) + firstOffset}
                            xlinkHref={i.pointStatus > WaybillStepStatusEnum.PENDING ? "#cigreen" : "#cigrey"}/>

                    </g>
                ))}

                {/* <text
                    className="point-font"
                    y={labelOffset}
                    x={firstOffset}
                    dx={-(getLen(first.pointName) / 2)}
                    textLength={getLen(first.pointName)}
                    textAnchor="start"
                    >
                    {first.pointName.trunc(22, true)}
                </text>
                <use
                    y={centerLine}
                    x={firstOffset}
                    xlinkHref={first.pointStatus === WaybillStepStatusEnum.DONE ? "#cigreen" : "#cigrey"} /> */}

            </svg>
        );

    } else {
        return <span>{t('waybills_label_no-route-data')}</span>;
    }


}
