export const userStatusEnum = {
    INACTIVE: 0,
    ACTIVE: 1
};

export default class User {
    
    /**
     * @param {User} dto 
     */
    constructor(dto) {

        /** @type {number} */
        this.id = dto.id;

        /** @type {number} */
        this.roleId = dto.roleId;

        /** @type {string[] | undefined} */
        this.permissions = dto.permissions;

        /** @type {string} */
        this.email = dto.email;

        /** @type {string} */
        this.passwordHash = dto.passwordHash;

        /** @type {string} */
        this.token = dto.token;

         /** @type {string | undefined} */
        this.phone = dto.phone;

        /** @type {string} */
        this.firstName = dto.firstName;

        /** @type {string | undefined} */
        this.lastName = dto.lastName;

        /** @type {boolean|undefined} */
		this.isActive = dto.isActive;

        /** @type {string[] | undefined} */
        this.companies = dto.companies;

        /** @type {string} */
        this.startUrl = dto.startUrl;
    }

    get name() {
        return (this.firstName + (this.lastName ? (' ' + this.lastName) : '')) || userStatusEnum.email;
    }
}