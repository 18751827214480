

export const pointAbbrsMap = [
    { from: 'points_enum_autonomous_region_with_dot', to: 'points_enum_autonomous_region_short' },
    { from: 'points_enum_autonomous_region', to: 'points_enum_autonomous_region_short' },
    { from: 'points_enum_countryside', to: 'points_enum_countryside_short' },
    { from: 'points_enum_railway_station', to: 'points_enum_railway_station_short' },
    { from: 'points_enum_platform', to: 'points_enum_platform_short' },
    { from: 'points_enum_playground', to: 'points_enum_playground_short' },
	{ from: 'points_enum_passing', to: 'points_enum_passing_short' },
	{ from: 'points_enum_township', to: 'points_enum_township_short' },
	{ from: 'points_enum_village_with_arrow', to: 'points_enum_village_short' },
	{ from: 'points_enum_village', to: 'points_enum_village_short' },
    { from: 'points_enum_dead_end', to: 'points_enum_dead_end_short' },
    { from: 'points_enum_plot_short', to: 'points_enum_plot_short' },
    { from: 'points_enum_bowery', to: 'points_enum_bowery_short' },
    { from: 'points_enum_route', to: 'points_enum_route_short' },
    { from: 'points_enum_section', to: 'points_enum_section_short' },
    { from: 'points_enum_letter', to: 'points_enum_letter_short' },
    { from: 'points_enum_settlement', to: 'points_enum_settlement_short' },
    { from: 'points_enum_vld', to: 'points_enum_vld_short' },
    { from: 'points_enum_corp_with_arrow', to: 'points_enum_corp_short' },
    { from: 'points_enum_corp', to: ' points_enum_corp_short' }
];

export const replaceAbbrs = (pointAddress) => {
    const fieldsToReplace = [
        'country', 'region', 'area', 'settlement', 'streetName',
        'streetNo', 'building', 'office', 'value'
    ];

    const newAddress = { ...pointAddress };
    fieldsToReplace.forEach((field) => {
        let value = newAddress[field] || '';
        pointAbbrsMap.forEach(({ from, to }) => {
            const regexp = new RegExp(from, 'g');
            value = value.replace(regexp, to);
        });
        newAddress[field] = value;
    });
    return newAddress;
};

export default class PointAddress {
    
    /** @param {PointAddress} dto */
    constructor(dto = {}) {

        /** @type {number} */
        //this.id = dto.id;
        this.country = dto.country;

        /** @type {string} */
        this.region = dto.region;

        /** @type {string} */
        this.area = dto.area;

        /** @type {string} */
        this.settlement = dto.settlement;

        /** @type {string} */
        this.streetName = dto.streetName;

        /** @type {string} */
        this.streetNo = dto.streetNo;

        /** @type {string} */
        this.building = dto.building;

        /** @type {string} */
        this.office = dto.office;

        /** @type {string} */
        this.postalCode = dto.postalCode;

        /** @type {string} */
        this.kladr = dto.kladr;

        /** @type {string} */
        this.fias = dto.fias;

        this.fias_level = dto.fias_level;

        /** @type {string} */
        this.geoLon = dto.geoLon;

        /** @type {string} */
        this.geoLat = dto.geoLat;

        /** @type {string} */
        this.value = dto.value;
    }


    toString() {
		let result = '';
		const isSettlementRegion = this.region && this.settlement && this.settlement.includes(this.region);
		if (this.region && !isSettlementRegion) {
            this.region && (result += `${this.region}`);
        }
		this.area && (result += `, ${this.area}`);
        this.settlement && (result += result ? `, ${this.settlement}` : this.settlement);
        this.streetName && (result += `, ${this.streetName}`);
		this.streetNo && (result += `, ${this.streetNo}`);
		this.building && (result += `, ${this.building}`);

        return result.trim();
    }

    toStringWithRegion() {
        if (this.settlement && this.settlement.includes(this.region)) {
            return this.toString();
        }
        return `${this.region}, ${this.settlement || 'points_enum_locality'}, ${this.streetName || 'points_enum_street'}, ${this.streetNo || 'points_enum_house'}`;
    }
}