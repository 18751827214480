import debounce from 'awesome-debounce-promise';
import React, {useContext, useEffect, useState} from 'react';
import {Form, Grid, GridColumn, Input, Table} from 'semantic-ui-react';
import {LegalFormOptions} from '../../../../api/model/Company';
import Point, {LoadCapacityOptions, LoadPositionOptions, PointTypeEnum} from '../../../../api/model/Point';
import UserPermissions from '../../../../api/model/UserPermissions';
import {getActivitiesRequest, getPlatformsRequest, pointClientSearchNameUpdate} from '../../../../api/pointsClient';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import { useTranslation } from "react-i18next";
import O from '../../../../components/Option';
import {ContextUser} from '../../../../services/context';
import {RecordData, TableRecord} from '../../../waybills/wbView/record/Record';
import PointFormDays from '../pointForm/PointFormDays';
import InputDropdownMultiple from "../../../../components/inputs/InputDropdownMultiple";
import {toast} from "../../../../services/toast";


const DefaultCompanyTypeOptions = [
    new O(PointTypeEnum.SHOP, "points_input_shop"),
    new O(PointTypeEnum.DISTRIBUTION_CENTER, "points_input_rc"),
    new O(PointTypeEnum.RECEPIENT, "points_input_counterparty"),
    new O(PointTypeEnum.CLIENT_WAREHOUSE, "points_input_client_stock")
];

const HubCompanyTypeOptions = [
    new O(PointTypeEnum.FM, "points_input_cross_dock"),
];

const updateSearchNameDebounced = debounce((id, companyId, searchName) => pointClientSearchNameUpdate(id, companyId, searchName), 1200);

export function DataCell({title, text}) {
    return <Table.Cell><TableRecord data={new RecordData(title, text)}/></Table.Cell>;
}

/**  */
export default function PointViewTabAddrParams({point, updatePoint, isClient, companyId}) {
    const {t} = useTranslation();

    const contextUser = useContext(ContextUser);

    const [legalFormOptions, setLegalFormOptions] = useState(buildLegalFormOptions());
    const [errors, setErrors] = useState([]);
    const hasPermissionFmidAddress = contextUser.current.permissions.includes(UserPermissions.FMID_ADDRESS);
    const [platformOptions, setPlatformOptions] = useState([]);
    const [activityOptions, setActivityOptions] = useState([]);

    useEffect(() => {
        getPlatforms();
    }, []);

    useEffect(() => {
        point.platformId && getActivities();
    }, [point.platformId]);

    const getPlatforms = async () => {
        const {platforms} = await getPlatformsRequest();

        setPlatformOptions(platforms.map(i => new O(i.id, i.name)))
    };

    const getActivities = async () => {
        const {activities} = await getActivitiesRequest(point.platformId);

        setActivityOptions(activities.map(i => new O(i.id, i.name)))
    };

    useEffect(() => {
        const errorsNew = getErrorsForm();
        setErrors(errorsNew)
    }, [point]);

    useEffect(() => {
        if (errors.includes('dockCapacity')) {
            toast.warning(t("points_text_capacity_warning"));
        }
    }, []);

    function getErrorsForm() {
        let errors = [];

        if (!point.dockCapacity || point.dockCapacity && !point.dockCapacity.length) {
            errors.push('dockCapacity')
        }
        return errors
    }

    function handleLegalFormOptionAdd(e, {value}) {
        setLegalFormOptions([new O(value, value), ...legalFormOptions]);
    }

    function buildLegalFormOptions() {
        const value = point.companyLegalForm;
        const custom = !LegalFormOptions.some(item => item.value === value);
        if (custom) {
            return [...LegalFormOptions, new O(value, value)];
        }
        return LegalFormOptions;
    };

    function update(prop) {
        const pointChanged = new Point({...point, ...prop});
        updatePoint(pointChanged);
    };

    function updateClientSearchName(searchName) {
        const pointChanged = new Point({...point, ...{pointName: searchName}});
        updatePoint(pointChanged, false);
        updateSearchNameDebounced(point.id, companyId, searchName); // eslint-disable-line
    }

    const contact = (point.contacts && point.contacts.length && point.contacts[0]) || {};

    const isPointHub = point.pointType === PointTypeEnum.FM;

    const hasPermissions = contextUser.current.permissions.includes(UserPermissions.POINTS);

    const defaultTypeOption = hasPermissions ?
        [...DefaultCompanyTypeOptions, ...HubCompanyTypeOptions] :
        DefaultCompanyTypeOptions;

    const companyTypeOptions = isPointHub ?
        HubCompanyTypeOptions :
        defaultTypeOption;

    return (
        <Form>
            <Grid columns='equal' divided='vertically'>
                <Grid.Row>
                    <GridColumn>
                        <Form.Field
                            required
                        >
                            <label>{t("points_label_company_name")}</label>
                            <Input
                                disabled
                                placeholder={t("points_label_company_name")}
                                value={point.companyName}
                                onChange={e => update({companyName: e.target.value})}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>
                <Grid.Row>
                    <GridColumn>
                        <Form.Field>
                            <label>{t("points_input_legal_name")}</label>
                            <Input
                                disabled
                                placeholder={t("points_input_legal_name")}
                                value={point.companyLegalForm}
                                onChange={e => update({ companyLegalForm: e.target.value })}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>
                <Grid.Row>
                <GridColumn>
                    <Form.Field required>
                        <label>{t("points_label_company_type")}</label>
                        <InputDropdown
                            placeholder={t("points_label_company_type")}
                            options={companyTypeOptions}
                            value={point.pointType}
                            onChange={val => update({pointType: val})}
                            disabled={isPointHub || isClient}
                        />
                    </Form.Field>
                </GridColumn>
                    {
                        point.pointType === PointTypeEnum.FM &&
                        <>
                            <GridColumn>
                            <Form.Field>
                                <label>{t("points_input_platform")}</label>
                                <InputDropdown
                                    placeholder={t("points_placeholder_choose_platform")}
                                    options={platformOptions}
                                    disabled
                                    fluid
                                    value={point.platformId}
                                    onChange={val => update({platformId: val, activityId: null})}
                                />
                            </Form.Field>
                            </GridColumn>
                            <GridColumn>
                            <Form.Field>
                                <label>{t("points_input_activity")}</label>
                                <InputDropdown
                                    placeholder={t("points_placeholder_choose_activity")}
                                    options={activityOptions}
                                    disabled
                                    value={point.activityId}
                                    onChange={val => update({activityId: val})}
                                />
                            </Form.Field>
                            </GridColumn>
                        </>
                    }
                </Grid.Row>
                {isClient && <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field>
                            <label>{isClient ? t("points_placeholder_name_for_fast_search") : t("points_input_address_name_in_clients_system")}</label>
                            <Form.Input
                                placeholder={isClient ? t("points_placeholder_name_for_fast_search") : t("points_input_address_name_in_clients_system")}
                                value={point.pointName || ''}
                                onChange={e => updateClientSearchName(e.target.value)}
                            />
                        </Form.Field>

                    </GridColumn>
                </Grid.Row>}
                {hasPermissionFmidAddress && <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field>
                            <label>{t("points_text_address_name_in_fm_system")}</label>
                            <Input
                                disabled={true}
                                placeholder={t("points_placeholder_fmid_address")}
                                value={point.fmid}
                                onChange={(e) => update({fmid: e.target.value})}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>}
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Input
                            label={t("points_input_contact_person")}
                            placeholder={t("points_input_contact_person")}
                            value={contact.name || ''}
                            onChange={e => update({
                                contacts: [{...contact, name: e.target.value}]
                            })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            label={t("points_input_phone")}
                            placeholder={t("points_input_phone")}
                            value={contact.phone || ''}
                            onChange={e => update({
                                contacts: [{...contact, phone: e.target.value}]
                            })}
                        />
                    </GridColumn>
                </Grid.Row>
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field required error={errors.includes('dockCapacity')}>
                            <label>{t("points_input_lifting_capacity")}</label>
                            <InputDropdownMultiple
                                disabled={isClient}
                                placeholder={t("points_input_lifting_capacity")}
                                disabled
                                options={LoadCapacityOptions}
                                value={point.dockCapacity}
                                onChange={val => update({dockCapacity: val})}
                            />
                        </Form.Field>
                    </GridColumn>
                    <GridColumn>
                        <Form.Field>
                            <label>{t("points_input_load_side")}</label>
                            <InputDropdown
                                placeholder={t("points_input_load_side")}
                                disabled
                                options={LoadPositionOptions}
                                value={point.dockPosition}
                                onChange={val => update({dockPosition: val})}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <PointFormDays
                            days={point.days}
                            onChange={val => update({days: val})}
                        />
                    </GridColumn>
                </Grid.Row>
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Input
                            label={t("points_input_comment")}
                            placeholder={t("points_input_comment")}
                            disabled
                            value={point.comment || ''}
                            onChange={e => update({comment: e.target.value})}
                        />
                    </GridColumn>
                </Grid.Row>
            </Grid>
        </Form>
    );
}
