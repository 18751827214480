import React from 'react';
import { toast } from './toast';
import i18next from "i18next";

export function isBrowserSupported() {
    let recomended = true, modr = window.Modernizr;
    for (let feature in modr) {
        if (typeof modr[feature] === "boolean" && modr[feature] === false) {
            recomended = false;
            break;
        }
    }
    return recomended;
}

export function isBrowserSupportedMin() {
    if (window.Modernizr.fetch) {
        window.needBrowserRecomendation = !isBrowserSupported();
        return true;
    } 
    return false;
}
 
export function checkForBrowserRecommendation() {
    if (window.needBrowserRecomendation) {
        const text = <span>{i18next.t('base_label_browser-recommended')}<br />{i18next.t('base_label_latest-browser-v')} <a href="https://www.google.ru/intl/ru/chrome/" target="_blank" rel="noopener noreferrer">Google Chrome</a></span>;

        toast.warning(text, 'base_label_browser-not-compatible');
        window.needBrowserRecomendation = false;
    }
}