// eslint-disable-next-line no-unused-vars
import { RequestTypeEnum } from "./Enums";
// eslint-disable-next-line no-unused-vars
import { PackTypeEnum } from "./Enums";
import Persistable from "./Persistable";

export default class LoadUnitsToOrderMapping extends Persistable {

    /** @param {LoadUnitsToOrderMapping} dto */
    constructor(dto) {
        super();

        /** @type {string} */
        this.id = dto.id; // || this.getId2(); fe only


        /** @type {string} */
        this.pointToId = dto.pointToId;      // is used for export


        /** @type {number} */
        this.loadUnitPositionStart = dto.loadUnitPositionStart;

        /** @type {number} */
        this.loadUnitPositionEnd= dto.loadUnitPositionEnd;


        // /** @type {string} */
        // this.shipperSearchName = dto.shipperSearchName;

        /** @type {string} */
        this.shipperOrderNo = dto.shipperOrderNo;


        // /** @type {string} */
        // this.recipientSearchName = dto.recipientSearchName;

        /** @type {string} */
        this.recipientOrderNo = dto.recipientOrderNo;

        /** @type {string} */
        this.torg12No = dto.torg12No;

        /** @enum {RequestTypeEnum} */
        this.orderType = dto.orderType;

        this.weightKgBrutto = dto.weightKgBrutto;

        this.volumeM3 = dto.volumeM3;

        this.cost = dto.cost;
    }
}
