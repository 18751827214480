import React from 'react';
import { Form, Input, Divider } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";

import PointFormAddress from './PointFormAddress/PointFormAddress';

const PointFormStep1 = ({ ve, data, hasPermissions, update, onAddressTabChange,
    addressDadata, setAddressDadata, addressManual, setAddressManual, activeAddressTab, setActiveAddressTab,
    fromWaybill, isClient
}) => {

    const { t } = useTranslation();

    return <React.Fragment>
        <div>
            <PointFormAddress
                error={ve.includes("address")}
                errorMessage={t("points_text_address_incorrect")}
                validationErrors={ve}
                address={data.address || {}}
                onChange={val => update({ address: val })}
                onAddressTabChange={onAddressTabChange}
                addressDadata={addressDadata}
                setAddressDadata={setAddressDadata}
                addressManual={addressManual}
                setAddressManual={setAddressManual}
                activeAddressTab={activeAddressTab}
                setActiveAddressTab={setActiveAddressTab}
            />
            <Divider />
            <Form.Group style={{ marginTop: 20 }} widths='equal'>
                {hasPermissions && !fromWaybill && !isClient && <>
                    <Form.Field required error={ve.includes("fmid")}>
                        <label>{t("points_placeholder_short_name")}</label>
                        <Input
                            placeholder={t("points_placeholder_short_name")}
                            value={data.fmid || ''}
                            onChange={e => update({ fmid: e.target.value.toUpperCase() })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("points_placeholder_zone_msk")}</label>
                        <Input
                            placeholder={t("points_placeholder_zone_msk")}
                            disabled={true}
                            value={data.zone || ''}
                            onChange={e => update({ zone: e.target.value })}
                        />
                    </Form.Field>
                </>}
                {isClient && <Form.Field>
                    <label>{t("points_placeholder_name_for_fast_search")}</label>
                    <Input
                        placeholder={t("points_placeholder_name_for_fast_search")}
                        value={data.pointName || ''}
                        onChange={e => update({ pointName: e.target.value })}
                    />
                </Form.Field>}
            </Form.Group>
        </div>
    </React.Fragment>;
};

export default PointFormStep1;