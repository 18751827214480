import React from 'react';
import Spinner from '../Spinner';
import Error from '../Error';

export default function Shield({ loading, loadingOver, error, children }) {
    return (
        <>
            {(loading && loadingOver) || (!loading && !error) ? children : null}
            {loading ? <Spinner show /> : null}
            {!loading && error ? <Error error={error} /> : null}
        </>
    );
    //при таком подходе перегружается страница полностью
    /* if (loading) {

          if (loadingOver) {
              return (
                  <React.Fragment>
                      {children}
                      <Spinner show />
                  </React.Fragment>
              );
          } else {
              return <Spinner show />;
          }

      } else {
          if (error) {
              return <Error error={error} />;
          } else {
              return children;
          }
      }*/
}
