import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';
import InputDropdown from '../../../components/inputs/InputDropdown';
import InputDropdownSearchMultiple from '../../../components/inputs/InputDropdownSearchMultiple';
import O from '../../../components/Option';
import {getCompanyOptionsBySearch} from "../../../api/companies";
import {useTranslation} from 'react-i18next';

UserForm.propTypes = {
    update: PropTypes.func.isRequired,
    data: PropTypes.shape({
        lastName: PropTypes.string,
        firstName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        isAllCompanies: PropTypes.bool
    }),
    profileOptions: PropTypes.arrayOf(PropTypes.shape(O)),
    companyOptions: PropTypes.arrayOf(PropTypes.shape(O))
};

export default function UserForm({ update, data = {}, profileOptions = [], companyOptions, isEdit, errors = [] }) {
    const {t} = useTranslation();

    if (!profileOptions.length)
        return null;

    return (
        <Form>
            <Form.Group widths="equal">
                <Form.Input
                    required
                    label={t("users_input_name")}
                    placeholder={t("users_input_name")}
                    value={data.firstName}
                    onChange={e => update({ firstName: e.target.value })}
                />
                <Form.Input
                    label={t("users_input_surname")}
                    placeholder={t("users_input_surname")}
                    value={data.lastName}
                    onChange={e => update({ lastName: e.target.value })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Input
                    required
                    error={errors.includes('email')}
                    label={t("users_input_email")}
                    placeholder={t("users_input_email")}
                    value={data.email}
                    onChange={e => update({ email: e.target.value })}
                />
                <Form.Input
                    label={t("users_input_telephone")}
                    placeholder={t("users_input_telephone")}
                    value={data.phone}
                    onChange={e => update({ phone: e.target.value })}
                />
            </Form.Group>
            <Form.Field>
                <label>{t("users_label_link-companies")}</label>
                <InputDropdownSearchMultiple
                    getOptionsBySearch={getCompanyOptionsBySearch}
                    placeholder={t("users_text_enter-three-letter")}
                    options={companyOptions}
                    disabled={data.isAllCompanies}
                    value={data.companies}
                    onChange={(values, options) => update({ companies: values })}
                />
            </Form.Field>
            <Form.Checkbox
                    label={t("users_label_link-all-companies")}
                    checked={data.isAllCompanies}
                    onChange={(e, item) => update({ isAllCompanies: item.checked })} />
            <Form.Field required>
                <label>{t("users_label_role")}</label>
                <InputDropdown
                    placeholder={t("users_label_choose-role")}
                    options={profileOptions}
                    value={data.roleId}
                    onChange={val => update({ roleId: val })}
                />
            </Form.Field>

            {isEdit
                ? <Form.Checkbox
                    label={t("users_text_locked")}
                    checked={data.isBlocked}
                    onChange={(e, item) => update({ isBlocked: item.checked })} />
                : <Form.Checkbox
                    label={t("users_text_send-password-after-creation")}
                    checked={data.sendPassword}
                    onChange={(e, item) => update({ sendPassword: item.checked })} />}
        </Form>
    );

}
