import React, {useEffect, useState} from 'react';
import {Form} from 'semantic-ui-react';

import {PointTypeEnum} from '../../../../api/model/Point';
import {LegalFormOptions} from '../../../../api/model/Company';
import InputDropdownSearch from '../../../../components/inputs/InputDropdownSearch';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import {useTranslation} from "react-i18next";
import O from '../../../../components/Option';
import {getActivitiesRequest, getPlatformsRequest} from "../../../../api/pointsClient";

const PointFormStep2 = ({ data, update, ve, hasPermissions, companyOptions, selectedCompany, setSelectedCompany, isClient }) => {

    const [legalFormOptions, setLegalFormOptions] = useState(LegalFormOptions);

    const [platformOptions, setPlatformOptions] = useState([]);

    const [activityOptions, setActivityOptions] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        getPlatforms();
    }, []);

    useEffect(() => {
        data.platformId && getActivities();
    }, [data.platformId]);

    const getPlatforms = async () => {
        const {platforms} = await getPlatformsRequest();

        setPlatformOptions(platforms.map(i => new O(i.id, i.name)))
    };

    const getActivities = async () => {
        const {activities} = await getActivitiesRequest(data.platformId);

        setActivityOptions(activities.map(i => new O(i.id, i.name)))
    };

    function handleLegalFormOptionAdd(e, { value }) {
        setLegalFormOptions([new O(value, value), ...legalFormOptions]);
        //setValue(value);
    }

    const contact = (data.contacts && data.contacts.length && data.contacts[0]) || {};

    const rowPointType = (
        <React.Fragment>
            <Form.Group inline>
                <Form.Radio
                    label={t("points_input_shop")}
                    value={data.pointType}
                    checked={data.pointType === PointTypeEnum.SHOP}
                    onChange={(e, el) => update({ pointType: PointTypeEnum.SHOP })}
                />
                <Form.Radio
                    label={t("points_input_rc")}
                    value={data.pointType}
                    checked={data.pointType === PointTypeEnum.DISTRIBUTION_CENTER}
                    onChange={(e, el) => update({ pointType: PointTypeEnum.DISTRIBUTION_CENTER})}
                />
                <Form.Radio
                    label={t("points_input_counterparty")}
                    value={data.pointType}
                    checked={data.pointType === PointTypeEnum.RECEPIENT}
                    onChange={(e, el) => update({ pointType: PointTypeEnum.RECEPIENT })}
                />
                <Form.Radio
                    label={t("points_input_client_stock")}
                    value={data.pointType}
                    checked={data.pointType === PointTypeEnum.CLIENT_WAREHOUSE}
                    onChange={(e, el) => update({ pointType: PointTypeEnum.CLIENT_WAREHOUSE })}
                />
                {hasPermissions && <Form.Radio
                    label={t("points_input_cross_dock")}
                    value={data.pointType}
                    checked={data.pointType === PointTypeEnum.FM}
                    onChange={(e, el) => update({ pointType: PointTypeEnum.FM })}
                />}
            </Form.Group>
        </React.Fragment>
    );

    const companyOption = new O(data.companyName, data.companyName);

    const rowCompany = (
            <Form.Field required error={ve.includes("companyName")}>
                    <label>{t("points_label_company_name")}</label>
                    <InputDropdownSearch
                        allowAdditions
                        placeholder={t("points_label_enter_company_name")}
                        options={data.companyName ? [companyOption] : []}
                        value={data.companyName}
                        onChange={(value, text) => update({
                            companyName: text,
                        })}
                    />
            </Form.Field>
    );

    const rowCompanyLegalName = (
            <Form.Field>
                <label>{t("points_input_legal_name")}</label>
                <InputDropdownSearch
                    allowAdditions
                    placeholder={t("points_input_legal_name")}
                    options={data.companyLegalName ? [companyOption] : []}
                    value={data.companyLegalName}
                    onChange={(value, text) => update({
                        companyLegalName: text,
                    })}
                />
            </Form.Field>
        );

    return <React.Fragment>

        {isClient && <Form.Field
            required
            error={ve.includes('selectedCompany')}
        >
            <label>{t("points_input_select_company")}</label>
            <InputDropdown
                placeholder={t("points_input_select_company")}
                options={companyOptions}
                value={selectedCompany}
                onChange={val => {
                    setSelectedCompany(val);
                    update({});
                }}
            />
        </Form.Field>}
        {rowCompany}
        {rowCompanyLegalName}
        {rowPointType}
        <Form.Group widths="equal">
            <Form.Input error={ve.includes("contactName")}
                label={t("points_input_contact_person")}
                placeholder={t("points_input_contact_person")}
                value={contact.name}
                onChange={e => update({
                    contacts: [{ ...contact, name: e.target.value }]
                })}
            />
            <Form.Input error={ve.includes("contactPhone")}
                label={t("points_input_phone")}
                placeholder={t("points_input_phone")}
                value={contact.phone}
                onChange={e => update({
                    contacts: [{ ...contact, phone: e.target.value }]
                })}
            />
        </Form.Group>
        {
            data.pointType === PointTypeEnum.FM &&
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>{t("points_input_platform")}</label>
                        <InputDropdown
                            placeholder={t("points_placeholder_choose_platform")}
                            options={platformOptions}
                            value={data.platformId}
                            onChange={val => update({platformId: val, activityId: null})}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("points_input_activity")}</label>
                        <InputDropdown
                            placeholder={t("points_placeholder_choose_activity")}
                            options={activityOptions}
                            disabled={!data.platformId}
                            value={data.activityId}
                            onChange={val => update({activityId: val})}
                        />
                    </Form.Field>
                </Form.Group>
        }
    </React.Fragment>;
};

export default PointFormStep2;
