import React, {useState, useEffect, useMemo} from 'react';
import {Button, Form, Message, Modal} from "semantic-ui-react";
import {EnumHoursOptions} from "./companyTabCutoffs/_shared/EnumHours";
import {EnumMinutesOptions} from "./companyTabCutoffs/_shared/EnumMinutes";
import {CargoTypeOptions, CompanyTypeOptions, CutoffDayOptions, WeekDayOptions} from "../../../../api/model/Enums";
import {useTranslation} from 'react-i18next';

import {
    companyCutoffTimeLTLAdd,
    companyCutoffUpdateRuleAdd,
    companyCutoffUpdateRuleEdit,
    getCutoffUpdateRule
} from "../../../../api/companyCutoffs";

const CutoffUpdateRuleAdd = ({children, fetchData, type, companyId, id}) => {
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isClickSave, setIsClickSave] = useState(false);
    const {t} = useTranslation();

    const getRule = async () => {
            const result = await getCutoffUpdateRule(companyId, id);
            setData({
                cutoffDay: result.cutoffDay,
                pointType: result.pointType,
                cargoType: result.cargoType,
                cutoffHour: result.time && result.time.split.length ? EnumHoursOptions.find(item => item.text === result.time.split(':')[0]).value : null,
                cutoffMinutes: result.time && result.time.split.length ? EnumMinutesOptions.find(item => item.text === result.time.split(':')[1]).value : null,
            })
    };

    useEffect(() => {
        if (isClickSave) {
            const errorsForm = getErrorsForm();
            setErrors(errorsForm);
        }
    }, [data]);

    function getErrorsForm() {
        let errorsNew = [];
        if (data && !data.cutoffHour && data.cutoffHour !== 0) {
            errorsNew.push('cutoffHour')
        }
        if (data && !data.cutoffMinutes && data.cutoffMinutes !== 0) {
            errorsNew.push('cutoffMinutes')
        }
        if (data && !data.cargoType && data.cargoType !== 0) {
            errorsNew.push('cargoType')
        }
        if (data && !data.pointType && data.pointType !== 0) {
            errorsNew.push('pointType')
        }
        if (data && !data.cargoType && data.cutoffDay !== 0) {
            errorsNew.push('cutoffDay')
        }
        return errorsNew;
    }

    function updateData(prop) {
        setData({ ...data, ...prop });
    }

    const handleOpen = () => {
        console.log('EnumHoursOptions',EnumHoursOptions);
        id && getRule();
        setOpen(true)
    };

    const handleClose = () => {
        setIsClickSave(false);
        setData({});
        setErrors([]);
        setOpen(false);
    };

    const mapData = () => {
        return {
            shippingType: type,
            cutoffDay: data.cutoffDay,
            pointType: data.pointType,
            cargoType: data.cargoType,
            time: `${data.cutoffHour}:${data.cutoffMinutes}`
        }
    };

    const handleSave = async () => {
        setIsClickSave(true);
        const errorsForm = getErrorsForm();
        if (errorsForm.length > 0) {
            setErrors(errorsForm);
        } else {
            setLoading(true);
            try {
                if (id) {
                    await companyCutoffUpdateRuleEdit(companyId, mapData(), id);
                    await fetchData();
                } else {
                    await companyCutoffUpdateRuleAdd(companyId, mapData());
                    fetchData();
                }
            } finally {
                setLoading(false);
                handleClose();
            }
        }
    };


    const cutoffDayOptionsTranslate = useMemo(() => {
        return CutoffDayOptions.map(bto => ({
            ...bto,
            text: t(bto.text)
        }))
    }, [CutoffDayOptions, t]);

    const cargoTypeOptionsTranslate = useMemo(() => {
        return CargoTypeOptions.map(bto => ({
            ...bto,
            text: t(bto.text)
        }))
    }, [CargoTypeOptions, t]);

    const enumHoursOptionsTranslate = useMemo(() => {
        return EnumHoursOptions.map(bto => ({
            ...bto,
            text: t(bto.text)
        }))
    }, [EnumHoursOptions, t]);

    const enumMinutesOptionsTranslate = useMemo(() => {
        return EnumMinutesOptions.map(bto => ({
            ...bto,
            text: t(bto.text)
        }))
    }, [EnumMinutesOptions, t]);


    const companyTypeOptionsTranslate = useMemo(() => {
        return CompanyTypeOptions.map(bto => ({
            ...bto,
            text: t(bto.text)
        }))
    }, [CompanyTypeOptions, t]);

    return (
        <Modal
            trigger={children}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            <Modal.Header>
                {id ? t("users_label_editing-time-limit") : t("users_label_adding-time-limit")}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Select
                            error={errors.includes('cutoffDay')}
                            required
                            label={t("users_grid_cutoff-date")}
                            placeholder={t("users_grid_cutoff-date")}
                            options={cutoffDayOptionsTranslate}
                            value={data.cutoffDay}
                            onChange={(e, { value }) => updateData({ cutoffDay: value })}
                        />
                        <Form.Select
                            error={errors.includes('cargoType')}
                            required
                            label={t("users_grid_cargo-type")}
                            placeholder={t("users_grid_cargo-type")}
                            options={cargoTypeOptionsTranslate}
                            value={data.cargoType}
                            onChange={(e, { value }) => updateData({ cargoType: value })}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Select
                            error={errors.includes('cutoffHour')}
                            required
                            label={t("users_grid_cutoff-hour")}
                            placeholder={t("users_grid_cutoff-hour")}
                            options={enumHoursOptionsTranslate}
                            value={data.cutoffHour}
                            onChange={(e, { value }) => updateData({ cutoffHour: value })}
                        />
                        <Form.Select
                            error={errors.includes('cutoffMinutes')}
                            required
                            label={t("users_grid_cutoff-minutes")}
                            placeholder={t("users_grid_cutoff-minutes")}
                            options={enumMinutesOptionsTranslate}
                            value={data.cutoffMinutes}
                            onChange={(e, { value }) => updateData({ cutoffMinutes: value })}
                        />
                    </Form.Group>
                    <Form.Select
                        error={errors.includes('pointType')}
                        required
                        label={t("users_grid_company-type")}
                        placeholder={t("users_grid_company-type")}
                        options={companyTypeOptionsTranslate}
                        value={data.pointType}
                        onChange={(e, { value }) => updateData({ pointType: value })}
                    />
                </Form>
                {errors.length > 0 && <Message warning>
                    {t("users_text_require")}
                </Message>}
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={loading} onClick={handleClose}>{t("base_btn_cancel")}</Button>
                <Button disabled={loading} primary onClick={handleSave}>{id ? t("base_btn_save") : t("base_btn_confirm")}</Button>
            </Modal.Actions>
        </Modal>
    )
};

export default CutoffUpdateRuleAdd;
