import React from 'react';
import { Popup } from "semantic-ui-react";

export function PopupTitle({children, title, position}) {
    return (
        <Popup
            closeOnTriggerClick
            trigger={children}
            content={title}
        />
    );
}