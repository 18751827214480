import React, {useContext, useEffect, useState} from 'react';
import {Dropdown, Icon} from 'semantic-ui-react';
import './index.css';
import {ContextLocale} from '../../../services/context';
import {getLanguages} from "../../../api/users";
import {useTranslation} from "react-i18next";

export default function LanguageMenu() {
    const [options, setOptions] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getOptions();
    }, []);

    const getOptions = async () => {
        const result = await getLanguages();
        setOptions(result.map(item => ({
            key: item.name,
            value: item.name,
            text: item.nativeName,
        })))
    };

    const contextLocale = useContext(ContextLocale);

    const handleItemClick = (e, {value}) => {
        contextLocale.setLocale(value, i18n);
    };

    return (
        <div className="language-menu-wrapper">
            <Icon name='globe' inline="true"/> <Dropdown
            onChange={handleItemClick}
            inline
            options={options}
            value={i18n.language}
        />
        </div>
    );

}

