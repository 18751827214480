import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserPermissions from '../../api/model/UserPermissions';
import { Authorized } from '../../components/Authorized';
import ErrorNotFound from '../ErrorNotFound';
import Companies from './companies/Companies';
import Points from './points/Points';
import { PointView } from './points/pointView/PointView';
import UserRoles from './userRoles/UserRoles';
import Users from './users/Users';
import Providers from './providers/Providers';
import CompanyTabs from './companies/CompanyTabs';

export function Admin() {
    return (
        <Switch>
            <Authorized
                path='/admin' exact
                component={Companies}
                permission={UserPermissions.COMPANIES}
            />
            <Authorized
                path="/admin/companies" exact
                component={Companies}
                permission={UserPermissions.COMPANIES}
            />
            <Authorized
                path='/admin/companies/:id/:tab'
                component={CompanyTabs}
                permission={UserPermissions.COMPANIES}
            />
            <Authorized
                path='/admin/points' exact
                component={Points}
                permission={UserPermissions.POINTS}
            />
            <Authorized
                path='/admin/points/:id/:tab' exact
                component={PointView}
                permission={UserPermissions.POINTS}
            />
            <Authorized
                path='/admin/users'
                component={Users}
                permission={UserPermissions.USERS}
            />
            <Authorized
                path='/admin/providers'
                component={Providers}
                permission={UserPermissions.PROVIDERS}
            />
            <Authorized
                path='/admin/profiles' exact
                component={UserRoles}
                permission={UserPermissions.USERPROFILES}
            />
            <Route component={ErrorNotFound} />
        </Switch>
    );
}
