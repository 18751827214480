const UserPermissions = {
    WAYBILLS: 1,
    WAYBILL_EDIT: 2,
	COMPANIES: 100,
    CREATING_COMPANIES: 101,
	PROVIDERS: 150,
    POINTS: 200,
    CREATING_POINTS: 201,
    CREATING_MY_POINTS: 202,
    USERS: 300,
    USERPROFILES: 400,
    WAYBILL_SOFT_DELETE: 3,
    GETTING_NOTIFICATIONS: 4,
    COMPANY_POINTS: 5,
    FMID_ADDRESS: 6,
    REGISTRIES: 500,
    COMPLETION_REGISTRIES: 501,
    REGISTRIES_NOTIFICATIONS: 502,
    REGISTRIES_DELETE : 503,
    BANNERS: 600,
    BANNER_NOTIFICATIONS: 601,
    AUTOCOMPLETE: 700,
    STANDARD_AUTOCOMPLETE: 701,
    SCHEDULES: 800,
    SCHEDULES_EDIT: 801,
    SCHEDULES_SETTINGS_VIEAW: 802,
    RETURN_WAYBILLS_EDIT: 900,
    DISALLOW_AVISATION: 1000,
    ALLOW_UPDATE_TRANSLATIONS: 1100,
    WAYBILL_STATUS_NOTIFICATIONS: 7,
    WAYBILLS_RESEND: 8,
    NEW_WAYBILLS_NOTIFICATION: 9
};

export default UserPermissions;
