import React, {useEffect, useState, useMemo} from 'react';
import {Button, Form, Message, Modal} from "semantic-ui-react";
import { companyCutoffTimeLTLAdd } from '../../../../../api/companyCutoffs';
import { CargoTypeOptions } from '../../../../../api/model/Enums';
import { EnumHoursOptions } from './_shared/EnumHours';
import { EnumMinutesOptions } from './_shared/EnumMinutes';
import {useTranslation} from 'react-i18next';

export function CutoffsLTLTimeAdd({ children, onAdd, companyId }) {
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isClickSave, setIsClickSave] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (isClickSave) {
            const errorsForm = getErrorsForm();
            setErrors(errorsForm);
        }
    }, [data]);

    function getErrorsForm() {
        let errorsNew = [];
        if (data && !data.cutoffHour && data.cutoffHour !== 0) {
            errorsNew.push('cutoffHour')
        }
        if (data && !data.cutoffMinutes && data.cutoffMinutes !== 0) {
            errorsNew.push('cutoffMinutes')
        }
        if (data && !data.cargoType && data.cargoType !== 0) {
            errorsNew.push('cargoType')
        }
        return errorsNew;
    }

    function updateData(prop) {
        setData({ ...data, ...prop });
    }

    function onClickClose() {
        setIsClickSave(false);
        setData({});
        setErrors([]);
        setOpen(false);
    }

    async function save() {
        setIsClickSave(true);
        const errorsForm = getErrorsForm();
        if (errorsForm.length > 0) {
            setErrors(errorsForm);
        } else {
            setLoading(true);
            try {
                const newRule = await companyCutoffTimeLTLAdd(companyId, data);
                if (newRule)
                    onAdd(data);
            } finally {
                setLoading(false);
                onClickClose();
            }
        }

    }

    const cargoTypeOptionsTranslate = useMemo(() => {
        return CargoTypeOptions.map(bto => ({
            ...bto,
            text: t(bto.text)
        }))
    }, [CargoTypeOptions, t]);

    return (
        <Modal
            trigger={children}
            dimmer="inverted"
            size="small"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={onClickClose}
        >
            <Modal.Header>{t("users_label_adding-time-limit-sending")}</Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
					<Form.Group widths="equal">
                    	<Form.Select
                            error={errors.includes('cutoffHour')}
							required
	                        label={t("users_grid_cutoff-hour")}
	                        placeholder={t("users_grid_cutoff-hour")}
							options={EnumHoursOptions}
							value={data.cutoffHour}
	                        onChange={(e, { value }) => updateData({ cutoffHour: value })}
	                    />
						<Form.Select
                            error={errors.includes('cutoffMinutes')}
							required
							label={t("users_grid_cutoff-minutes")}
							placeholder={t("users_grid_cutoff-minutes")}
							options={EnumMinutesOptions}
							value={data.cutoffMinutes}
							onChange={(e, { value }) => updateData({ cutoffMinutes: value })}
						/>
                    </Form.Group>
                    <Form.Select
                        error={errors.includes('cargoType')}
						required
                        label={t("users_grid_cargo-type")}
                        placeholder={t("users_grid_cargo-type")}
                        options={cargoTypeOptionsTranslate}
                        value={data.cargoType}
                        onChange={(e, { value }) => updateData({ cargoType: value })}
                    />
                    <Form.Checkbox
                        label={t("users_label_crossdock")}
						checked={data.isHubLoading}
						onChange={(e, item) => updateData({ isHubLoading: item.checked })}
                    />
                </Form>
                {errors.length > 0 && <Message warning>
                    {t("users_text_require")}
                </Message>}
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={loading} onClick={onClickClose}>{t("base_btn_cancel")}</Button>
                <Button disabled={loading} primary onClick={save}>{t("base_btn_save")}</Button>
            </Modal.Actions>
        </Modal>
    );
}
