import React from 'react';
import './AppTableToolbar.css';


export default function AppTableToolbar({children}) {

    return (
        <div className="app-table-toolbar flex-row">
            {children}
        </div>
    );
}

AppTableToolbar.Left = function({children}) {
    return <div className="app-table-toolbar-left">{children}</div>;
};
AppTableToolbar.Right = function({children}) {
    return <div className="app-table-toolbar-right">{children}</div>;
};