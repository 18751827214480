import React, { useContext, useState } from 'react';
import {
    Button, Confirm,
    Icon,
    Label,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {
    WaybillStatusAvizationEnum,
    WaybillStatusEnum,
    WaybillStatusOptions,
} from '../../../api/model/Waybill';
import useReactRouter from 'use-react-router';
import { ContextWb } from '../../../services/context';
import { LoadCapacityOptions } from '../../../api/model/Point';
import WbAvizModal from '../wbGrid/WbAvizModal';
import { getWaybillsInVisit, waybillDelete } from '../../../api/waybills';
import { reservationDelete } from '../../../api/waybillAviz';
import { useTranslation } from 'react-i18next';

const columns = [
    {
        key: 'fmid',
        text: 'waybills_grid_num',
        type: 'LINK',
    },
    {
        key: 'status',
        text: 'registers_grid_status',
        type: 'STATUS',
    },
    {
        key: 'recipientOrderNo',
        text: 'registers_grid_recipient-num-order',
        type: 'ENUM',
    },
    {
        key: 'epCount',
        text: 'waybills_grid_pallet-count',
    },
    {
        key: 'bxCount',
        text: 'waybills_grid_box-count',
    },
    {
        key: 'address',
        text: 'waybills_grid_address',
    },
    {
        key: 'shipper',
        text: 'waybills_grid_consignee',
    },
];

const WbAvisTable = ({
    items = [],
    id,
    reservationId,
}) => {
    const { history } = useReactRouter();
    const contextWb = useContext(ContextWb);

    let [isConfirm, setIsConfirm] = useState(false);
    let [currentId, setCurrentId] = useState(null);

    const getContentCell = (type, value, id) => {
        if (type && value) {
            if (type === 'ENUM') {
                return typeof value === 'object' ? value.join(', ') : value;
            }

            if (type === 'LINK') {
                return <Link to={`/waybills/${id}/view/info`}>{value || id}</Link>;
            }

            if (type === 'STATUS') {
                let o = WaybillStatusOptions.find(o => o.key === value);

                return o ? (
                    <Label
                        color={o.color}
                        style={{ textAlign: 'center', marginRight: '5px' }}
                        title={o.text.t}
                    >
                        {o.text.t}
                    </Label>
                ) : (
                    <Label style={{ textAlign: 'center', marginRight: '5px' }}>{value}</Label>
                );
            }
        }

        return value || '';
    };

    const handleDelete = (rowId) => {
        setIsConfirm(true);
        setCurrentId(rowId);
    };

    const confirmDelete = async () => {
        setIsConfirm(false);
        contextWb.setLoading(true);
        try {
            const ids = await reservationDelete(currentId, reservationId);
            let rows = [];
            if (ids && ids.length > 0) {
                rows = await getWaybillsInVisit(ids);
            }

            history.push({ pathname: `/waybills`, state: { rows } });
        } finally {
            contextWb.setLoading(false);
            setCurrentId(null);
        }
    };
    const { t } = useTranslation();

    return (
        <>
            <Table>
                <TableHeader>
                    <TableRow>
                        {columns.map(column => (
                            <TableHeaderCell key={column.key}>{t(column.text)}</TableHeaderCell>
                        ))}
                        <TableHeaderCell>
                            {t('registers_label_actions')}
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {items.map(row => (
                        <TableRow key={row.id} className={row.id == id ? 'active' : ''}>
                            {columns.map(column => (
                                <TableCell key={`${column.key}_${row.id}`}>
                                    {getContentCell(column.type, row[column.key], row.id)}
                                </TableCell>
                            ))}
                            <TableCell>
                                {[
                                    WaybillStatusEnum.SUBMITTED,
                                    WaybillStatusEnum.ON_APPROVAL,
                                    WaybillStatusEnum.EXECUTING,
                                ].includes(row.status) &&
                                [
                                    WaybillStatusAvizationEnum.CONFIRMED,
                                    WaybillStatusAvizationEnum.FILL_DRIVER,
                                ].includes(row.avisationStatus) ? (
                                    <Icon
                                        size="large"
                                        name="trash alternate outline"
                                        className="avis-table-button-link"
                                        onClick={() => handleDelete(row.id)}
                                    />
                                ) : null}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Confirm
                open={isConfirm}
                cancelButton={t('base_btn_cancel')}
                header={t('waybills_label_del-from-booking-question')}
                content={t('waybills_label_del-from-booking-if-yes')}
                onCancel={() => setIsConfirm(false)}
                onConfirm={confirmDelete}
            />
        </>
    );
};

export default WbAvisTable;
