import React, { useState, useEffect } from 'react';
import { Modal, Button, Dimmer, Loader, Form } from 'semantic-ui-react';
import InputDropdownMultiple from '../../../components/inputs/InputDropdownMultiple';
import { getPermissionOptions } from '../../../api/usersRoles';
import InputDropdown from '../../../components/inputs/InputDropdown';
import { useTranslation } from 'react-i18next';

const colors = [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'teal',
    'blue',
    'violet',
    'purple',
    'pink',
    'brown',
    'grey',
    'black',
];

export default function UserRoleForm({open, onClose, data: propsData = {}, title, submit, isEdit }) {
    const permissionOptions = getPermissionOptions();
    const [errors, setErrors] = useState([]);
    const [data, setData] = useState(propsData || {
        name: '',
        color: '',
        permissions: []
    });
    const [loading, setLoading] = useState(false);

    const tagOptions = colors.map(c =>
        ({
            text: c,
            value: c,
            label: { color: c, empty: true, circular: true }
        })
    );

    useEffect(() => {
       isEdit && open && setData(propsData);
    }, [propsData]);

    const handleClose = () => {
        onClose();
        setData({});
    };

    const getValidationErrors = (data) => {
        const res = [];
        if (!data.name) {
            res.push('name');
        }
        if (!data.permissions.length) {
            res.push('permissions');
        }
        return res;
    };

    const resetFrom = () => {
        setLoading(false);
        setErrors([]);
        handleClose();
    };

    const handleSubmit = async () => {
        const ve = getValidationErrors(data);
        setErrors(ve);
        if (ve.length) {
            return;
        }
        setLoading(true);
        try {
            await submit(data);
            resetFrom();
        } finally {
            setLoading(false);
        }

    };

    const update = (partialData) => {
        const newData = { ...data, ...partialData };
        setErrors([]);
        setData(newData);
    };
    const { t } = useTranslation();

    const form = (
        <Form>
            <Form.Input
                required
                error={errors.includes('name')}
                label={t("users_input_role-name")}
                placeholder={t("users_input_role-name")}
                maxLength={200 }
                value={data.name}
                onChange={e => update({ name: e.target.value })}
            />
            <Form.Field>
                <label>{t("users_label_color")}</label>
                <InputDropdown
                    placeholder={t("users_input_choose-color")}
                    options={tagOptions}
                    value={data.color}
                    onChange={val => update({ color: val })}

                />
            </Form.Field>
            <Form.Field error={errors.includes('permissions')} requried>
                <label>{t("users_label_accesses")} <span style={{ color: '#db2828' }}>*</span></label>
                <InputDropdownMultiple
                    required
                    placeholder={t("users_placeholder_choose-accesses")}
                    options={permissionOptions}
                    value={data.permissions}
                    onChange={val => update({ permissions: !val.length ? [] : val})}
                />
            </Form.Field>
        </Form>
    );

    return (
        <Modal
            centered={true}
            dimmer="inverted"
            size="small"
            closeIcon
            open={open}
            onClose={handleClose}
            closeOnEscape={!loading}
            closeOnDimmerClick={!loading}
        >
            <Modal.Header>{title || t("base_btn_add")}</Modal.Header>
            <Modal.Content>
                <Dimmer.Dimmable dimmed={loading}>
                    <Dimmer active={loading} inverted>
                        <Loader></Loader>
                    </Dimmer>
                    {form}
                </Dimmer.Dimmable>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={handleClose}
                    disabled={loading}>{t("base_btn_cancel")}</Button>
                <Button
                    positive
                    disabled={loading}
                    onClick={handleSubmit} >{t("base_btn_save")}</Button>
            </Modal.Actions>
        </Modal>
    );

}
