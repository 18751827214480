import React from "react";
import {WeekDayOptions} from "../../api/model/Enums";
import {Image} from "semantic-ui-react";
import {useTranslation} from 'react-i18next';

function ucFirst(str) {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
}

const ScheduleShema = ({points}) => {
    const {t} = useTranslation();
    return (
        <svg
            width={650}
            height="100"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <foreignObject y={10} x={5} width="85" height="50">
                <Image src="/img/m2.png" style={{width: '100%', height: '100%', objectFit: 'contain'}}/>
            </foreignObject>
            <foreignObject y={5} x={0} width="80" height="40">
                <div>
                    <b>{ucFirst(t(WeekDayOptions.getText(points.consolidationPoint.day)))}</b>
                </div>
            </foreignObject>
            <foreignObject y={60} x={0} width="80" height="40">
                <div>
                    <i>{t("schedules_label_consolidation")}</i>
                </div>
                <div>
                    {`${t("schedules_label_week")}${points.consolidationPoint.weekNumber}`}
                </div>
            </foreignObject>
            <line y1={45} x1={55} y2={45} x2={305} strokeWidth="1" stroke="#0e4194"/>
            <foreignObject y={0} x={285} width="85" height="85">
                <Image src="/img/m1.png" style={{width: '100%', height: '100%', objectFit: 'contain'}}/>
            </foreignObject>
            <foreignObject y={5} x={280} width="80" height="40">
                <div>
                    <b>{ucFirst(t(WeekDayOptions.getText(points.shipmentPoint.day)))}</b>
                </div>
            </foreignObject>
            <foreignObject y={60} x={280} width="80" height="40">
                <div>
                    <i>{t("schedules_label_send")}</i>
                </div>
                <div>
                    {`${t("schedules_label_week")}${points.shipmentPoint.weekNumber}`}
                </div>
            </foreignObject>
            <line
                y1={45}
                x1={335}
                y2={45}
                x2={555}
                strokeWidth="1"
                stroke="#0e4194"
            />
            <foreignObject y={25} x={535} width="55" height="35">
                <Image src="/img/m3.png" style={{width: '100%', height: '100%', objectFit: 'contain'}}/>
            </foreignObject>
            <foreignObject y={5} x={525} width="80" height="40">
                <div>
                    <b>{ucFirst(t(WeekDayOptions.getText(points.deliveryPoint.day)))}</b>
                </div>
            </foreignObject>
            <foreignObject y={60} x={525} width="80" height="40">
                <div>
                    <i>{t("schedules_label_delivery")}</i>
                </div>
                <div>
                    {`${t("schedules_label_week")}${points.deliveryPoint.weekNumber}`}
                </div>
            </foreignObject>
        </svg>
);
};

export default ScheduleShema;
