// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import O from "../../components/Option";
import Persistable from "./Persistable";
import { getText } from "./Enums";
import {getIsoDate} from "../waybills";
import i18next from "i18next";


export const WaybillTypeEnum = {
    LTL: "LTL",
    FTL: "FTL",
    Rail: "Rail",
    RailLTL: "RailLTL",
    Return: 'Return',
    PlanReturn: 'PlanReturn',
    UrbanDelivery: 'UrbanDelivery',
};

export const WaybillAvisationStatus = {
    // Не требует авизации
    NONE: 0,
    // Требует авизацию
    REQUESTED: 1,
    // Авизовано
    CONFIRMED: 9
};
//
export const WaybillTypeOptions = [
    new O('LTL', "waybills_enum_ltl"),
    new O('FTL', "waybills_enum_ftl"),
   /* new O(WaybillTypeEnum.Rail, "Rail"),
    new O(WaybillTypeEnum.RailLTL, "RailLTL"),*/
    new O('Return', "waybills_enum_return"),
    /*new O(WaybillTypeEnum.PlanReturn, "PlanReturn"),*/
    new O('UrbanDelivery', "waybills_enum_urbandelivery"),
];
WaybillTypeOptions.getText = getText;

export const WaybillCarTypeEnum = {
    BOX: 10,
    ISOTERM: 20,
    OPEN: 30,
    LUGGAGE: 40,
    CONTHIGH: 100,
    CONTWIDE: 110,
    CONT_REF_20F: 120,
    CONT_REF_40F: 121,
    CONT_INS_20F: 130,
    CONT_INS_40F: 131,
    CONT_20F: 140,
    CONT_40F: 141,
    REF: 150
};

export const WaybillCarTypeOptions = [
    new O(WaybillCarTypeEnum.BOX, "waybills_enum_boxcar"),
    new O(WaybillCarTypeEnum.ISOTERM, "waybills_enum_isothermal-carriage"),
    new O(WaybillCarTypeEnum.OPEN, "waybills_enum_open-carriage"),
    new O(WaybillCarTypeEnum.LUGGAGE, "waybills_enum_luggage"),
    new O(WaybillCarTypeEnum.CONTHIGH, "waybills_enum_conthigh"),
    new O(WaybillCarTypeEnum.CONTWIDE, "waybills_enum_contwide"),
    new O(WaybillCarTypeEnum.CONT_REF_20F, "waybills_enum_cont-ref-20f"),
    new O(WaybillCarTypeEnum.CONT_REF_40F, "waybills_enum_cont-ref-40f"),
    new O(WaybillCarTypeEnum.CONT_INS_20F, "waybills_enum_cont-ins-20f"),
    new O(WaybillCarTypeEnum.CONT_INS_40F, "waybills_enum_cont-ins-40f"),
    new O(WaybillCarTypeEnum.CONT_20F, "waybills_enum_cont-20f"),
    new O(WaybillCarTypeEnum.CONT_40F, "waybills_enum_cont-40f"),
    new O(WaybillCarTypeEnum.REF, "waybills_enum_ref")
];
WaybillCarTypeOptions.getText = getText;

export const BodyCapacityEnum = {
    Capacity16: 16,
    Capacity35: 35,
    Capacity45: 45,
    Capacity86: 86,
};

export const TransTypeEnum = {
    PICK_UP: 1,
    DELIVERY: 2,
    PICK_UP_AND_DELIVERY: 3,
};

export const BodyCapacityOptions = [
    new O(BodyCapacityEnum.Capacity16, "waybills_enum_16-m3"),
    new O(BodyCapacityEnum.Capacity35, "waybills_enum_35-m3"),
    new O(BodyCapacityEnum.Capacity45, "waybills_enum_45-m3"),
    new O(BodyCapacityEnum.Capacity86, "waybills_enum_86-m3"),
];
WaybillCarTypeOptions.getText = getText;


export class Oc extends O {
    constructor(id, text, color) {
        super(id, text);
        this.color = color;
    }
};

export const WaybillStatusEnum = {
    // Черновик
    DRAFT: 0,
    // Отправлено
    SUBMITTED: 101,
    // Проверка
    ON_APPROVAL: 102,
    // Отмена
    CANCELLED: 111,
    // Исполняется
    EXECUTING: 120,
    // Получено у отправителя
    RECEIVED_FROM_SENDER: 130,
    // Прибыло в РЦ
    ARRIVED_RC: 140,
    // Отправлено в РЦ
    SENT_FROM_RC: 150,
    // Груз доставлен грузополучателю
    CARGO_DELIVERED: 230,
    // Завершена
    COMPLETED: 290

};

export const WaybillStatusAnomaly = {

    /// Брак
    REJECT: 240,

    /// Излишек
    OVERAGE:  242,

    /// Недосдача
    SHORTAGE: 243,

    /// Проблема с документами
    PROBLEM_DOCUMENTS: 249,

    /// Проблема с товаром
    PROBLEM_GOODS: 250

};

export const WaybillStatusAvizationEnum = {
    NONE: 0,
    REQUIRES: 1,
    FILL_DRIVER: 2,
    CONFIRMED: 9
};

export const WaybillStatusAnomalyOptions = [
	new Oc(WaybillStatusAnomaly.REJECT, "waybills_enum_reject", "red"),
    new Oc(WaybillStatusAnomaly.OVERAGE, "waybills_enum_overage", "orange"),
    new Oc(WaybillStatusAnomaly.SHORTAGE, "waybills_enum_shortage", "pink"),
    new Oc(WaybillStatusAnomaly.PROBLEM_DOCUMENTS, "waybills_enum_problem-docs", "blue"),
    new Oc(WaybillStatusAnomaly.PROBLEM_GOODS, "waybills_enum_problem-goods", "teal"),
];

export const WaybillStatusOptions = [
	new Oc(WaybillStatusEnum.DRAFT, "waybills_enum_draft"),
	new Oc(WaybillStatusEnum.SUBMITTED, "waybills_enum_submitted", "yellow"),
    new Oc(WaybillStatusEnum.ON_APPROVAL, "waybills_enum_on-approval", "orange"),
    new Oc(WaybillStatusEnum.CANCELLED, "waybills_enum_cancelled", "pink"),
    new Oc(WaybillStatusEnum.EXECUTING, "waybills_enum_executing", "green"),
    new Oc(WaybillStatusEnum.RECEIVED_FROM_SENDER, "waybills_enum_received-from-sender", "blue"),
    new Oc(WaybillStatusEnum.ARRIVED_RC, "waybills_enum_arrived-rc", "purple"),
    new Oc(WaybillStatusEnum.SENT_FROM_RC, "waybills_enum_sent-from-rc", "brown"),
    new Oc(WaybillStatusEnum.CARGO_DELIVERED, "waybills_enum_cargo-delivered", "teal"),
    new Oc(WaybillStatusEnum.COMPLETED, "waybills_enum_completed", "olive"),
];

export const WaybillStatusAvizationOptions = [
    new Oc(WaybillStatusAvizationEnum.NONE, "waybills_enum_docs-aviz-none"),
    new Oc(WaybillStatusAvizationEnum.CONFIRMED, "waybills_enum_docs-aviz-confirmed", "green"),
    new Oc(WaybillStatusAvizationEnum.REQUIRES, "waybills_enum_docs-aviz-requires", "red"),
    new Oc(WaybillStatusAvizationEnum.FILL_DRIVER, "waybills_enum_docs-fill-driver", "orange")
];

export const RouteStatusOptions = [
    new Oc(200, "waybills_enum_planned", "blue"),
    new Oc(201, "waybills_enum_ts-assigned", "blue"),
    new Oc(210, "waybills_enum_ts-for-load", "blue"),
    new Oc(212, "waybills_enum_ts-from-load", "orange"),
    new Oc(220, "waybills_enum_cargo-at-cross-dock", "red"),
	new Oc(221, "waybills_enum_cargo-in-transit", "pink"),
	new Oc(225, "waybills_enum_ts-for-unload", "teal"),
	new Oc(226, "waybills_enum_ts-from-unload", "green")
];

export const LoadCapacityEnum = {
    C2000: 2000,
    C5000: 5000,
    C10000: 10000,
    C20000: 20000,
};

export const nonDeleteErrorStatusSet = [
    WaybillStatusEnum.SUBMITTED,
    WaybillStatusEnum.ON_APPROVAL,
    WaybillStatusEnum.EXECUTING,
    WaybillStatusEnum.CARGO_DELIVERED
];

export const nonDeleteErrorAvisStatusSet = [
    WaybillAvisationStatus.NONE,
    WaybillAvisationStatus.REQUESTED,
];

// take from order
export default class Waybill extends Persistable {

    /** @param {Waybill | undefined} dto */
    constructor(dto = {}) {
        super();

        /** @type {string}  */
        this.id = dto.id || this.getId2();           // internal guid id (or string)

        /** @type {string | undefined}  */
        this.fmid = dto.fmid || i18next.t("waybills_label_draft1");        // is also used as draft name for waybill


        /** @type {string | undefined} */
        this.clientId = dto.clientId;                // company searchName


        /** @type {bool } */
        this.canCopy = dto.canCopy;                // canCopy waybill

        /** @type {string | undefined} */
        this.slotId = dto.slotId;                // slotId

		//this.visitId = dto.visitId;
		this.reservationId = dto.reservationId;
		this.visitNumber = dto.visitNumber;

        const hasPointsUnloading = dto.pointsUnloading && dto.pointsUnloading.length;

		this.deliveryDate = hasPointsUnloading
            ? getIsoDate(dto.pointsUnloading[dto.pointsUnloading.length - 1], "to")
            : null;

        const hasPointsLoading = dto.pointsLoading && dto.pointsLoading.length;

        this.loadingDate = hasPointsLoading
            ? getIsoDate(dto.pointsLoading[0])
            : null;

        /** @type {string | undefined} */
        this.providerId = dto.providerId;                // providerId

        /** @type {string | undefined} */
        this.providerName = dto.providerName;                // providerName

        /** @enum {WaybillTypeEnum} */
        this.shippingType = dto.shippingType || WaybillTypeEnum.LTL;

        /** @type {WaybillStatusEnum} */
        this.status = dto.status || WaybillStatusEnum.DRAFT;

        /** @type {WaybillStatusAnomaly} */
        this.statusAnomaly = dto.statusAnomaly


        this.statusShipping = dto.statusShipping;

        /** @type {string} */
        this.shippingTemperatureCondition = dto.shippingTemperatureCondition;

        /** @enum {LoadCapacityEnum} */
        this.vehicleCapacity = dto.vehicleCapacity;

        /** @enum {WaybillCarTypeEnum} */
        this.carType = dto.carType;

        /** @type {string} */
        this.cargoType = dto.cargoType;

        /** @type {number | undefined} */
        this.cargoCost = dto.cargoCost;


        /** @type {WaybillPoint[] | undefined} */
        this.pointsLoading = dto.pointsLoading || [];

        /** @type {WaybillPoint[] | undefined} */
        this.pointsUnloading = dto.pointsUnloading || [];

        this.steps = dto.steps;

        /** @type {boolean} */
		this.isValid = dto.isValid;

		/** @type {boolean} */
		this.isPooling = dto.isPooling;

        /** @type {boolean | undefined} */
        this.hubLoading = dto.hubLoading;

        /** @type {boolean | undefined} */
        this.pickupType = dto.pickupType;

        /** @type {boolean | undefined} */
        this.bodyCapacity = dto.bodyCapacity;

        /** @type {string | undefined} */
        this.pickupId = dto.pickupId;

        /** @enum {WaybillAvisationStatus} */
        this.avisationStatus = dto.avisationStatus;

        /** @type {boolean | undefined} */
		this.canHaveAvisation = dto.canHaveAvisation;

		/** @type {string | undefined} */
		this.podIdAvailable = dto.podIdAvailable;

		this.temperatureCondition = dto.temperatureCondition;

		this.returnWaybillId = dto.returnWaybillId;

		this.returnWaybillFMID = dto.returnWaybillFMID;

		this.shippings = dto.shippings;
    }

    isValidated() {
        return false;
    }
}
