import AwesomeDebouncePromise from 'awesome-debounce-promise';
import qs from 'query-string';
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import {normalizeTime} from "../../../../services/normalizeTime";

const iconClear = "close icon fitted";

const handleValueChangeDebounced = AwesomeDebouncePromise(handleFilterApply, 1500);

function handleFilterApply(applyFilter, field, val) {
    applyFilter({
        [field]: val
    });
}

function WbGridFilterStringContains({field, applyFilter, removeFilter, location, isTime}) {

    const q = qs.parse(location.search);

    const [value, setValue] = useState(q[field] || '');

    useEffect(() => {
        const q = qs.parse(location.search);
        setValue(q[field] || '');
    }, [location.search]);

    async function handleValueChange(val) {
        setValue(val);
        await handleValueChangeDebounced(applyFilter, field, val);
        if (!val) {
            setValue("");
            removeFilter(field);
        }
    }

    function handleClearFilterClick() {
        if (value) {
            setValue("");
            removeFilter(field);
        }
    };

    return (
        <div
            className="filter-flex-container"
            style={{ backgroundColor: value ? '#ffface' : null }}>
            <span className="filter-flex-left">=</span>
            <span className="filter-flex-center">
                <input
                    className="filter-date-input noXButton"
                    style={ { backgroundColor: value ? '#ffface' : null } }
                    type="text"
                    onChange={e => handleValueChange(isTime ? normalizeTime(e.target.value) : e.target.value)}
                    value={value} />
            </span>
            {value &&
                <span
                    className="filter-flex-right filter-flex-right-active"
                    onClick={handleClearFilterClick}>
                    <i aria-hidden="true" className={iconClear} />
                </span>
            }
        </div>
    );
}

export default withRouter(WbGridFilterStringContains);
