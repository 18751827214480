import React from 'react';
import { withRouter } from "react-router-dom";
import UrlFilter from './models/UrlFilter';
import FilterInputClearButton from './FilterInputClearButton';
import FilterMenuList from './FilterMenuList';
import {withTranslation} from "react-i18next";


class WbGridFilterList extends React.Component {

    handleInputClick() {
        console.log('initial input click (to be reassined.)');
    }

    handleOptionClick(name) {
        this.props.applyFilter({
            [this.props.field]: name
        });
    };

    handleClearClick() {
        this.props.removeFilter(this.props.field);
    };

    render() {
        const { t } = this.props;
        const urlState = new UrlFilter(this.props.location.search, this.props.field);
        const value = urlState.value;

        const option = value && this.props.options
            .find(o => (typeof o.key !== "string" ? o.key.toString() : o.key) === value);

        const text = option && option.text;

        return (
            <div
                className="filter-flex-container"
                style={{ backgroundColor: value ? '#ffface' : null }}>
                <div className="ui input filter-flex-center">
                    <input
                        className="filter-date-input"
                        style={ { backgroundColor: value ? '#ffface' : null } }
                        type="text"
                        readOnly
                        value={this.props.value || t(text)}
                        onClick={() => this.handleInputClick && this.handleInputClick()}
                    />
                </div>
                <div className={value ? "filter-flex-right filter-flex-right-active" : "filter-flex-right"}>
                    {
                        (this.props.value || value)
                            ? <FilterInputClearButton
                                handleInputClearClick={this.handleClearClick.bind(this)} />
                            : <FilterMenuList
                                options={this.props.options}
                                handleExternalClick={click => this.handleInputClick = click}
                                handleOptionClick={this.handleOptionClick.bind(this)} />
                    }
                </div>

            </div>
        );
    }
}

export default withTranslation()(withRouter(WbGridFilterList));
