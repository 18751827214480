import React, {useContext, useEffect, useRef, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {getAll} from '../../../api/waybills';
import {LinkInfo} from '../../../layout/navbar/Navbar';
import Shield from '../../../layout/page/shield/Shield';
import {ContextFooter, ContextGridSettings, ContextNavi} from '../../../services/context';
import './WbGrid.css';
import WbGridTable from './WbGridTable';
import OrdersToolbar from './wbGridToolbar/WbGridToolbar';
import WbAvizModal from "./WbAvizModal";
import {LoadCapacityOptions} from "../../../api/model/Point";
import {actionBanner, closeBanner, getViewBanners} from "../../../api/banners";
import {Button, Icon, Message} from "semantic-ui-react";
import { useTranslation } from 'react-i18next';


export default function WbGrid({location, history}) {
    const { t } = useTranslation();

    const contextNavi = useContext(ContextNavi);
    const contextFooter = useContext(ContextFooter);
    const contextGridSettings = useContext(ContextGridSettings);
    const headers = contextGridSettings.columns.filter(c => !c.hide).sort(function(a, b) {
        return a.order - b.order;
    });

    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [banners, setBanners] = useState([]);
    const [sendActionBanner, setSendActionBanner] = useState(false);
    const scrollContainer = useRef(null);

    const [isOpenAvizModal, setIsOpenAvizModal] = useState(false);
    const [avisRows, setAvisRows] = useState([]);

    async function fetchData() {
        setLoading(true);
        try {
            const result = await getAll(location.search);
            setRows(result);
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);

            // чтоб при уходе с карточки можно было открыть окно авизации на гриде

            if (location.state && location.state.rows && location.state.rows.length) {
                setAvisRows(location.state.rows);
                setIsOpenAvizModal(true);
                history.replace(location.pathname);
            }
        }
    }

    async function getBanners() {
        const banners = await getViewBanners();
        setBanners(banners);
    }

    function updateFMIDs(scope) {
        const rowsUpdated = [...rows];
        const targetIds = scope.map(wb => wb.id);
        const targets = rowsUpdated.filter(r => targetIds.includes(r.id));
        targets.forEach(t => {
            const source = scope.find(wb => wb.id === t.id);
            t.fmid = source.fmid;
            t.status = source.status;
        });
        setRows(rowsUpdated);
    }

    //const OrdersToolbarWithRouter = withRouter(OrdersToolbar);

    useEffect(() => {
        fetchData();
    }, [location.search]);
    useEffect(() => {
        contextNavi.setItems([new LinkInfo('waybills_label_my-applications', "/")]);
        getBanners();
    }, []);
    useEffect(() => {
        contextFooter.setIndicator(
            () => <div className="table-footer">
            {selectedIds.length
                ? `${t('waybills_label_is-select')}: ${selectedIds.length} / `
                : null}
            {rows.length} {t('waybills_label_rows')}
        </div>
        );
        return (() => {
            contextFooter.setIndicator(null);
        });
    }, [selectedIds, rows]);

    const handleDismissBanner = async () => {
        await closeBanner(banners[0].id);
        getBanners();
    };

    const handleActionBanner = async () => {
        setSendActionBanner(true);
        try {
            if(banners[0].url && banners[0].url.trim())
                window.open(banners[0].url, "_blank");

            await actionBanner(banners[0].id);
            getBanners();
        } finally {
            setSendActionBanner(false);
        }

    };

    const OrdersToolbarWithRouter = withRouter(OrdersToolbar);

    return (
        <Shield loading={loading} loadingOver={loaded}>
            {
                banners && banners.length
                ? <div className="banners">
                        <Message>
                            <Message.Header>
                                {t(banners[0].title)}
                            </Message.Header>
                            <Message.Content>
                                <div className="banner-close">
                                    <Icon name="close" size="large" title={t('waybills_btn_close-banner')} onClick={handleDismissBanner} />
                                </div>
                                <div>
                                    {
                                        t(banners[0].text)
                                    }
                                    <Button loading={sendActionBanner} disabled={sendActionBanner} onClick={handleActionBanner}>
                                        {t(banners[0].buttonText)}
                                    </Button>
                                </div>
                            </Message.Content>
                        </Message>
                    </div>

                    : null
            }
            <OrdersToolbarWithRouter
                selectedRows={rows.filter(r => selectedIds.includes(r.id))}
                hasRows={rows.length}
                setSelectedIds={setSelectedIds}
                refresh={fetchData}
                updateFMIDs={updateFMIDs}
                loading={setLoading}
                setIsOpenAvizModal={setIsOpenAvizModal}
            />
            <WbAvizModal
                open={isOpenAvizModal}
                onClose={() => {
                    setIsOpenAvizModal(false);
                    setAvisRows([]);
                    /* refresh();*/
                }}
                onAvisation={() => {
                    setIsOpenAvizModal(false);
                    fetchData();
                    setAvisRows([]);
                }}
                selectedRows={avisRows && avisRows.length ? avisRows : rows.filter(r => selectedIds.includes(r.id))}
                optionsTypeTransport={LoadCapacityOptions}
            >
            </WbAvizModal>
            <div className="table-wrapper-grid">
             {/*   <div className="table-scroll table-header">
                    <WbGridHeaders
                        headers={headers}
                        selectedIds={selectedIds || []}
                        selectIds={setSelectedIds}
                        getRowsIds={() => rows.map(r => r.id)}
                    />
                </div>*/}
                <div className={headers.length <= 11 ? 'table-scroll' : "table-scroll-grid"} ref={scrollContainer}>
                        <WbGridTable
                            scrollComponent={scrollContainer}
                            setLoading={setLoading}
                            headers={headers}
                            rows={rows}
                            setRows={setRows}
                            selectedIds={selectedIds || []}
                            selectIds={setSelectedIds}
                            getRowsIds={() => rows.map(r => r.id)}
                        />
                </div>
            </div>
        </Shield>
    );

}
