import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Checkbox, Form, Input, Popup, Visibility} from "semantic-ui-react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useTranslation } from 'react-i18next';

const searchDebounced = AwesomeDebouncePromise((search) => search(), 1000);
const formStyle = {
    maxHeight: "250px",
    overflowY: "auto",
    overflowX: "hidden",
};

const inputStyle = {
    textOverflow: "ellipsis",
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
};

const labelStyle = {
    fontWeight: 'bold'
};

const searchStyle = {
    marginBottom: "12px",
};

const searchStickyStyle = {
    position: 'sticky',
    top: '0',
    background: 'white',
    zIndex: '10',
};


const SelectClientMulti = ({options: optionsProps = [], onChange, placeholder, value = {isAll: false, ids: []}, fetchData, onClose}) => {
    const { t } = useTranslation();

    let [search, setSearch] = useState();
    let [options, setOptions] = useState(optionsProps);
    let [page, setPage] = useState(1);
    let [isAll, setIsAll] = useState(value.isAll);

    const inputRef = useRef(null);
    const context = useRef(null);

    const popupStyle = useMemo(() => {
        return {
            marginTop: '0',
            borderTop: 'none',
            maxWidth: `${inputRef.current && inputRef.current.offsetWidth}px`,
            minWidth: `${inputRef.current && inputRef.current.offsetWidth}px`,
        }
    }, [inputRef && inputRef.current]);

    useEffect(() => {
        setOptions(optionsProps);
    }, [optionsProps]);

    const toggle = (e, {value: newValue}) => {
        let values = [...value.ids];
        if (values.some(x => x === newValue)) {
            values.splice(values.indexOf(`${newValue}`), 1);
        } else {
            values.push(newValue);
        }
        //setIsAll(false);
        onChange && onChange({isAll: false, ids: values});
    };

    const toggleALL = () => {
        //setIsAll(isAll => !isAll)
        onChange({isAll: !value.isAll, ids: []})
    };

    const handleChangeSearch = async (e, {value}) => {
        setSearch(value);
        await searchDebounced(() => {
            setPage(1);
            fetchData(1, value, true);
        });
    };

    const scroll = () => {
        setPage(page => page + 1);
        fetchData(page + 1, search);
    };


    const handleOpen = () => {
        fetchData(page);
    };

    const handleClose = () => {
        setPage(1);
        onClose();
    };

    const content = (
        <div ref={context} style={formStyle}>
            <Form>
                <div style={searchStickyStyle}>
                <Input value={search} style={searchStyle} icon='search' placeholder={t('base_input_find')} onChange={handleChangeSearch}/>
                </div>
                {options.length ? <Form.Field>
                    <Checkbox checked={value.isAll} onChange={toggleALL}
                              label={<label style={labelStyle}>{t('base_label_all1')}</label>}/>
                </Form.Field> : null}
                {options.map(x => {
                    let label = (
                        <label>
                            {x.text}
                        </label>
                    );
                    return (<Form.Field key={x.key}>
                        <Checkbox value={x.value} checked={value.isAll || value.ids.includes(x.value)} onChange={toggle} label={label}/>
                    </Form.Field>);
                })}
                <Visibility
                    continuous={false}
                    once={false}
                    context={undefined}
                    onTopVisible={scroll}
                />
            </Form>
        </div>
    );


    return (
        <div className="">
            <Popup
                trigger={
                    <input
                        style={inputStyle}
                        readOnly
                        placeholder={placeholder}
                        value={value.isAll ? t('base_label_all') : value.ids.join(', ')}
                        ref={inputRef}
                    />
                }
                style={popupStyle}
                basic
                onOpen={handleOpen}
                onClose={handleClose}
                content={content}
                position='bottom left'
                on='click'
            />
        </div>
    );
};

export default SelectClientMulti;
