import moment from 'moment';
import React, { useState, useEffect, useRef, createRef } from 'react';
import { Form, Input, Popup, Icon } from 'semantic-ui-react';
import AppButtonLink from '../../../components/buttons/AppButtonLink';
import { PointTimeslot } from '../../../api/model/PointTimeslot';
import {normalizeTime} from "../../../services/normalizeTime";
import { useTranslation } from 'react-i18next';


function normalizeTimeLength(part) {

    if (!part) return "";

    const partLen = part.length;

    switch (partLen) {
        case 1:
            return `0${part}:00`;
        case 2:
            return `${part}:00`;
        case 4:
            return `${part}0`;
        default:
            return part;
    }
}

// const timeHint = (
//     <Form.Group inline>
//         <Form.Radio
//             label='9:00-10:00'
//             value='sm'
//             checked={true}
//             onChange={this.handleChange}
//         />
//     </Form.Group>
// );

export default function PointCardTabsMainTimeslot({ value, hubLoading, onChange, children, errors, disabled }) {

    value = value || new PointTimeslot(); // null is destructurable to false

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const initialised = useRef(false);

    const inputRef = createRef();

    function handleFromChange(fromValue) {

        const from = normalizeTime(fromValue);

        let { to } = value || {};

        if (from && from.length === 5) {

            if (to && to.length === 5) {

                let shouldSync = moment(from, 'H:mm').isAfter(moment(to, 'H:mm'));

                if (shouldSync) {

                    to = from;
                }
            } else {
                to = from;
            }
        } else {
                if (!to || to.length < 5) {
                    to = from;
                }
        }

        const timeslot = `${from}-${to}`;

        onChange(timeslot);
    }

    function handleToChange(toValue) {

        const to = normalizeTime(toValue);

        const timeslot = `${value.from}-${to}`;

        onChange(timeslot);
    }

    const handleClear = () => {
        const from = normalizeTime("");
        const to = normalizeTime("");

        const timeslot = `${from}-${to}`;

        onChange(timeslot);
    };

    function addTime(amount) {

        const to = moment(value.to, "HH:mm")
            .add(amount, 'minutes')
            .format('HH:mm');

        const timeslot = `${value.from}-${to}`;
        onChange(timeslot);

    }

    function onKeyPress(e) {
        if (e.key === "Enter") {
            setOpen(false);
        }
    }

    useEffect(() => {

        if (open) {
            inputRef.current.focus(); // move to effect
        } else {

            if (initialised.current) {
                const val = value || new PointTimeslot();
                const from = normalizeTimeLength(val.from);
                const to = normalizeTimeLength(val.to);

                const timeslot = `${from}-${to}`;
                onChange(timeslot);
            } else {
                initialised.current = true;
            }
        }

    }, [open]);

    const endingDisabled = !value.from || value.from.length < 5;

    return (
        <Popup
            disabled={disabled}
            trigger={children}
            on='click'
            size="small"
            position="bottom left"
            hideOnScroll
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            open={open}
        >
            <Popup.Header as="h5">{t('waybills_label_load-window')}</Popup.Header>
            <Popup.Content>
                <Form style={{ width: '350px' }}>
                    {/* {timeHint} */}
                    <Form.Group widths='equal'>
                        <Form.Field required error={errors.includes('from')}>
                            <label>{t('waybills_input_start')}</label>
                            <Input
                                ref={inputRef}
                                fluid
                                icon={<Icon name='delete' link onClick={handleClear} />}
                                placeholder={t('waybills_input_start')}
                                position="bottom center"
                                value={value.from || ''}
                                onKeyPress={onKeyPress}
                                onChange={e => handleFromChange(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field disabled={endingDisabled} error={errors.includes('to')}>
                            <label>{t('waybills_input_finish')}&nbsp;
                                <AppButtonLink
                                    disabled={!value.to}
                                    onClick={addTime.bind(null, 30)}
                                >
                                    {t('waybills_btn_plus30min')}&nbsp;
                                </AppButtonLink>
                                <AppButtonLink
                                    disabled={!value.to}
                                    onClick={addTime.bind(null, 60)}
                                >
                                    {t('waybills_btn_plus1hour')}&nbsp;
                                </AppButtonLink>
                            </label>
                            <Input
                                disabled={endingDisabled}
                                fluid
                                icon={<Icon name='delete' link onClick={() => handleToChange("")} />}
                                placeholder={t('waybills_input_finish')}
                                position="bottom center"
                                value={value.to || ''}
                                onChange={e => handleToChange(e.target.value)}
                                onKeyPress={onKeyPress}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Popup.Content>
        </Popup>
    );
}
