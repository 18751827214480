import React, {useEffect, useState} from 'react';
import {Button, Grid, Icon, Table} from "semantic-ui-react";
import {WeekDayOptions} from '../../../../../api/model/Enums';
import RowDeleteAction from './_shared/RowDeleteAction';
import {
    companyCutoffDateUrbanDeliveryDelete,
    getCompanyCutoffs
} from '../../../../../api/companyCutoffs';
import {CutoffsCutoffsUrbanDeliveryDateAdd} from './CutoffsCutoffsUrbanDeliveryDateAdd';
import {useTranslation} from 'react-i18next';

export default function CutoffsUrbanDeliveryDate({match}) {
    const [rows, setRows] = useState([]);
    const {t} = useTranslation();

    function onAdd(row) {
        setRows([...rows, row]);
    }

    async function removeAsync(row) {
        await companyCutoffDateUrbanDeliveryDelete(match.params.id, row);
    }

    function Row({row}) {
        return (
            <Table.Row>
                <Table.Cell>{t(WeekDayOptions.getText(row.weekdaySubmitting))}</Table.Cell>
                <Table.Cell>{t(WeekDayOptions.getText(row.weekdayLoading))}</Table.Cell>
                <Table.Cell textAlign="center"><RowDeleteAction {...{row, rows, setRows, removeAsync}} /></Table.Cell>
            </Table.Row>
        );
    }

    useEffect(() => {
        async function getCompanyCutoffsLocal() {
            const res = await getCompanyCutoffs(match.params.id, "urbanDelivery/date");
            setRows(res || []);
        }

        getCompanyCutoffsLocal();
    }, [match.params.id]);

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column align="right">
                        <CutoffsCutoffsUrbanDeliveryDateAdd onAdd={onAdd} companyId={match.params.id}>
                            <Button primary>
                                <Icon name="plus"/>
                                {t("users_grid_add-rule")}
                            </Button>
                        </CutoffsCutoffsUrbanDeliveryDateAdd>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={5}>{t("users_label_day-week-sending-app")}</Table.HeaderCell>
                        <Table.HeaderCell width={5}>{t("users_label_transfer-day-fm")}</Table.HeaderCell>
                        <Table.HeaderCell width={1} textAlign="center" />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((r, i) => <Row key={i} row={r}/>)}
                </Table.Body>
            </Table>
        </>
    );
}
