import React from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";

import PointFormDays from './PointFormDays';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import { LoadCapacityOptions, PointLoadingPositionEnum } from '../../../../api/model/Point';
import InputDropdownMultiple from "../../../../components/inputs/InputDropdownMultiple";

const PointFormStep3 = ({ ve, data, update }) => {
    const { t } = useTranslation();
    const rowLoadingUnloading = (
        <React.Fragment>
            <Form.Group inline>
                <label>{t("points_input_download_unload")}</label>
                <Form.Radio
                    label={t("points_input_back")}
                    value={PointLoadingPositionEnum.BACK}
                    checked={data.dockPosition === PointLoadingPositionEnum.BACK}
                    onChange={(e, item) => update({ dockPosition: item.value })}
                />
                <Form.Radio
                    label={t("points_input_lateral")}
                    value={PointLoadingPositionEnum.SIDE}
                    checked={data.dockPosition === PointLoadingPositionEnum.SIDE}
                    onChange={(e, item) => update({ dockPosition: item.value })}
                />
                <Form.Radio
                    label={t("points_input_upper")}
                    value={PointLoadingPositionEnum.TOP}
                    checked={data.dockPosition === PointLoadingPositionEnum.TOP}
                    onChange={(e, item) => update({ dockPosition: item.value })}
                />
            </Form.Group>
        </React.Fragment>
    );

    return <React.Fragment>
        <div className="m-b-10">
            <PointFormDays
                days={data.days}
                onChange={val => update({ days: val })}
            />
        </div>
        <Form.Field required error={ve.includes("dockCapacity")}>
            <label>{t("points_input_lifting_capacity")}</label>
            <InputDropdownMultiple
                placeholder={t("points_input_lifting_capacity")}
                options={LoadCapacityOptions}
                value={data.dockCapacity}
                onChange={val => update({ dockCapacity: val })}
            />
        </Form.Field>
        {rowLoadingUnloading}
        <Form.TextArea
            className="te-100"
            label={t("points_input_special_notes")}
            value={data.comment || ''}
            onChange={e => update({ comment: e.target.value })}
        />
    </React.Fragment>;
};

export default PointFormStep3;
