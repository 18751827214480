import React, {useEffect, useState} from 'react';
import {Label, Menu} from 'semantic-ui-react';
import PointCardTabMain from './WbPointCardTabsMain';
import WbPointCardTabsPositionsLoad from './WbPointCardTabsPositionsLoad';
import WbPointCardTabsPositionsUnload from './WbPointCardTabsPositionsUnload';
import PointCardTabServices from './WbPointCardTabsServices';
import {WaybillTypeEnum} from '../../../api/model/Waybill';
import {getCompaniesIsMixAllowed} from "../../../api/companies";
import { useTranslation } from 'react-i18next';

const tabMainName = 'waybills_label_basic-info';
const tabOptionsName = 'waybills_label_services';
const tabPositions = 'waybills_label_cargo-units';

export default function PointCardTabs({
                                          value = {},
                                          handleTabChange, activeTab, wbId, wbType,
                                          profile, updatePoint, updateWb, positions, unitsLoadedTotal,
                                          oppositeDate, needCalc, pointsAll, isClient, wbClientId
                                      }) {
    const { t } = useTranslation();

    const [isMixCargoUnitsConfig, setIsMixCargoUnitsConfig] = useState(false);
    useEffect(() => {
        getIsMixAllowed();
    }, [wbClientId])

    async function getIsMixAllowed() {
        const isMix = await getCompaniesIsMixAllowed(wbClientId);
        setIsMixCargoUnitsConfig(isMix);
    }

    const handleTabClick = (e, tab) => {
        handleTabChange(tab.id);
    };

    const handleDataChange = async (key, val, loadingsChanged) => {

        const waybillPoint = {...value};

        waybillPoint[key] = val;

        await updatePoint(waybillPoint, loadingsChanged);
    };

    const countUsedPositions = (positions) => {
        let usedIndexes = [];

        positions.forEach(p => {
            let posStart = p.loadUnitPositionStart || 0;
            let posEnd = p.loadUnitPositionEnd || posStart;

            if (posStart) {
                for (let index = posStart; index <= posEnd; index++) {
                    if (!usedIndexes.includes(index)) {
                        usedIndexes.push(index);
                    }
                }
            }
        });

        // we have 5 pos
        // next we have refs to 1 to 2 and 4 to 4

        return usedIndexes.length;
    };

    const isLoading = profile === "pointsLoading";

    let pointUnitSets = [], pointUnitsCount = 0, loadIndicatorColor = "red";

    const waybillPoint = value;

    if (isLoading) {
        pointUnitSets = waybillPoint.loadUnitSets || [];
        pointUnitsCount = pointUnitSets.reduce((val, pus) => val + (pus.loadUnitsCount || 0), 0);
        loadIndicatorColor = pointUnitsCount > 0 ? "green" : "red";
    } else {
        pointUnitSets = waybillPoint.loadUnitsToOrderMappings || [];

        pointUnitsCount = pointUnitSets.length && countUsedPositions(pointUnitSets);
        loadIndicatorColor = countUsedPositions(positions) === unitsLoadedTotal
            ? "green"
            : "red";
    }

    const isRail = wbType === WaybillTypeEnum.Rail || wbType === WaybillTypeEnum.RailLTL || wbType === WaybillTypeEnum.UrbanDelivery;
    const tabsCount = isRail ? 2 : 3;

    return (
        <>
            <Menu pointing widths={tabsCount} size="small" className="point-card-tabs">
                <Menu.Item
                    id={tabMainName}
                    disabled={isClient}
                    active={activeTab === tabMainName}
                    onClick={handleTabClick}
                >
                    <span className="required-icon">{t(tabMainName)}</span>
                </Menu.Item>
                <Menu.Item
                    id={tabPositions}
                    disabled={profile === 'pointsLoading' && isClient}
                    active={activeTab === tabPositions}
                    onClick={handleTabClick}>
                    <span className="required-icon">{t(tabPositions)}</span>
                    {pointUnitsCount > 0 && <Label floating color={loadIndicatorColor}>{pointUnitsCount}</Label>}
                </Menu.Item>
                {!isRail && <Menu.Item
                    id={tabOptionsName}
                    disabled={isClient}
                    active={activeTab === tabOptionsName}
                    onClick={handleTabClick}>
                    {t(tabOptionsName)}
                    {waybillPoint.services && waybillPoint.services.length
                        ? <Label floating color="yellow">{waybillPoint.services.length}</Label>
                        : null
                    }
                </Menu.Item>}
            </Menu>
            <div>
                {activeTab === tabMainName
                    ? <PointCardTabMain
                        wbId={wbId}
                        wbType={wbType}
                        pointsAll={pointsAll}
                        data={waybillPoint}
                        handleDataChange={handleDataChange}
                        isLoading={isLoading}
                        oppositeDate={oppositeDate}
                        needCalc={needCalc}
                        isClient={isClient}
                        wbClientId={wbClientId}
                    />
                    : activeTab === tabOptionsName
                        ? <PointCardTabServices
                            isLoading={isLoading}
                            isLTL={wbType === "LTL"}
                            services={waybillPoint.services}
                            update={handleDataChange.bind(null, "services")}/>
                        : isLoading
                            ? <WbPointCardTabsPositionsLoad
                                id={waybillPoint.id}
                                isMixAllowed={isMixCargoUnitsConfig}
                                pointsAll={pointsAll}
                                pointId={waybillPoint.pointId}
                                positions={pointUnitSets}
                                isClient={isClient}
                                handleDataChange={(key, val) => handleDataChange(key, val, true)}
                                updateWb={updateWb}
                            />
                            : <WbPointCardTabsPositionsUnload
                                isClient={isClient}
                                unitsLoadedTotal={unitsLoadedTotal}
                                pointId={waybillPoint.pointId}
                                positions={pointUnitSets}
                                handleDataChange={handleDataChange}
                                updateWb={updateWb}
                            />
                }
            </div>
        </>
    );
};
