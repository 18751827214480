import React, {useState, useRef} from 'react';
import { Card, Icon } from 'semantic-ui-react';
import { get } from '../../services/http';
import {downloadFilePod} from "../../api/waybillDocs";
import downloadFile from "../../services/downloadFile";

// import ga from './GalleryArtifact.txt';

export default function GalleryItem({
	header,
	meta,
	description,
	url,
	icon,
	isDirectDownload,
	id
}) {
	const downBlob = async (e) => {
		e.preventDefault();
		const res = await downloadFilePod(id);
		downloadFile(res);
	};
    const down = async (e) => {
		if (isDirectDownload)
			return true;

		e.preventDefault();
		const link = e.currentTarget;


		try {
			const fileDto = await get(url);
			link.pathname = fileDto.url;
			isDirectDownload = true;
			link.click();
			isDirectDownload = false;
		} catch (error) {
		}
    };

    return (
		<Card
			link
			download
			href={url}
			target="_blank"
			onClick={isDirectDownload ? downBlob : down}
		>
			<Card.Content>
				<Icon size="large" className="f-r" name={icon} />
				<Card.Header>{header}</Card.Header>
				<Card.Meta>{meta}</Card.Meta>
				<Card.Description>{description}</Card.Description>
			</Card.Content>
			{/* <Card.Content extra>
				<div className='ui two buttons'>
					<Button basic color='green' onClick={down}>Cкачать</Button>
					<Button basic color='red'>Удалить</Button>
				</div>
				<div style={{ display: 'none' }}><a download href="##" ref={a => data.aElement = a}>file</a></div>
			</Card.Content> */}
		</Card>
		// <div style={{ display: 'none' }}><a download href="##" ref={a => aElement = a}>file</a></div>
    );
}
