import React from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";

const PointFormAddressManual = ({ ve, address, mergeToAddress }) => {
    const { t } = useTranslation();
    return <div className="point-form-manual-wrap">
        <Form.Group widths='equal'>
            <Form.Input
                required
                error={ve.includes("country")}
                label={t("points_input_country")}
                placeholder={t("points_input_country")}
                value={address.country}
                onChange={e => mergeToAddress({ country: e.target.value })}
            />
        </Form.Group>

        <Form.Group widths='equal'>
            <Form.Input
                required
                error={ve.includes("settlement")}
                label={t("points_input_locality_with_type")}
                placeholder={t("points_placeholder_locality")}
                value={address.settlement}
                onChange={e => mergeToAddress({ settlement: e.target.value })}
            />
            <Form.Input
                required
                error={ve.includes("postalCode")}
                label={t("points_input_index")}
                placeholder={t("points_input_index")}
                value={address.postalCode}
                onChange={e => mergeToAddress({ postalCode: e.target.value })}
            />
            <Form.Input
                label={t("points_input_street_with_type")}
                placeholder={t("points_placeholder_street_including_type")}
                value={address.streetName}
                onChange={e => mergeToAddress({ streetName: e.target.value })}
            />
        </Form.Group>

        <Form.Group widths='equal' devided="">
            <Form.Input
                label={t("points_input_house_number_with_type")}
                placeholder={t("points_placeholder_house_number_example")}
                value={address.streetNo}
                onChange={e => mergeToAddress({ streetNo: e.target.value })}
            />
            <Form.Input
                label={t("points_input_office")}
                placeholder={t("points_placeholder_office_or_apartment")}
                value={address.office}
                onChange={e => mergeToAddress({ office: e.target.value })}
            />
        </Form.Group>
    </div>;
};

export default PointFormAddressManual;

