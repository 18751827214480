import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SemanticToastContainer from './components/toast/SemanticToastContainer';
import EntryBrowserFailed from './pages/access/EntryBrowserFailed';
import { Login } from './pages/access/Login';
import Pages from './pages/Pages';
import { ContextLocaleProvider } from "./services/context";
import { isBrowserSupportedMin } from './services/ua';

export default function App() {
    
    if (!isBrowserSupportedMin()) {
        return <EntryBrowserFailed />;
    }

    return (
        <ContextLocaleProvider>
            <Switch>
                <Route path='/login' exact component={Login} />
                <Route path='/' component={Pages} />
            </Switch>
            <SemanticToastContainer position="bottom-right" />
        </ContextLocaleProvider>
    );

}
