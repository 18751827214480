import React from 'react';
import Highlighter from 'react-highlight-words';
import './InputDadata.css';
import { withTranslation } from 'react-i18next';
import {withRouter} from "react-router";

// export const BoundsType = 'region' | 'area' | 'city' | 'settlement' | 'street' | 'house';

// export interface Props {
//     token: string
//     placeholder?: string
//     query?: string
//     autoload?: boolean
//     count?: number
//     onChange?: (suggestion: DadataSuggestion) => void
//     autocomplete?: string
//     validate?: (value: string) => void
//     className?: string
//     disabled?: boolean
//     fromBound?: BoundsType
//     toBound?: BoundsType
//     address?: DadataSuggestion
// }

// export interface State {
//     query: string
//     inputQuery: string
//     inputFocused: boolean
//     suggestions: Array<DadataSuggestion>
//     suggestionIndex: number
//     suggestionsVisible: boolean
//     isValid: boolean
// }


class InputDadata extends React.Component {

    state = {
        query: this.props.query ? this.props.query : '',
        inputQuery: this.props.query ? this.props.query : '',
        inputFocused: false,
        suggestions: [],
        suggestionIndex: -1,
        suggestionsVisible: true,
        isValid: false
    }

    /**
     * HTML-input
     */
    textInput; //?: HTMLInputElement;

    /**
     * XMLHttpRequest instance
     */
    xhr; //?: XMLHttpRequest

    componentDidMount() {
        if (this.props.autoload && this.state.query) {
            this.fetchSuggestions();
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.query !== this.props.query) {
            this.setState({ query: this.props.query });
        }
    }

    onInputFocus = () => {
        this.setState({ inputFocused: true });
        if (this.state.suggestions.length === 0) {
            this.fetchSuggestions();
        }
    };

    onInputBlur = () => {
        this.setState({ inputFocused: false });
        if (this.state.suggestions.length === 0) {
            this.fetchSuggestions();
        } else {

        }
    };

    onInputBlurOrEnter = (suggestions) => {
        this.props.onChange(suggestions && suggestions[0]);
    }

    /** @param {React.ChangeEvent} event */
    onInputChange = (event) => { //: React.ChangeEvent<HTMLInputElement>
        const value = event.target.value;
        const { onInputChange } = this.props;
        onInputChange && onInputChange(value);
        this.setState({ query: value, inputQuery: value, suggestionsVisible: true }, () => {
            if (this.props.validate) {
                this.props.validate(value);
            }
            //this.fetchSuggestions((suggestions) => {
                //  this.props.onChange(suggestions && suggestions[0]);
            //});
            this.fetchSuggestions();
        });
    };

    /** @param {React.KeyboardEvent} event */
    onKeyPress = (event) => { //: React.KeyboardEvent<HTMLInputElement>

        if (event.which === 40) {
            // Arrow down
            event.preventDefault();
            if (this.state.suggestionIndex < this.state.suggestions.length) {
                const newSuggestionIndex = this.state.suggestionIndex + 1;
                const newInputQuery = this.state.suggestions[newSuggestionIndex].value;
                this.setState({ suggestionIndex: newSuggestionIndex, query: newInputQuery });
            }
        } else if (event.which === 38) {
            // Arrow up
            event.preventDefault();
            if (this.state.suggestionIndex >= 0) {
                const newSuggestionIndex = this.state.suggestionIndex - 1;
                const newInputQuery = newSuggestionIndex === -1 ? this.state.inputQuery : this.state.suggestions[newSuggestionIndex].value;
                this.setState({ suggestionIndex: newSuggestionIndex, query: newInputQuery });
            }
        } else if (event.which === 13) {
            // Enter
            event.preventDefault();
            if (this.state.suggestionIndex >= 0) {
                this.selectSuggestion(this.state.suggestionIndex);
            } else {
                //var result = this.state.suggestions;
            }
        } else {
            // костыль... почему onChnage не реагирует на space
            if (event.which === 32) {
                const q = this.state.query;
                if (q.charCodeAt(q.length - 1) !== 32) {
                    const newInputQuery = q + " ";
                    this.setState({ inputQuery: newInputQuery, query: newInputQuery });
                }
            }
        }
    };

    getSuggestionsPayload(count) {
        let requestPayload = {
            query: this.state.query,
            count: count || (this.props.count ? this.props.count : 10),
        };
        // Checking for granular suggestions
        if (this.props.fromBound && this.props.toBound) {
            // When using granular suggestion, all dadata components have to receive address property that contains shared address info.
            if (!this.props.address) {
                throw new Error("You have to pass address property with DaData address object to connect separate components");
            }
            requestPayload.from_bound = { value: this.props.fromBound };
            requestPayload.to_bound = { value: this.props.toBound };
            requestPayload.restrict_value = true;

            if (this.props.address.data) {
                // Define location limitation
                let location = {};
                if (this.props.address.data.region_fias_id) {
                    location.region_fias_id = this.props.address.data.region_fias_id;
                }
                if (this.props.address.data.city_fias_id) {
                    location.city_fias_id = this.props.address.data.city_fias_id;
                }
                if (this.props.address.data.settlement_fias_id) {
                    location.settlement_fias_id = this.props.address.data.settlement_fias_id;
                }
                if (this.props.address.data.street_fias_id) {
                    location.street_fias_id = this.props.address.data.street_fias_id;
                }
                requestPayload.locations = [location];
            }
        }
        return requestPayload;
    }

    fetchSuggestions = (callback) => {
        if (this.xhr) {
            this.xhr.abort();
        }

        const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5";

        this.xhr = new XMLHttpRequest();
        this.xhr.open("POST", url);
        this.xhr.setRequestHeader("Accept", "application/json");
        this.xhr.setRequestHeader("Authorization", `Token ${this.props.token}`);
        this.xhr.setRequestHeader("Content-Type", "application/json");

        const requestPayload = this.getSuggestionsPayload(callback ? 1 : 10);

        this.xhr.send(JSON.stringify(requestPayload));

        this.xhr.onreadystatechange = () => {
            if (!this.xhr || this.xhr.readyState != 4) { // eslint-disable-line
                return;
            }

            if (this.xhr.status == 200) { // eslint-disable-line
                const responseJson = JSON.parse(this.xhr.response);

                if (responseJson && responseJson.suggestions) {
                    this.setState({ suggestions: responseJson.suggestions, suggestionIndex: -1 });
                }

                // is payload is custom -> response is returned
                if (callback) {
                    callback(responseJson && responseJson.suggestions);
                }
            }
        };
    };

    /**
     * @param {number} index
     * @param {React.MouseEvent} event
     */
    onSuggestionClick = (index, event) => {
        event.stopPropagation();
        this.selectSuggestion(index);
    };

    /**
     * @param {number} index
     */
    selectSuggestion = (index) => {

        if (this.state.suggestions.length >= index - 1) {

            const selected = this.state.suggestions[index];

            this.setState({
                    query: selected.value,
                    suggestionsVisible: false,
                    inputQuery: selected.value
                },
                () => {
                    //this.fetchSuggestions();
                    if (this.props.onChange) {

                        if (selected.data.fias_level > 6) {
                            this.fetchSuggestions((suggestions) => {
                                this.props.onChange(suggestions && suggestions[0]);
                            });
                        } else {
                            this.props.onChange(selected);
                        }

                    }
                    setTimeout(() => this.setCursorToEnd(this.textInput), 100);
                }
            );


        }
    };

    setCursorToEnd = (element) => {
        if (!element) { return; }
        const valueLength = element.value.length;
        if (element.selectionStart || element.selectionStart == '0') { // eslint-disable-line
            // Firefox/Chrome
            element.selectionStart = valueLength;
            element.selectionEnd = valueLength;
            element.focus();
        }
    };

    /**
     * @returns {Array}
     */
    getHighlightWords = () => { //: Array<string>
        //const wordsToPass = ['г', 'респ', 'ул', 'р-н', 'село', 'деревня', 'поселок', 'пр-д', 'пл', 'к', 'кв', 'обл', 'д'];
        const { t } = this.props;

        const wordsToPass = [
            t('base_enum_city'),
            t('base_enum_republic'),
            t('base_enum_st'),
            t('base_enum_district'),
            t('base_enum_village'),
            t('base_enum_village1'),
            t('base_enum_settlement'),
            t('base_enum_prd'),
            t('base_enum_area'),
            t('base_enum_k'),
            t('base_enum_kv'),
            t('base_enum_region'),
            t('base_enum_house')
        ];
        let words = this.state.inputQuery.replace(',', '').split(' ');
        words = words.filter((word) => {
            return wordsToPass.indexOf(word) < 0;
        });
        return words;
    };

    render() {
        const { t } = this.props;

        let classNames = ['react-dadata__input'];
        if (this.props.className) {
            classNames.push(this.props.className);
        }
        return (
            <div className="react-dadata react-dadata__container">
                <div>
                    <input className={classNames.join(' ')}
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder ? this.props.placeholder : ''}
                        value={this.state.query}
                        ref={(input) => { this.textInput = input; }} // as HTMLInputElement
                        onChange={this.onInputChange}
                        onKeyPress={this.onKeyPress}
                        onKeyDown={this.onKeyPress}
                        onFocus={this.onInputFocus}
                        onBlur={this.onInputBlur}
                        validate={this.props.validate}
                        autoComplete={this.props.autocomplete ? this.props.autocomplete : 'off'}
                    />
                </div>
                {this.state.inputFocused && this.state.suggestionsVisible && this.state.suggestions && this.state.suggestions.length > 0 && <div className="react-dadata__suggestions">
                    <div className="react-dadata__suggestion-note">{t('base_label_choose-opt-or-continue')}</div>
                    {this.state.suggestions.map((suggestion, index) => {
                        let suggestionClass = 'react-dadata__suggestion';
                        if (index === this.state.suggestionIndex) {
                            suggestionClass += ' react-dadata__suggestion--current';
                        }
                        return <div key={suggestion.value} onMouseDown={this.onSuggestionClick.bind(this, index)} className={suggestionClass}><Highlighter highlightClassName="react-dadata--highlighted" autoEscape={true} searchWords={this.getHighlightWords()} textToHighlight={suggestion.value} /></div>;
                    })}
                </div>}
            </div>
        );
    }
}

export default withRouter(withTranslation()(InputDadata));
