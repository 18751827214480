import React, {useEffect, useMemo, useState} from "react";
import {Button, Form, Modal} from "semantic-ui-react";
import {nanoid} from "nanoid";
import Fields from "../../components/rulesConstructor/fields";
import InputDropdown from "../../components/inputs/InputDropdown";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import RulesConstructor from "../../components/rulesConstructor";
import {getFieldsApi, getFieldsFromRulesApi} from "../../api/calculatingFieldsRules";
import {useTranslation} from 'react-i18next';

const AddModalComponent = ({
                               children,
                               id,
                               indexRule,
                               companyId,
                               fetchData,
                               getRuleById,
                               createRule,
                               editRule,
                           }) => {
    let [open, setOpen] = useState(false);
    let [fields, setFields] = useState([]);

    let [field, setField] = useState(null);
    let [value, setValue] = useState(null);

    let [rules, setRules] = useState([{nanoId: nanoid()}]);

    let [fieldsFromRules, setFieldsFromRules] = useState([]);

    let [loading, setLoading] = useState(false);
    let [saveProgress, setSaveProgress] = useState(false);

    const {t} = useTranslation();

    const isDisabledSaveBtn = useMemo(() => {
        let isDisabled = false;

        if (!field || !field.key || !value) isDisabled = true;

        if (
            rules.find(
                item =>
                    !item.key ||
                    item.type === undefined ||
                    !item.values ||
                    !item.values.length ||
                    item.values.some(value => value === null || value === "")
            )
        )
            isDisabled = true;

        return isDisabled;
    }, [field, value, rules]);

    const getRules = async () => {
        setLoading(true);
        try {
            const result = await getRuleById(id, companyId);
            setRules(
                result.rules.map(item => ({
                    ...item,
                    nanoId: nanoid()
                }))
            );
            setValue(result.targetField.value);
            const field = (
                fields.find(item => item.key === result.targetField.key) || {}
            ).value;
            setField(field);
        } finally {
            setLoading(false);
        }
    };

    const getFields = async () => {
        const fields = await getFieldsApi();

        setFields(fields);
    };

    const getFieldsFromRules = async () => {
        const result = await getFieldsFromRulesApi();

        setFieldsFromRules(result);
    };

    useEffect(() => {
        id && fields.length && getRules();
    }, [fields]);

    const handleOpen = () => {
        setOpen(true);
        getFields();
        getFieldsFromRules();
    };

    const handleClose = () => {
        setField(null);
        setValue(null);
        setRules([{}]);
        setOpen(false);
        fetchData();
    };

    const handleChangeField = value => {
        setField(value);
        setValue("");
    };

    const handleChangeValue = value => {
        setValue(value);
    };

    const handleSave = async () => {
        setSaveProgress(true);
        try {
            const params = {
                index: indexRule,
                targetField: {
                    key: field.key,
                    value: value
                },
                rules
            };

            if (id) {
                await editRule(id, params, companyId);
            } else {
                await createRule(params, companyId);
            }

            handleClose();
        } finally {
            setSaveProgress(false);
        }
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            trigger={children}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            <Modal.Header>
                {id ? t("waybillautocomplte_label_edit-rule") : t("users_label_add-rule")}
            </Modal.Header>
            <Modal.Content scrolling style={{minHeight: "calc(100vh - 290px)"}}>
                <Form loading={loading}>
                    <Form.Group widths="equal">
                        <Form.Field required>
                            <label>
                                {t("waybillautocomplte_label_fillable-field")}
                            </label>
                            <InputDropdown
                                options={fields}
                                value={field}
                                placeholder={t("users_input_select-field")}
                                onChange={val => handleChangeField(val)}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>
                                {t("users_input_value")}
                            </label>
                            <Fields
                                value={[value]}
                                disabled={!field || !field.key}
                                fieldOptions={field && (field.field || {})}
                                onChange={([val]) => handleChangeValue(val)}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Divider/>
                <RulesConstructor
                    items={rules}
                    disabled={!field || !field.key}
                    fields={fieldsFromRules}
                    onChange={setRules}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t("base_btn_undo")}</Button>
                <Button
                    primary
                    disabled={isDisabledSaveBtn}
                    loading={saveProgress}
                    onClick={handleSave}
                >
                    {id ? t("base_btn_save") : t("base_btn_create")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
export default AddModalComponent;
