import React from "react";
import { Button, Popup, Checkbox, Icon, Form } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';

const SelectCheckboxMulti = ({ value, onChange, options, name, onClose }) => {
    const { t } = useTranslation();

    let values = value
        ? value.split(",")
        : [];

    const toggle = (e, { value }) => {
        if (values.some(x => `${x}` === `${value}`)) {
            values.splice(values.indexOf(`${value}`), 1);
        } else {
            values.push(value);
        }
        onChange && onChange(values.join(","));
    };

    const content = (
        <Form>
            {options.map(x => {
                let label = (
                    <label>
                        <Icon color={x.color} inverted={x.inverted} name="circle" />
                        {t(x.text)}
                    </label>
                );
                return (<Form.Field key={x.value}>
                    <Checkbox value={x.key} checked={values.includes(`${x.key}`)} onChange={toggle} label={label} />
                </Form.Field>);
            })}
        </Form>
    );

    return (
        <div className="facet-sortable facet-input">
            <Popup
                trigger={
                    <input
                        className="filter-date-input filter-select"
                        style={ { backgroundColor: value ? '#ffface' : null } }
                        type="text"
                        readOnly
                        value={ values.length > 0
                            ? values.length + " " + t('base_label_is_select')
                            : t('base_label_all1')}
                    />
                }
                content={content}
                on='click'
                hideOnScroll
                position='bottom left'
                onClose={onClose}
            />
        </div >
    );

};
export default SelectCheckboxMulti;
