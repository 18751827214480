import React from 'react';
import { Label, Container } from 'semantic-ui-react';
import { getUsers, postUser, getUserBySearch } from '../../../api/users';
import AppTableButtonLink from '../../../components/buttons/AppTableButtonLink';
import AppTable from '../../../components/tables/AppTable';
import AppTableHeader from '../../../components/tables/AppTableHeader';
import AppTableToolbar from '../../../components/tables/AppTableToolbar';
import { LinkInfo } from '../../../layout/navbar/Navbar';
import Page from '../../../layout/page/Page';
import { ContextNavi } from '../../../services/context';
import { getRoles } from '../../../api/usersRoles';
import O from '../../../components/Option';
import TableSearch from "../../../components/miscs/TableSearch";
import UserFormNew from './UserFormNew';
import AppToolbarButton from '../../../components/buttons/AppToolbarButton';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router";

const headers = [
    //new AppTableHeader("id", "ID"),
    new AppTableHeader("name", "users_grid_fio"),
    new AppTableHeader("roleName", "users_grid_role"),
    new AppTableHeader("email", "users_grid_email"),
    new AppTableHeader("isBlocked", "users_grid_status"),
    new AppTableHeader("companies", "users_grid_link-companies"),
];

class UsersIndex extends Page {
    static contextType = ContextNavi;

    state = {
        ...this.state,
        searchKey: 'email'
    }

    componentWillMount() {
        if (this.context.setItems) {
            this.context.setItems([
                new LinkInfo("users_label_users", "/users"),
            ]);
        }
    }

    fetchData() {
        return Promise.all([getUsers(), getRoles()]).then(results => {
            this.setState({
                rows: results[0],
                options: {
                    profileOptions: results[1].map(p => new O(p.id, p.name))
                }
            });
        });
    }

    async addItem(user) {
        await postUser(user);
        return this.fetchData();
    }

    async editItem(user) {
        await postUser(user);
        return this.fetchData();
    }

    delItem(row) {
        const {t} = this.props;
        alert(t("users_text_deleting-item-x"));
    }


    renderItem(item, key) {
        const val = item[key];
        const {t} = this.props;

        const style = {
            minWidth: '70px',
            display: 'inline-block',
            textAlign: 'center'
        };

        const lblStyle = {
            marginBottom: "5px",
            marginLeft: "2px",
            marginRight: "2px",
        };

        switch (key) {
			case "companies":

				return val
					? val.map(e => <Label style={lblStyle} key={e}>{e}</Label>)
					: null;

            case 'roleName':

				return val
                    ? <Label key={val} color={item.roleColor} style={style}>{val}</Label>
                    : null;

            case 'isBlocked':

				let text = val === true ? t("users_text_locked-sm") : t("users_grid_active");
				let color = val === true ? "red" : "teal";
                return <Label key={val} color={color} style={style}>{text.t}</Label>;

            default:
                return val;
        }
    }

    async handleSearch(searchKey, searchValue) {
        const {t} = this.props;
        let rows;
        let hasSearch = false;

        if (searchValue) {

            if (searchValue.length < 3) {
                alert(t("users_label_error-length"));
                return;
            }

            rows = await getUserBySearch(searchKey, searchValue);
            hasSearch = true;

            this.setState({rows, hasSearch});
        } else {
            await this.fetchData();
        }
    }

    renderData() {
        const {t} = this.props;

        const editRowAction = (data) => {
            return <AppTableButtonLink key="edit"
                url={`/admin/users/${data.id}`}
                title={t("users_label_edit_user")}
                icon="edit" />;
        };

        const { history } = this.props;

        return (
            <div className="bgn-white h-100">
                <Container className="h-100 app-table">
                    <div className="flex-col h-100">
                        <div style={{minHeight: '53px'}}>
                            <AppTableToolbar>
                                <AppTableToolbar.Left>
                                    <TableSearch
                                        setState={this.setState.bind(this)}
                                        handleSearch={this.handleSearch.bind(this)}
                                        hasSearch={this.state.hasSearch}
                                        searchKey={this.state.searchKey}
                                        options={[
                                            new O("email", t("users_input_email")),
                                            new O("name", t("users_input_fio")),
                                            new O("companyName", t("users_input_name-company"))
                                        ]}
                                    />
                                </AppTableToolbar.Left>
                                <AppTableToolbar.Right>
									<UserFormNew
										companyOptions={this.state.options.companyOptions}
										roleOptions={this.state.options.profileOptions}
										fetchData={this.fetchData.bind(this)}
									>
										<AppToolbarButton icon="plus">{t("users_label_add-user")}</AppToolbarButton>
									</UserFormNew>
                                    {/* <ModalForm
                                        title={"Новый пользователь".t}
                                        size="small"
                                        submit={this.addItem.bind(this)}
                                        form={UserForm}
                                        options={this.state.options}
                                    >
                                        <AppToolbarButton icon="plus">Добавить пользователя</AppToolbarButton>
                                    </ModalForm> */}
                                </AppTableToolbar.Right>
                            </AppTableToolbar>
                        </div>
                        <div className="flex-grow scrollable-v">
                            <AppTable
                                headers={headers}
                                rows={this.state.rows}
                                onRowClick={(data) => history.push(`/admin/users/${data.id}`)}
                                renderItem={this.renderItem.bind(this)}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withTranslation()(withRouter(UsersIndex))