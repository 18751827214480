import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { getUserCurrent, getRole } from '../../api/users';
import { clearUserContext, saveUserContext, getToken } from '../../services/auth';
import { ContextUser } from '../../services/context';
import { delay } from '../../services/http';
import "./Entry.css";

function Entry({history, verified}) {

    const contextUser = useContext(ContextUser);



    async function checkUser() {

        

        var wait = await delay(1300); // eslint-disable-line

        const token = getToken();

        if (token) {
            // verify token on the server 
            const user = await getUserCurrent();
            const role = await getRole();

            if (user) {
                contextUser.current = { ...user, role };

                verified(true);

            } else {
                // user token not valid (empty db -> goto login)
                contextUser.current = null;

                clearUserContext();
                
                history.push("/login");
            }
        } else {
            // there is no context at all
            
            history.push("/login");
        }
    }

    useEffect(() => {
        checkUser();
    }, []);

    

    return (
        <div className="login-bgn">
            <div className="entry-container">
                <div className="entry-line">
                    <div className="entry-dot entry-dot1"></div>
                    <div className="entry-dot entry-dot2"></div>
                    <div className="entry-dot entry-dot3"></div>
                    <div className="entry-dot entry-dot4"></div>
                    <div className="entry-dot entry-dot5"></div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Entry);