import React from 'react';
import { Form, Grid, Input, Checkbox } from 'semantic-ui-react';
import { PointService, PointServiceEnum } from '../../../api/model/PointService';
import { useTranslation } from 'react-i18next';

const rowEditableStyle = {
    paddingTop: '0',
    paddingBottom: '0',
    minHeight: '34px'
};

class ServiceInfo {
	constructor(disabled, key, keyTitle, value, valueType, valueTip) {
		this.disabled = disabled;
        this.key = key;
        this.keyTitle = keyTitle;
		this.value = value;
        this.valueType = valueType;
        this.valueTip = valueTip;
	}
}

export default function PointCardTabsServices({ isLTL, isLoading, update, services = [] })  {
    const { t } = useTranslation();

    const checkedKeys = services.map(s => s.key);
    const serviceCheckedChanged = (key, e, data) => {
        const newServices = [...services];
        if (data.checked) {
            newServices.push(new PointService(key));
        } else {
            const deletableIndex = newServices.findIndex(s => s.key === key);
            deletableIndex > -1 && newServices.splice(deletableIndex, 1);
        }
        update(newServices);
    };
    const serviceValueChanged = (key, value) => {
        const newServices = [...services];
        const updateTarget = newServices.find(s => s.key === key);
        updateTarget && (updateTarget.value = value);
        update(newServices);
    };

    const getValueByKey = (key) => {
        let result = '';

        const svc = services.find(s => s.key === key);
        if (svc) {
            result = svc.value || '';
        }

        return result;
	};

	var serviceItems = [];

    // common services
	// serviceItems.push(new ServiceInfo(
	// 	false,
    //     PointServiceEnum.CRATING,
    //     "Обрешетка",
    //     getValueByKey(PointServiceEnum.CRATING),
    //     "number",
    //     "за куб. м.")
    // );

	if (isLoading) {
        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.TAILLIFT,
            t('waybills_label_tail-lift'),
            getValueByKey(PointServiceEnum.TAILLIFT))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.PALLETSFEED_STRETCHFILM,
            t('waybills_label_empty-pallets-plus'),
            getValueByKey(PointServiceEnum.PALLETSFEED_STRETCHFILM),
            "number",
            t('base_label_count'))
        );
        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.BELTS,
            t('waybills_label_belts'),
            getValueByKey(PointServiceEnum.BELTS),
            "number",
            t('base_label_count'))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.ROCLA,
            t('waybills_label_rocla'),
            getValueByKey(PointServiceEnum.ROCLA))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.MEDICAL_BOOK,
            t('waybills_label_med-book'),
            getValueByKey(PointServiceEnum.MEDICAL_BOOK))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.CERTIFICATE_SANITIZATION_VEHICLE,
            t('waybills_label_sanitization-certificate'),
            getValueByKey(PointServiceEnum.CERTIFICATE_SANITIZATION_VEHICLE))
        );

    } else {
        /*serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.LOADUNLOAD,
            "ПРР (силами водителя)",
            getValueByKey(PointServiceEnum.LOADUNLOAD),
            "number",
            "паллет")
        );

        serviceItems.push(new ServiceInfo(
			false,
			PointServiceEnum.LOADERS,
			"Грузчики",
			getValueByKey(PointServiceEnum.LOADERS),
			"number",
			"чел.")
		);

		serviceItems.push(new ServiceInfo(
			false,
			PointServiceEnum.WAITING,
			"Плановый простой",
			getValueByKey(PointServiceEnum.WAITING),
			"number",
			"часов")
		);
		serviceItems.push(new ServiceInfo(
			false,
			PointServiceEnum.PALLETSPLITTING,
			"Раздел паллета",
            getValueByKey(PointServiceEnum.PALLETSPLITTING),
            "number",
			"паллет")
		);*/

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.TAILLIFT,
            t('waybills_label_tail-lift'),
            getValueByKey(PointServiceEnum.TAILLIFT))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.PALLETSFEED_STRETCHFILM,
            t('waybills_label_empty-pallets-plus'),
            getValueByKey(PointServiceEnum.PALLETSFEED_STRETCHFILM),
            "number",
            t('base_label_count'))
        );
        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.BELTS,
            t('waybills_label_belts'),
            getValueByKey(PointServiceEnum.BELTS),
            "number",
            t('base_label_count'))
        );
        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.MEDICAL_BOOK,
            t('waybills_label_med-book'),
            getValueByKey(PointServiceEnum.MEDICAL_BOOK))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.CERTIFICATE_SANITIZATION_VEHICLE,
            t('waybills_label_sanitization-certificate'),
            getValueByKey(PointServiceEnum.CERTIFICATE_SANITIZATION_VEHICLE))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.LOADER,
            t('waybills_label_loader'),
            getValueByKey(PointServiceEnum.LOADER))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.FORWARDER,
            t('waybills_label_forwarder'),
            getValueByKey(PointServiceEnum.FORWARDER))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.UNLOADING_BY_DRIVER,
            t('waybills_label_unloading-by-driver'),
            getValueByKey(PointServiceEnum.UNLOADING_BY_DRIVER))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.REGISTRATION_TTN,
            t('waybills_label_reg-ttn'),
            getValueByKey(PointServiceEnum.REGISTRATION_TTN))
        );

        serviceItems.push(new ServiceInfo(
            false,
            PointServiceEnum.OVERALLS_SAFETY_FOOTWEAR,
            t('waybills_label_overalls-safety-footwear'),
            getValueByKey(PointServiceEnum.OVERALLS_SAFETY_FOOTWEAR))
        );
    }


    // specific services
	/*if (isLTL) {

        if (!isLoading) {

			// serviceItems.push(new ServiceInfo(
			// 	true,
			// 	PointServiceEnum.TIMESLOT,
			// 	"Доставка во временной слот",
			// 	getValueByKey(PointServiceEnum.TIMESLOT))
			// );

			// serviceItems.push(new ServiceInfo(
			// 	true,
            //     PointServiceEnum.AFTERHOURS,
            //     "Доставка в нерабочее время",
            //     getValueByKey(PointServiceEnum.AFTERHOURS))
			// );

			// serviceItems.push(new ServiceInfo(
			// 	true,
            //     PointServiceEnum.WEEKEND,
            //     "Доставка в праздничные и выходные дни",
            //     getValueByKey(PointServiceEnum.WEEKEND))
            // );
        }


	} else {

        if (isLoading) {
            //serviceItems.push(new ServiceInfo(
            //    false,
            //    PointServiceEnum.ESCORT,
            //    "Охрана, сопровождение груза",
            //    getValueByKey(PointServiceEnum.ESCORT),
            //    "select",
            //    "выбрать тип")
            //);
            //serviceItems.push(new ServiceInfo(
            //    false,
            //    PointServiceEnum.BOXRESP,
            //    "Покоробочное сопровождение груза",
            //    getValueByKey(PointServiceEnum.BOXRESP))
            //);
            serviceItems.push(new ServiceInfo(
                false,
                PointServiceEnum.STAKES,
                "Коники",
                getValueByKey(PointServiceEnum.STAKES),
                "number",
                "кол-во шт.")
            );
            serviceItems.push(new ServiceInfo(
                false,
                PointServiceEnum.STRAPS,
                "Ремни, распорки",
                getValueByKey(PointServiceEnum.STRAPS),
                "number",
                "кол-во шт."
            ));
            serviceItems.push(new ServiceInfo(
                false,
                PointServiceEnum.TEMPREC,
                "Термописцы",
                getValueByKey(PointServiceEnum.TEMPREC))
            );
        } else {
            //serviceItems.push(new ServiceInfo(
            //    false,
            //    PointServiceEnum.PALLETSERVING,
            //    "Выкатка паллет (за ТС)",
            //    getValueByKey(PointServiceEnum.PALLETSERVING))
            //);
        }
    }*/

    return (
        <Form size="small" className="point-form-services">
            <Grid columns='equal' className="m-0">
                {serviceItems.map(si =>
                    <Grid.Row key={si.key} style={rowEditableStyle}>
                        <Grid.Column verticalAlign='middle'>
                            <Form.Field disabled={si.disabled}>
                                <Checkbox
                                    className="ellipsis"
                                    label={si.keyTitle}
                                    size="small"
                                    key={si.key}
                                    type="checkbox"
                                    checked={checkedKeys.includes(si.key)}
                                    onChange={serviceCheckedChanged.bind(null, si.key)} />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            {si.valueType &&
                                <Form.Field inline error={checkedKeys.includes(si.key) && !si.value}>
                                    <Input
                                        required={checkedKeys.includes(si.key)}
                                        placeholder={si.valueTip}
                                        type={si.valueType}
                                        value={si.value}
                                        disabled={!checkedKeys.includes(si.key)}
                                        onChange={e => serviceValueChanged(si.key, e.target.value)}
                                    />
                                </Form.Field>
                            }
                        </Grid.Column>


                    </Grid.Row>
                )}
            </Grid>
        </Form>
    );



}
