import { get, post, put } from '../services/http';
import moment from "moment";

export const dateUtc = (date, format = 'DD.MM.YYYY HH:mm') => {
    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc)
        .local()
        .format(format);

    return local;
};

export const getAll = async () => {
    const banners = await get(`banners`);
    return banners.map(item => ({
        ...item,
        period: `${dateUtc(item.periodFrom, "DD.MM.YYYY")} - ${dateUtc(item.periodTo, "DD.MM.YYYY")}`
    }));
};

export const getBanner = async (id) => {
    const banner = await get(`banners/${id}`);

    return banner;
};

export const createBanner = async (params) => {
    return await post(`banners`, params)
};

export const editBanner = async (params) => {
    return await put(`banners/${params.id}`, params)
};

export const changeActiveBanner = async (id, value) => {
    return await put(`banners/${id}/active`, {value});
};

export const getViewBanners = async () => {
    return await get('banners/view');
};

export const closeBanner = async (id) => {
    return await post(`banners/${id}/action/close`)
};

export const actionBanner = async (id) => {
    return await post(`banners/${id}/action/button`)
};


