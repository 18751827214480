import React from 'react';
import { companyPointRemove } from '../../../api/companies';
import ModalConfirm from '../../../components/modals/ModalConfirm';
import { useTranslation } from 'react-i18next';

export default function ActionPointRemove({selectedRows, updateRows, children}) {

    async function handleRemoveFromList() {
        const promises = selectedRows
            .map(async ({companyId, pointId}) => await companyPointRemove(companyId, pointId));
        await Promise.all(promises);
        await updateRows();
    };

    const { t } = useTranslation();

    return (
        <ModalConfirm 
            title={t("points_label_address_delete")}
            text={t("points_label_address_delete_question")}
            onConfirm={handleRemoveFromList}
        >
                {children}
        </ModalConfirm>
    );
}