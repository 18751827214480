import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Dropdown, Confirm } from 'semantic-ui-react';
import ModalProxy from '../../../../components/modals/ModalProxy';
import { useTranslation } from "react-i18next";
import { pointDelete } from '../../../../api/points';
import ActionMenu from '../../../../components/buttons/ActionMenu';
import ActionPointRemove from '../../../points/pointsToolbar/ActionPointRemove';

const PointViewHeaderActions = ({ point, fetchData, backToList, setLoading, isClient, companyId, history }) => {

    const [confirmOpen, setConfirmOpen] = useState(false);

    const { t } = useTranslation();


    async function handleConfirm() {

        setConfirmOpen(false);

        const deleted = await pointDelete(point.id);

        if (deleted) {
            backToList();
        }
    }

    return <span></span>;
};

export default withRouter(PointViewHeaderActions);