/** @typedef {number} WaybillPointServiceEnum **/

/** @enum {PointServiceEnum} */
export const PointServiceEnum = {
	LOADUNLOAD: 1,
	LOADERS: 2,

	CRATING: 5,
	PALLETSERVING: 6,
	PALLETSPLITTING: 7,

    WAITING: 10,
    TIMESLOT: 11,     //LTL Only - Доставка во временной слот
    AFTERHOURS: 12,   //LTL Onlt - Доставка в не рабочее время
    WEEKEND: 13,      //LTL Only Доставка в праздники и выходные дни

    ESCORT: 20,

    BOXRESP: 30,

    TEMPREC: 40,
    STAKES: 41,
    STRAPS: 42,
    PALLETS: 45,


    TAILLIFT: 46,
    PALLETSFEED_STRETCHFILM: 47,
    BELTS: 48,
    ROCLA: 49,
    MEDICAL_BOOK: 50,
    CERTIFICATE_SANITIZATION_VEHICLE: 51,
    LOADER: 52,
    FORWARDER: 53,
    UNLOADING_BY_DRIVER: 54,
    REGISTRATION_TTN: 55,
    OVERALLS_SAFETY_FOOTWEAR: 56,

};

export const PointServiceValueTypeEnum = {
    NONE: 0,
    INFO: 1,
    INT: 2,
    NUM: 3,
    OPT: 9
};

export class PointServiceOption {

    /**
     * @param {PointServiceEnum} key
     * @param {String} text
     * @param {String} valueTypeText
    */
    constructor(scope, title, key,  valueType, valueTip) {
        this.scope = scope;
        this.title = title;
        this.key = key;
        this.valueType = valueType;
        this.valueTip = valueTip;
    }
}

const LTL = "LTL";
const FTL = "FTL";

export const PointServiceOptions = [
    new PointServiceOption(
        null,
        "points_enum_crafting",
        PointServiceEnum.CRATING,
        PointServiceValueTypeEnum.INT,
        "points_enum_m3"
    ),
    new PointServiceOption(
        null,
        "points_enum_movers",
        PointServiceEnum.LOADERS,
        PointServiceValueTypeEnum.INT,
        "points_enum_count"
    ),
    new PointServiceOption(
        null,
        "points_enum_ppr",
        PointServiceEnum.LOADUNLOAD,
        PointServiceValueTypeEnum.INT,
        "points_enum_pallet_count"
    ),
    new PointServiceOption(
        FTL,
        "points_enum_pallet_rolling_out",
        PointServiceEnum.PALLETSERVING,
        PointServiceValueTypeEnum.INFO,
        "points_enum_for_tc"
    ),
    new PointServiceOption(
        null,
        "points_enum_separating_pallet",
        PointServiceEnum.PALLETSPLITTING,
        PointServiceValueTypeEnum.INT,
        "points_enum_pallet"
    ),
    new PointServiceOption(
        null,
        "points_enum_plan_stagnation",
        PointServiceEnum.WAITING,
        PointServiceValueTypeEnum.NUM,
        "points_enum_hours_count"
    ),
    new PointServiceOption(
        LTL,
        "points_enum_delivery_in_time_slot",
        PointServiceEnum.TIMESLOT,
        PointServiceValueTypeEnum.INFO,
        "points_enum_range_5_h_max",
    ),
    new PointServiceOption(
        LTL,
        "points_enum_delivery_not_in_work_time",
        PointServiceEnum.AFTERHOURS,
        PointServiceValueTypeEnum.INFO,
        "points_enum_delivery_from_18_to_8"
    ),
    new PointServiceOption(
        LTL,
        "points_enum_delivery_on_holydays",
        PointServiceEnum.WEEKEND,
        PointServiceValueTypeEnum.NONE,
    ),

    new PointServiceOption(
        FTL,
        "points_enum_security_for_cargo",
        PointServiceEnum.ESCORT,
        PointServiceValueTypeEnum.OPT,
        ["points_enum_security_guard_in_car", "points_enum_escort_car"]
    ),

    new PointServiceOption(
        FTL,
        "points_enum_box_expedition_cargo",
        PointServiceEnum.BOXRESP,
        PointServiceValueTypeEnum.NONE
    ),

    new PointServiceOption(
        FTL,
        "points_enum_thermographers",
        PointServiceEnum.TEMPREC,
        PointServiceValueTypeEnum.NONE
    ),
    new PointServiceOption(
        FTL,
        "points_enum_koniki",
        PointServiceEnum.STAKES,
        PointServiceValueTypeEnum.INT,
        "points_enum_count"
    ),
    new PointServiceOption(
        FTL,
        "points_enum_belts_spacers",
        PointServiceEnum.STRAPS,
        PointServiceValueTypeEnum.INT,
        "points_enum_count"
    ),
    new PointServiceOption(
        LTL,
        "points_enum_empty_pallets",
        PointServiceEnum.PALLETS,
        PointServiceValueTypeEnum.INT,
        "points_enum_count"
    ),
    new PointServiceOption(
        null,
        "points_enum_tail_lift",
        PointServiceEnum.TAILLIFT,
        PointServiceValueTypeEnum.NONE,
    ),

    new PointServiceOption(
        null,
        "points_enum_empty_pallets_stretch_film",
        PointServiceEnum.PALLETSFEED_STRETCHFILM,
        PointServiceValueTypeEnum.INT,
        "points_enum_count"
    ),

    new PointServiceOption(
        null,
        "points_enum_belts",
        PointServiceEnum.BELTS,
        PointServiceValueTypeEnum.INT,
        "points_enum_count"
    ),

    new PointServiceOption(
        null,
        "points_enum_rocla",
        PointServiceEnum.ROCLA,
        PointServiceValueTypeEnum.NONE
    ),

    new PointServiceOption(
        null,
        "points_enum_medical_book",
        PointServiceEnum.MEDICAL_BOOK,
        PointServiceValueTypeEnum.NONE
    ),

    new PointServiceOption(
        null,
        "points_enum_sanitization_certificate",
        PointServiceEnum.CERTIFICATE_SANITIZATION_VEHICLE,
        PointServiceValueTypeEnum.NONE
    ),

    new PointServiceOption(
        null,
        "points_enum_mover",
        PointServiceEnum.LOADER,
        PointServiceValueTypeEnum.NONE
    ),

    new PointServiceOption(
        null,
        "points_enum_forwarder",
        PointServiceEnum.FORWARDER,
        PointServiceValueTypeEnum.NONE
    ),

    new PointServiceOption(
        null,
        "points_enum_unloading_by_driver",
        PointServiceEnum.UNLOADING_BY_DRIVER,
        PointServiceValueTypeEnum.NONE
    ),

    new PointServiceOption(
        null,
        "points_enum_registration_ttn",
        PointServiceEnum.REGISTRATION_TTN,
        PointServiceValueTypeEnum.NONE
    ),

    new PointServiceOption(
        null,
        "points_enum_overalls",
        PointServiceEnum.OVERALLS_SAFETY_FOOTWEAR,
        PointServiceValueTypeEnum.NONE
    ),
];

export class PointService {

    /**
     * @param {PointServiceEnum} key
     * @param {String} value
    */
    constructor(key, value) {
        this.key = key;
        this.value = value;
    }
}

export const servicesConst = () => {
    let obj = {};
    PointServiceOptions.forEach(item => {
        obj = {
            ...obj,
            [item.key.toString()]: item.title
        }
    });
    return obj;
};
