import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Popup} from 'semantic-ui-react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import MomentLocaleUtils, {formatDate,} from 'react-day-picker/moment';
import 'moment/locale/ru';
import 'moment/locale/uk';
import {ContextLocale} from '../../services/context';
import moment from "moment";

InputDate.propTypes = {
    placeholder: PropTypes.string,
    style: PropTypes.object,
    position: PropTypes.string
};

export default function InputDate({onChange, value: selectedDay, disabledDays, icon, placeholder, position, style, disabled, maxDate = null, minDate = null, onFocus}) {

    const contextLocale = useContext(ContextLocale);

    function handleDayChange(day, {selected, disabled}) {

        if (disabled) {
            return;
        }

        if (selected) {
            // Unselect the day if already selected
            onChange && onChange(null);
        } else {
            onChange && onChange(day);
        }

    }


    const inputClass = icon ? "ui icon input" : "ui input";

    const input = (
        <div className={inputClass}>
            <input type="text"
                   readOnly
                   placeholder={placeholder}
                   style={style}
                   onFocus={onFocus}
                   value={selectedDay ? formatDate(selectedDay, "L", contextLocale.locale) : ''}
            />
            {icon && <i aria-hidden="true" className={`${icon} icon`}></i>}
        </div>
    );
    const disabledDaysList = [
        {
            before: minDate ? moment(minDate)._d : null,
            after: maxDate ? moment(maxDate)._d : null
        }];

    const picker = (
        <DayPicker
            onDayClick={handleDayChange}
            selectedDays={selectedDay}
            firstDayOfWeek={1}
            disabledDays={disabledDaysList}
            localeUtils={MomentLocaleUtils}
            locale={contextLocale.locale}/>
    );

    return (
        <Popup
            disabled={disabled}
            trigger={input}
            content={picker}
            on='focus'
            position={position || 'top center'}
        />
    );

}

