import React from 'react';
import { keys } from '../translations/keys';
import Localized from '../components/Translate';

const env = 'Development';
const defaultLocale = 'ru';
//window.locale = localStorage.getItem('locale') || defaultLocale;

export const locales = {
    ru: 'ru',
    en: 'en',
};

export function translate (key, currentLocale = defaultLocale) {
    /*if (currentLocale === defaultLocale) {
        return key;
    } else {
        var candidates = keys.filter(function (el) { return el[defaultLocale] == key }); // eslint-disable-line
        if (candidates.length !== 1) {
            if (env !== 'Development') {
                return 'Error translate for key ' + key;
            }
            return key;
        }
        return null;//candidates[0][currentLocale];
    }*/

    return key;
}

Object.defineProperty(String.prototype, 'l', { // eslint-disable-line
    get: function () {
        return <Localized>{this}</Localized>;
    },
    enumerable: false,
    configurable: false
});

Object.defineProperty(String.prototype, 't', { // eslint-disable-line
    get: function () {
        return translate(this, window.locale);
    },
    enumerable: false,
    configurable: false
});
