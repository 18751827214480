import InputDropdownSearch from "../../../../components/inputs/InputDropdownSearch";
import {Form} from "semantic-ui-react";
import React, {useState} from "react";
import InputDropdown from "../../../../components/inputs/InputDropdown";
import {CompanyStatusOptions} from "../../../../api/model/Company";
import {ProviderStatusOptions} from "../../../../api/model/Provider";
import {useTranslation} from 'react-i18next';

export default function ProviderFrom({CompaniesList, data, setData, errors, isAdd}) {
    const update = prop => setData((prevState) => ({ ...prevState, ...prop }));
    const {t} = useTranslation();

    return (
        <Form>
            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Input
                        required
                        error={errors.includes('legalName')}
                        label={t("users_input_supplier-legal-name")}
                        placeholder={t("users_input_supplier-legal-name")}
                        value={data.legalName}
                        onChange={(e, {value}) => update({ legalName: value })}
                    />
                </Form.Field>
                <Form.Input
                    required
                    error={errors.includes('fmid')}
                    label={t("users_input_name-fm")}
                    placeholder={t("users_input_name-fm")}
                    value={data.fmid}
                    onChange={(e, {value}) => update({ fmid: value })}
                />
            </Form.Group>
            <Form.Field>
                <label>{t("users_placeholder_client")}</label>
                <InputDropdownSearch
                    selectOnBlur={false}
                    placeholder={t("users_placeholder_client")}
                    options={CompaniesList}
                    value={data.clientId}
                    onChange={val => update({ clientId: val === '' ? null : val })}
                />
            </Form.Field>
            {!isAdd && <Form.Field>
                <label>{t("users_grid_status")}</label>
                <InputDropdown
                    placeholder={t("users_grid_status")}
                    options={ProviderStatusOptions}
                    value={data.status}
                    onChange={val => update({status: val})}
                />
            </Form.Field>
            }
        </Form>
    )
}
