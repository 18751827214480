import qs from 'query-string';

const separator = "%";

export default class UrlFilter {
    constructor(search, field) {
        const q = (qs.parse(search)[field] || '').split(separator); 
        
        this.field = field;
        this.value = q[0];
        this.condition = q[1] || 'e';
    }
};