import React, {useEffect, useState} from 'react';
import {Grid, Header, Icon, Input, Table} from 'semantic-ui-react';
import {
    companyCargoRestrictionsCreate,
    companyCargoRestrictionsUpdate,
    getCompanyCargoRestrictions
} from '../../../../api/companies';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import {useTranslation} from 'react-i18next';

const iconStyle = {margin: '-5px 0', cursor: 'pointer'};
const editingCellStyle = {
    padding: '0 0 0 4px'
};

const cellStyle = {
    textIndent: 8
};

export default function CompanyTabsCargoRestrictions({data}) {
    const [restrictions, setRestrictions] = useState([]);
    const [editingItem, setEditingItem] = useState(null);
    const [value, setValue] = useState(0);
    const [hasError, setHasError] = useState(false);
    const {t} = useTranslation();

    const companyId = data.fmid;

    useEffect(() => {
        (async () => {
            if (!companyId) {
                return;
            }
            const result = await getCompanyCargoRestrictions(companyId);
            setRestrictions(result);
        })();
    }, [companyId]);

    const buildOptions = (index) => {
        const length = restrictions.length;
        const start = index !== 0 ? restrictions[index - 1].maxPallets + 1 : 1;
        const end = index !== length - 1 ? restrictions[index + 1].maxPallets - 1 : 33;
        const result = [];

        for (let i = start; i <= end; i++) {
            result.push({key: i, value: i, text: i});
        }

        return result;
    };

    const save = async () => {
        const isLast = editingItem === restrictions.length - 1;
        if (isLast) {
            const prevItem = restrictions[restrictions.length - 2];
            if (value <= prevItem.maxPallets) {
                setHasError(true);
                return;
            }
        }
        const item = restrictions[editingItem];

        const dto = {...item, maxPallets: value};
        let newRestr = [...restrictions];
        newRestr[editingItem] = dto;
        setRestrictions(newRestr);

        if (item.id) {
            await companyCargoRestrictionsUpdate(item.id, dto);
        } else {
            const result = await companyCargoRestrictionsCreate(companyId, dto);
            newRestr = [...restrictions];
            newRestr[editingItem] = result;
            setRestrictions(newRestr);
        }
        setEditingItem(null);
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Header>{t("users_label_lifting-restrictions")}</Header>
                    <Table selectable celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={5}>
                                    {t("users_grid_lifting-capacity")}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={5}>
                                    {t("users_grid_max-pallets")}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {restrictions.map((r, index) => {
                                const isEditing = editingItem === index;
                                const isLast = index === restrictions.length - 1;
                                return <Table.Row key={index}>
                                    <Table.Cell width={5}>
                                        {r.maxCapacity}
                                    </Table.Cell>
                                    <Table.Cell style={isEditing ? editingCellStyle : cellStyle} width={5}>
                                        {isEditing ?
                                            (isLast ?
                                                    <Input
                                                        error={hasError}
                                                        value={value}
                                                        onChange={(e) => {
                                                            setHasError(false);
                                                            setValue(e.target.value);
                                                        }}
                                                    /> :
                                                    <InputDropdown
                                                        value={value}
                                                        options={buildOptions(editingItem)}
                                                        onChange={(v) => {
                                                            setValue(v);
                                                        }}
                                                    />
                                            ) :
                                            r.maxPallets
                                        }
                                    </Table.Cell>
                                    <Table.Cell style={{fontSize: 18}} width={1}>
                                        {isEditing ?
                                            <>
                                                <Icon
                                                    name="save"
                                                    onClick={save}
                                                    style={iconStyle}
                                                />
                                                {/* <Icon
                                            name="close"
                                            onClick={save}
                                            style={iconStyle}
                                        /> */}

                                            </> :
                                            <Icon
                                                name="pencil"
                                                style={iconStyle}
                                                onClick={() => {
                                                    setEditingItem(index);
                                                    setHasError(false);
                                                    setValue(r.maxPallets);
                                                }}
                                            />
                                        }
                                    </Table.Cell>
                                </Table.Row>;
                            })}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
