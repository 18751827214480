import React from 'react';
import {Button, Dropdown, Form, Header, Icon, List, Menu, MenuHeader, Modal, Popup} from 'semantic-ui-react';
import {getCompanyPoints} from '../../../api/companies';
import {WaybillPointTypeEnum} from '../../../api/model/Enums';
import {PointBookmarkTypeEnum} from '../../../api/model/Point';
import PointAddress from '../../../api/model/PointAddress';
import WaybillPoint from '../../../api/model/WaybillPoint';
import {getPointById, getPointsBySearch, getPointsRecent, Od, pointCreate} from '../../../api/points';
import {waybillPointCreate, waybillPointUpdate} from '../../../api/waybills';
import {ContextWb} from '../../../services/context';
import WbPointAddFormBookmarks from './WbPointAddFormBookmarks';
import { withTranslation } from 'react-i18next';


const profiles = {
    pointsLoading: {
        title: 'waybills_grid_loading',
        addTitle: 'waybills_label_add-address-loading',
        companyTitle: 'waybills_label_shipper',
        calcDateTitle: 'waybills_label_calc-delivery-date',
        servicesOnPoint: 'waybills_label_loading-services'
    },
    pointsUnloading: {
        title: 'waybills_grid_unloading',
        addTitle: 'waybills_label_add-address-unloading',
        companyTitle: 'waybills_label_consignee',
        calcDateTitle: 'waybills_label_calc-arrival-fm-date',
        servicesOnPoint: 'waybills_label_unloading-services'
    }
};
const profilesEdit = {
    pointsLoading: {
        title: 'waybills_grid_loading',
        addTitle: 'waybills_label_change-address-loading',
        companyTitle: 'waybills_label_shipper',
        calcDateTitle: 'waybills_label_calc-delivery-date',
        servicesOnPoint: 'waybills_label_loading-services'
    },
    pointsUnloading: {
        title: 'waybills_grid_unloading',
        addTitle: 'waybills_label_change-address-unloading',
        companyTitle: 'waybills_label_consignee',
        calcDateTitle: 'waybills_label_calc-arrival-fm-date',
        servicesOnPoint: 'waybills_label_unloading-services'
    }
};

const tab1Name = 'base_btn_search';
const tab2Name = 'waybills_label_favorite-addresses';

class WbPointAddForm extends React.Component {

    static contextType = ContextWb;

    state = {
        value: this.props.value, // id, fmid, companyName // :WaybillPoint

        addressSearchIsFetching: false,
        addresses: [],

        isSubmitting: false,

        loading: false,
        open: this.props.open,
        activeTab: tab1Name,
        bookmarks: [],
        bookmarksLoading: false
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open !== prevState.open) {
            this.setState({open: this.props.open}, () => {
            });
            if (this.props.open) {
                this.handleOpen();
            }
        }
    }

    loadBookmarks = async () => {
        if (this.state.bookmarksLoading) {
            return;
        }
        this.setState({bookmarksLoading: true});
        const shared = await getCompanyPoints(`?bookmarkType=${PointBookmarkTypeEnum.SHARE}&companyId=${this.props.wbClientId}`);
        let specific;
        if (this.props.profile === 'pointsLoading') {
            specific = await getCompanyPoints(`?bookmarkType=${PointBookmarkTypeEnum.LOADING}&companyId=${this.props.wbClientId}`);
        } else {
            specific = await getCompanyPoints(`?bookmarkType=${PointBookmarkTypeEnum.UNLOADING}&companyId=${this.props.wbClientId}`);
        }
        this.setState({
            bookmarks: [...specific, ...shared],
            bookmarksLoading: false
        });
    };

    async handleOpen() {
        this.setState({
            loading: true,
            companies: [],
            addresses: [],
            value: this.props.value,
        }, async () => {
            const addresses = await getPointsRecent(this.props.wbClientId, this.props.value && this.props.value.pointId);
            this.setState({addresses, loading: false});
            this.loadBookmarks();
        });
    }

    handleClose() {
        const {onClose} = this.props;
        onClose && onClose();
        this.setState({
            isSubmitting: false,
            value: {},
        });
    }

    searchTextAndDescription(options, query) {
        const q = query.toLowerCase();
        const qterms = q.split(' ');
        const q0 = qterms[0];
        return options.length ? options.filter(opt => opt.text && opt.text.toLowerCase().includes(q0) || opt.description && opt.description.toLowerCase().includes(q0)) : [];
    }

    async handlePointSearchChange(e, {searchQuery}) {
        searchQuery = searchQuery && searchQuery.trim();
        const hasSearch = searchQuery && searchQuery.length > 2;

        this.setState({
            addressSearchIsFetching: hasSearch
        });

        if (hasSearch) {

            const newAddresses = await getPointsBySearch(searchQuery, this.props.wbClientId);

            //const existingIds = this.state.addresses.map(a => a.id);

            if (newAddresses.length) {

                //const addresses = [...this.state.addresses, ...(newAddresses.filter(adr => !existingIds.includes(adr.id)))];
                const addresses = newAddresses;

                this.setState({
                    addresses,
                    addressSearchIsFetching: false
                });

            } else {
                this.setState({
                    addressSearchIsFetching: false
                });
            }
        }
    }

    buildPointData = (newState, address) => {
        newState.value = new WaybillPoint();
        newState.value.pointId = address.id;
        newState.value.pointFMID = address.fmid; // is used to
        newState.value.address = address.address.value || address.address.toString();
        newState.value.type = this.props.profile === "pointsLoading" ? WaybillPointTypeEnum.LOADING : WaybillPointTypeEnum.UNLOADING;
        newState.value.contact = (address.contacts && address.contacts.length && address.contacts[0]) || {};

        newState.value.companyName = address.companyName;
        if (address.companyLegalForm) {
            newState.value.companyName = `${address.companyLegalForm} ${newState.value.companyName}`;
        }
        newState.value.isHub = address.isHub;
    };

    async handlePointChange(e, dd) {

        const newState = {...this.state};

        if (dd.value) {
            const address = newState.addresses.find(adr => adr.id === dd.value);
            this.buildPointData(newState, address);
        } else {
            // deleted value
            newState.value = new WaybillPoint();
            newState.value.isHub = false;
        }

        this.setState(newState);
    }

    handleBookmarkPointChange = async (e, dd) => {

        const newState = {...this.state};

        if (dd.value) {
            const address = await getPointById(dd.value);

            const newAddresses = [...this.state.addresses, address];
            this.setState({
                value: {
                    id: address.id
                },
                address: newAddresses
            });
            this.buildPointData(newState, address);
        } else {
            // deleted value
            newState.value = new WaybillPoint();
            newState.value.isHub = false;
        }

        this.setState(newState);
    };

    addNewPoint = async (newPoint, alreadyCreated = false) => {

        const updateScope = {};

        // creating waibillPoint!
        if (!alreadyCreated) {
            newPoint.createdByClient = this.props.wbClientId;
        }
        let savedPoint;
        if (alreadyCreated) {
            savedPoint = newPoint;
        } else {
            savedPoint = await pointCreate(newPoint);
        }

        if (savedPoint) {
            newPoint.id = savedPoint.id;
            newPoint.address = new PointAddress(savedPoint.address);

            updateScope.addresses = [newPoint];

            updateScope.value = new WaybillPoint();
            updateScope.value.pointId = newPoint.id;
            updateScope.value.point = savedPoint;
            updateScope.value.address = newPoint.address.value || newPoint.address.toString();
            updateScope.value.companyName = newPoint.companyName;
            if (newPoint.companyLegalForm) {
                updateScope.value.companyName = `${newPoint.companyLegalForm} ${updateScope.value.companyName}`;
            }
            updateScope.value.contact = (newPoint.contacts && newPoint.contacts.length && newPoint.contacts[0]) || {};
            // the problem here is that we missing on the address!

            this.setState(updateScope);
        }
    };

    cargoUnitsCount = (points) => {
        let cargoUnitsCount = 0;
        points.forEach(point =>
            point.loadUnitSets.forEach(unit =>
                cargoUnitsCount += unit.loadUnitsCount)
        );
        return cargoUnitsCount;
    }

    async submit() {

        let wbPoint = {...this.state.value};
        const {onClose} = this.props;
        const {wb} = this.context;

        this.setState({isSubmitting: true});

        if (!wbPoint.id) {
            // when creating point for ftl -> we do not have any wbId -> need to submit wbPoint instead
            if (this.props.wbId && !this.props.isEdit) {
                wbPoint = await waybillPointCreate(this.props.wbId, wbPoint);
            }
            if (this.props.wbId && this.props.isEdit) {
                wbPoint = await waybillPointUpdate(this.props.wbId, {...wbPoint, id: this.props.wbPointId});
            }
        }

        if (wbPoint.type === WaybillPointTypeEnum.UNLOADING && wb.pointsUnloading.length === 0 && this.cargoUnitsCount(wb.pointsLoading) > 0){
            wbPoint.loadUnitsToOrderMappings = [
                {
                    pointToId: wbPoint.pointId,
                    loadUnitPositionStart: 1,
                    loadUnitPositionEnd: this.cargoUnitsCount(wb.pointsLoading),
                }
            ]
        }

        if (wbPoint.pointFMID) {
            await this.props.submit(wbPoint);
        } else {
            if (!wbPoint.point) {
                // for unregistered points we need to load point for editing on card
                // todo -> move loading into form inself
                wbPoint.point = await getPointById(wbPoint.pointId);
            }
            await this.props.submit(wbPoint);
        }

        this.setState({
            value: {},
            isSubmitting: false
        });

        onClose && onClose();
    }

    handleItemClick = (e, {id}) => {
        this.setState({
            activeTab: id
        });
    }

    render() {

        const {t} = this.props;

        const {
            value,
            addresses, addressSearchIsFetching,
            isSubmitting
        } = this.state;

        const valueIsOk = !!(value.pointId && value.companyName);

        const profile = this.props.isEdit ? profilesEdit[this.props.profile] : profiles[this.props.profile];

        const addressOptions = (addresses || [])
            .map(p => new Od(p.id, p.address.toString(), (p.pointName || p.companyName || '').trunc(50)));
        const searchTipsText = (
            <List bulleted>
                <List.Item>{t('waybills_text_search-address')} <em>{t('waybills_text_search-address-example')}</em></List.Item>
                <List.Item>{t('waybills_text_search-name-company')} <em>{t('waybills_text_search-name-company-example')}</em></List.Item>
                <List.Item>{t('waybills_text_quick-search-address')}</List.Item>
            </List>
        );

        const searchTips = (
            <Popup
                flowing
                trigger={<Icon name='info circle' color="blue" style={{fontSize: "1.1em"}}/>}
                content={
                    <>
                        <Header>{t('waybills_label_search-hint')}</Header>
                        <div>{t('waybills_text_for-search')} <em>{t('waybills_text_address-fm')}</em> {t('waybills_text_use-criteria')}</div>
                        {searchTipsText}
                        <div>{t('waybills_text_use-name-address')}<em>{t('waybills_text_use-name-address-example')}</em></div>
                    </>
                }
            />
        );

        const tab1 = (
            <>
                <Form.Field disabled={isSubmitting}>
                    <label>{t('waybills_label_search-address-fm')} {searchTips}</label>
                    <Dropdown
                        clearable
                        noResultsMessage={t('base_label_no_results')}
                        selection
                        search={this.searchTextAndDescription}
                        value={value.pointId || ''}

                        onSearchChange={this.handlePointSearchChange.bind(this)}
                        onChange={this.handlePointChange.bind(this)}

                        className="InputDropdown"
                        placeholder={t('waybills_placeholder_search-address-fm')}
                        fluid

                        closeOnChange

                        options={addressOptions || []}
                        loading={addressSearchIsFetching === true}
                    />
                </Form.Field>
                <Form.Field>
                    <label>{t('waybills_label_shipper')}</label>
                    <span>{value.companyName || <span className="tc-grey">{t('waybills_label_no-data')}</span>}</span>
                </Form.Field>
            </>
        );

        const tab2 = (
            <>
                <WbPointAddFormBookmarks
                    list={this.state.bookmarks}
                    onClick={(point) => {
                        this.handleBookmarkPointChange(null, {value: point.pointId});
                    }}
                />
            </>
        );

        const {activeTab} = this.state;

        const tabs = (
            <>
                <Menu fluid widths={2} tabular>
                    <Menu.Item id={tab1Name} active={activeTab === tab1Name} onClick={this.handleItemClick}>
                        <MenuHeader>{t(tab1Name)}</MenuHeader>
                    </Menu.Item>
                    <Menu.Item id={tab2Name} active={activeTab === tab2Name} onClick={this.handleItemClick}>
                        <MenuHeader>{t(tab2Name)}</MenuHeader>
                    </Menu.Item>
                </Menu>

                {activeTab === tab1Name ? tab1 : tab2}
            </>
        );

        return (
            <Modal
                trigger={this.props.children}
                closeOnDimmerClick={false}
                size="small"
                dimmer="inverted"
                closeIcon
                open={this.props.open}
                onOpen={this.handleOpen.bind(this)}
                onClose={this.handleClose.bind(this)}

            >
                <Modal.Header>{this.props.title || t(profile.addTitle) || t('waybills_label_title')}</Modal.Header>
                <Modal.Content>
                    <div className="m-b-10" style={{height: 230}}>
                        <Form
                            loading={this.state.loading}
                            disabled={this.state.isSubmitting}
                        >
                            {tabs}
                        </Form>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose.bind(this)}>{t('base_btn_cancel')}</Button>
                    <Button
                        loading={this.state.isSubmitting}
                        positive
                        onClick={this.submit.bind(this)}
                        disabled={!valueIsOk}>{this.props.isEdit ? t('base_btn_replace') : t('base_btn_add')}</Button>
                </Modal.Actions>
            </Modal>
        );
    }
};

export default withTranslation()(WbPointAddForm);
