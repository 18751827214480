import React from 'react';
import { Form, Icon } from 'semantic-ui-react';
import InputDadata from '../../../../../components/inputs/inputDadata_/InputDadata';
import { useTranslation } from "react-i18next";
import { PopupTitle } from '../../../../../components/popupTitle/PopupTitle';

const PointFormAddressDadata = ({ address, count, query, onChangeDD, onDadataInputChange, isDatataInvalid, ve }) => {
    const { t } = useTranslation();
	const tip = t("points_text_use_manual_address");
	const tipEl = <PopupTitle title={tip}><Icon name='info circle' color="blue" style={{ fontSize: "1.1em" }} /></PopupTitle>;

	return <div style={{ marginTop: 20 }} className="point-form-custom-opacity">
        <Form.Field error={isDatataInvalid} required>
            <label>{t("points_text_enter_address_by_one_string")} {tipEl}</label>
            <InputDadata
                token="0fb6ae1c0c887e551252998350e4447074ab0af0"
                query={query}
                count={count}
                autoload={!!query}
                placeholder={t("points_placeholder_enter_address_by_parts")}
                onChange={onChangeDD}
                onInputChange={onDadataInputChange}
            />
        </Form.Field>

        <Form.Group widths='equal'>
            <Form.Input
                required
                error={ve.includes("country")}
                label={t("points_input_country")}
                placeholder={t("points_input_country")}
                value={address.country || ''}
                disabled
            />
        </Form.Group>

        <Form.Group widths='equal'>
            <Form.Input
                required
                error={ve.includes("settlement")}
                label={t("points_input_locality_with_type")}
                placeholder={t("points_placeholder_locality")}
                value={address.settlement || ''}
                disabled
            />
            <Form.Input
                required
                error={ve.includes("postalCode")}
                label={t("points_input_index")}
                placeholder={t("points_input_index")}
                value={address.postalCode || ''}
                disabled
            />
            <Form.Input
                label={t("points_input_street_with_type")}
                placeholder={t("points_placeholder_street_including_type")}
                value={address.streetName || ''}
                disabled
            />
        </Form.Group>

        <Form.Group widths='equal' devided="">
            <Form.Input
                label={t("points_input_house_number_with_type")}
                placeholder={t("points_placeholder_house_number")}
                value={address.streetNo || ''}
                disabled
            />
            <Form.Input
                label={t("points_input_office")}
                placeholder={t("points_placeholder_office_or_apartment")}
                value={address.office || ''}
                disabled
            />
        </Form.Group>
    </div>;
};

export default PointFormAddressDadata;
