import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi, ContextUser} from "../../services/context";
import {Button, Checkbox, Icon, Label, Menu, Message, Modal, Popup, Table} from "semantic-ui-react";
import Shield from "../../layout/page/shield/Shield";
import {exportToExcel, getAll, rgCompleted, rgDelete} from "../../api/registries";
import {RegistrieStatusOptions, RegistrieStatusOptionsFromFilter} from "../../api/model/registries";
import useReactRouter from 'use-react-router';
import {dateUtc} from "../../api/banners";
import UserPermissions from "../../api/model/UserPermissions";
import qs from "query-string";
import iconClearFilters from "../waybills/wbGrid/WbGridHeadersClearFiltersIcon.png";
import WbGridFilterStringContains from "../waybills/wbGrid/wbGridFilters/WbGridFilterStringContains";
import WbGridFilterDatePeriod from "../waybills/wbGrid/wbGridFilters/WbGridFilterDatePeriod";
import WbGridFilterListMulti from "../waybills/wbGrid/wbGridFilters/WbGridFilterListMulti";
import { useTranslation } from 'react-i18next';

const headers = [
    {
        key: "state",
        text: 'registers_grid_status',
        sources: RegistrieStatusOptionsFromFilter,
        sortable: true,
    },
    {
        key: "clientCompany",
        text: 'registers_grid_client-name',
        sortable: true,
    },
    {
        key: "registryNumber",
        text: 'registers_grid_number',
        sortable: true,
    },
    {
        key: "period",
        text: 'registers_grid_period',
        sortable: true,
    },
    {
        key: "createdOn",
        text: 'registers_grid_created-date',
        sortable: true,
    },
    {
        key: "planDateAgreement",
        text: 'registers_grid_plan-date-agreement',
        sortable: true,
    },
    {
        key: "factDateAgreement",
        text: 'registers_grid_actual-date-agreement',
        sortable: true,
    },
    {
        key: "registrySumm",
        text: 'registers_grid_sum',
        sortable: true,
    },
    {
        key: "registrySummVAT",
        text: 'registers_grid_sum-vat',
        sortable: true,
    }
];

const noRows = (text) => (
    <div className="no-rows-wrapper">
        <div className="no-rows">{text}</div>
    </div>
);

const RgGrid = ({location}) => {
    const contextNavi = useContext(ContextNavi);
    const contextUser = useContext(ContextUser);
    const {history} = useReactRouter();

    let [rows, setRows] = useState([]);
    let [loaded, setLoaded] = useState(false);
    let [loading, setLoading] = useState(true);
    let [selectedIds, setSelectedIds] = useState(new Set());
    let [completeOpenModal, setCompleteOpenModal] = useState(false);
    let [completeProgress, setCompleteProgress] = useState(false);

    let [deleteOpenModal, setDeleteOpenModal] = useState(false);
    let [deleteProgress, setDeleteProgress] = useState(false);

    const intervalId = useRef();

    const getList = async () => {
        const result = await getAll(location.search);

        setRows(result);
    };

    const fetchData = (noLoader) => {
        setLoading(!noLoader);
        try {
            if (intervalId.current) clearInterval(intervalId.current);

            intervalId.current = setInterval(()  => {
                getList();
            }, 30000);

            getList();
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    };

    useEffect(() => {

        fetchData();

        return () => clearInterval(intervalId.current);
    }, []);

    useEffect(() => {
        contextNavi.setItems([new LinkInfo('registers_label_registers', "/registries")]);
    }, []);

    useEffect(() => {
        fetchData();
    }, [location.search]);

    let query = qs.parse(location.search);

    const {sortBy, sortDir} = query;

    function updateQuery(q) {
        query = q;
        history.push("?" + qs.stringify(query));
    }

    const handleSort = clickedColumn => () => {
        let {sortBy, sortDir} = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = "ascending";
        } else {
            sortDir = sortDir === "ascending" ? "descending" : "ascending";
        }

        updateQuery({...query, sortBy, sortDir});
    };

    function applyFilter(filter) {
        updateQuery({...query, ...filter});
        selectedIds.size && setSelectedIds(new Set());
    }

    function removeFilter(key) {
        updateQuery({...query, [key]: undefined});
        selectedIds.size && setSelectedIds(new Set());
    }

    function clearFilters() {
        updateQuery({});
        selectedIds.size && setSelectedIds(new Set());
    }

    const clearFilterButton = (
        <span
            className="table-clear-filters"
            onClick={clearFilters}
            style={{paddingTop: "5px", display: "inline-block", width: "17px"}}
        >
      <img src={iconClearFilters} alt="icf" style={{height: "13px"}}/>
    </span>
    );

    const getFilter = (key, sources) => {
        switch (key) {
            case "clientCompany":
            case "registryNumber":
            case "registrySumm":
            case "registrySummVAT":
                return (
                    <WbGridFilterStringContains
                        field={key}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                );
            case 'state':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={sources || []}/>;
            case "period":
            case "createdOn":
            case "planDateAgreement":
            case "factDateAgreement":
                return (
                    <WbGridFilterDatePeriod
                        field={key}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                );
            default:
                return <div className="filter-flex-container"/>;
        }
    };

    const notMassComplete = useMemo(() => {
        if (!selectedIds.size) {
            return true;
        } else {
            return !(
                rows.filter(
                    item => selectedIds.has(item.id) && item.isAvailableComplete
                ).length &&
                contextUser.current.permissions.includes(
                    UserPermissions.COMPLETION_REGISTRIES
                )
            );
        }
    }, [selectedIds, rows]);

    const handleRowClick = id => {
        history.push(`/registries/${id}`);
    };

    const deselect = () => {
        if (selectedIds.size) {
            setSelectedIds(new Set());
        } else {
            setSelectedIds(new Set(rows.map(item => item.id)));
        }
    };

    const handleRowSelection = id => {
        const newSelectedIds = new Set(selectedIds);

        newSelectedIds[!selectedIds.has(id) ? "add" : "delete"](id);

        setSelectedIds(newSelectedIds);
    };

    const handleComplete = () => {
        setCompleteOpenModal(true);
    };

    const handleDelete = () => {
        setDeleteOpenModal(true);
    };

    const handleConfirmComplete = async () => {
        setCompleteProgress(true);
        try {
            await rgCompleted(Array.from(selectedIds));
            setCompleteOpenModal(false);
            await fetchData();
        } finally {
            setCompleteProgress(false);
        }
    };

    const handleConfirmDelete = async () => {
        setDeleteProgress(true);
        try {
            await rgDelete(Array.from(selectedIds));
            setDeleteOpenModal(false);
            await fetchData();
        } finally {
            setDeleteProgress(false);
        }
    };

    const getCell = (key, val) => {
        switch (key) {
            case "state":
                const o = RegistrieStatusOptions.find(o => o.key === val);

                return o ? (
                    <Label
                        color={o.color}
                        style={{textAlign: "center", marginRight: "5px"}}
                        title={t(o.text)}
                    >
                        {t(o.text)}
                    </Label>
                ) : (
                    <Label style={{textAlign: "center", marginRight: "5px"}}>
                        {t(val)}
                    </Label>
                );
            /*
                              case "planDateAgreement":
                              case 'factDateAgreement':
                                  return val ? moment(val).format("DD.MM.YYYY HH:mm") : null;*/
            case "createdOn":
                return dateUtc(val);
            default:
                return val;
        }
    };

    const styleDisabledHeader = {
        background: "#f9fafb",
        cursor: "default"
    };

    const handleExportToExcel = async () => {
        const fileInfo = await exportToExcel(Array.from(selectedIds), query);
        if (!fileInfo.error) {
            window.open(`/api/file/${fileInfo.id}`, "_blank");
        }
    };

    const { t } = useTranslation();

    return (
        <Shield loading={loading} loadingOver={loaded}>
            <Menu
                className="waybills-toolbar shd-inset"
                style={{marginBottom: "0"}}
                size="small"
                borderless
            >
                <Menu.Menu position="right">
                    <Menu.Item disabled={notMassComplete} onClick={handleComplete}>
                        <Icon color="green" name="chevron circle down"/>
                        {t('registers_btn_to-status-approved')}
                    </Menu.Item>
                    <Menu.Item
                        disabled={
                            !(
                                contextUser.current.permissions.includes(
                                    UserPermissions.REGISTRIES_DELETE
                                ) && selectedIds.size > 0
                            )
                        }
                        onClick={handleDelete}
                    >
                        <Icon color="green" name="close"/>
                        {t('registers_btn_delete')}
                    </Menu.Item>
                    <Menu.Item onClick={handleExportToExcel}>
                        <Icon name="arrow up"/>
                        {t('registers_btn_exports-to-excel')}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <div className="table-wrapper-grid table-wrapper-grid-130 table-registry">
                <Table celled sortable singleLine>
                    <Table.Header className="table-header-fixed">
                        <Table.Row>
                            <Table.HeaderCell
                                className="table-first-col table-item-sticky table-registry-select-all"
                                style={{zIndex: "6"}}
                            >
                                <Checkbox
                                    checked={selectedIds.size === rows.length}
                                    indeterminate={
                                        selectedIds.size && selectedIds.size !== rows.length
                                    }
                                    onChange={deselect}
                                />
                            </Table.HeaderCell>
                            {headers.map(item => (
                                <Table.HeaderCell
                                    title={item.title}
                                    sorted={item.sortable && sortBy === item.key ? sortDir : null}
                                    style={item.sortable ? null : styleDisabledHeader}
                                    onClick={item.sortable ? handleSort(item.key) : null}
                                    className="table-select-cell table-header-fixed__title"
                                    key={item.key}
                                >
                                    {t(item.text)}
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                        <Table.Row key="filters" style={{height: "37px"}}>
                            <Table.Cell
                                className="table-first-col table-item-sticky table-registry-filter-clear"
                                style={{
                                    backgroundColor: "#f9fafb",
                                    textAlign: "center",
                                    paddingTop: "5px",
                                    paddingBottom: "5px"
                                }}
                            >
                                <Popup
                                    content={t('base_btn_clear-all-filters')}
                                    trigger={clearFilterButton}
                                />
                            </Table.Cell>
                            {headers.map(({key, sources}) => (
                                <Table.Cell
                                    key={key}
                                    sorted={sortBy === key ? sortDir : null}
                                    style={{background: "#fff;"}}
                                    className={"table-select-cell table-filter-fixed__title"}
                                >
                                    {getFilter(key, sources)}
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    {rows && rows.length ? (
                        <Table.Body>
                            {rows.map(row => (
                                <Table.Row key={row.id} style={{cursor: "pointer"}}>
                                    <Table.Cell className="table-first-col table-item-sticky">
                                        <Checkbox
                                            id={row.id}
                                            checked={selectedIds.has(row.id)}
                                            onChange={e => {
                                                e.stopPropagation();
                                                handleRowSelection(row.id);
                                            }}
                                        />
                                    </Table.Cell>
                                    {headers.map(column => (
                                        <Table.Cell
                                            key={`${column.key}_${row.id}`}
                                            onClick={() => handleRowClick(row.id)}
                                        >
                                            {getCell(column.key, row[column.key])}
                                        </Table.Cell>
                                    ))}
                                </Table.Row>
                            ))}
                        </Table.Body>
                    ) : (
                        <div className="table-empty"/>
                    )}
                </Table>

                {!rows.length && noRows(t('registers_label_no-rows'))}
            </div>
            <Modal open={completeOpenModal}>
                <Modal.Header>{t('registers_label_to-status-approved')}</Modal.Header>
                <Modal.Content>
                    <Message
                        icon="info"
                        info
                        content={
                            <div>
                                <div>
                                    {t('registers_text_to-status-approved-message-top')}
                                </div>
                                <div>
                                    {t('registers_text_to-status-approved-message-bottom')}
                                </div>
                            </div>
                        }
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setCompleteOpenModal(false)}>{t('base_btn_cancel')}</Button>
                    <Button
                        color="green"
                        loading={completeProgress}
                        onClick={handleConfirmComplete}
                    >
                        {t('registers_btn_to-approve')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal open={deleteOpenModal}>
                <Modal.Header>{t('registers_label_deletion')}</Modal.Header>
                <Modal.Content>
                    <Message
                        icon="info"
                        info
                        content={
                            <div>
                                <div>{t('registers_text_deletion-message-top')}</div>
                                <div>
                                    {t('registers_text_deletion-message-bottom')}
                                </div>
                            </div>
                        }
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setDeleteOpenModal(false)}>{t('base_btn_cancel')}</Button>
                    <Button
                        color="green"
                        loading={deleteProgress}
                        onClick={handleConfirmDelete}
                    >
                        {t('base_btn_delete')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Shield>
    );
};

export default RgGrid;
