import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Dropdown} from 'semantic-ui-react';
import O from '../Option';
import './InputDropdown.css';
import {useTranslation} from 'react-i18next';


InputDropdown.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape(O))
};

export default function InputDropdown({
                                          value,
                                          options = [],
                                          onChange,
                                          handleAdd,
                                          clearable = false,
                                          placeholder,
                                          style,
                                          disabled,
                                          multiple,
                                          fluid
                                      }) {
    const {t} = useTranslation();

    function handleChange(e, {value}) {
        onChange(value);
    };

    const optionsTranslate = useMemo(() => {
        return options.map(bto => ({
            ...bto,
            text: t(bto.text)
        }))
    }, [options, t]);

    // function handleAdd(e, { value }) {
    //     setOptions([new O(value, value), ...options]);
    //     setValue(value);
    // }

    return (
        <Dropdown
            noResultsMessage={t('base_label_no_results')}
            allowAdditions={!!handleAdd}
            style={style}
            className="InputDropdown"
            placeholder={placeholder || 'te'}
            value={value}
            onChange={handleChange}
            onAddItem={handleAdd}
            closeOnChange
            search={!!handleAdd}
            selection
            fluid={fluid}
            multiple={multiple}
            clearable={clearable}
            options={optionsTranslate}
            disabled={disabled}
        />
    );
}

