import moment from "moment";

export class PointTimeslot {

    constructor(str) {
        if (str) {
            if (typeof str === "object") {
            
                this.from = str.from;
                
                this.to = str.to;
            }
            if (typeof str == "string") {
                let segments = str.split('-');
            
                /**  @type {string} HH:mm*/
                this.from = segments[0];
                
                /**  @type {string} HH:mm*/
                this.to = segments.length === 2 ? segments[1] : null;
            }
        }
    }

	getDifferenceInHours() {
		let result = 0;
		if (this.from && this.to && this.from !== this.to) {
			const dfrom = new moment(this.from, 'HH:mm');
			const dto = new moment(this.to, 'HH:mm');
			result = moment.duration(dfrom, dto).asHours();
		}
		return result;
	}

    toString() {
        return `${this.from || ''}-${this.to || ''}`;
    }
}