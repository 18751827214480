import React, {useEffect, useState} from 'react';
import {Button, Grid, Icon, Table} from 'semantic-ui-react';
import {CutoffsLTLTimeAdd} from './CutoffsLTLTimeAdd';
import {CargoTypeOptions} from '../../../../../api/model/Enums';
import RowDeleteAction from './_shared/RowDeleteAction';
import {companyCutoffTimeLTLDelete, getCompanyCutoffs} from '../../../../../api/companyCutoffs';
import {useTranslation} from 'react-i18next';

export default function CutoffsLTLTime({match}) {
    const {t} = useTranslation();
    const [rows, setRows] = useState([]);

    function onAdd(row) {
        setRows([...rows, row]);
    }

    async function removeAsync(row) {
        await companyCutoffTimeLTLDelete(match.params.id, row);
    }

    function Row({row}) {
        return (
            <Table.Row>
                <Table.Cell>{row.cutoffHour}:{row.cutoffMinutes.toString().padStart(2, '0')}</Table.Cell>
                <Table.Cell>{t(CargoTypeOptions.getText(row.cargoType))}</Table.Cell>
                <Table.Cell>{row.isHubLoading ? t("waybillautocomplte_grid_crossdock") : t("waybillautocomplte_grid_clients_stock")}</Table.Cell>
                <Table.Cell textAlign="center"><RowDeleteAction {...{row, rows, setRows, removeAsync}} /></Table.Cell>
            </Table.Row>
        );
    }

    useEffect(() => {
        async function getCompanyCutoffsLocal() {
            const res = await getCompanyCutoffs(match.params.id, "ltl/time");
            setRows(res || []);
        }

        getCompanyCutoffsLocal();
    }, [match.params.id]);

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column align="right">
                        <CutoffsLTLTimeAdd onAdd={onAdd} companyId={match.params.id}>
                            <Button primary>
                                <Icon name="plus"/>
                                {t("users_grid_add-rule")}
                            </Button>
                        </CutoffsLTLTimeAdd>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={5}>{t("users_grid_cutoff-time")}</Table.HeaderCell>
                        <Table.HeaderCell width={5}>{t("users_grid_cargo-type")}</Table.HeaderCell>
                        <Table.HeaderCell width={5}>{t("users_grid_loading-point-type")}</Table.HeaderCell>
                        <Table.HeaderCell width={1} textAlign="center" />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((r, i) => <Row key={i} row={r}/>)}
                </Table.Body>
            </Table>
        </>
    );
}
